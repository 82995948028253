/*
=================================
CANDIDATE ASSESSMENT SERVICES
=================================
*/

import {
  candidateBasicFitURL,
  candidateCompleteAssessmentURL,
  candidateDomainKnowledgeURL,
  candidatePersonalityURL,
  candidateSoftSkillURL,
  candidateWorkMotivatorsURL,
  candidateWorkQualitiesURL,
  candidateWorkStyleURL,
  fetchBasicFitURL,
  fetchCandidateAssessmentInfoURL,
  getRequest,
  postRequest,
  startCandidateAssessmentURL,
} from "api";

/**
 * Fetch assessment information
 * @returns axios promise
 */

export const fetchCandidateAssessmentInfoService = (id: string) => {
  const requestData = {
    url: fetchCandidateAssessmentInfoURL(id),
  };
  return getRequest(requestData);
};

interface startCandidateAssessmentRequestData {
  assessment: string;
  email: string;
}
/**
 * Start assessment service
 * @returns axios promise
 */

export const startCandidateAssessmentService = (
  data: startCandidateAssessmentRequestData
) => {
  const requestData = {
    url: startCandidateAssessmentURL(),
    data,
  };
  return postRequest(requestData);
};

// type Base64 = string;

export type Base64<imageType extends string> =
  `data:image/${imageType};base64${string}`;

export interface candidateBasicFitRequestData {
  candidate: number;
  assessment: string;
  full_name: string;
  portfolio: string;
  phone_number: string;
  basic_expectation?: {
    location: string;
    exp_level: string;
    exp_years: string;
    emp_type: string;
    salary_expectation: number;
    exp_environment: string;
  };
  resume: Base64<"pdf">;
}
/**
 * Candidate basic fit service
 * @returns axios promise
 */

export const candidateBasicFitService = (
  data: candidateBasicFitRequestData
) => {
  const requestData = {
    url: candidateBasicFitURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Candidate fetch basic fit service
 * @returns axios promise
 */

export const candidateFetchBasicFitService = (id) => {
  const requestData = {
    url: `${candidateBasicFitURL()}?assessment=${id}`,
  };
  return getRequest(requestData);
};

export interface candidatePersonalityRequestData {
  id: string;
  data: {
    question1: {
      option: "A" | "B" | "C" | "D";
      rating: number;
    };
    question2: {
      option: "A" | "B" | "C" | "D";
      rating: number;
    };
    question3: number;
    question4: number;
    question5: number;
    question6: number;
    question7: number;
    question8: number;
    minutes_left: number;
  };
}

/**
 * Candidate personality service
 * @returns axios promise
 */

export const candidatePersonalityService = ({
  id,
  data,
}: candidatePersonalityRequestData) => {
  const requestData = {
    url: candidatePersonalityURL(id),
    data,
  };
  return postRequest(requestData);
};

export interface SoftSKillAdmin {
  question1: {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
  };
  question2: string;
}
export interface SoftSkillAnalytical {
  question1: {
    option: string;
    rating: number;
  };
  question2: number[];
}
export interface SoftSkillCreative {
  question1: number[];
  question2: string;
}
export interface SoftSkillEmpathy {
  question1: {
    a: number;
    b: number;
    c: number;
    d: number;
  };
  question2: string;
}
export interface SoftSkillLeadership {
  option: boolean;
  question1: number[] | number;
  question2: string | number;
}
export interface SoftSkillPersuasive {
  option: boolean;
  question1?: number[];
  question2?: string;
}
export interface SoftSKillEloquence {
  question1_others: null | string;
  question1: number;
  question2: number;
}
export interface SoftSkillTechCuriosity {
  question2: {
    tool: string;
    rating: number;
  }[];
  question1: number;
}
export interface SoftSkillLearningCuriosity {
  question1: number;
  question2: number;
}
export interface SoftSkillAgility {
  question1: number;
  question2: number;
}
export interface SoftSkillResearch {
  question1: {
    option: number;
    rating: number;
  };
}
export interface candidateSoftSkillResponses {
  administration?: SoftSKillAdmin;
  analytical_thinking?: SoftSkillAnalytical;
  creative_thinking?: SoftSkillCreative;
  empathy?: SoftSkillEmpathy;
  leadership_and_management?: SoftSkillLeadership;
  persuasion?: SoftSkillPersuasive;
  eloquence?: SoftSKillEloquence;
  technology_curiosity?: SoftSkillTechCuriosity;
  learning_curiosity?: SoftSkillLearningCuriosity;
  agility_work_ethic?: SoftSkillAgility;
  research?: SoftSkillResearch;
  minutes_left: number;
}

export interface candidateSoftSkillRequestData {
  id: string;
  data: candidateSoftSkillResponses;
}

/**
 * Candidate soft skill service
 * @returns axios promise
 */

export const candidateSoftSkillService = ({
  id,
  data,
}: candidateSoftSkillRequestData) => {
  const requestData = {
    url: candidateSoftSkillURL(id),
    data,
  };
  return postRequest(requestData);
};

/**
 * Fetch Candidate soft skill service
 * @returns axios promise
 */

export const fetchCandidateSoftSkillService = (id: string) => {
  const requestData = {
    url: candidateSoftSkillURL(id),
  };
  return getRequest(requestData);
};

export interface candidateWorkQualitiesRequestData {
  id: string;
  data: {
    question1: string;
    question2: string;
    minutes_left: number;
  };
}

/**
 * Candidate work qualities service
 * @returns axios promise
 */

export const candidateWorkQualitiesService = ({
  id,
  data,
}: candidateWorkQualitiesRequestData) => {
  const requestData = {
    url: candidateWorkQualitiesURL(id),
    data,
  };
  return postRequest(requestData);
};

export interface candidateWorkMotivatorsRequestData {
  id: string;
  data: {
    motivators: string[];
    minutes_left: number;
  };
}

/**
 * Candidate work motivators service
 * @returns axios promise
 */

export const candidateWorkMotivatorsService = ({
  id,
  data,
}: candidateWorkMotivatorsRequestData) => {
  const requestData = {
    url: candidateWorkMotivatorsURL(id),
    data,
  };
  return postRequest(requestData);
};

export interface candidateWorkStyleRequestData {
  id: string;
  data: {
    question1: {
      priority: number;
      visualizing: number;
      coordinating: number;
      planning: number;
    };
    question2: {
      priority: number;
      visualizing: number;
      coordinating: number;
      planning: number;
    };
    minutes_left: number;
  };
}

/**
 * Candidate work motivators service
 * @returns axios promise
 */

export const candidateWorkStyleService = ({
  id,
  data,
}: candidateWorkStyleRequestData) => {
  const requestData = {
    url: candidateWorkStyleURL(id),
    data,
  };
  return postRequest(requestData);
};

export interface SubmitDomainKnowledgeData {
  question: number;
  type: "open_ended" | "single_choice" | "multiple_choice";
  response: number | string | number[];
}

export interface candidateDomainKnowledgeRequestData {
  id: string;
  data: { domain_knowledge: SubmitDomainKnowledgeData[]; minutes_left: number };
}

/**
 * Candidate domain knowledge service
 * @returns axios promise
 */

export const candidateDomainKnowledgeService = ({
  id,
  data,
}: candidateDomainKnowledgeRequestData) => {
  const requestData = {
    url: candidateDomainKnowledgeURL(id),
    data,
  };
  return postRequest(requestData);
};

/**
 * Fetch candidate domain knowledge questions service
 * @returns axios promise
 */

export const fetchCandidateDomainKnowledgeService = (id: string) => {
  const requestData = {
    url: candidateDomainKnowledgeURL(id),
  };
  return getRequest(requestData);
};

interface candidateCompleteAssessmentRequestData {
  response_id: string;
  rating: number;
  feedback?: string;
  malpractice: boolean;
}

/**
 * Candidate complete assessment service
 * @returns axios promise
 */

export const candidateCompleteAssessmentService = (
  data: candidateCompleteAssessmentRequestData
) => {
  const requestData = {
    url: candidateCompleteAssessmentURL(),
    data: data,
  };
  return postRequest(requestData);
};

/**
 * Fetch basic expectation service
 * @returns axios promise
 */

export const fetchBasicFitService = (id: string) => {
  const requestData = {
    url: fetchBasicFitURL(id),
  };
  return getRequest(requestData);
};
