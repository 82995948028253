import { PathRouteProps } from "react-router-dom";
import { Routes } from "./routes";
import {
  Assessments,
  Billings,
  Candidates,
  Contact,
  CreateAssessment,
  Demo,
  EmailVerification,
  EmployerOverview,
  FAQ,
  ForgotPassword,
  FullReport,
  Home,
  NewPassword,
  PreviewAssessment,
  Pricing,
  PrivacyPolicy,
  PublicReport,
  Report,
  ReportRole,
  Reports,
  Settings,
  Signin,
  Signup,
  TermsOfUse,
  ViewAssessment,
} from "pages";
import { AuthLayout, EmployerDashboardLayout, LandingLayout } from "layout";

// Route Builder Item Props
export interface RouteBuilderItem extends PathRouteProps {
  Layout?: React.FC<any>; // If you wish to add a layout to the page
  Element: React.FC;
  props?: any;
  isProtected?: boolean;
}

/**
 * ROUTE BUILDER
 *
 * ===============================================
 *
 * This is a list of all our application routes.
 *
 * A single item on this list contains the necessary Route props needed by React Router to do it's job.
 *
 * If you wish to add a new route to the application,
 * just fulfill all the necessary props needed by the RouteBuilder item. Ciao!
 *
 */
export const RouteBuilder: RouteBuilderItem[] = [
  {
    path: Routes.home,
    Element: Home,
    Layout: LandingLayout,
    props: {
      active: "home",
    },
  },
  {
    path: Routes.pricing,
    Element: Pricing,
    Layout: LandingLayout,
    props: {
      active: "pricing",
    },
  },
  {
    path: Routes.faq,
    Element: FAQ,
    Layout: LandingLayout,
    props: {
      active: "faq",
    },
  },
  {
    path: Routes.contact,
    Element: Contact,
    Layout: LandingLayout,
  },
  {
    path: Routes.candidates,
    Element: Candidates,
    Layout: LandingLayout,
    props: {
      active: "candidates",
    },
  },
  {
    path: Routes.privacyPolicy,
    Element: PrivacyPolicy,
    Layout: LandingLayout,
  },
  {
    path: Routes.termsOfUse,
    Element: TermsOfUse,
    Layout: LandingLayout,
  },
  {
    path: Routes.demo,
    Element: Demo,
    Layout: LandingLayout,
  },
  // Auth
  {
    path: Routes.signup,
    Element: Signup,
    Layout: AuthLayout,
  },
  {
    path: Routes.emailVerification,
    Element: EmailVerification,
  },
  {
    path: Routes.signin,
    Element: Signin,
    Layout: AuthLayout,
  },
  {
    path: Routes.forgotPassword,
    Element: ForgotPassword,
    Layout: AuthLayout,
  },
  {
    path: Routes.newPassword,
    Element: NewPassword,
    Layout: AuthLayout,
  },
  {
    path: Routes.employerDashbord,
    Element: EmployerOverview,
    Layout: EmployerDashboardLayout,
    props: {
      active: "dashboard",
    },
    isProtected: true,
  },
  {
    path: Routes.employerAssessments,
    Element: Assessments,
    Layout: EmployerDashboardLayout,
    props: {
      active: "assessment",
    },
    isProtected: true,
  },
  {
    path: Routes.employerCreateAssessment,
    Element: CreateAssessment,
    Layout: EmployerDashboardLayout,
    props: {
      active: "assessment",
    },
    isProtected: true,
  },
  {
    path: Routes.employerEditAssessment,
    Element: CreateAssessment,
    Layout: EmployerDashboardLayout,
    props: {
      active: "assessment",
    },
    isProtected: true,
  },
  {
    path: Routes.employerReports,
    Element: Reports,
    Layout: EmployerDashboardLayout,
    props: {
      active: "report",
    },
    isProtected: true,
  },
  {
    path: Routes.employerReportRole,
    Element: ReportRole,
    Layout: EmployerDashboardLayout,
    props: {
      active: "report",
    },
    isProtected: true,
  },
  {
    path: Routes.employerReport(":id"),
    Element: Report,
    Layout: EmployerDashboardLayout,
    props: {
      active: "report",
    },
    isProtected: true,
  },
  {
    path: Routes.employerReportFull(":id"),
    Element: FullReport,
    isProtected: true,
  },
  {
    path: Routes.employerReportPublic({ id: ":id", role: ":role" }),
    Element: PublicReport,
  },
  {
    path: Routes.employerPreviewAssessment(":id"),
    Element: PreviewAssessment,
    isProtected: true,
  },
  {
    path: Routes.settings,
    Element: Settings,
    Layout: EmployerDashboardLayout,
    props: {
      active: "settings",
    },
    isProtected: true,
  },
  {
    path: Routes.billings,
    Element: Billings,
    Layout: EmployerDashboardLayout,
    props: {
      active: "billings",
    },
    isProtected: true,
  },

  // Candidates
  {
    path: Routes.assessmentOverview(":id"),
    Element: ViewAssessment,
  },

  {
    path: "*",
    Layout: AuthLayout,
    Element: Signin,
  },
];
