import { createSoftSkillsRequestData, createSoftSkillsService } from "api";
import { Loader } from "components";
import { SoftSkillData, SoftSkillsForm } from "features";
import { useToast, useApiRequest } from "hooks";
import { useState, useEffect, useMemo } from "react";

interface SoftSkillsProps {
  callback: (data: SoftSkillData) => void;
  softskill: SoftSkillData | undefined;
  skip: () => void;
  back: () => void;
  title: string;
}

const SoftSkills: React.FC<SoftSkillsProps> = ({
  callback,
  softskill: initSoftskill,
  skip,
  back,
  title,
}) => {
  const [softskill, setData] = useState<SoftSkillData | undefined>(
    initSoftskill
  );
  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");
  useEffect(() => {
    setData(initSoftskill);
  }, [initSoftskill]);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: reponse, requestStatus, error } = useApiRequest({});

  const onSubmit = (data: SoftSkillData) => {
    setData(data);

    const skills: any = {};
    data.skills.map((item) => (skills[item.value] = item.rating));

    const submitData: createSoftSkillsRequestData = {
      assessment: id,
      soft_skills: skills,
    };
    run(createSoftSkillsService(submitData));
  };

  useMemo(() => {
    if (reponse?.status === 201) {
      softskill && callback(softskill);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to submit your soft skill expectations, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [reponse, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <SoftSkillsForm
        softskill={softskill}
        submit={onSubmit}
        back={back}
        skip={skip}
        title={title}
      />
    </>
  );
};
export { SoftSkills };
