import {
  SubmitDomainKnowledgeData,
  fetchAssessmentDomainKnowledgeService,
  fetchCandidateDomainKnowledgeService,
} from "api";
import { Loader } from "components";
import {
  AssessmentDomainKnowledgeUI,
  PreviewDomainKnowledgeUI,
  PreviewDynamicInputProps,
} from "features";
import { useToast, useApiRequest } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

interface AssessmentDomainKnowledgeProps {
  callback: (metric: string) => void;
}

const AssessmentDomainKnowledge: React.FC<AssessmentDomainKnowledgeProps> = ({
  callback,
}) => {
  // Hooks
  const { openToast } = useToast();
  const { id } = useParams();

  // API Request Hooks
  const {
    run: runFetch,
    data: fetchResponse,
    requestStatus: fetchStatus,
    error: fetchError,
  } = useApiRequest({});

  const fetchQuestions = (id) =>
    runFetch(fetchAssessmentDomainKnowledgeService(id));

  useEffect(() => {
    if (id) fetchQuestions(id);
  }, []);

  const inputs = useMemo<PreviewDynamicInputProps[]>(() => {
    if (fetchResponse?.status === 200) {
      const data = fetchResponse.data.data;
      const questions: PreviewDynamicInputProps[] = [];

      data.question_groups.map((group) =>
        group.questions.map((question, index) => {
          questions.push({
            name: `${group.title}_${index}`,
            label: `${group.title}: ${question.question_content}`,
            type: question.question_type,
            _id: question.id,
            instruction: question.description,
            options: question.options.map((option) => ({
              label: option.content,
              value: option.id,
            })),
            image: question.image
              ? {
                  value: question.image,
                  description: question.image_description,
                }
              : undefined,
          });
        })
      );

      return questions;
    } else if (fetchError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          fetchError?.response?.data?.message ??
          "Failed to fetch questions, please contact support",
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [fetchResponse, fetchError]);

  const submit = (data: SubmitDomainKnowledgeData[]) => {
    const answered = parseInt(localStorage.getItem("answered") ?? "0");
    localStorage.setItem("answered", `${answered + inputs.length}`);

    callback("domain_knowledge");
  };

  return (
    <>
      <Loader loading={fetchStatus.isPending} />
      <PreviewDomainKnowledgeUI submit={submit} inputs={inputs} />
    </>
  );
};

export { AssessmentDomainKnowledge };
