const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getDateTime = (date: string) => {
  if (!date || date === "") {
    return {
      dateString: `---`,
      date: `---`,
      time: `---`,
    };
  }

  const dateD = new Date(date);

  const year = dateD.getUTCFullYear();
  const month = dateD.getUTCMonth();
  const day = dateD.getUTCDate();
  const hours = dateD.getUTCHours();
  const minutes = dateD.getUTCMinutes();

  return {
    dateString: `${day} ${months[month]} ${year}`,
    date: `${month + 1}/${day}/${year}`,
    time: `${hours < 10 ? 0 : ""}${hours}:${minutes < 10 ? 0 : ""}${minutes}`,
  };
};

export const isFutureDate = (date: string) => {
  const today = new Date().getTime();
  let date2 = new Date(date).getTime();

  return today < date2;
};

export const getTimeDifference = (dateString: string) => {
  const currentDate = new Date();
  const givenDate = new Date(dateString);
  const timeDifference = givenDate.getTime() - currentDate.getTime();

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDifference / 1000) % 60);

  const isFutureDate = timeDifference > 0;

  return {
    days,
    hours,
    minutes,
    seconds,
    isFutureDate,
  };
};

export const getDaysBetween = (date) => {
  const today = new Date();
  const other = new Date(date);

  // To calculate the time difference of two dates in milliseconds
  const timeDiff = other.getTime() - today.getTime();

  console.log(timeDiff);
  console.log(Math.floor(Math.abs(timeDiff) / (1000 * 60)));

  // To calculate the no. of days between two dates
  const seconds = timeDiff < 0 ? 0 : Math.floor(timeDiff / 1000);
  const minutes = timeDiff < 0 ? 0 : Math.floor(timeDiff / (1000 * 60));
  const hours = timeDiff < 0 ? 0 : Math.floor(timeDiff / (1000 * 60 * 60));
  const days = timeDiff < 0 ? 0 : Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  return { days, hours, minutes, seconds, isFutureDate: isFutureDate(date) };
};

export const getTimeBreakdown = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = hours > 0 ? Math.floor(mins - 60 * hours) : mins;
  const seconds = Math.floor((mins % 1) * 60);

  return {
    hours,
    minutes,
    seconds,
  };
};
