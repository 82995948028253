/*
=================================
AUTH URLS
=================================
*/

import { appendParams } from "helpers";

/**
 * Signup url
 *
 * @returns url string
 *
 */

export const signupURL = () => `/auth/signup/`;

/**
 * Refresh token url
 *
 * @returns url string
 *
 */

export const refreshTokenURL = () => `/auth/token/refresh/`;

/**
 * Login url
 *
 * @returns url string
 *
 */

export const loginURL = () => `/auth/login/`;

/**
 * Logout url
 *
 * @returns url string
 *
 */

export const logoutURL = () => `/auth/logout/`;

/**
 * Verify email url
 *
 * @returns url string
 *
 */

export const verifyEmailURL = () => `/auth/confirm-email/`;

/**
 * Frogot password url
 *
 * @returns url string
 *
 */

export const forgotPasswordURL = ({ email }) =>
  `/auth/reset-password/?${appendParams({ email })}`;

/**
 * Reset password url
 *
 * @returns url string
 *
 */

export const resetPasswordURL = () => `/auth/reset-password/`;

/**
 * Resend signup verification mail url
 *
 * @returns url string
 *
 */

export const resendVerificationURL = ({email}) =>
  `/auth/resend-email-verification/?${appendParams({ email })}`;
