import { WhatsappIcon } from "assets";
import styles from "./styles.module.scss";

const Support = ({ text, message }: { text?: string; message?: string }) => {
  return (
    <>
      <a
        className={styles.whatsapp}
        href={`http://wa.me/2348031301167?text=${message}`}
        target="blank"
      >
        <WhatsappIcon />
        {text && <p>{text}</p>}
      </a>
    </>
  );
};

export { Support };
