/*
=================================
CONTACT URLS
=================================
*/

/**
 * Contact Us URL url
 *
 * @returns url string
 *
 */

export const contactUsURL = () => `/contact-us`;
