import { useState } from "react";
import styles from "./styles.module.scss";
import { Plan, PlanProps } from "./plan";
import { BillingHistoryProps, History } from "./history";
import { Payment, PaymentProps } from "./payment";
import { useAppSelector } from "state/hooks";
import { useDispatch } from "react-redux";
import { updatePreference } from "state";

interface BillingsProps {
  plan: PlanProps;
  view: string;
  handleView: (view: string) => void;
  payment: PaymentProps;
  history: BillingHistoryProps;
}

const BillingsUI: React.FC<BillingsProps> = ({
  plan,
  view,
  handleView,
  payment,
  history,
}) => {
  const dispatch = useDispatch();
  const { currency } = useAppSelector((state) => state.preference);

  const setCurrency = (currency: string) => {
    dispatch(updatePreference({ currency }));
  };

  return (
    <>
      <p className={styles.txt}>Manage your billing and payment details</p>
      {view !== "payment" && (
        <section className={styles.navSec}>
          <div
            className={`${styles.navSec__tabs} ${styles["navSec__tabs--1"]}`}
          >
            <button
              onClick={() => handleView("plan")}
              className={view === "plan" ? styles.active : ""}
            >
              My plan
            </button>
            <button
              onClick={() => handleView("history")}
              className={view === "history" ? styles.active : ""}
            >
              History
            </button>
          </div>

          {view === "plan" && (
            <div
              className={`${styles.navSec__tabs} ${styles["navSec__tabs--2"]}`}
            >
              <button
                onClick={() => setCurrency("USD")}
                className={currency === "USD" ? styles.active : ""}
              >
                USD
              </button>
              <button
                onClick={() => setCurrency("NGN")}
                className={currency === "NGN" ? styles.active : ""}
              >
                NGN
              </button>
            </div>
          )}
        </section>
      )}
      {view === "plan" ? (
        <Plan {...plan} currency={{ value: currency, onChange: setCurrency }} />
      ) : view === "history" ? (
        <History {...history} />
      ) : view === "payment" ? (
        <Payment {...payment} />
      ) : (
        ""
      )}
    </>
  );
};

export { BillingsUI };
