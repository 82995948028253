const copyToClipBoard = async ({ link, onSuccess, onError }) => {
  await navigator.clipboard
    .writeText(link)
    .then((res) => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });
};

export { copyToClipBoard };
