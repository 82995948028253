import { Button, Input, Modal } from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import {
  AddIconCircle,
  CloseIcon,
  CopyIcon2,
  DocumentIcon,
  EyeOpenIcon,
  TickIcon,
  TrashIcon,
  UploadIcon,
} from "assets";
import { useState } from "react";
import { getMegaByte } from "helpers";
import { inviteCandidatesRequestData } from "api";

interface InviteData {
  email: string;
  name: string;
}

interface InviteCandidateDetails {
  candidates: InviteData[];
}

const schema = yup
  .object({
    candidates: yup
      .array()
      .of(
        yup
          .object({
            email: yup
              .string()
              .required("Required")
              .email("Enter a valid email"),
            name: yup.string().required("Required"),
          })
          .required()
      )
      .required()
      .min(1, "Add at least one candidate"),
  })
  .required();

interface Props {
  show: boolean;
  close: () => void;
  title: string;
  text?: string;
  links?: {
    preview: string;
    candidate: string;
  };
  submit: (data: inviteCandidatesRequestData[]) => void;
  submitCSV: (data: FormData) => void;
}

const InviteCandidateModal: React.FC<Props> = ({
  show,
  close,
  title,
  text,
  links,
  submit,
  submitCSV,
}) => {
  const [csv, setCsv] = useState<File | undefined>(undefined);
  const [showBulk, setShowBulk] = useState(false);
  const [copied, setCopied] = useState(false);
  const [csvError, setCSVError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<InviteCandidateDetails>({
    resolver: yupResolver(schema),
    defaultValues: { candidates: [{ email: "", name: "" }] },
  });
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "candidates",
  });

  const onAppend: SubmitHandler<InviteCandidateDetails> = () => {
    append({ email: "", name: "" });
  };
  const onSubmit: SubmitHandler<InviteCandidateDetails> = (data) => {
    submit(
      data.candidates.map((item) => ({
        email: item.email,
        full_name: item.name,
      }))
    );
  };

  const handleUpload = (e) => {
    const doc = e.target.files[0];
    if (doc) setCsv(doc);
  };

  const handleSubmitCSV = () => {
    if (csv) {
      const formData: FormData = new FormData();
      formData.append("file", csv, csv?.name);
      setCSVError("");
      submitCSV(formData);
    } else {
      setCSVError("Please upload a csv file");
    }
  };

  const copyToClipBoard = async (link) => {
    await navigator.clipboard
      .writeText(link)
      .then((res) => {
        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch(() => {
        setCopied(false);
      });
  };

  const template =
    "https://res.cloudinary.com/dvpn332n6/raw/upload/v1704801006/Precision_Bulk_Invite_Template_-_Sheet_ngmcja.csv";
  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <div className={styles.header}>
          <h1 className={styles.header__ttl}>
            {showBulk ? "Bulk Invite" : title}
          </h1>
          {text || showBulk ? (
            <p className={styles.header__txt}>
              {showBulk ? (
                <>
                  Download our
                  <a target="_blank" href={template}>
                    template
                  </a>
                  and upload here to bulk invite your candidates.
                </>
              ) : (
                text
              )}
            </p>
          ) : (
            ""
          )}
        </div>
        {links ? (
          <section className={styles.links}>
            <div className={styles.links__ctas}>
              <a target="_blank" href={links?.preview}>
                <EyeOpenIcon /> Preview link
              </a>
              <button onClick={() => copyToClipBoard(links.candidate)}>
                {copied ? <TickIcon /> : <CopyIcon2 />}
                {copied ? "Copied!" : "Assessment link"}
              </button>
            </div>
            <p className={styles.links__txt}>Invite candidates</p>
          </section>
        ) : (
          ""
        )}
        <section>
          {showBulk ? (
            <section className={styles.bulk}>
              <label
                role="button"
                className={styles.bulk__upload}
                htmlFor="csv"
              >
                <DocumentIcon />{" "}
                <p>
                  {csv?.name ? (
                    <>
                      {csv?.name} <br /> {getMegaByte(csv.size)} MB
                    </>
                  ) : (
                    <>
                      Select to upload file <br />
                      csv
                    </>
                  )}
                </p>
                <input
                  style={{ display: "none" }}
                  onChange={handleUpload}
                  id="csv"
                  type="file"
                  accept=".csv"
                />
              </label>
              {csvError !== "" && (
                <p className={styles.bulk__error}>{csvError}</p>
              )}
              <div className={styles.ctaSec2}>
                <Button
                  className={styles.cancel}
                  type={"outline"}
                  onClick={() => {
                    setCsv(undefined);
                    setShowBulk(false);
                    setCSVError("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.submit}
                  type={"fill"}
                  onClick={handleSubmitCSV}
                >
                  Send invite
                </Button>
              </div>
            </section>
          ) : (
            <form className={styles.form}>
              {fields.map((item, index) => {
                return (
                  <fieldset key={item.id} className={styles.fieldset}>
                    <Input
                      label="Name"
                      placeholder="Please input"
                      type="text"
                      required
                      validatorMessage={
                        errors?.candidates
                          ? errors?.candidates[index]?.name?.message
                          : ""
                      }
                      name={`candidates.${index}.name`}
                      register={register}
                      parentClassName={styles.inputWrap}
                    />
                    <Input
                      label="Email"
                      placeholder="Please input"
                      type="email"
                      required
                      validatorMessage={
                        errors?.candidates
                          ? errors?.candidates[index]?.email?.message
                          : ""
                      }
                      name={`candidates.${index}.email`}
                      register={register}
                      parentClassName={styles.inputWrap}
                    />
                    <TrashIcon
                      className={styles.remove}
                      role="button"
                      onClick={() => {
                        if (fields.length === 1) reset();
                        else remove(index);
                      }}
                    />
                  </fieldset>
                );
              })}
              <div className={styles.ctaSec1}>
                <Button
                  className={styles.newCandidate}
                  type="transparent"
                  onClick={handleSubmit(onAppend)}
                >
                  <AddIconCircle />
                  New candidate
                </Button>

                <Button
                  className={styles.bulkInvite}
                  type="transparent"
                  onClick={() => setShowBulk(true)}
                >
                  <UploadIcon />
                  Bulk invite
                </Button>
              </div>
              <div className={styles.ctaSec2}>
                <Button
                  className={styles.cancel}
                  type={"outline"}
                  onClick={() => {
                    reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.submit}
                  type={"fill"}
                  onClick={handleSubmit(onSubmit)}
                >
                  Send invite
                </Button>
              </div>
            </form>
          )}
        </section>
      </Modal>
    </>
  );
};

export { InviteCandidateModal };
