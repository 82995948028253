import { Assessment } from "./assessment";
import { FAQs } from "./faqs";
import { HeroSection } from "./heroSection";
import { Review } from "./review";
import styles from "./styles.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const CandidatesUI = () => {
  useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 1000,
      offset: 100,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <section className={styles.bg}>
        <HeroSection />
        <Review />
        <Assessment />
        <FAQs />
      </section>
    </>
  );
};

export { CandidatesUI };
