import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import { Input } from "components/form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { topupPricing } from "utils/pricing";

interface ExtendAccessData {
  days: string;
}

const schema = yup
  .object({
    days: yup
      .string()
      .required("Required")
      .matches(/\d/, "Enter a valid number"),
  })
  .required();

interface Props {
  show: boolean;
  close: () => void;
  handleBuy: (days: number) => void;
}

const ExtendAccessModal: React.FC<Props> = ({ show, close, handleBuy }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ExtendAccessData>({
    resolver: yupResolver(schema),
    defaultValues: { days: undefined },
  });

  const days = watch("days");

  const onSubmit: SubmitHandler<ExtendAccessData> = (data) => {
    handleBuy(parseInt(data.days));
  };

  const amount = topupPricing.costPerDay * parseInt(days);

  return (
    <>
      <Modal contentClassName={styles.modal} show={show} close={close}>
        <h1 className={styles.ttl}>Extend Access</h1>
        <p className={styles.txt1}>Extend your subscription for quick access</p>
        <p className={styles.txt2}>${topupPricing.costPerDay} per day</p>
        <Input
          label="How many days access do you want?"
          placeholder={"Enter the number of days"}
          name={"days"}
          validatorMessage={errors.days?.message}
          register={register}
          type="number"
        />
        <p className={styles.txt3}>${days ? amount.toFixed(2) : 0}</p>
        <div className={styles.btnSec}>
          <button className={styles.btnSec__close} onClick={close}>
            Cancel
          </button>
          <button
            className={styles.btnSec__upgrade}
            onClick={handleSubmit(onSubmit)}
          >
            Buy Now
          </button>
        </div>
      </Modal>
    </>
  );
};

export { ExtendAccessModal };
