import { PreviewWorkQualitiesUI } from "features";

interface AssessmentWorkQualitiesProps {
  callback: (metric: string) => void;
}

const AssessmentWorkQualities: React.FC<AssessmentWorkQualitiesProps> = ({
  callback,
}) => {
  const submit = (data) => {
    const answered = parseInt(localStorage.getItem("answered") ?? "0");

    localStorage.setItem("answered", `${answered + 2}`);

    callback("work_qualities");
  };

  return (
    <>
      <PreviewWorkQualitiesUI submit={submit} />
    </>
  );
};

export { AssessmentWorkQualities };
