import * as yup from "yup";

export interface OptionType {
  label: string;
  value: any;
}

export const initOptionType: OptionType = { label: "", value: "" };

export const optionTypeSchema = (msg?: string) =>
  yup.object({
    label: yup.string().required(msg ?? "Required"),
    value: yup.string().required(msg ?? "Required"),
  });
