import {
  fetchAssessmentInfoService,
  fetchDomainKnowledgeService,
  fetchRoleReportsService,
  moveReportRequestData,
  moveReportService,
} from "api";
import {
  AutoScorePromptModal,
  ConfirmationModal,
  Loader,
  OptionType,
  ReportRoleTableItem,
  initOptionType,
} from "components";
import { ReportRoleUI } from "features";
import { getDateTime, queryObject } from "helpers";
import { useApiRequest, useDebounce, useToast } from "hooks";
import { Navbar } from "layout";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { AIScoring } from "./aiScoring";
import { bucketOptions } from "utils/options";

const ReportRole = () => {
  // States
  const [pages, setPages] = useState({
    total: 0,
    count: 0,
    current: 1,
  });
  const [status, setStatus] = useState("assessed");
  const [search, setSearch] = useState("");
  const [score, setScore] = useState<OptionType>(initOptionType);
  const [progress, setProgress] = useState<OptionType>(initOptionType);
  const [scoring, setScoring] = useState<{
    show: boolean;
    assessment: number;
    candidates: number[];
    buckets: string[];
  }>({
    show: false,
    assessment: -1,
    candidates: [],
    buckets: [],
  });
  const [movePrompt, setMovePrompt] = useState<{
    show: boolean;
    data: moveReportRequestData | undefined;
  }>({
    show: false,
    data: undefined,
  });

  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(search, 500);
  const { id } = useParams();
  const { state, search: urlSearch } = useLocation();

  const urlSearchObj = queryObject(urlSearch);
  const role = urlSearchObj?.role?.replaceAll("_", " ");

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});
  const {
    run: runMove,
    data: moveResponse,
    requestStatus: moveStatus,
    error: moveError,
  } = useApiRequest({});
  const {
    run: runDK,
    data: dkResponse,
    requestStatus: dkStatus,
    error: dkError,
  } = useApiRequest({});
  const {
    run: runAssessment,
    data: assessmentResponse,
    requestStatus: assessmentStatus,
    error: assessmentError,
  } = useApiRequest({});

  console.log(assessmentResponse?.data?.title);
  const fetchDomainKnowledge = () =>
    id && runDK(fetchDomainKnowledgeService(id));

  const fetchReports = ({ page, bucket }: { page?; bucket? }) =>
    id &&
    run(
      fetchRoleReportsService({
        page: page ?? pages.current,
        bucket: bucket ?? status,
        search,
        assessment: id,
        progress: progress.value,
        lower_score: score.value
          .replaceAll(" ", "")
          .replace("%", "")
          .split("-")[1],
        upper_score: score.value
          .replaceAll(" ", "")
          .replace("%", "")
          .split("-")[0],
      })
    );

  const fetchAssessment = () =>
    id && runAssessment(fetchAssessmentInfoService(id));

  useEffect(() => {
    fetchReports({});
  }, [id, debouncedSearchTerm, progress, score]);

  useEffect(() => {
    fetchDomainKnowledge();
    fetchAssessment();
  }, [id]);

  useEffect(() => {
    if (state?.autoScore && id) {
      setScoring({
        show: true,
        assessment: parseInt(id),
        candidates: [],
        buckets: [],
      });
    }
  }, [state]);

  const reports = useMemo<ReportRoleTableItem[]>(() => {
    if (response?.status === 200) {
      setPages({
        ...pages,
        total: Math.ceil(response.data.count / 8),
        count: response.data.count,
      });

      return response.data.results.map((item) => ({
        id: item.id,
        candidate: item.full_name,
        date: getDateTime(item.updated_at).date,
        time: getDateTime(item.updated_at).time,
        email: item.email,
        phone: item.phone_number,
        cv: item.resume,
        portfolio: item.portfolio,
        score: parseInt(item.total_score),
        isAutoScored: item.auto_scored,
      }));
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Unable to fetch reports, please refresh the page or try again later",
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [response, error]);

  const isDefault = useMemo<boolean>(() => {
    if (dkResponse?.status === 200) {
      return !dkResponse.data.data.use_custom;
    }
    return false;
  }, [dkResponse, dkError]);

  const handleView = (reportId) => {
    navigate(Routes.employerReport(reportId));
  };

  const handlePages = (page) => {
    fetchReports({ page });

    setPages((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const handleMoveReports = (data: moveReportRequestData) => {
    runMove(moveReportService(data));
  };

  useMemo(() => {
    if (moveResponse?.status === 200) {
      fetchReports({ page: 1 });
    } else if (moveError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          moveError?.response?.data?.error?.message ??
          "Unable to move candidate, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [moveResponse, moveError]);

  const handleStatus = (status) => {
    setStatus(status);
    fetchReports({ page: 1, bucket: status });

    setPages((prev) => ({
      ...prev,
      current: 1,
    }));
  };

  const [chooseBuckets, setChooseBuckets] = useState(false);

  const showLoader = requestStatus.isPending || moveStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <AIScoring
        {...scoring}
        close={() =>
          setScoring({
            show: false,
            assessment: -1,
            candidates: [],
            buckets: [],
          })
        }
      />
      <AutoScorePromptModal
        show={chooseBuckets}
        close={() => setChooseBuckets(false)}
        handleSubmit={(buckets) => {
          setChooseBuckets(false);
          setScoring((prev) => ({ ...prev, show: true, buckets }));
        }}
      />
      <ConfirmationModal
        show={movePrompt.show}
        close={() => setMovePrompt({ show: false, data: undefined })}
        handleContinue={() =>
          movePrompt.data && handleMoveReports(movePrompt.data)
        }
        title={"Move Candidate(s)"}
        text={`Are you sure you want to move ${
          movePrompt?.data?.selected_responses?.length &&
          movePrompt?.data?.selected_responses?.length > 1
            ? "these candidates"
            : "this candidate"
        } to the ${
          bucketOptions.find(
            (item) => item.value === movePrompt?.data?.new_bucket
          )?.label
        } bucket?`}
      />
      <Navbar
        search={{
          value: search,
          placeholder: "Search",
          handleChange: setSearch,
        }}
        title={"Track & View Reports"}
      />
      <ReportRoleUI
        reports={reports}
        handleView={handleView}
        pagination={{
          handleChange: handlePages,
          ...pages,
        }}
        status={{
          value: status,
          onChange: handleStatus,
        }}
        progress={{
          value: progress,
          onChange: setProgress,
        }}
        score={{
          value: score,
          onChange: setScore,
        }}
        handleBucket={(data) => setMovePrompt({ show: true, data })}
        role={assessmentResponse?.data?.title ?? ""}
        handleScoring={(candidates) => {
          if (id) {
            setChooseBuckets(true);
            setScoring({
              show: false,
              assessment: parseInt(id),
              candidates: candidates ?? [],
              buckets: [],
            });
          }
        }}
        isDefault={isDefault}
      />
    </>
  );
};

export { ReportRole };
