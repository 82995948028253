import { useState } from "react";
import styles from "./styles.module.scss";
import { FAQItemData, FAQItem, ExperiencePrecision } from "components";
import { Link } from "react-router-dom";
import { Routes } from "router";

const FAQUI = () => {
  const [tab, seTab] = useState("employer");
  const [active, setActive] = useState(-1);
  const [category, setCategory] = useState(0);

  // const faqs: FAQItemData[] = [
  //   {
  //     question: "What is precision?",
  //     answer: "The only thing you need",
  //   },
  //   {
  //     question: "What is precision?",
  //     answer: "The only thing you need",
  //   },
  //   {
  //     question: "What is precision?",
  //     answer: "The only thing you need",
  //   },
  //   {
  //     question: "What is precision?",
  //     answer: "The only thing you need",
  //   },
  //   {
  //     question: "What is precision?",
  //     answer: "The only thing you need",
  //   },
  //   {
  //     question: "What is precision?",
  //     answer: "The only thing you need",
  //   },
  // ];

  // const candidateFaq = [
  //   {
  //     title: "Assessment Process",
  //     faqs,
  //   },
  //   {
  //     title: "Assessment Result",
  //     faqs,
  //   },
  //   {
  //     title: "Candidate Experience ",
  //     faqs,
  //   },
  //   {
  //     title: "Retaking Assessment",
  //     faqs,
  //   },
  //   {
  //     title: "Privacy & Data Security",
  //     faqs,
  //   },
  //   {
  //     title: "Data Security",
  //     faqs,
  //   },
  //   {
  //     title: "Reporting and Analytics",
  //     faqs,
  //   },
  //   {
  //     title: "Customization",
  //     faqs,
  //   },
  // ];

  // const employerFaq = [
  //   {
  //     title: "Assessment Process",
  //     faqs,
  //   },
  //   {
  //     title: "Assessment Result",
  //     faqs,
  //   },
  //   {
  //     title: "Candidate Experience ",
  //     faqs,
  //   },
  //   {
  //     title: "Retaking Assessment",
  //     faqs,
  //   },
  //   {
  //     title: "Privacy & Data Security",
  //     faqs,
  //   },
  //   {
  //     title: "Data Security",
  //     faqs,
  //   },
  //   {
  //     title: "Reporting and Analytics",
  //     faqs,
  //   },
  //   {
  //     title: "Customization",
  //     faqs,
  //   },
  // ];

  // const categories = tab === "employer" ? employerFaq : candidateFaq;

  const candidateFaq: FAQItemData[] = [
    {
      question: "How do I begin the assessment?",
      answer:
        "You would get an invite on your email or the recruiter would send a link to you. When you click on the link, you will be directed to the assessment, with the instruction page first displayed, once this is understood, you can enter your email address and get started. ",
    },
    {
      question: "Is the assessment timed?",
      answer:
        "Yes. After the instructions screen and you have entered your email address, the questions are displayed and your time starts counting.",
    },
    {
      question: "What do I expect during the assessment?",
      answer:
        "Our assessment is to test your work culture, personality, and technical skills. You can expect questions in these areas.",
    },
    {
      question: "Can I pause my assessment and come back to it?",
      answer: (
        <>
          No, you can’t. Once, you have gone through the instructions and
          started your assessment, you are expected to complete the assessment
          within the time provided without exiting.
          <br />
          If you encounter any issues while testing, kindly reach out to us at
          contact@precisionmatch.ai
        </>
      ),
    },
    {
      question: "Can I view my assessment score? ",
      answer:
        "No, the report on your assessment would be viewed only by the recruiter. ",
    },
    {
      question: "I can’t link my CV, what should I do?",
      answer:
        "You can also input the link to your portfolio and any other relevant link that would show your work experience so far. ",
    },
    {
      question:
        "How can I prepare for Precision screening to maximize my chances of success?",
      answer: `To optimize your chances, ensure that your resume and application materials accurately reflect your skills, experiences, and qualifications. Be authentic in your responses and showcase your technical and cultural alignment with the company requirements for the role`,
    },
  ];

  const employerFaq: FAQItemData[] = [
    {
      question: "What is Precision all about?",
      answer:
        "Precision is an AI-powered solution that empowers recruiters, companies, and hiring teams to eliminate talent mismatches and build a high-performing team effortlessly for better business success",
    },
    {
      question: "Where is the service available right now?",
      answer: "This service is available for use to all companies worldwide",
    },
    {
      question: "How do I access help or support when needed?",
      answer:
        "You can reach us across all social media accounts and mail at contact@precisionmatch.ai ",
    },
    {
      question: "Can I get a demo of the application? ",
      answer:
        "Yes, you can send us a message via the website with the form provided or send an email to contact@precisionmatch.ai ",
    },
    {
      question:
        "Can Precision be customized to fit my company's specific needs and culture?",
      answer:
        "Yes, Precision can be tailored to align with your company's unique culture and hiring requirements. Our platform allows for the customization of evaluation criteria and weighting, ensuring that the screening process reflects your organization's values and preferences.",
    },
    {
      question: "How does Precision improve hiring efficiency?",
      answer:
        "Precision streamlines the hiring process by automating candidate screening and evaluation. By analyzing various data points and providing insights into candidates' cultural alignment and suitability for specific roles, Precision enables HR managers to make more informed hiring decisions quickly and efficiently, reducing time-to-hire and minimizing resources spent on manual screening.",
    },
    {
      question: "Does Precision serve only hiring needs?",
      answer:
        "While Precision primarily focuses on pre-employment screening and facilitating the hiring process, it can also be utilized for other talent management purposes. For example, it can assist in identifying internal candidates for promotions or transfers, assessing training needs, and enhancing overall workforce planning and development strategies",
    },
    {
      question:
        "Can I guard against candidates’ malpractice during the assessment?",
      answer:
        "Precision employs advanced algorithms and data analysis techniques to assess candidates' suitability for specific roles and cultural alignment with your organization. It’s designed to minimize the possibility of cheating or gaming the system, including safeguards and validation measures to enhance the accuracy and integrity of the assessment process.",
    },
    {
      question: "How are candidates classified?",
      answer:
        "Talent to role fitness based on various criteria, including qualifications, experiences, skills, and cultural fit with your organization. Precision AI and talent matching algorithms analyze an assessed talent to role fitness based on defined metrics and categorize them into Excellent fit, Effective fit,  Average fit, and Low fit, enabling HR managers to prioritize and focus on the most promising candidates for further consideration.",
    },
    {
      question: "What’s the typical assessment length?",
      answer:
        "The assessment length varies depending on the complexity of the role and the customization settings configured by the employer. Typically, candidates can expect the assessment to take anywhere from 30 minutes to an hour to complete. However, the precise duration may vary based on individual factors and the specific requirements of the position.",
    },
    {
      question: "Can I access my talent pool?",
      answer:
        "Yes, Precision provides employers with access to their talent pool, allowing them to review and manage candidate profiles, assessment results, and other relevant information.",
    },
    {
      question: "I have a high hiring frequency. Who should I contact?",
      answer:
        "If you have a high hiring frequency or specific requirements for your talent acquisition needs, please reach out to our team at contact@precisionmatch.ai.",
    },
    {
      question:
        "Do I get to customize the assessment to my organization’s need at a time? ",
      answer:
        "Yes, the whole aim of Precision is to allow you to specify the kind of talent you want, and the work culture you are looking out for and assess candidates based on these metrics. You get to customize the metrics to suit your organization.",
    },
    {
      question: "How secure are our data on the platform?",
      answer:
        "All data of the organisation are retained with the approval of the organisation and are not shared with a third party. Every organization reserves the right to get their data deleted when they choose",
    },
  ];
  const faqs = tab === "employer" ? employerFaq : candidateFaq;
  return (
    <>
      <section className={styles.main}>
        <section className={`container ${styles.wrapper}`}>
          <div className={styles.header}>
            <h1 className={styles.header__ttl}>FAQs</h1>
            <p className={styles.header__txt}>
              In our FAQ section, you can find the information you’re looking
              for. We’re happy to help.
            </p>
          </div>
          <div className={styles.tabs}>
            <button
              onClick={() => seTab("employer")}
              className={tab === "employer" ? styles.active : ""}
            >
              Employer
            </button>
            <button
              onClick={() => seTab("candidate")}
              className={tab === "candidate" ? styles.active : ""}
            >
              Candidate
            </button>
          </div>
          <div className={styles.faqs}>
            {/* <div className={styles.categories}>
              <p className={styles.categories__ttl}>CATEGORIES</p>
              <div className={styles.categoryList}>
                {categories.map((item, index) => (
                  <button
                    onClick={() => setCategory(index)}
                    className={index === category ? styles.active : ""}
                  >
                    <span></span> {item.title}
                  </button>
                ))}
              </div>
            </div> */}
            <div className={styles.faqList}>
              {faqs.map((item, index) => (
                <FAQItem
                  {...item}
                  key={`faw_${index}`}
                  state={index}
                  active={active}
                  changeActive={(x) => setActive(x)}
                />
              ))}
              <p className={styles.contact}>
                Still have questions? <Link to={Routes.contact}>Reach out</Link>
                , we’d be happy to help.
              </p>
            </div>
          </div>
        </section>
        <ExperiencePrecision />
      </section>
    </>
  );
};

export { FAQUI };
