import { candidateWorkStyleRequestData, candidateWorkStyleService } from "api";
import { Loader } from "components";
import { AssessmentWorkStyleUI } from "features";
import { getError, handleAssessmentTimer } from "helpers";
import { useToast, useApiRequest } from "hooks";
import { useMemo, useState } from "react";

interface AssessmentWorkStyleProps {
  callback: (metric: string) => void;
}

const AssessmentWorkStyle: React.FC<AssessmentWorkStyleProps> = ({
  callback,
}) => {
  // State
  const [startTime, setStartTime] = useState<number>(0);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const submit = (data) => {
    const candidate = localStorage.getItem("assessmentId");

    if (!candidate) return;
    const secondsLeft = parseInt(localStorage.getItem("secondsLeft") ?? "0");

    const submitData: candidateWorkStyleRequestData = {
      id: candidate,
      data: {
        question1: {
          priority: data.productive.liberty,
          visualizing: data.productive.inCharge,
          coordinating: data.productive.communicate,
          planning: data.productive.detail,
        },
        question2: {
          priority: data.priority.importance,
          visualizing: data.priority.reason,
          coordinating: data.priority.stakeholders,
          planning: data.priority.method,
        },
        minutes_left: Math.ceil(secondsLeft / 60),
      },
    };

    // Set start time
    setStartTime(new Date().getTime());
    run(candidateWorkStyleService(submitData));
  };

  useMemo(() => {
    if (response?.status === 201) {
      const data = response.data.data;
      const answered = parseInt(localStorage.getItem("answered") ?? "0");
      localStorage.setItem("answered", `${answered + 8}`);
      handleAssessmentTimer(startTime);
      callback("work_style");
    } else if (error) {
      handleAssessmentTimer(startTime);
      openToast({
        show: true,
        ...getError({
          error,
          text: "Failed to submit, please try again or contact support",
          heading: "Sorry",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <AssessmentWorkStyleUI submit={submit} />
    </>
  );
};

export { AssessmentWorkStyle };
