import {
  Pagination,
  PaginationProps,
  TableBody,
  TableBodyRow,
} from "components/";
import styles from "./styles.module.scss";
import { ChevronRightIcon } from "assets";

export interface DraftAssessmentTableItem {
  id: string;
  title: string;
  created: { date: string; time: string };
  metrics: string;
}

interface TableBodyProps {
  tableBodyItems: DraftAssessmentTableItem[];
  handleView: (id) => void;
  tableBodyItemClassName: string;
  tableBodyRowClassName: string;
  pagination?: PaginationProps;
}

const DraftAssessmentTable: React.FC<TableBodyProps> = ({
  tableBodyItemClassName,
  tableBodyItems,
  handleView,
  tableBodyRowClassName,
  pagination,
}) => {
  return (
    <>
      <TableBody customClassName={`${styles.tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            style={{ zIndex: tableBodyItems.length - index }}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Assessment Title</p>
              {item.title}
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Date Created</p>
              <div className={styles.date}>
                <span>{item.created.date} </span>
                <span>{item.created.time}</span>
              </div>
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Metrics set</p>
              <span className={styles.metrics} >{item.metrics}</span>
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <button
                onClick={() => handleView(item.id)}
                className={styles.copyBtn}
              >
                Continue
                <ChevronRightIcon />
              </button>
            </span>
          </TableBodyRow>
        ))}
      </TableBody>
      {pagination ? (
        <Pagination {...pagination} className={styles.pagination} />
      ) : (
        ""
      )}
    </>
  );
};

export { DraftAssessmentTable };
