import styles from "./styles.module.scss";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
  ChartData,
  ChartOptions,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.unregister();

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Filler,
  CategoryScale,
  LinearScale,
  PointElement
);

interface DoughnutChartProps {
  score: number;
  className?: string;
  cutout?: string;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  score,
  className,
  cutout = "85%",
}) => {
  const chartData: ChartData<"doughnut"> = {
    datasets: [
      {
        data: [score, 100 - score],
        borderColor: "transparent",
        backgroundColor: [
          score >= 90
            ? "#18B368"
            : score >= 70
            ? "#605BFF"
            : score >= 50
            ? "#EDA12F"
            : "#E62E2E",
          score >= 90
            ? "#18B36810"
            : score >= 70
            ? "#605BFF10"
            : score >= 50
            ? "#EDA12F10"
            : "#E62E2E10",
        ],
        hoverOffset: 0,
        borderAlign: "inner",
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    responsive: true,
    cutout: cutout,
    animation: {
      animateRotate: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: false },
    },
  };

  const config = {
    type: "doughnut",
    data: chartData,
    options: options,
  };

  return (
    <>
      {/* <section  > */}
      <Doughnut className={`${styles.container} ${className}`} {...config} />
      {/* </section> */}
    </>
  );
};

export { DoughnutChart };
