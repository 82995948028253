import {
  candidateWorkMotivatorsRequestData,
  candidateWorkMotivatorsService,
} from "api";
import { Loader } from "components";
import { AssessmentWorkMotivatorsUI } from "features";
import { getError, handleAssessmentTimer } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useMemo, useState } from "react";

interface AssessmentWorkMotivatorsProps {
  callback: (metric: string) => void;
}

const AssessmentWorkMotivators: React.FC<AssessmentWorkMotivatorsProps> = ({
  callback,
}) => {
  // State
  const [startTime, setStartTime] = useState<number>(0);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const submit = (data) => {
    const candidate = localStorage.getItem("assessmentId");
    if (!candidate) return;
    const secondsLeft = parseInt(localStorage.getItem("secondsLeft") ?? "0");

    const submitData: candidateWorkMotivatorsRequestData = {
      id: candidate,
      data: {
        motivators: data,
        minutes_left: Math.ceil(secondsLeft / 60),
      },
    };

    // Set start time
    setStartTime(new Date().getTime());
    run(candidateWorkMotivatorsService(submitData));
  };

  useMemo(() => {
    if (response?.status === 201) {
      const data = response.data.data;

      const answered = parseInt(localStorage.getItem("answered") ?? "0");
      localStorage.setItem("answered", `${answered + 1}`);

      handleAssessmentTimer(startTime);

      callback("work_motivators");
    } else if (error) {
      handleAssessmentTimer(startTime);
      openToast({
        show: true,
        ...getError({
          error,
          text: "Failed to submit, please try again or contact support",
          heading: "Sorry",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <AssessmentWorkMotivatorsUI submit={submit} />
    </>
  );
};

export { AssessmentWorkMotivators };
