import { ArrowRightIcon } from "assets";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { layouts } from "chart.js";
import { useState } from "react";

const TermsOfUseUI = () => {
  const [barHeight, setBarHeight] = useState(0);

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);

    if (element) {
      let pos = element.style.position;
      let top = element.style.top;
      element.style.position = "relative";
      element.style.top = "-120px";
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      element.style.top = top;
      element.style.position = pos;
    }
  };

  const navItems = [
    {
      label: "Welcome",
      value: "welcome",
    },
    {
      label: "Consent",
      value: "consent",
    },
    {
      label: "Talent Assessments",
      value: "talent-assessments",
    },
    {
      label: "Accounts",
      value: "accounts",
    },
    {
      label: "Eligibility",
      value: "eligibility",
    },
    {
      label: "License & Restrictions ",
      value: "license",
    },
    {
      label: "Candidates, Employers & Partner Platforms ",
      value: "candidates",
    },
    {
      label: "Copyright & Content Ownership",
      value: "copyright",
    },
    {
      label: "Prohibited Acts ",
      value: "prohibited",
    },
    {
      label: "Payments & Fees ",
      value: "payments",
    },
    {
      label: "Modifications",
      value: "modifications",
    },

    {
      label: "Intellectual Property ",
      value: "IP",
    },
    {
      label: "Copyright Infringement Notice:",
      value: "infringement",
    },
    {
      label: "3rd Party Links ",
      value: "third-party",
    },
    {
      label: "Cookies",
      value: "cookies",
    },
    {
      label: "Disclaimer",
      value: "disclaimer",
    },
    {
      label: "Limitation of Liability",
      value: "liability",
    },

    {
      label: "Severability",
      value: "severability",
    },
    {
      label: "Waiver",
      value: "waiver",
    },
    {
      label: "Indemnification",
      value: "indemnification",
    },
    {
      label: "Data Protection",
      value: "data-protection",
    },
    {
      label: "Amendments",
      value: "amendments",
    },
    {
      label: "Entire Agreement ",
      value: "agreement",
    },
    {
      label: "Agreement to Arbitrate",
      value: "arbitrate",
    },
    {
      label: "Notice of Dispute",
      value: "dispute",
    },
    {
      label: "Binding Arbitration:",
      value: "binding-arbitration",
    },
    {
      label: "Submissions & Privacy",
      value: "submission",
    },
    {
      label: "Typographical Errors",
      value: "typos",
    },
    {
      label: "General",
      value: "general",
    },
    {
      label: "Contact Us",
      value: "contact",
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <section className={`container ${styles.header}`}>
          <h1 className={styles.header__ttl}>Terms & Condition of Use</h1>
          <p className={styles.header__txt}>
            Our Terms and Condition of Use was last updated on 21st February
            2024.
          </p>
        </section>
        <section className={`container ${styles.body}`}>
          <div id="nav" className={styles.nav}>
            <ArrowRightIcon className={styles.nav__arrow} />
            {navItems.map(({ label, value }) => (
              <button id={`${value}-nav`} onClick={() => handleScrollTo(value)}>
                {label}
              </button>
            ))}
          </div>
          <div>
            <section id="welcome" className={styles.section}>
              <p className={styles.section__ttl}>Welcome</p>
              <p>
                These Terms of Service constitute a legally binding agreement
                between customer users (individually referred to as “you”) and
                Yudimy Services Ltd. ("Yudimy", “we”, “us”, or “our”) governing
                your access to and use of the website located at
                <Link to="/">https://precisionmatch.ai/</Link> (the “Services”,
                “Precision by Yudimy”, or “Precision Site”). By accessing or
                using the Services, you agree to comply with and be bound by
                these Terms of Service. If you do not agree to these Terms of
                Service, you may not access or use the Services.
              </p>
            </section>
            <section id="consent" className={styles.section}>
              <p className={styles.section__ttl}>Consent</p>
              <p>
                By using our website/app, registering an account, or making a
                purchase, you consent to these Terms & Conditions, including our
                Privacy Policy. We have updated our Terms & Conditions to
                provide transparency regarding the data we collect and how it is
                used.
              </p>
            </section>
            <section id="talent-assessments" className={styles.section}>
              <p className={styles.section__ttl}>Talent Assessments</p>
              <p>
                Yudimy's talent assessments are designed solely for recruiting,
                talent development, career guidance, talent mobility, and human
                capital management purposes. The Services do not constitute a
                diagnosis of any psychiatric or medical condition. Yudimy
                disclaims any responsibility for the misuse of the Services for
                diagnostic purposes.
              </p>
            </section>
            <section id="accounts" className={styles.section}>
              <p className={styles.section__ttl}>Accounts</p>
              <ul>
                <p>To create an account on the Precision Site, you:</p>
                <li>Agree to these Terms of Service,</li>
                <li>Provide a valid email address, and</li>
                <li>
                  Furnish any other information required during the registration
                  process.
                </li>
              </ul>
              <p>
                You agree to provide accurate, complete, and current information
                and to update this information promptly to maintain its
                accuracy. You are responsible for maintaining the
                confidentiality of your account credentials and for all
                activities that occur under your account.
              </p>
              <p>
                You may not use false, misleading, or offensive information when
                creating your account. You may only maintain one account unless
                expressly authorized by Yudimy. Automated account registration
                methods are prohibited.
              </p>
            </section>
            <section id="eligibility" className={styles.section}>
              <p className={styles.section__ttl}>Eligibility</p>
              <p>
                By using the Precision Site, you affirm that you are at least
                eighteen (18) years old and capable of entering into legally
                binding agreements. You must provide accurate registration
                information and comply with all applicable extant laws and
                regulations.
              </p>
            </section>
            <section id="license" className={styles.section}>
              <p className={styles.section__ttl}>
                License Grants and Restrictions
              </p>
              <p>
                Yudimy grants you a limited, non-exclusive, non-transferable
                license to access and use the Precision Site for personal or
                internal business purposes, subject to these Terms of Service.
                You may not permit unauthorized third-party use of the Precision
                Site. Modification, reverse engineering, or any form of
                unauthorized access to the Precision Site is prohibited.
                <br />
                Yudimy retains all rights not expressly granted in these Terms
                of Service. You acknowledge that Yudimy owns all rights, title,
                and interest in the Precision Site, including all intellectual
                property rights.
              </p>
            </section>
            <section id="candidates" className={styles.section}>
              <p className={styles.section__ttl}>
                Candidates, Employers, and Partner Platforms
              </p>
              <p className={styles.section__subTtl}>Assessment:</p>
              <ul>
                <p>
                  Upon taking an assessment either for an employer or through a
                  partner that utilizes the Services, you acknowledge and agree
                  that:
                </p>
                <li>
                  Precision by Yudimy does not control the actions of such
                  employers/partners, and each party may have certain terms of
                  use/policies that apply to you and your data (personal
                  information and assessment report).
                </li>
                <li>
                  Employers/partners may share your personal information with
                  third parties, including but not limited to, applicant
                  tracking systems, job boards, and other services. 
                </li>
                <li>
                  Precision by Yudimy does not track such usage, and it is
                  encouraged that you inquire directly with such third parties
                  about how they intend to use the profile and assessment
                  information.
                </li>
              </ul>
              <p className={styles.section__subTtl}>
                Submission of Profile/Assessment Data:
              </p>
              <p>
                For clarity, in the context of talent acquisition services
                (e.g., candidates applying to a role), when you click on an
                application link via the employer’s career site, a third-party
                job board, or an email invite link shared directly with you, and
                subsequently click on "start assessment," you explicitly consent
                to our Privacy Policy and Terms of Service. Upon completion of
                an assessment, your results are automatically shared with the
                employer to which you applied.
              </p>
              <p className={styles.section__subTtl}>Candidate Support:</p>
              <p>
                As an employer or partner platform utilising Precision by
                Yudimy, you consent to candidates receiving post-assessment
                support beneficial for their career development. This includes
                candidates receiving free sponsored career insight reports and
                being redirected to a third-party platform where they can
                further explore insights and prepare for their next career
                opportunity. This aligns with our mission to promote talent
                inclusion and facilitate decent work for assessed talent.
              </p>
              <p className={styles.section__subTtl}>Employment Decisions:</p>
              <p>
                Precision by Yudimy assumes no responsibility for any hiring or
                similar decisions made by an employer. The employer and the
                person or individuals making decisions for or on behalf of the
                employer are entirely responsible for any information obtained
                through the Services and utilized in connection with any
                employment or similar decision. Precision by Yudimy makes no
                representation, warranty, or guarantee of any kind regarding an
                individual's job, potential employment, or any other outcomes
                concerning a current or prospective employer. If you are an
                employer, you must only consider relevant information under
                applicable laws when making employment and similar decisions. As
                the Precision by Yudimy assessment report serves as supportive
                insight for hiring and talent teams to make decisions, you must
                not rely solely on the Services for making final decisions.
              </p>
              <p className={styles.section__subTtl}>Usage responsibilities: </p>
              <p>
                You are solely responsible for all activities conducted under
                your account and must ensure compliance with all applicable
                local, state, provincial, territorial, federal, national, and
                international laws, treaties, and regulations pertaining to your
                utilization of the Precision Site or its Services. This includes
                adherence to extant laws and regulations governing intellectual
                property protection, data privacy, international communication,
                and the transmission of technical or personal information. 
              </p>
              <ul>
                <p>You agree to: </p>
                <p>
                  (i) promptly inform Precision by Yudimy of any unauthorised
                  access to, or usage of, your account or password, as well as
                  any known or suspected security breaches; and 
                </p>
                <p>
                  (ii) immediately inform Precision by Yudimy of and take
                  reasonable measures to halt any unauthorised reproduction or
                  dissemination of the Precision Site or its Content that you
                  are aware of or suspect. 
                </p>
                <p>
                  (iii) By accessing and utilizing Precision by Yudimy you
                  acknowledge and agree that we are not liable or responsible
                  for any failure on your part to securely share reports
                  generated through our platform. It is your sole responsibility
                  to ensure the safe and secure sharing of any reports or data
                  generated using our services. We disclaim any responsibility
                  for any consequences resulting from your failure to do so.
                </p>
              </ul>
              <p>
                You are responsible for procuring and maintaining all necessary
                equipment (collectively referred to as "Equipment") and
                supplementary services required for connecting to, accessing, or
                utilizing the Precision Site, which may include but are not
                limited to modems, hardware, servers, software, internet
                browsers, operating systems, networking facilities, web servers,
                and long-distance or local telephone services. You must ensure
                that such Equipment is compatible with the Precision Site and
                bear the responsibility for its usage and security maintenance.
              </p>
              <p className={styles.section__subTtl}>
                Authorised Use of the Service:
              </p>
              <p>
                You are permitted to utilise the Service solely for lawful
                purposes and in a manner consistent with the intended nature and
                purpose of Precision by Yudimy. It is strictly prohibited to
                employ the Service for evaluating candidates for any positions
                that are unlawful, hazardous, offensive, discriminatory, or
                otherwise inappropriate. Furthermore, you must refrain from
                using the Service to solicit information from candidates that
                could potentially be utilised for discriminatory purposes.
                Unauthorised use of the Service may constitute a criminal
                offense and/or result in a claim for damages, for which you, as
                the employer, would be held liable strictly or vicariously.
              </p>
              <p className={styles.section__subTtl}>Feedback: </p>
              <p>
                While utilising the Precision Site, you may provide feedback,
                encompassing suggestions, observations, errors, issues, and
                defects concerning the Precision Site ("Feedback"). By providing
                Feedback, you hereby grant Precision by Yudimy a worldwide,
                irrevocable, perpetual, royalty-free, transferable,
                sublicensable, non-exclusive right to utilise, replicate,
                modify, distribute, display, perform, generate derivative works
                from, and otherwise exploit all such Feedback.
              </p>
              <p className={styles.section__subTtl}>Assessment Audits: </p>
              <p>
                Yudimy retains the right to continually refine the scientific
                methodologies underpinning the Services, which includes
                enhancements to the Employers' role expectation survey,
                candidates’ assessments, and Precision scores, aimed at
                delivering improved outcomes across the Precision by Yudimy
                platform. Periodically, you may observe alterations to candidate
                assessment scores, hiring metrics, match scores, and other
                components associated with Yudimy assessments. Yudimy reserves
                the discretion to modify the aforementioned aspects without
                prior notification.
              </p>
            </section>
            <section id="copyright" className={styles.section}>
              <p className={styles.section__ttl}>
                Copyright and Content Ownership: 
              </p>
              <p>
                The Precision Site, its contents, and all assessment materials,
                including but not limited to assessment and survey questions
                generated within the Precision Site, responses provided by
                candidates and employees, computed scores, assessment outcomes,
                and any other materials and data furnished to you as part of the
                Services, are the exclusive property of Precision by Yudimy and
                are safeguarded by the extant copyright laws. Unauthorised
                reproduction, display, publication, or distribution, whether
                electronic or otherwise, of the Precision Site, its content, or
                the assessment materials is strictly prohibited. For any
                requests pertaining to the usage of assessment materials beyond
                the scope authorised by these Terms of Service, please reach out
                to Yudimy for permission.
              </p>
            </section>
            <section id="prohibited" className={styles.section}>
              <p className={styles.section__ttl}>
                Prohibited Acts & Restrictions:
              </p>
              <ul>
                <p>
                  As a prerequisite for using the Precision Site and its
                  Services, you agree not to:
                </p>
                <li>
                  Upload, post, email, transmit, or otherwise disseminate any
                  information, materials, or content that is unlawful, harmful,
                  threatening, abusive, harassing, defamatory, obscene,
                  pornographic, or otherwise offensive, invades another's
                  privacy, or advocates bigotry, racism, or hatred toward any
                  individual or group.
                </li>
                <li>
                  Assume the identity of any person or entity, or falsely
                  represent your affiliation with any person or entity.
                </li>
                <li>
                  Engage in phishing activities or collect, upload, post, email,
                  transmit, or otherwise distribute account data or passwords
                  for other websites, software, or services.
                </li>
                <li>
                  Collect, upload, post, email, transmit, or otherwise disclose
                  credit card information or other financial data used for
                  payment purposes.
                </li>
                <li>
                  Forge headers or manipulate identifiers to disguise the origin
                  of any content transmitted through the Precision Site.
                </li>
                <li>
                  Upload, post, email, transmit, or otherwise provide any
                  information, materials, or content that infringes upon the
                  rights of any other person, including intellectual property
                  rights.
                </li>
                <li>
                  Distribute unsolicited or unauthorised advertising,
                  promotional materials, spam, chain letters, pyramid schemes,
                  or any other form of solicitation.
                </li>
                <li>
                  Introduce software, viruses, or any other computer code or
                  programs designed to interrupt, destroy, or limit the
                  functionality of any computer software, hardware, or
                  telecommunications equipment used to access the Precision
                  Site.
                </li>
                <li>
                  Utilize manual or automated processes to "crawl," "spider," or
                  "screen scrape" any web pages within the Precision Site.
                </li>
                <li>
                  Reverse engineer, decompile, or disassemble any software used
                  to provide the Precision Site.
                </li>
                <li>
                  Reproduce, duplicate, copy, or exploit any portion of the
                  Precision Site without explicit written permission from Plum.
                </li>
                <li>
                  Disrupt or interfere with the operation of the Precision Site,
                  its servers, or connected networks, or disregard any network
                  requirements, procedures, policies, or regulations.
                </li>
                <li>
                  Obtain, collect, store, or modify the personal information of
                  any other user.
                </li>
                <li>
                  Modify, adapt, or attempt to hack the Precision Site or
                  misrepresent its association with any other website or entity.
                </li>
                <li>
                  Use the Precision Site for any illegal or unauthorised
                  purposes, violating laws within your jurisdiction, including
                  but not limited to intellectual property laws.
                </li>
                <li>
                  Access the Services or its features or resources, including
                  Precision Reports, to develop a competitive product or
                  service, or to replicate ideas, features, functions, graphics,
                  or source code of the Service.
                </li>
                <li>
                  License, sublicense, sell, transfer, assign, distribute, or
                  commercially exploit the Service or its content without the
                  express written consent of the Company.
                </li>
                <li>
                  Create derivative works based on the Service or its content.
                </li>
                <li>
                  Share non-public Service features or content with any third
                  party.
                </li>
                <li>
                  Attempt to reverse engineer, decompile, disassemble, or derive
                  source code or other information from the Service.
                </li>
                <li>
                  Interfere with the integrity or performance of the Service or
                  its data.
                </li>
                <li>
                  Alter, remove, obscure, or add to any notices or indications
                  of ownership or trademarks affixed to the Service.
                </li>
                <li>
                  Frame, deep link, or establish unauthorised links to any part
                  of the Service.
                </li>
              </ul>
            </section>
            <section id="payments" className={styles.section}>
              <p className={styles.section__ttl}>
                Payments, Fees, Termination, Modifications:
              </p>
              <p className={styles.section__subTtl}>Free Trial Plan:</p>
              <p>
                Precision by Yudimy may, at its discretion, offer candidate
                assessment tokens free of charge under a "Free Trial" plan. You
                will not incur any charges for utilising the Free trial plan.
                Precision by Yudimy reserves the right, at any time and without
                prior notice, to 
              </p>
              <ul>
                <p>(i) amend the terms of use of the Free plan offer, or </p>
                <p>(ii) terminate the Free trial plan offer.</p>
              </ul>
              <p className={styles.section__subTtl}>Fee Changes:</p>
              <p>
                In its sole discretion and at any time, Precision by Yudimy may
                modify the fees for per-role, per-candidate, top-ups, and in-app
                purchases. Any changes in token fees will take effect upon the
                repurchase of an access token. Precision by Yudimy will provide
                reasonable prior notice of any fee changes to allow you an
                opportunity to terminate your Subscription before such
                modifications become effective. Your continued use of the
                Service after the modification of token fees constitutes your
                acceptance of the modified fee.
              </p>
              <p className={styles.section__subTtl}>Pricing:</p>
              <p>
                Precision shall not be held liable for any expectations not
                explicitly indicated under each pricing item on the checkout
                page. You are free to select the payment option that best suits
                your team or organization from the available price plans. Unused
                tokens expire at the end of the subscription period; rollover
                options are not provided.
              </p>
              <p className={styles.section__subTtl}>Termination:</p>
              <p>
                We reserve the right to terminate or suspend your account, with
                or without prior notice or liability, for any reason, including
                breach of the Terms. Upon termination, your right to use the
                Services will cease immediately. You may terminate your account
                by discontinuing the use of the Service. Refunds will not be
                issued for unused time or access tokens upon account
                termination. For annual or quarterly service contracts, you are
                responsible for properly cancelling your account renewal. To
                cancel your account renewal, email contact@precisionmatch.ai at
                least sixty (60) business days before the contract expiration.
              </p>
            </section>
            <section id="modifications" className={styles.section}>
              <p className={styles.section__ttl}>
                Modifications to Our Website/App:
              </p>
              <p>
                Yudimy reserves the right, with or without notice, to modify or
                discontinue the Precision Site and Services. Yudimy shall not be
                liable for any modification, price change, suspension, or
                discontinuance of the Precision Site or Services.
              </p>
            </section>
            <section id="IP" className={styles.section}>
              <p className={styles.section__ttl}>
                Content Licensing and Intellectual Property:
              </p>
              <p>
                Our Service enables you to post, link, store, share, and make
                available various content ("Content"). You are responsible for
                the lawfulness, reliability, accuracy, and appropriateness of
                the Content you post. You represent and warrant that you own or
                have the legal right to use the Content, and its posting does
                not infringe any third-party rights. Precision by Yudimy retains
                exclusive property rights to aggregated and anonymized
                assessment questions and scores for Service improvement purposes
                including but not limited to benchmarking of scores, assessment,
                and algorithm optimization.
              </p>
            </section>
            <section id="infringement" className={styles.section}>
              <p className={styles.section__ttl}>
                Copyright Infringement Notice:
              </p>
              <ul>
                <p>
                  If you believe material on our website/app infringes your
                  copyright, please contact us with specific information
                  outlined in our Copyright Infringement Notice here. 
                </p>
                <p>
                  (a) a physical or electronic signature of the copyright owner
                  or a person authorised to act on his behalf; 
                </p>
                <p>
                  (b) identification of the material that is claimed to be
                  infringing; 
                </p>
                <p>
                  (c) your contact information, including your address,
                  telephone number, and an email; 
                </p>
                <p>
                  (d) a statement by you that you have a good faith belief that
                  use of the material is not authorised by the copyright owners;
                  and 
                </p>
                <p>
                  (e) a statement that the information in the notification is
                  accurate, and, under penalty of perjury you are authorised to
                  act on behalf of the owner. 
                </p>
              </ul>
            </section>
            <section id="third-party" className={styles.section}>
              <p className={styles.section__ttl}>
                Links to Other Websites/ Third-Party Services
              </p>
              <p>
                Our Services may contain links to third-party websites or
                services that are not owned or controlled by the Precision site.
                Yudimy has no control over and assumes no responsibility for,
                the content, accuracy, completeness, timeliness, validity,
                copyright compliance, legality, decency, quality privacy
                policies, or practices of any third-party websites or services.
                You further acknowledge and agree that Yudimy shall not be
                responsible or liable, directly or indirectly, for any damage or
                loss caused or alleged to be caused by or in connection with the
                use of or reliance on any such content, goods, or services
                available on or through any such websites or services. We
                strongly advise you to read the terms of use and privacy
                policies of any third-party websites or services that you visit.
              </p>
            </section>
            <section id="cookies" className={styles.section}>
              <p className={styles.section__ttl}>Cookies:</p>
              <p>
                Yudimy utilises "Cookies" to recognize the areas of our website
                that you have visited. A cookie is a small piece of data stored
                on your computer or mobile device by your web browser. While
                cookies enhance the performance and functionality of our
                website/app, they are non-essential to its use. However, without
                these cookies, certain functionalities such as videos may become
                unavailable, or you may need to enter your login details every
                time you visit the website/app, as we cannot remember your
                previous login. Most web browsers can disable the use of
                cookies; however, disabling cookies may affect your ability to
                access functionality on our website correctly or at all. We do
                not store Personally Identifiable Information in cookies.
              </p>
            </section>
            <section id="disclaimer" className={styles.section}>
              <p className={styles.section__ttl}>Disclaimer:</p>
              <p>
                Your use of the Service is entirely at your own risk. The
                Service is provided on an "AS IS" and "AS AVAILABLE" basis.
                Yudimy makes no warranties or guarantees of any kind, whether
                express or implied, including but not limited to implied
                warranties of merchantability, fitness for a particular purpose,
                non-infringement, or course of performance.
              </p>
              <ul>
                <p>
                  Yudimy, its subsidiaries, affiliates, and licensors do not
                  warrant that:
                </p>
                <p>
                  a) The Service will function uninterrupted, securely, or be
                  available at any specific time or location;
                </p>
                <p>b) Any errors or defects will be corrected;</p>
                <p>
                  c) The Service is free of viruses or other harmful components;
                  or
                </p>
                <p>
                  d) The results of using the Services will be 100% accurate and
                  meet your requirements.
                </p>
              </ul>
              <p>
                Yudimy and its licensors disclaim all warranties for any
                information, content, or advice obtained through the Services or
                the Precision Site. Additionally, they disclaim all warranties
                for services or goods received through or advertised on the
                Precision Site or received via any links provided on the
                Precision Site.
              </p>
              <p>
                You understand and agree that your use of the forms and content
                on the Precision Site and as part of the Services is at your own
                discretion and risk. You will be solely responsible for any loss
                of data or similar loss resulting from the submission or
                download of such forms or content.
              </p>
              <p>
                No oral advice or written information provided by Yudimy or its
                affiliates, employees, officers, directors, agents, or similar
                parties will create a warranty. Price and availability
                information are subject to change without notice.
              </p>
              <p>
                Technical support is exclusively provided to paying account
                holders and is available solely via email and online support
                channels. Yudimy will make commercially reasonable efforts to
                respond within a reasonable amount of time during regular
                business hours.
              </p>
            </section>
            <section id="liability" className={styles.section}>
              <p className={styles.section__ttl}>Limitation of Liability:</p>
              <ul>
                <p>
                  In no event shall Yudimy, or its directors, employees,
                  partners, agents, suppliers, or affiliates ("Suppliers"), be
                  liable for any special, incidental, indirect, or consequential
                  damages whatsoever, including, but not limited to, damages for
                  loss of profits, loss of data or other information, business
                  interruption, personal injury, or loss of privacy arising out
                  of or in any way related to:
                </p>
                <p>(i) The use of or inability to use the website/app;</p>
                <p>
                  (ii) Third-party software and/or third-party hardware used
                  with the website/app;
                </p>
                <p>
                  (iii) Any conduct or content of any third party on the
                  Services;
                </p>
                <p>(iv) Any content obtained from the Service; and</p>
                <p>
                  (v) Unauthorised access, use, or alteration of your
                  transmissions or content.
                </p>
                <p>
                  This includes damages arising from warranty, contract, tort
                  (including negligence), or any other legal theory, even if we
                  have been advised of the possibility of such damage, and even
                  if a remedy set forth herein is found to have failed of its
                  essential purpose.
                </p>
                <p>
                  Notwithstanding any damages that you might incur, the entire
                  liability of Yudimy and any of its Suppliers under any
                  provision of this Agreement and your exclusive remedy for all
                  of the foregoing shall be limited to the amount paid by you
                  for the Services on the website/app.
                </p>
              </ul>
            </section>
            <section id="severability" className={styles.section}>
              <p className={styles.section__ttl}>Severability:</p>
              <p>
                If any provision of this Agreement is deemed unenforceable or
                invalid, such provision will be modified and interpreted to
                achieve the objectives of the provision to the fullest extent
                permitted by applicable law, and the remaining provisions will
                remain in full force and effect. 
                <br />
                This Agreement, along with the Privacy Policy and any other
                legal notices published by Yudimy on the Services, constitutes
                the entire agreement between you and Yudimy regarding the
                Services. 
                <br />
                If any provision of this Agreement is deemed invalid by a court
                of competent jurisdiction, the invalidity of such provision
                shall not affect the validity of the remaining provisions, which
                shall remain in full force and effect. 
                <br />
                No waiver of any term of this Agreement shall be considered a
                continuing waiver of such term or any other term. Failure to
                assert any right or provision under this Agreement shall not
                constitute a waiver of such right or provision.
              </p>
            </section>
            <section id="waiver" className={styles.section}>
              <p className={styles.section__ttl}>Waiver:</p>
              <p>
                Except as provided herein, the failure to exercise a right or to
                enforce an obligation under this Agreement shall not affect a
                party's ability to exercise such right or enforce such
                obligation at any time thereafter. Waiver of a breach shall not
                constitute waiver of any subsequent breach. No failure to
                exercise and no delay in exercising, on the part of either
                party, any right or power under this Agreement shall operate as
                a waiver of that right or power. Nor shall any single or partial
                exercise of any right or power under this Agreement preclude
                further exercise of that or any other right granted herein. In
                case of conflict between this Agreement and any applicable
                purchase or other terms, this Agreement shall prevail.
              </p>
            </section>
            <section id="indemnification" className={styles.section}>
              <p className={styles.section__ttl}>Indemnification:</p>
              <ul>
                <p>
                  You agree to indemnify and hold Precision by Yudimy and its
                  parents, subsidiaries, affiliates, officers, employees,
                  agents, partners, and licensors (if any) harmless from any
                  claim or demand, including reasonable attorneys' fees, arising
                  out of or related to your: 
                </p>
                <p>(a) use of the website/app; </p>
                <p>
                  (b) violation of this Agreement or any law or regulation; or 
                </p>
                <p>(c) violation of any rights of a third party.</p>
              </ul>
            </section>

            <section id="data-protection" className={styles.section}>
              <p className={styles.section__ttl}>Data Protection:</p>
              <p>
                You acknowledge that information about you and the Content
                posted will be collected, held, and used by us following our{" "}
                <Link to={Routes.privacyPolicy}>Privacy Policy</Link>
              </p>
            </section>
            <section id="amendments" className={styles.section}>
              <p className={styles.section__ttl}>Amendments to these Terms:</p>
              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material, we
                will provide 30 days' notice before any new terms take effect.
                Determination of what constitutes a material change shall be at
                our sole discretion. By continuing to access or use our Service
                after those revisions become effective, you agree to be bound by
                the revised terms. If you do not agree to the new terms, please
                discontinue using the Service.
              </p>
            </section>
            <section id="agreement" className={styles.section}>
              <p className={styles.section__ttl}>Entire Agreement:</p>
              <p>
                The Agreement constitutes the entire agreement between you and
                Yudimy regarding your use of the website/app, superseding all
                prior and contemporaneous written or oral agreements between you
                and Yudimy. You may be subject to additional terms and
                conditions when using or purchasing other Yudimy services, which
                Yudimy will provide to you at the time of such use or purchase.
              </p>
            </section>
            <section id="arbitrate" className={styles.section}>
              <p className={styles.section__ttl}>Agreement to Arbitrate:</p>
              <p>
                This section applies to any dispute, except for disputes
                relating to claims for injunctive or equitable relief regarding
                the enforcement or validity of your or intellectual property
                rights. The term "dispute" encompasses any dispute, action, or
                controversy between you and Yudimy concerning the Services or
                this agreement, whether in contract, warranty, tort, statute,
                regulation,bye-laws , or any other legal or equitable basis.
                "Dispute" will be given the broadest possible meaning allowable
                under law. The governing law is the laws of the Federal Republic
                of Nigeria.
              </p>
            </section>

            <section id="dispute" className={styles.section}>
              <p className={styles.section__ttl}>Notice of Dispute:</p>
              <p>
                In the event of a dispute, you or Yudimy must provide the other
                with a Notice of Dispute, which is a written statement including
                the name, address, and contact information of the party giving
                it, the facts giving rise to the dispute, and the relief
                requested. You must send any Notice of Dispute via email to
                <a href="mailto:contact@precisionmatch.ai">
                  contact@precisionmatch.ai
                </a>
                . Yudimy will send any Notice of Dispute to you by mail to your
                address if available, or otherwise to your email address. You
                and Yudimy will attempt to resolve any dispute through informal
                negotiation within sixty (60) days from the date the Notice of
                Dispute is sent. After sixty (60) days, you or Yudimy may
                commence arbitration.
              </p>
            </section>
            <section id="binding-arbitration" className={styles.section}>
              <p className={styles.section__ttl}>Binding Arbitration:</p>
              <p>
                If you and Yudimy do not resolve any Dispute by informal
                negotiation, any further effort to resolve the dispute will be
                conducted exclusively by binding arbitration as described in
                this section. You are waiving the right to litigate (or
                participate as a party or class member) all disputes in court
                before a judge or jury. The dispute shall be settled by binding
                arbitration per the commercial arbitration rules of the
                Arbitration and Mediation Act, 2023, of Nigeria. Either party
                may seek interim or preliminary injunctive relief from any court
                of competent jurisdiction, as necessary to protect the party's
                rights or property pending the completion of arbitration. Any
                legal, accounting, and other costs, fees, and expenses incurred
                by the prevailing party shall be borne by the non-prevailing
                party.
              </p>
            </section>
            <section id="submission" className={styles.section}>
              <p className={styles.section__ttl}>Submissions and Privacy:</p>
              <p>
                In the event that you submit or post any ideas, creative
                suggestions, designs, photographs, information, advertisements,
                data, or proposals, including ideas for new or improved
                products, services, features, technologies, or promotions, you
                agree that such submissions will automatically be treated as
                non-confidential and non-proprietary and will become the sole
                property of Yudimy without any compensation or credit to you.
                Yudimy and its affiliates shall have no obligations regarding
                such submissions and may use the ideas contained in them for any
                purposes in any medium in perpetuity.
              </p>
            </section>
            <section id="typos" className={styles.section}>
              <p className={styles.section__ttl}>Typographical Errors:</p>
              <p>
                If a product or service is listed at an incorrect price or with
                incorrect information due to a typographical error, we reserve
                the right to refuse or cancel any orders placed for the product
                or service listed at the incorrect price. We also reserve the
                right to refuse or cancel any such order whether or not the
                order has been confirmed and your credit card charged. If your
                credit card has already been charged for the purchase and your
                order is canceled, we will issue a credit to your credit card
                account or other payment accounts in the amount of the charge.
              </p>
            </section>
            <section id="general" className={styles.section}>
              <p className={styles.section__ttl}>General:</p>
              <p>
                These Terms of Service will be governed by and construed in
                accordance with the laws of the Federal Republic of Nigeria,
                without giving effect to its conflict of laws provisions or
                those in your actual state, province, or country of residence.
                If any provision or portion of these Terms of Service is found
                to be unenforceable by a court of competent jurisdiction, the
                remainder of these Terms of Service will continue in full force
                and effect, and such finding shall not affect the enforceability
                of that provision or portion of these Terms of Service in any
                other jurisdiction. These Terms of Service constitute the entire
                agreement between the parties regarding the subject matter and
                supersede all prior or contemporaneous understandings or
                agreements, written or oral, regarding such subject matter. In
                the event of any conflict between these Terms of Service and a
                valid and executed written agreement between you and Yudimy, the
                terms of the written agreement shall govern to resolve the
                conflict. Any waiver of any provision of these Terms of Service
                will be effective only if in writing and signed by an authorised
                representative of Yudimy.
              </p>
            </section>
            <section id="contact" className={styles.section}>
              <p className={styles.section__ttl}>Contact Us:</p>
              <p>
                If you have any questions about these Terms, please contact us
                at{" "}
                <a href="mailto:contact@precisionmatch.ai">
                  contact@precisionmatch.ai
                </a>
                 
              </p>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export { TermsOfUseUI };
