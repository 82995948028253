import { emptyAssessmentsImg } from "assets";
import styles from "./styles.module.scss";
import { ReactNode } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, InputScale, Textarea } from "components";

interface FeedbackData {
  rating: number;
  review: string | undefined;
}

const initFeedback: FeedbackData = {
  rating: -1,
  review: "",
};

const schema = yup
  .object()
  .shape({
    review: yup.string(),
    rating: yup.number().min(0).required("Required"),
  })
  .required();

interface AssessmentCompleteUIProps {
  title?: string;
  text?: ReactNode | string;
  submit: (data: { feedback?; rating }) => void;
  isComplete: boolean;
}

const AssessmentCompleteUI = ({
  title,
  text,
  submit,
  isComplete,
}: AssessmentCompleteUIProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FeedbackData>({
    resolver: yupResolver(schema),
    defaultValues: initFeedback,
  });

  const onSubmit: SubmitHandler<FeedbackData> = (data) => {
    if (data.review === "") {
      submit({ rating: data.rating });
    } else {
      submit({ rating: data.rating, feedback: data.review });
    }
  };

  return (
    <>
      {!isComplete ? (
        <section className={styles.feedback}>
          <p className={styles.feedback__ttl}>
            We’d love your feedback on the ease of the application process to
            enable us improve.{" "}
          </p>

          <p className={styles.feedback__label}>
            How easy was the assessment process?
          </p>
          <InputScale
            register={register}
            name={"rating"}
            validatorMessage={errors.rating?.message}
            length={10}
            leftMost="Extremely easy"
            rightMost="Not easy"
            parentClassName={styles.scaleWrap}
            startAt={10}
          />
          <p className={styles.feedback__label}>
            Comments to share with us (What do you think about this application
            process? Tell us what we should improve. We thrive on candor we'd
            like to see this in the potential candidates we're considering. Good
            or bad share with us
          </p>
          <Textarea
            placeholder={"Tell us"}
            name={"review"}
            validatorMessage={errors.review?.message}
            register={register}
          />
          <Button
            className={styles.submitBtn}
            type="fill"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </section>
      ) : (
        <section className={styles.container}>
          <img src={emptyAssessmentsImg} />
          <h1>{title ?? "Assessment submitted successfully"}</h1>
          <p>
            {text ?? (
              <>
                <span>What’s next?</span> The assessment will be evaluated by
                your test administrator. You may close the tab now.
              </>
            )}
          </p>
        </section>
      )}
    </>
  );
};

export { AssessmentCompleteUI };
