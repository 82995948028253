import { ChevronRightIcon } from "assets";
import styles from "./styles.module.scss";
import { useRef } from "react";

export interface FAQItemData {
  question: string;
  answer: any;
}

interface FAQITemProps extends FAQItemData {
  active: number;
  state: number;
  changeActive: (state: number) => void;
}

const FAQItem: React.FC<FAQITemProps> = ({
  active,
  state,
  changeActive,
  question,
  answer,
}) => {
  const contentEl = useRef<HTMLDivElement>(null);
  return (
    <div
      className={`${styles.faqItem} ${
        active === state ? styles.activeItem : styles.inactiveItem
      }`}
    >
      <button
        onClick={() => changeActive(active === state ? -1 : state)}
        className={`${styles.faqBtn} ${active === state ? styles.open : ""}`}
      >
        <span>{question}</span>{" "}
        <ChevronRightIcon
          className={state === active ? styles.down : styles.up}
        />
      </button>
      <div
        ref={contentEl}
        className={styles.faqBody}
        style={
          active === state && contentEl.current
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div>{answer}</div>
      </div>
    </div>
  );
};

export { FAQItem };
