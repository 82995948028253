import {
  candidateBasicFitRequestData,
  candidateBasicFitService,
  fetchBasicFitService,
} from "api";
import { Loader } from "components";
import { AssessmentBasicFitUI, BasicFitData } from "features";
import {
  getBase64,
  getError,
  handleAssessmentTimer,
  openFullScreen,
} from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { AssessmentProgress } from "..";

interface AssessmentBasicFitProps {
  hasBasicExpectation: boolean;
  assessmentID: string;
  callback: (metric: string) => void;
  resume: (data: AssessmentProgress) => void;
}

const AssessmentBasicFit: React.FC<AssessmentBasicFitProps> = ({
  assessmentID,
  hasBasicExpectation,
  callback,
  resume,
}) => {
  // State
  const [startTime, setStartTime] = useState<number>(0);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});
  const {
    run: runFetch,
    data: fetchResponse,
    requestStatus: fetchStatus,
    error: fetchError,
  } = useApiRequest({});

  useEffect(() => {
    hasBasicExpectation && runFetch(fetchBasicFitService(assessmentID));
  }, [assessmentID, hasBasicExpectation]);

  const reqExpEnv =
    fetchResponse?.data?.data?.experience_environment !== "not_important";

  const submit = async (data: BasicFitData) => {
    openFullScreen();
    const candidate = JSON.parse(localStorage.getItem("candidate") ?? "{}");

    const file = data.resume;
    let base64;

    if (file) {
      base64 = await getBase64(file[0]).then((res) => {
        return res;
      });
    }

    const submitData: candidateBasicFitRequestData = {
      full_name: data.name,
      phone_number: data.number.value,
      candidate: candidate.id,
      assessment: assessmentID,
      portfolio: data.portfolio,
      resume: base64,
    };

    if (hasBasicExpectation)
      submitData.basic_expectation = {
        emp_type: data.employmentType.value,
        exp_environment: reqExpEnv
          ? data.workEnvironment.value
          : "not_important",
        exp_level: data.experienceLevel.value,
        exp_years: data.experienceYears.value,
        location: data.location ?? "",
        salary_expectation: parseInt(data.salaryExpectation ?? "0"),
      };

    // Set start time
    setStartTime(new Date().getTime());
    run(candidateBasicFitService(submitData));
  };

  useMemo(() => {
    if (response?.status === 201) {
      const data = response.data.data;
      handleAssessmentTimer(startTime);

      localStorage.setItem("assessmentId", `${data.id}`);

      const answered = parseInt(localStorage.getItem("answered") ?? "0");
      hasBasicExpectation
        ? localStorage.setItem("answered", `${answered + 10}`)
        : localStorage.setItem("answered", `${answered + 4}`);

      callback("basic_expectations");
    } else if (error) {
      if (error.response?.data?.data?.status === "started") {
        const data = error.response?.data?.data;
        localStorage.setItem("assessmentId", `${data.id}`);
        openToast({
          show: true,
          text: "Looks like you've already started this assessment. Restoring your progress in 3..2..1",
          heading: "Uh-oh",
          type: "error",
          timeOut: 2000,
        });
        setTimeout(() => {
          resume({
            basic_expectations: true,
            work_motivators: data.progress.work_motivators ?? false,
            work_qualities: data.progress.work_qualities ?? false,
            work_style: data.progress.work_style ?? false,
            personality: data.progress.personality ?? false,
            soft_skill: data.progress.soft_skill ?? false,
            domain_knowledge: data.progress.domain_knowledge ?? false,
          });
        }, 2000);
      } else {
        handleAssessmentTimer(startTime);
        openToast({
          show: true,
          ...getError({
            error,
            text: "Failed to submit, please try again or contact support",
            heading: "Sorry",
          }),
          type: "error",
          timeOut: 5000,
        });
      }
    }
  }, [response, error]);

  const salaryData = useMemo<
    | {
        currency: string;
        term: string;
      }
    | undefined
  >(() => {
    if (fetchResponse?.status === 200) {
      const data = fetchResponse.data.data;

      return {
        currency: data.salary_range_currency,
        term: data.salary_range_term,
      };
    } else if (fetchError) {
      openToast({
        show: true,
        ...getError({
          error: fetchError,
          text: "Failed to fetch expectations, please reload the page or contact support",
          heading: "Sorry",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
    return undefined;
  }, [fetchResponse, fetchError]);

  const showLoader = requestStatus.isPending || fetchStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <AssessmentBasicFitUI
        submit={submit}
        hasBasicExpectation={hasBasicExpectation}
        salaryData={salaryData}
        requiresLocation={fetchResponse?.data?.data?.country ? true : false}
        requiresEnv={hasBasicExpectation && reqExpEnv}
      />
    </>
  );
};

export { AssessmentBasicFit };
