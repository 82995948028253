import { logoutService } from "api";
import { ConfirmationModal, Loader } from "components";
import { useApiRequest, useToast } from "hooks";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";

const Signout = ({ show, close }) => {
  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const logout = () => run(logoutService());

  useMemo(() => {
    if (requestStatus.isPending || requestStatus.isIdle) return;
    if (response?.status === 200) {
      navigate(Routes.signin);
      localStorage.setItem("logout", "true");
    } else if (error) {
      openToast({
        show: true,
        heading: "Logout Error",
        text:
          error?.response?.data?.error?.message ??
          "Failed to logout, please try again later",
        type: "error",
        timeOut: 2500,
      });
    }
  }, [response, error]);

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <ConfirmationModal
        show={show}
        close={close}
        handleContinue={logout}
        title={"Logout"}
        text={"Are you sure you want to logout?"}
        isRed
      />
    </>
  );
};

export { Signout };
