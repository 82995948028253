import { HomeUI } from "features";

const Home = () => {
  return (
    <>
      <HomeUI />
    </>
  );
};
export { Home };
