import { PlanData, reduxPlanAction } from "../types";

const initalState: PlanData = {
  billingCycle: "",
  plan: "",
  roles: { total: 0, used: 0 },
  candidates: { total: 0, used: 0 },
  status: "active",
  amount: "",
  daysLeft: 0,
};

const planReducer = (state = initalState, action: reduxPlanAction) => {
  switch (action.type) {
    case "UPDATE_PLAN":
      return action?.payload;
    default:
      return state;
  }
};

export default planReducer;
