import { emojiAvatarsImg } from "assets";
import styles from "./styles.module.scss";

const Review = () => {
  return (
    <section className={`container ${styles.wrapper}`}>
      <h2 data-aos="slide-up" className={styles.ttl}>
        Taking the Precision Assessment: <span>A Reflective Experience</span>
      </h2>
      <div data-aos="slide-up" className={styles.content}>
        <img className={styles.content__img} src={emojiAvatarsImg} alt="" />
        <div className={styles.review}>
          <p className={styles.review__txt}>
            "The questions are straightforward and offer a lot of insight about
            the candidate. They provide valuable feedback to the employer about
            the person they're considering hiring. It also gives the candidate a
            glimpse into the job interview process and the level of preparation
            required."
          </p>
          <p className={styles.review__name}>Precious Goziemba</p>
          <p className={styles.review__role}>
            Applicant for Product Manager Role
          </p>
        </div>
      </div>
    </section>
  );
};

export { Review };
