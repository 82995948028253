import { DemoUI } from "features";

const Demo = () => {
  return (
    <>
      <DemoUI />
    </>
  );
};

export { Demo };
