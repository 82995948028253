import {
  SoftSKillAdmin,
  SoftSKillEloquence,
  SoftSkillAgility,
  SoftSkillAnalytical,
  SoftSkillCreative,
  SoftSkillEmpathy,
  SoftSkillLeadership,
  SoftSkillLearningCuriosity,
  SoftSkillPersuasive,
  SoftSkillResearch,
  SoftSkillTechCuriosity,
  candidateSoftSkillRequestData,
  candidateSoftSkillResponses,
  candidateSoftSkillService,
  fetchCandidateSoftSkillService,
} from "api";
import { Loader } from "components";
import { AssessmentSoftSkillsUI } from "features";
import { CandidateSoftSkillData } from "features/assessments/viewAssessment";
import { getError, handleAssessmentTimer } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useEffect, useMemo, useState } from "react";

interface AssessmentSoftSkillsProps {
  callback: (metric: string) => void;
}

const AssessmentSoftSkills: React.FC<AssessmentSoftSkillsProps> = ({
  callback,
}) => {
  // State
  const [startTime, setStartTime] = useState<number>(0);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const {
    run: runFetch,
    data: fetchResponse,
    requestStatus: fetchStatus,
    error: fetchError,
  } = useApiRequest({});
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const candidate = localStorage.getItem("assessmentId");

  const fetchExpectations = (id) => {
    runFetch(fetchCandidateSoftSkillService(id));
  };

  useEffect(() => {
    if (candidate) fetchExpectations(candidate);
  }, [candidate]);

  const submit = (data: CandidateSoftSkillData) => {
    if (!candidate) return;
    const secondsLeft = parseInt(localStorage.getItem("secondsLeft") ?? "0");

    const administration: SoftSKillAdmin = {
      question1: {
        a: data.administration.eventPlanning ?? 0,
        b: data.administration.proofReading ?? 0,
        c: data.administration.multiTasking ?? 0,
        d: data.administration.recording ?? 0,
        e: data.administration.appointment ?? 0,
      },
      question2: data.administration.humiliate ?? "",
    };

    const analytical_thinking: SoftSkillAnalytical = {
      question1: {
        option: data.analyticalThinking.statement ?? "",
        rating: data.analyticalThinking.statementRating ?? 0,
      },
      question2:
        data.analyticalThinking.list?.map((item) => parseInt(item.id)) ?? [],
    };

    const creative_thinking: SoftSkillCreative = {
      question1:
        data.creativeThinking.list?.map((item) => parseInt(item.id)) ?? [],
      question2: data.creativeThinking.routine ?? "",
    };

    const empathy: SoftSkillEmpathy = {
      question1: {
        a: data.empathy.relate ?? 0,
        b: data.empathy.desireToLearn ?? 0,
        c: data.empathy.smallTalk ?? 0,
        d: data.empathy.emotional ?? 0,
      },
      question2: data.empathy.successful ?? "",
    };

    const leadership: SoftSkillLeadership = {
      option: data.leadership.experience === "yes",
      question1:
        data.leadership.experience === "yes"
          ? data.leadership.overwhelm?.map((item) => parseInt(item)) ?? []
          : data.leadership.resources ?? 0,
      question2:
        data.leadership.experience === "yes"
          ? data.leadership.justice ?? ""
          : data.leadership.teach ?? 0,
    };
    const persuasion: SoftSkillPersuasive = {
      option: data.persuasion.listen === "true",
    };
    if (data.persuasion.listen === "true") {
      persuasion.question1 = data.persuasion.stress?.map((item) =>
        parseInt(item)
      );
      persuasion.question2 = data.persuasion.task;
    }

    const eloquence: SoftSKillEloquence = {
      question1: data.eloquence.daily ? parseInt(data.eloquence.daily) : 0,
      question2: data.eloquence.fear ? parseInt(data.eloquence.fear) : 0,
      question1_others:
        data.eloquence.daily === "2" && data.eloquence.dailyOther
          ? data.eloquence.dailyOther
          : null,
    };

    const technology_curiosity: SoftSkillTechCuriosity = {
      question1: data.techCuriosity.preference ?? 0,
      question2:
        data.techCuriosity.tools?.map(({ tool, proficiency }) => ({
          tool,
          rating: proficiency,
        })) ?? [],
    };
    const learning_curiosity: SoftSkillLearningCuriosity = {
      question1: data.learningCuriosity.breakAndFix ?? 0,
      question2: data.learningCuriosity.workings ?? 0,
    };
    const agility: SoftSkillAgility = {
      question1: data.agility.compete ?? 0,
      question2: data.agility.vigor ?? 0,
    };
    const research: SoftSkillResearch = {
      question1: {
        option: data.research.resilience
          ? parseInt(data.research.resilience)
          : 0,
        rating: data.research.resilienceRating ?? 0,
      },
    };

    const candidateResponses: candidateSoftSkillResponses = {
      minutes_left: Math.ceil(secondsLeft / 60),
    };

    skills.map((skill) => {
      switch (skill) {
        case "agility_work_ethic":
          candidateResponses.agility_work_ethic = agility;
          break;
        case "analytical_thinking":
          candidateResponses.analytical_thinking = analytical_thinking;
          break;
        case "administration":
          candidateResponses.administration = administration;
          break;
        case "empathy":
          candidateResponses.empathy = empathy;
          break;
        case "creative_thinking":
          candidateResponses.creative_thinking = creative_thinking;
          break;
        case "research":
          candidateResponses.research = research;
          break;
        case "eloquence":
          candidateResponses.eloquence = eloquence;
          break;
        case "learning_curiosity":
          candidateResponses.learning_curiosity = learning_curiosity;
          break;
        case "leadership_and_management":
          candidateResponses.leadership_and_management = leadership;
          break;
        case "persuasion":
          candidateResponses.persuasion = persuasion;
          break;
        case "technology_curiosity":
          candidateResponses.technology_curiosity = technology_curiosity;
          break;
        default:
          break;
      }
    });

    const submitData: candidateSoftSkillRequestData = {
      id: candidate,
      data: candidateResponses,
    };

    // Set start time
    setStartTime(new Date().getTime());
    run(candidateSoftSkillService(submitData));
  };

  const skills = useMemo<string[]>(() => {
    if (fetchResponse?.status === 200) {
      const data = fetchResponse.data.data;

      const skill = [
        data.skill1,
        data.skill2,
        data.skill3,
        data.skill4,
        data.skill5,
      ];
      return skill;
    } else if (fetchError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          fetchError?.response?.data?.message ??
          "Failed to fetch expectations, please contact support",
        type: "error",
        timeOut: 5000,
      });
    }
    return [];
  }, [fetchResponse, fetchError]);

  useMemo(() => {
    if (response?.status === 201) {
      const data = response.data.data;
      const answered = parseInt(localStorage.getItem("answered") ?? "0");
      const leadershipCount = skills.find(
        (item) => item === "leadership_and_management"
      )
        ? 3
        : 2;

      const totalCount = answered + 8 + leadershipCount;
      localStorage.setItem("answered", `${totalCount}`);
      handleAssessmentTimer(startTime);
      callback("soft_skill");
    } else if (error) {
      handleAssessmentTimer(startTime);
      openToast({
        show: true,
        ...getError({
          error,
          text: "Failed to submit, please try again or contact support",
          heading: "Sorry",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  return (
    <>
      <Loader loading={requestStatus.isPending || fetchStatus.isPending} />
      <AssessmentSoftSkillsUI skills={skills} submit={submit} />
    </>
  );
};

export { AssessmentSoftSkills };
