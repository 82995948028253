import {
  Button,
  CustomMultiSelect,
  OptionType,
  StarRating,
  optionTypeSchema,
} from "components";
import styles from "./styles.module.scss";
import { qualitiesOptions, talentFactorOptions } from "utils/options";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { useEffect, useState } from "react";
import { CloseIcon } from "assets";

export interface WorkCultureData {
  factors: OptionType[];
  qualities: OptionType[];
  prioritize: number;
  collaborative: number;
  innovative: number;
  efficient: number;
}

const initData: WorkCultureData = {
  factors: [],
  qualities: [],
  prioritize: -1,
  collaborative: -1,
  innovative: -1,
  efficient: -1,
};

const schema = yup
  .object({
    factors: yup
      .array()
      .of(optionTypeSchema())
      .required("Required")
      .min(1, "Select at least one")
      .length(3, "Select 3 factors")
      .max(3, "Select at most 3 factors"),
    qualities: yup
      .array()
      .of(optionTypeSchema())
      .required("Required")
      .min(1, "Select at least one")
      .max(3, "You can only select 3 qualities"),
    prioritize: yup.number().min(0, "Required").required("Required"),
    collaborative: yup.number().min(0, "Required").required("Required"),
    innovative: yup.number().min(0, "Required").required("Required"),
    efficient: yup.number().min(0, "Required").required("Required"),
  })
  .required();

interface WorkCultureProps {
  skip: () => void;
  back: () => void;
  submit: (data: WorkCultureData) => void;
  culture: WorkCultureData | undefined;
  title: string;
}

const WorkCultureForm: React.FC<WorkCultureProps> = ({
  skip,
  back,
  submit,
  culture,
  title,
}) => {
  const [error, setError] = useState("");
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<WorkCultureData>({
    resolver: yupResolver(schema),
    defaultValues: culture ?? initData,
  });

  useEffect(() => {
    culture && reset(culture);
  }, [culture]);

  const isChecked = (option: OptionType) => {
    const value = watch("factors");

    return Array.isArray(value) &&
      value.find((item) => item.value === option.value)?.value
      ? true
      : false;
  };

  const handleAdd = (val: OptionType) => {
    const value = [...watch("factors")];

    const index = value.findIndex((object) => {
      return object.value === val.value;
    });

    if (index === -1) {
      const prevList = [...value];
      setValue("factors", [val, ...prevList]);
    } else {
      handleRemove(val);
    }
  };

  const handleRemove = (val) => {
    const value = [...watch("factors")];

    const index = value.findIndex((object) => {
      return object.value === val.value;
    });
    const prevList = [...value];
    prevList.splice(index, 1);
    setValue("factors", [...prevList]);
  };

  const onSubmit: SubmitHandler<WorkCultureData> = (data) => {
    if (
      data.prioritize === 6 &&
      data.innovative === 6 &&
      data.collaborative === 6 &&
      data.efficient === 6
    ) {
      return setError(
        "This is an unrealistic expectation of a potential hire, please set attainable ratings"
      );
    }
    return submit(data);
  };

  const onSubmitError: SubmitErrorHandler<WorkCultureData> = () => {
    const data = watch();
    if (
      data.prioritize === 6 &&
      data.innovative === 6 &&
      data.collaborative === 6 &&
      data.efficient === 6
    ) {
      return setError(
        "This is an unrealistic expectation of a potential hire, please set attainable ratings"
      );
    }
  };

  const handleRemoveQuality = (index) => {
    const value = [...watch("qualities")];

    const prevList = [...value];
    prevList.splice(index, 1);
    setValue("qualities", [...prevList]);
  };

  const isEmpty = JSON.stringify(watch()) === JSON.stringify(initData);

  useEffect(() => {
    if (isEmpty) localStorage.removeItem("work-cultureIsFilled");
    else localStorage.setItem("work-cultureIsFilled", "filled");
  }, [watch()]);

  return (
    <>
      <section className={styles.heading}>
        <h2 className={styles.ttl}>{title}</h2>
        <p className={styles.txt}>
          Evaluate candidates based on their compatibility with your team's
          working style, and motivation to succeed.
        </p>
        <Button className={styles.skipBtn} type="outline" onClick={skip}>
          Skip
        </Button>
      </section>
      <form className={styles.form}>
        <div className={styles.factors}>
          <p className={styles.ttl}>
            The best talent to work on this role, for this organisation are
            those who:
          </p>
          <p className={styles.txt}>
            Select three (3) critical factors you would consider
          </p>
          <fieldset className={styles.factorSet}>
            {talentFactorOptions.map((item, idx) => (
              <label
                key={`skill_${idx}`}
                className={`${styles.factor} ${styles.skill} ${
                  watch("factors").length >= 3 && !isChecked(item)
                    ? styles.disable
                    : ""
                }`}
              >
                <input
                  onChange={() => handleAdd(item)}
                  checked={isChecked(item)}
                  type="checkbox"
                  disabled={watch("factors").length >= 3 && !isChecked(item)}
                />
                <span>{item.label}</span>
              </label>
            ))}
          </fieldset>
          <p className={styles.error}>{errors.factors?.message}</p>
        </div>
        <div className={styles.qualities}>
          <p className={styles.ttl}>Choose 3 relevant candidate qualities</p>
          <CustomMultiSelect
            placeholder="Relevant qualities for role and team culture."
            options={qualitiesOptions}
            onChange={(val) => setValue("qualities", val)}
            validatorMessage={errors?.qualities?.message?.toString() ?? ""}
            name={"qualities"}
            value={watch("qualities")}
            max={3}
          />
          <div className={styles.qualityList}>
            {watch("qualities").map((item, index) => (
              <span key={`quality_${index}`}>
                {item.label}{" "}
                <CloseIcon
                  role="button"
                  onClick={() => handleRemoveQuality(index)}
                />
              </span>
            ))}
          </div>
        </div>
        <div className={styles.demands}>
          <p className={styles.ttl}>This role demands that the hired talent:</p>
          <p className={styles.txt}>
            Rate the following from highest (7) to the lowest (1) as they relate
            to the role
          </p>

          <ol>
            <li>
              <span className={errors.prioritize?.message ? styles.red : ""}>
                1. Prioritizes what needs to be done and executes quickly
              </span>
              <StarRating
                count={7}
                startAt={0}
                value={watch("prioritize")}
                onChange={(x) => setValue("prioritize", x)}
              />
            </li>
            <li>
              <span className={errors.collaborative?.message ? styles.red : ""}>
                2. Creates a collaborative atmosphere for others to thrive
              </span>
              <StarRating
                count={7}
                startAt={0}
                value={watch("collaborative")}
                onChange={(x) => setValue("collaborative", x)}
              />
            </li>
            <li>
              <span className={errors.innovative?.message ? styles.red : ""}>
                3. Generates innovative ideas and approaches to achieving
                company goals
              </span>
              <StarRating
                count={7}
                startAt={0}
                value={watch("innovative")}
                onChange={(x) => setValue("innovative", x)}
              />
            </li>
            <li>
              <span className={errors.efficient?.message ? styles.red : ""}>
                4. Creates efficient processes that gets the job done
              </span>
              <StarRating
                count={7}
                startAt={0}
                value={watch("efficient")}
                onChange={(x) => setValue("efficient", x)}
              />
            </li>
          </ol>
          {error !== "" ? <p className={styles.error}>{error}</p> : ""}
        </div>
        <div className={styles.btnSec}>
          <Button type="outline" onClick={back}>
            Previous
          </Button>
          <Button type="fill" onClick={handleSubmit(onSubmit, onSubmitError)}>
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

export { WorkCultureForm };
