import { resetPasswordService } from "api";
import { Loader } from "components";
import { NewPasswordSuccessModal } from "components/modalVariants";
import { NewPasswordUI } from "features";
import { queryObject } from "helpers";
import { useToast, useApiRequest } from "hooks";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const NewPassword = () => {
  const [success, setSuccess] = useState(false);
  const [clear, setClear] = useState(false);
  const { search } = useLocation();

  const { email, token } = queryObject(search);
  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const submit = (data) => {
    run(resetPasswordService({ email, token, password: data.password }));
  };

  useMemo(() => {
    if (response?.status === 200) {
      setSuccess(true);
      setClear((prev) => !prev);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to change password, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <NewPasswordSuccessModal show={success} close={() => setSuccess(false)} />
      <NewPasswordUI clear={clear} sumbit={submit} />
    </>
  );
};

export { NewPassword };
