import {
  fetchAssessmentActivityService,
  fetchGraphDataService,
  fetchStatsService,
  updateCompanyService,
} from "api";
import { GraphProps, ImageUpload, Loader } from "components";
import { EmployerOverviewUI } from "features";
import { getDateTime, getDaysBetween } from "helpers";
import { useApiRequest, useFetchUser, useToast } from "hooks";
import { Navbar } from "layout";
import { OnboardingModal } from "pages";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { useAppSelector } from "state/hooks";

const EmployerOverview = () => {
  // States
  const [period, setPeriod] = useState("past_day");
  const [image, setImage] = useState(false);

  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();
  const { company, companyLogo } = useAppSelector((state) => state.user);
  const { getUser } = useFetchUser();

  // API Request Hooks
  const {
    run: runStats,
    data: statsResponse,
    requestStatus: statsStatus,
    error: statsError,
  } = useApiRequest({});
  const {
    run: runActivity,
    data: activityResponse,
    requestStatus: activityStatus,
    error: activityError,
  } = useApiRequest({});
  const {
    run: runInsights,
    data: insightsResponse,
    requestStatus: insightsStatus,
    error: insightsError,
  } = useApiRequest({});
  const {
    run: runUpdateCompany,
    data: updateCompanyResponse,
    requestStatus: updateCompanyStatus,
    error: updateCompanyError,
  } = useApiRequest({});

  const fetchStats = () => runStats(fetchStatsService());
  const fetchActivity = () => runActivity(fetchAssessmentActivityService());
  const fetchInsights = () => runInsights(fetchGraphDataService(period));

  useEffect(() => {
    fetchStats();
    fetchActivity();
  }, []);

  useEffect(() => {
    fetchInsights();
  }, [period]);

  const stats = useMemo(() => {
    if (statsResponse?.status === 200) {
      const data = statsResponse.data.data;

      return {
        activeAssessments: data.active_assessments,
        totalTalents: data.total_talents_assessed,
        createdAssessments: data.created_assessments,
        daysLeft: !data.plan.has_expired
          ? data.days_left_to_expiration > 0
            ? data.days_left_to_expiration
            : "< 1" ?? "---"
          : "---",
        plan: data.plan?.plan_type.replaceAll("_", " ") ?? "---",
      };
    } else if (statsError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          statsError?.response?.data?.error?.message ??
          "Failed to fetch stats, please reload or try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [statsResponse, statsError]);

  const createAssessment = () => {
    navigate(Routes.employerCreateAssessment);
  };

  const activity = useMemo(() => {
    if (activityResponse?.status === 200) {
      const data = activityResponse.data.data;

      return data.map((item) => ({
        title: item.title,
        start: {
          date: getDateTime(item.start_date).date,
          time: getDateTime(item.start_date).time,
        },
        status: item.status,
        completed: item.candidate_summary.completed,
        abandoned: item.candidate_summary.abandoned,
        deadline:
          getDaysBetween(item.end_date).days > 0
            ? `${getDaysBetween(item.end_date).days} ${
                getDaysBetween(item.end_date).days > 1 ? "days" : "day"
              }`
            : item.status === "closed"
            ? "Expired"
            : "< 1 day",
      }));
    } else if (activityError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          activityError?.response?.data?.error?.message ??
          "Failed to fetch assessment activity, please reload or try again later",
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [activityResponse, activityError]);

  const graphData = useMemo<GraphProps>(() => {
    if (insightsResponse?.status === 200) {
      const data = insightsResponse.data.data;

      return {
        data: data.graph_plots.map((item) => item.plot_values.total),
        labels: data.graph_plots.map((item) => item.plot_name),
        breakdown: data.graph_plots.map((item) => ({
          title: item.plot_name,
          abandoned: item.plot_values.abandoned,
          completed: item.plot_values.completed,
        })),
      };
    } else if (insightsError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          statsError?.response?.data?.error?.message ??
          "Failed to fetch report insights, please reload or try again later",
        type: "error",
        timeOut: 5000,
      });
    }

    return {
      data: [],
      labels: [],
      breakdown: [],
    };
  }, [insightsResponse, insightsError]);

  const handleCompanyUpdate = (data) => {
    runUpdateCompany(updateCompanyService(data));
  };

  useMemo(() => {
    if (updateCompanyResponse?.status === 200) {
      getUser();
      setImage(false);
      openToast({
        show: true,
        heading:
          updateCompanyResponse?.data?.message ?? "Company profile updated!",
        text: "",
        type: "success",
        timeOut: 2000,
      });
    } else if (updateCompanyError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          updateCompanyError?.response?.data?.error?.message ??
          "Failed to update your company profile, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [updateCompanyResponse, updateCompanyError]);

  const showLoader =
    statsStatus.isPending ||
    activityStatus.isPending ||
    insightsStatus.isPending ||
    updateCompanyStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <ImageUpload
        show={image}
        type="company_logo"
        close={() => setImage(false)}
        submit={(image) => handleCompanyUpdate({ company_logo: image })}
      />
      <OnboardingModal />
      <Navbar title={"Dashboard"} />
      <EmployerOverviewUI
        statData={stats}
        activites={activity}
        createAssessment={createAssessment}
        graphData={graphData}
        period={{
          value: period,
          onChange: setPeriod,
        }}
        handleLogo={() => setImage(true)}
        company={company}
        logo={companyLogo}
        handleNameChange={(name) => handleCompanyUpdate({ name })}
      />
    </>
  );
};

export { EmployerOverview };
