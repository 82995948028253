import { FAQItem, FAQItemData } from "components";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "router";

const FAQs = () => {
  const [active, setActive] = useState(-1);

  const faqs: FAQItemData[] = [
    {
      question: "Is there a free version of the app for users? ",
      answer: "Yes, we offer a 14-day free trial to new users",
    },
    {
      question: "What are the pricing packages available? ",
      answer: (
        <>
          Our pricing packages are:
          <ul style={{ paddingLeft: "20px" }}>
            <li>Pay-As-You-Go - $20</li>
            <li>
              Regular - $85 - recruit for up to 5 roles and 750 candidates{" "}
            </li>
            <li>Pro - $170 - recruit for up to 10 roles and 1500 candidates</li>
          </ul>
        </>
      ),
    },
    {
      question: "Can I upgrade my plan? ",
      answer:
        "Yes, you can opt for any of our plan packages at any time, you can upgrade to Pro to assess for more roles and more candidates. ",
    },
    {
      question: "Can I choose a lower plan for a bit?",
      answer:
        "You can subscribe to a plan that serves your organization best at any given time. Our different plans are available at all times to all users. ",
    },
    {
      question: "What is Autoscoring?",
      answer: `Autoscoring is automatic AI scoring done on a candidate's open-ended question response to eliminate the need for manual reviews of multiple entries for specific assessment question types on Precision

      This can be triggered by the recruiter or hiring team on a per-need basis.
      `,
    },
    {
      question: "Is Autoscoring done for free? ",
      answer:
        "Autoscoring is paid for. The recruiter can pay for a candidate or candidates. Autoscoring is charged per candidate selected",
    },
    {
      question: "What are custom plans?",
      answer:
        "The custom plan helps recruiters create a subscription package that works better for them apart from what is available already. You can set the number of roles and candidates you would love to manage per time and pay for them.",
    },
  ];

  return (
    <>
      <section className={`container ${styles.wrapper}`}>
        <h3 data-aos="slide-up" className={styles.ttl}>
          Frequently Asked Questions?
        </h3>

        <div data-aos="slide-up" className={styles.faqList}>
          {faqs.map((item, index) => (
            <FAQItem
              {...item}
              key={`faw_${index}`}
              state={index}
              active={active}
              changeActive={(x) => setActive(x)}
            />
          ))}
        </div>
        <p className={styles.contact}>
          Still have questions? <Link to={Routes.contact}>Reach out</Link>, we’d
          be happy to help.
        </p>
      </section>
    </>
  );
};

export { FAQs };
