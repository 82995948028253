import * as React from "react";
import styles from "./styles.module.css";
import { ToastData } from "state";

const Toast: React.FC<ToastData> = ({ heading, text, show, type }) => {
  if (!show) return null;

  return (
    <aside className={`${styles.container} ${styles[type]}`}>
      <div className={styles.body}>
        <p className={styles.ttl}>{heading}</p>
        <p className={styles.txt}>{text}</p>
      </div>
    </aside>
  );
};

export { Toast };
