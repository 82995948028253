import { TickIconCircleFill, CloseIconCircleFIll } from "assets";
import styles from "./styles.module.scss";

interface MetricExpectationsProps {
  data: { title: string; isMatch?: boolean }[];
}

const MetricExpectations: React.FC<MetricExpectationsProps> = ({ data }) => {
  return (
    <>
      <section className={styles.legend}>
        {data.map((item) => (
          <div
            key={item.title}
            className={`${styles.legend__item} ${
              styles[`legend__item--${item.isMatch === false ? "red" : "blue"}`]
            }`}
          >
            <p>{item.title}</p>
            {item.isMatch === true ? (
              <TickIconCircleFill className={styles.blueIcon} />
            ) : item.isMatch === false ? (
              <CloseIconCircleFIll />
            ) : (
              ""
            )}
          </div>
        ))}
      </section>
    </>
  );
};

export { MetricExpectations };
