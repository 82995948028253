import { PreviewCompleteUI } from "features";

const AssessmentComplete = () => {
  return (
    <>
      <PreviewCompleteUI />
    </>
  );
};

export { AssessmentComplete };
