import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, InputScale } from "components";

interface WorkStyleData {
  productive: {
    liberty: number;
    inCharge: number;
    detail: number;
    communicate: number;
  };
  priority: {
    importance: number;
    reason: number;
    method: number;
    stakeholders: number;
  };
}

const initData: WorkStyleData = {
  productive: {
    liberty: -1,
    inCharge: -1,
    detail: -1,
    communicate: -1,
  },
  priority: {
    importance: -1,
    reason: -1,
    method: -1,
    stakeholders: -1,
  },
};

const schema = yup
  .object()
  .shape({
    productive: yup
      .object({
        liberty: yup.number().min(0, "Required").required("Required"),
        inCharge: yup.number().min(0, "Required").required("Required"),
        detail: yup.number().min(0, "Required").required("Required"),
        communicate: yup.number().min(0, "Required").required("Required"),
      })
      .required("Required"),
    priority: yup
      .object({
        importance: yup.number().min(0, "Required").required("Required"),
        reason: yup.number().min(0, "Required").required("Required"),
        method: yup.number().min(0, "Required").required("Required"),
        stakeholders: yup.number().min(0, "Required").required("Required"),
      })
      .required("Required"),
  })
  .required();

interface AssessmentWorkStyleProps {
  submit: (data: WorkStyleData) => void;
}
const AssessmentWorkStyleUI: React.FC<AssessmentWorkStyleProps> = ({
  submit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = useForm<WorkStyleData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const onSubmit: SubmitHandler<WorkStyleData> = (data) => {
    const errorPriority =
      data.priority.importance === 6 &&
      data.priority.reason === 6 &&
      data.priority.method === 6 &&
      data.priority.stakeholders === 6;

    const errorProductive =
      data.productive.communicate === 6 &&
      data.productive.detail === 6 &&
      data.productive.inCharge === 6 &&
      data.productive.liberty === 6;

    if (errorPriority || errorProductive) {
      const msg =
        "This is an unrealistic self assessment, please re-assess yourself realistically";
      const msg2 = "Please re-assess";

      if (errorPriority) {
        setError("priority", { message: msg });
        setError("priority.importance", { message: msg2 });
        setError("priority.reason", { message: msg2 });
        setError("priority.method", { message: msg2 });
        setError("priority.stakeholders", { message: msg2 });
      }

      if (errorProductive) {
        setError("productive", { message: msg });
        setError("productive.communicate", { message: msg2 });
        setError("productive.detail", { message: msg2 });
        setError("productive.inCharge", { message: msg2 });
        setError("productive.liberty", { message: msg2 });
      }
    } else {
      submit(data);
    }
  };

  return (
    <>
      <form>
        <section className={styles.questionWrap}>
          <p className={styles.question}>
            In the following statements, rate how you are most productive. You
            are most productive when:
          </p>
          {errors.productive?.message && (
            <p className={styles.errorMessage}>{errors.productive?.message}</p>
          )}
          <InputScale
            register={register}
            name={"productive.liberty"}
            validatorMessage={errors.productive?.liberty?.message}
            label="You know exactly what needs to be done and you’re given the liberty to get work done without excessive interruptions."
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"productive.inCharge"}
            validatorMessage={errors.productive?.inCharge?.message}
            label="You are in charge of generating the ideas and strategies, painting a picture of the possible impact an idea might have. "
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"productive.detail"}
            validatorMessage={errors.productive?.detail?.message}
            label="You can detail all aspects of work to be done and meticulously implement
            "
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"productive.communicate"}
            validatorMessage={errors.productive?.communicate?.message}
            label="You have to communicate with, meet people and strike conversations or foster a collaborative environment for people to get work done.
            "
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
        </section>
        <section className={styles.questionWrap}>
          <p className={styles.question}>
            Which of these questions are likely to come first to your mind when
            you get a project brief?
            <span>
              Rank on a scale from most likely to ask to the least likely to ask
            </span>
          </p>
          {errors.priority?.message && (
            <p className={styles.errorMessage}>{errors.priority?.message}</p>
          )}

          <InputScale
            register={register}
            name={"priority.importance"}
            validatorMessage={errors.priority?.importance?.message}
            label="What is important to get done?"
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"priority.reason"}
            validatorMessage={errors.priority?.reason?.message}
            label="Why are we using this approach?"
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"priority.method"}
            validatorMessage={errors.priority?.method?.message}
            label="How are we going to get this done?"
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"priority.stakeholders"}
            validatorMessage={errors.priority?.stakeholders?.message}
            label="Who are the stakeholders?"
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
        </section>
        <Button
          className={styles.nextBtn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </Button>
      </form>
    </>
  );
};

export { AssessmentWorkStyleUI };
