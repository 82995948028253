import { FAQItem, FAQItemData } from "components";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "router";

const FAQs = () => {
  const [active, setActive] = useState(-1);

  const faqs: FAQItemData[] = [
    {
      question: "How do I begin the assessment?",
      answer:
        "You would get an invite on your email or the recruiter would send a link to you. When you click on the link, you will be directed to the assessment, with the instruction page first displayed, once this is understood, you can enter your email address and get started. ",
    },
    {
      question: "Is the assessment timed?",
      answer:
        "Yes. After the instructions screen and you have entered your email address, the questions are displayed and your time starts counting.",
    },
    {
      question: "What do I expect during the assessment?",
      answer:
        "Our assessment is to test your work culture, personality, and technical skills. You can expect questions in these areas.",
    },
    {
      question: "Can I pause my assessment and come back to it?",
      answer: (
        <>
          No, you can’t. Once, you have gone through the instructions and
          started your assessment, you are expected to complete the assessment
          within the time provided without exiting.
          <br />
          If you encounter any issues while testing, kindly reach out to us at
          contact@precisionmatch.ai
        </>
      ),
    },
    {
      question: "Can I view my assessment score? ",
      answer:
        "No, the report on your assessment would be viewed only by the recruiter. ",
    },
    {
      question: "I can’t link my CV, what should I do?",
      answer:
        "You can also input the link to your portfolio and any other relevant link that would show your work experience so far. ",
    },
    {
      question:
        "How can I prepare for Precision screening to maximize my chances of success?",
      answer: `To optimize your chances, ensure that your resume and application materials accurately reflect your skills, experiences, and qualifications. Be authentic in your responses and showcase your technical and cultural alignment with the company requirements for the role`,
    },
  ];
  return (
    <>
      <section className={`container ${styles.wrapper}`}>
        <h3 data-aos="slide-up" className={styles.ttl}>
          Frequently Asked Questions?
        </h3>

        <div data-aos="slide-up" className={styles.faqList}>
          {faqs.map((item, index) => (
            <FAQItem
              {...item}
              key={`faw_${index}`}
              state={index}
              active={active}
              changeActive={(x) => setActive(x)}
            />
          ))}
        </div>
        <p className={styles.contact}>
          Still have questions? <Link to={Routes.contact}>Reach out</Link>, we’d
          be happy to help.
        </p>
      </section>
    </>
  );
};

export { FAQs };
