import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, InputScale, RadioInput } from "components";
import { ProblemSolvingOptions, SocialOptions } from "./options";

interface PersonalityData {
  social: string | undefined;
  socialRating: number | undefined;
  problemSolving: string | undefined;
  problemSolvingRating: number | undefined;
  innovation: number | undefined;
  drive: number | undefined;
  judge: number | undefined;
  othersNeeds: number | undefined;
  improvise: number | undefined;
  productive: number | undefined;
}

const initData: PersonalityData = {
  social: "",
  socialRating: 0,
  problemSolving: "",
  problemSolvingRating: 0,
  innovation: 0,
  drive: 0,
  judge: 0,
  othersNeeds: 0,
  improvise: 0,
  productive: 0,
};

const schema = yup.object().shape({
  social: yup.string(),
  socialRating: yup.number(),
  problemSolving: yup.string(),
  problemSolvingRating: yup.number(),
  innovation: yup.number(),
  drive: yup.number(),
  judge: yup.number(),
  othersNeeds: yup.number(),
  improvise: yup.number(),
  productive: yup.number(),
});

interface PreviewPersonalityProps {
  submit: (data: PersonalityData) => void;
}

const PreviewPersonalityUI: React.FC<PreviewPersonalityProps> = ({
  submit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<PersonalityData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const onSubmit: SubmitHandler<PersonalityData> = (data) => submit(data);

  return (
    <>
      <form>
        <RadioInput
          register={register}
          name={"social"}
          validatorMessage={
            errors.social?.message ?? errors.socialRating?.message
          }
          label="1. When you're in a social situation, which of the following do you prefer?"
          instruction="Select the most likely option and rate how operationally relevant it is to you."
          options={SocialOptions}
          type="radio"
          value={watch("social")}
          rating={{
            count: 7,
            startAt: 0,
            value: watch("socialRating") ?? 0,
            onChange: (x) => setValue("socialRating", x),
          }}
        />
        <RadioInput
          register={register}
          name={"problemSolving"}
          validatorMessage={
            errors.problemSolving?.message ??
            errors.problemSolvingRating?.message
          }
          label="2. When it comes to problem-solving, which of the following approaches do you find most effective?"
          instruction="Select the most likely option and rate how operationally relevant it is to you."
          options={ProblemSolvingOptions}
          type="radio"
          value={watch("problemSolving")}
          rating={{
            count: 7,
            startAt: 0,
            value: watch("problemSolvingRating") ?? 0,
            onChange: (x) => setValue("problemSolvingRating", x),
          }}
        />
        <InputScale
          register={register}
          name={"innovation"}
          validatorMessage={errors.innovation?.message}
          label="3. Creativity at the expense of practicality is foolishness. The best innovations are what people are familiar with. Do you agree with this rationale?"
          length={7}
          leftMost="Yes, I do"
          rightMost="I do not agree"
          startAt={6}
        />
        <InputScale
          register={register}
          name={"drive"}
          validatorMessage={errors.drive?.message}
          label="4. Which drives you more present realities or possibilities of the future?"
          length={7}
          leftMost="Present realities"
          rightMost="Future possibilities"
          startAt={6}
        />
        <InputScale
          register={register}
          name={"judge"}
          validatorMessage={errors.judge?.message}
          label="5. You are made judge of a dicey situation (with both facts and people’s lives at stake), in passing judgement which would ultimately guide your decision?"
          length={7}
          leftMost="Gut"
          rightMost="Facts"
          startAt={6}
        />
        <InputScale
          register={register}
          name={"othersNeeds"}
          validatorMessage={errors.othersNeeds?.message}
          label="6. Are you prone to putting others' needs ahead of yours?"
          length={7}
          leftMost="Yes, I do"
          rightMost="Only if it makes sense"
          startAt={6}
        />
        <InputScale
          register={register}
          name={"improvise"}
          validatorMessage={errors.improvise?.message}
          label="7. It’s easier for you to improvise than plan."
          length={7}
          leftMost="True, I prefer improvising"
          rightMost="False, I prefer to plan"
          startAt={6}
        />
        <InputScale
          register={register}
          name={"productive"}
          validatorMessage={errors.productive?.message}
          label="8. To have the most productive day, do you make progress on a bunch of tasks or thoroughly complete a few tasks?"
          length={7}
          leftMost="Make progress"
          rightMost="Thorough completion"
          startAt={6}
        />
        <Button
          className={styles.nextBtn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </Button>
      </form>
    </>
  );
};

export { PreviewPersonalityUI };
