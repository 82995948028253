import { candidateCompleteAssessmentService } from "api";
import { Loader } from "components";
import { AssessmentMalpracticeUI } from "features";
import { useApiRequest, useToast } from "hooks";
import { ReactNode, useEffect, useMemo, useState } from "react";

const AssessmentMalpractice = () => {
  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const candidate = localStorage.getItem("assessmentId");

  useEffect(() => {
    handleSubmit();
  }, []);

  useMemo(() => {
    if (response?.status === 200) {
      localStorage.removeItem("secondsLeft");
      localStorage.removeItem("answered");
      localStorage.removeItem("candidate");
      localStorage.removeItem("assessmentId");
      localStorage.setItem("isComplete", "true");
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.message ??
          "Failed to complete assessment, please reload the page again or contact support if the error persists",
        type: "error",
        timeOut: 15000,
      });
    }
  }, [response, error]);

  const handleSubmit = () => {
    candidate &&
      run(
        candidateCompleteAssessmentService({
          rating: 1,
          response_id: candidate,
          malpractice: true,
        })
      );
  };

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <AssessmentMalpracticeUI />
    </>
  );
};

export { AssessmentMalpractice };
