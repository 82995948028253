/*
=================================
ASSESSMENT REPORT URLS
=================================
*/

import { appendParams } from "helpers";

/**
 * Fetch assessments for reports url
 *
 * @returns url string
 *
 */

export const fetchAssessmentForReportsURL = ({
  status,
  page,
  search,
  dateFrom,
  dateTo,
}) =>
  `/reports/assessments/?${appendParams({
    status,
    page,
    search,
    dateFrom,
    dateTo,
  })}`;

/**
 * Fetch reports for a role/assessment url
 *
 * @returns url string
 *
 */

export const fetchRoleReportsURL = ({
  bucket,
  page,
  search,
  assessment,
  progress,
  lower_score,
  upper_score,
}) =>
  `/reports/?${appendParams({
    bucket,
    page,
    search,
    assessment,
    progress,
    lower_score,
    upper_score,
  })}`;

/**
 * Move report to a bucket url
 *
 * @returns url string
 *
 */
export const moveReportURL = () => `/reports/move/`;

/**
 * Fetch candidate report url
 *
 * @returns url string
 *
 */
export const fetchReportURL = (id) => `/reports/${id}/`;

/**
 * Generate AI scoring for report url
 *
 * @returns url string
 *
 */
export const generateAIScoreURL = (action?) =>
  `/reports/domain/score/?${appendParams({ action })}`;

/**
 * Fetch candidate report unauthenticated url
 *
 * @returns url string
 *
 */
export const fetchReportUnauthURL = (id) => `/candidate/reports/${id}/`;
