import {
  Button,
  CustomSelect,
  EmptyTable,
  OptionType,
  ReportRoleTable,
  ReportRoleTableItem,
  Table,
  TableHeaderItemProps,
  ToolTip,
  initOptionType,
} from "components";
import styles from "./styles.module.scss";
import { MouseEvent, useEffect, useState } from "react";
import { InfoIcon, emptyReport2 } from "assets";
import { moveReportRequestData } from "api";
import { bucketOptions } from "utils/options";
import { Routes } from "router";
import { Link } from "react-router-dom";

const tableHeaderTitles: TableHeaderItemProps[] = [
  { title: "Candidate name" },
  { title: "Date & Time Assessed" },
  { title: "Email" },
  { title: "Phone number" },
  { title: "CV" },
  { title: "Portfolio/Links" },
  { title: "Score" },
];

interface ReportsProps {
  handleView: (id: string) => void;
  reports: ReportRoleTableItem[];
  pagination: {
    handleChange: (page) => void;
    total: number;
    current: number;
    count: number;
  };
  status: {
    value: string;
    onChange: (status: string) => void;
  };
  progress: {
    value: OptionType;
    onChange: (status: OptionType) => void;
  };
  score: {
    value: OptionType;
    onChange: (status: OptionType) => void;
  };
  handleBucket: (data: moveReportRequestData) => void;
  role: string;
  handleScoring: (candidates?: number[]) => void;
  isDefault: boolean;
}

const ReportRoleUI: React.FC<ReportsProps> = ({
  handleView,
  reports,
  status,
  progress,
  score,
  pagination,
  handleBucket,
  role,
  handleScoring,
  isDefault,
}) => {
  const [bucket, setBucket] = useState<OptionType>(initOptionType);
  const [checks, setChecks] = useState<boolean[]>(
    new Array(reports.length).fill(false)
  );
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    setCheckAll(checks.every((item) => item));
    setBucket(initOptionType);
  }, [checks]);

  useEffect(() => {
    setChecks(new Array(reports.length).fill(false));
  }, [reports]);

  const tabs = [
    {
      title: "Assessed",
      id: "assessed",
    },
    {
      title: "Consider",
      id: "consider",
    },
    {
      title: "Interview",
      id: "to_interview",
    },
    {
      title: "Accepted",
      id: "accepted",
    },
    {
      title: "Reject",
      id: "reject",
    },
    {
      title: "Malpractice",
      id: "malpractice",
    },
  ];

  const scoreOptions: OptionType[] = [
    {
      label: "100 - 90%",
      value: "100 - 90%",
    },
    {
      label: "89 - 70%",
      value: "89 - 70%",
    },
    {
      label: "69 - 50%",
      value: "69 - 50%",
    },
    {
      label: "49 - 30%",
      value: "49 - 30%",
    },
    {
      label: "29 - 0%",
      value: "29 - 0%",
    },
  ];

  const progressOptions: OptionType[] = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Started",
      value: "started",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Abandoned",
      value: "abandoned",
    },
  ];

  return (
    <>
      <p className={styles.txt}>
        <Link to={Routes.employerReports}>Assessment</Link> / {role}
      </p>
      <div className={styles.actions}>
        <section className={styles.filterSec}>
          <CustomSelect
            onChange={score.onChange}
            validatorMessage={""}
            name={""}
            placeholder={"Score range"}
            options={scoreOptions}
            value={score.value}
            inputClass={styles.select}
            parentClassName={styles.selectWrap}
          />
          <CustomSelect
            onChange={progress.onChange}
            validatorMessage={""}
            name={""}
            placeholder={"Progress"}
            options={progressOptions}
            value={progress.value}
            inputClass={styles.select}
            parentClassName={styles.selectWrap}
          />
        </section>
        {reports.length > 0 && isDefault && (
          <div className={styles.ctaSec}>
            <ToolTip
              className={styles.info}
              title="Auto-scoring"
              text={`Eliminate manual report review, activate this feature to
                  automate the scoring of open-ended responses for default
                  questions.`}
            />
            <Button type={"fill"} onClick={() => handleScoring()}>
              Auto-score all
            </Button>
          </div>
        )}
      </div>

      <section className={styles.container}>
        <div className={styles.tabs}>
          {tabs.map((item, index) => (
            <span
              role="button"
              key={index}
              onClick={() => status.onChange(item.id)}
              className={item.id === status.value ? styles.active : ""}
            >
              {item.title}
            </span>
          ))}
        </div>
        {status.value !== "malpractice" && checks.some((item) => item) ? (
          <section className={styles.bucketFilter}>
            {reports.length > 0 && isDefault ? (
              <div className={styles.ctaSec}>
                <ToolTip
                  className={styles.info}
                  title="Auto-scoring"
                  text={`Eliminate manual report review, activate this feature to
                  automate the scoring of open-ended responses for default
                  questions.`}
                />
                <Button
                  disabled={bucket.value !== ""}
                  type={"fill"}
                  onClick={() => {
                    const selected = checks
                      .map((item, index) => (item ? reports[index].id : ""))
                      .filter((item) => item !== "");
                    handleScoring(selected.map((item) => parseInt(item)));
                  }}
                >
                  Auto-score selected
                </Button>
              </div>
            ) : (
              ""
            )}
            <CustomSelect
              onChange={setBucket}
              validatorMessage={""}
              name=""
              label={"Move to"}
              placeholder={"Select bucket"}
              options={bucketOptions}
              value={bucket}
              inputClass={styles.select}
              parentClassName={styles.selectWrap}
            />
            {bucket.value !== "" ? (
              <Button
                type="fill"
                onClick={() => {
                  const selected = checks
                    .map((item, index) => (item ? reports[index].id : ""))
                    .filter((item) => item !== "");

                  handleBucket({
                    new_bucket: bucket.value,
                    selected_responses: selected.map((item) => parseInt(item)),
                  });
                }}
                className={styles.reset}
              >
                Move
              </Button>
            ) : (
              ""
            )}
          </section>
        ) : (
          ""
        )}
        <Table
          tableHeaderTitles={tableHeaderTitles}
          tableHeaderCheck={{
            value: checkAll,
            handleCheck: () => {
              setCheckAll(!checkAll);
              setChecks((prev) => prev.map((item) => !checkAll));
            },
            className: styles.checkWrap,
          }}
          tableBody={
            <ReportRoleTable
              tableBodyItems={reports}
              handleView={handleView}
              tableBodyItemClassName={""}
              tableBodyRowClassName={styles.tableRow}
              pagination={{
                current: pagination.current,
                total: pagination.total,
                handleChange: pagination.handleChange,
                count: pagination.count,
                length: 8,
              }}
              checks={checks}
              handleCheck={({ index, value }) => {
                setChecks((prev) =>
                  prev.map((item, idx) => (idx === index ? value : item))
                );
              }}
            />
          }
          customTableClasses={{
            tableContainerClassName: styles.tableWrap,
            tableHeaderClassName: styles.tableHeader,
            tableHeaderItemClassName: styles.tableHeaderItem,
          }}
          emptyTable={{
            show: reports.length === 0,
            element: (
              <EmptyTable
                image={emptyReport2}
                text="Your candidates are yet to complete an assessment"
              />
            ),
          }}
        />
      </section>
    </>
  );
};

export { ReportRoleUI };
