import { SparklesIcon, assessmentsMockupImg } from "assets";
import styles from "./styles.module.scss";

const Intro = () => {
  return (
    <section className={styles.introBg}>
      <div className={`container ${styles.intro}`}>
        <div className={styles.intro__content}>
          <p className={styles.intro__tag}>
            <SparklesIcon /> Introducing Precision
          </p>
          <h2 className={styles.intro__ttl}>Assessing Real Performance</h2>
          <p className={styles.intro__txt}>
            Spotting competent and effective talent from a sea of applicants can
            feel like an uphill battle. It's tough to sift through CVs and
            introductory calls, trying to decipher who's genuine and who's
            embellishing their credentials.
          </p>
        </div>
        <img
          data-aos="slide-left"
          className={styles.intro__img}
          src={assessmentsMockupImg}
          alt=""
        />
      </div>
    </section>
  );
};

export { Intro };
