import { SignupRequestData, signupService } from "api";
import { Loader } from "components";
import { SignupUI } from "features";
import { useApiRequest, useToast } from "hooks";
import { useMemo, useState } from "react";
import { EmailVerificationPrompt } from "../emailVerificationPrompt";

const Signup = () => {
  // States
  const [clear, setClear] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const {
    run: runSignup,
    data: signupResponse,
    requestStatus,
    error,
  } = useApiRequest({});

  useMemo(() => {
    if (signupResponse?.status === 201) {
      setSuccess(true);
      setClear((prev) => !prev);
      openToast({
        show: true,
        heading: "Signup successful!",
        text: "Please check your email for the next steps",
        type: "success",
        timeOut: 4000,
      });
    } else if (error) {
      const err = error?.response?.data;

      const otherError = err.non_field_errors
        ? err.non_field_errors[0]
        : undefined;
      const emailError = err.email ? err.email[0] : undefined;
      const detailError = err.detail ? err.detail[0] : undefined;

      openToast({
        show: true,
        heading: "Sorry",
        text:
          err?.error?.message ??
          emailError ??
          detailError ??
          otherError ??
          "Failed to create your account, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [signupResponse, error]);

  const showLoader = requestStatus.isPending;

  const submit = (data: SignupRequestData) => {
    setEmail(data.email);
    runSignup(signupService(data));
  };

  return (
    <>
      <Loader loading={showLoader} />
      <SignupUI submit={submit} clear={clear} />
      <EmailVerificationPrompt
        email={email}
        show={success}
        close={() => setSuccess(false)}
      />
    </>
  );
};

export { Signup };
