import {
  DoughnutChart,
  Pagination,
  PaginationProps,
  TableBody,
  TableBodyRow,
} from "components/";
import styles from "./styles.module.scss";
import { CopyIcon, EyeOpenIcon } from "assets";
import { useState } from "react";

export interface ReportRoleTableItem {
  id: string;
  candidate: string;
  date: string;
  time: string;
  email: string;
  phone: string;
  cv: string;
  portfolio: string;
  score: number;
  isAutoScored: boolean;
}

interface TableBodyProps {
  tableBodyItems: ReportRoleTableItem[];
  handleView: (id) => void;
  tableBodyItemClassName: string;
  tableBodyRowClassName: string;
  pagination?: PaginationProps;
  checks: boolean[];
  handleCheck: ({ index, value }) => void;
}

const ReportRoleTable: React.FC<TableBodyProps> = ({
  tableBodyItemClassName,
  tableBodyItems,
  handleView,
  tableBodyRowClassName,
  pagination,
  checks,
  handleCheck,
}) => {
  const [copied, setCopied] = useState(-1);

  const copyToClipBoard = async (link, index) => {
    await navigator.clipboard
      .writeText(link)
      .then((res) => {
        setCopied(index);

        setTimeout(() => {
          setCopied(-1);
        }, 2000);
      })
      .catch(() => {
        setCopied(-1);
      });
  };
  return (
    <>
      <TableBody customClassName={`${styles.tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            style={{ zIndex: tableBodyItems.length - index }}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            <span className={styles.checkWrap}>
              <input
                className={styles.check}
                onChange={() => handleCheck({ index, value: !checks[index] })}
                checked={checks[index]}
                type={"checkbox"}
              />
            </span>
            <span
              role="button"
              onClick={() => handleView(item.id)}
              className={`${tableBodyItemClassName} ${styles.title}`}
            >
              <p className={styles.label}>Candidate name</p>
              <p className={styles.name}>
                {item.candidate} {item.isAutoScored ? <span>AS</span> : ""}
              </p>
            </span>
            <span className={`${tableBodyItemClassName} ${styles.date}`}>
              <p className={styles.label}>Date & Time assessed</p>
              <div className={styles.dateBreakdown}>
                <span>{item.date} </span>
                <span>{item.time}</span>
              </div>
            </span>

            <span className={`${tableBodyItemClassName} ${styles.emailWrap}`}>
              <p className={styles.label}>Email</p>
              <a href={`mailto:${item.email}`}>{item.email}</a>
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Phone number</p>
              {item.phone}
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>CV</p>
              <a className={styles.viewBtn} href={item.cv} target="_blank">
                View <EyeOpenIcon />
              </a>
            </span>
            <span className={`${tableBodyItemClassName} ${styles.copyLinkSec}`}>
              <p className={styles.label}>Portfolio/Links</p>
              {item.portfolio === "" ? (
                "-"
              ) : (
                <button
                  onClick={() => copyToClipBoard(item.portfolio, index)}
                  className={styles.copyBtn}
                >
                  {copied === index ? "Copied!" : "Copy link"} <CopyIcon />
                </button>
              )}
            </span>
            <span className={`${tableBodyItemClassName} ${styles.scoreWrap}`}>
              <DoughnutChart className={styles.score} score={item.score} />
              <p
                className={`${
                  item.score >= 90
                    ? styles.green
                    : item.score >= 70
                    ? styles.blue
                    : item.score >= 50
                    ? styles.orange
                    : styles.red
                }`}
              >
                {item.score}
              </p>
            </span>
          </TableBodyRow>
        ))}
      </TableBody>
      {pagination ? (
        <Pagination {...pagination} className={styles.pagination} />
      ) : (
        ""
      )}
    </>
  );
};

export { ReportRoleTable };
