import { ExperiencePrecision } from "components";
import styles from "./styles.module.scss";
import { FAQs } from "./faqs";
import { ContactForm, ContactFormProps } from "./contactForm";
import {
  ArrowRightIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedInIcon,
} from "assets";

interface ContactUIProps {
  contactForm: ContactFormProps;
}

const ContactUI: React.FC<ContactUIProps> = ({ contactForm }) => {
  return (
    <>
      <section className={styles.main}>
        <section className={`container ${styles.wrapper}`}>
          <div className={styles.header}>
            <h1 className={styles.header__ttl}>Contact support</h1>
            <p className={styles.header__txt}>
              Contact us regarding any concerns or inquiries.
            </p>
          </div>
          <div className={styles.contact}>
            <ContactForm {...contactForm} />
            <div className={styles.info}>
              <div>
                <p className={styles.info__ttl}>Get in touch</p>
                <p className={styles.info__txt}>
                  If you prefer to drop us a mail you could reach us with the
                  mail below
                </p>
                <a
                  className={styles.info__link}
                  href="mailto:contact@precisionmatch.ai"
                >
                  contact@precisionmatch.ai <ArrowRightIcon />
                </a>
              </div>
              <div>
                <p className={styles.info__ttl}>Social media</p>
                <div className={styles.info__socials}>
                  <a>
                    <FacebookIcon />
                  </a>
                  <a>
                    <InstagramIcon />
                  </a>
                  <a>
                    <TwitterIcon />
                  </a>
                  <a>
                    <LinkedInIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <FAQs /> */}
        <ExperiencePrecision />
      </section>
    </>
  );
};

export { ContactUI };
