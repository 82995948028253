import { topupService } from "api";
import { Loader, TopupModal } from "components";
import { getError } from "helpers";
import { useApiRequest } from "hooks";
import { useMemo } from "react";

const TopupCandidates = ({ show, close, openToast, callback }) => {
  const {
    run: runTopup,
    data: topupResponse,
    requestStatus: topupStatus,
    error: topupError,
  } = useApiRequest({});

  const handleTopup = (candidates: number) => {
    runTopup(topupService({ candidates, callback_url: window.location.href }));
  };

  useMemo(() => {
    if (topupResponse?.status === 200) {
      const data = topupResponse.data.data.data;

      const heading = data.authorization_url
        ? topupResponse.data.message ??
          "We have received your request to top-up candidates!"
        : topupResponse.data.data.message ?? "Failed to initiate";
      const text = data.authorization_url
        ? "Redirecting to payment in 3...2...1"
        : "";
      openToast({
        show: true,
        heading,
        text,
        type: data.authorization_url ? "success" : "error",
        timeOut: 3500,
      });

      if (data.authorization_url) {
        callback();
        setTimeout(() => {
          window.location.assign(data.authorization_url);
          close();
        }, 2000);
      }
    } else if (topupError) {
      openToast({
        show: true,
        ...getError({
          error: topupError,
          heading: "Sorry",
          text: "Failed to topup candidates, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [topupResponse, topupError]);

  const showLoader = topupStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <TopupModal show={show} close={close} handleBuy={handleTopup} />
    </>
  );
};

export { TopupCandidates };
