import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { CloseImg, SuccessImg, logoWithText } from "assets";
import { Routes } from "router";
import { Button } from "components";

interface EmailVerificationProps {
  success: boolean;
  handleResend: () => void;
}

const EmailVerificationUI: React.FC<EmailVerificationProps> = ({
  success,
  handleResend,
}) => {
  const Img = success ? <SuccessImg /> : <CloseImg />;
  const heading = success ? "Account Verified" : "Account Verification failed";
  const text = success
    ? "Your account has been successfully verified"
    : "The verification link has timed-out. Please request a new one";
  return (
    <>
      <main className={styles.container}>
        <Link to={Routes.home}>
          <img
            className={styles.logo}
            src={logoWithText}
            alt="precision logo"
          />
        </Link>

        <section className={styles.content}>
          {Img}
          <h1 className={styles.ttl}>{heading}</h1>
          <p className={styles.txt}>{text}</p>
          {success ? (
            <Link className={styles.btn} to={Routes.signin}>
              Login to Dashboard
            </Link>
          ) : (
            <Button type="fill" onClick={handleResend} className={styles.btn}>
              Resend email
            </Button>
          )}
        </section>
      </main>
    </>
  );
};

export { EmailVerificationUI };
