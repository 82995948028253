/**
 * ROUTES
 *
 * ===============================================
 *
 * This object depicts the component url structure.
 * It contains a key-value pair of components and their respective URLs
 *
 */

export const Routes = {
  // Landing
  home: "/",
  pricing: "/pricing",
  candidates: "/candidates",
  faq: "/faq",
  blog: "/blog",
  contact: "/contact",
  privacyPolicy: "/privacy-policy",
  termsOfUse: "/terms-of-use",
  demo: "/request-demo",

  // Auth
  signup: "/signup",
  signin: "/signin",
  forgotPassword: "/forgot-password",
  newPassword: "/new-password",
  emailVerification: "/signup/verification",

  // Employer Dashboard
  employerDashbord: "/employer/dashboard",
  employerAssessments: "/employer/assessments",
  employerCreateAssessment: "/employer/assessments/new",
  employerEditAssessment: "/employer/assessments/edit/:id",
  employerEditAssessmentID: (id) => `/employer/assessments/edit/${id}`,
  employerReports: "/employer/reports",
  employerReportRole: "/employer/report/:id",
  employerReportRoleID: (id) => `/employer/report/${id}`,
  employerReport: (id) => `/employer/report/candidate/${id}`,
  employerReportFull: (id) => `/employer/report/candidate/${id}/full`,
  employerReportPublic: ({ id, role }) => `/report/${role}/${id}`,

  // Employer Preview
  employerPreviewAssessment: (id) => `/employer/assessments/preview/${id}`,
  employerPreviewBasic: (id) =>
    `/employer/assessments/preview/${id}/?metric=basic-fit`,
  employerPreviewWorkQualities: (id) =>
    `/employer/assessments/preview/${id}/?metric=work-qualities`,
  employerPreviewWorkMotivators: (id) =>
    `/employer/assessments/preview/${id}/?metric=work-motivators`,
  employerPreviewWorkStyle: (id) =>
    `/employer/assessments/preview/${id}/?metric=work-style`,
  employerPreviewPersonality: (id) =>
    `/employer/assessments/preview/${id}/?metric=personality`,
  employerPreviewSoftskills: (id) =>
    `/employer/assessments/preview/${id}/?metric=soft-skills`,
  employerPreviewDomainKnowledge: (id) =>
    `/employer/assessments/preview/${id}/?metric=domain-knowledge`,
  employerPreviewComplete: (id) =>
    `/employer/assessments/preview/${id}/?complete=true`,

  // Candidates
  assessmentOverview: (id) => `/assessment/${id}`,
  assessmentBasic: (id) => `/assessment/${id}/?metric=basic-fit`,
  assessmentWorkQualities: (id) => `/assessment/${id}/?metric=work-qualities`,
  assessmentWorkMotivators: (id) => `/assessment/${id}/?metric=work-motivators`,
  assessmentWorkStyle: (id) => `/assessment/${id}/?metric=work-style`,
  assessmentPersonality: (id) => `/assessment/${id}/?metric=personality`,
  assessmentSoftskills: (id) => `/assessment/${id}/?metric=soft-skills`,
  assessmentDomainKnowledge: (id) =>
    `/assessment/${id}/?metric=domain-knowledge`,
  assessmentComplete: (id) => `/assessment/${id}/?complete=true`,
  assessmentMalpractice: (id) => `/assessment/${id}/?malpractice=true`,

  settings: "/employer/settings",
  billings: "/employer/billings",
};
