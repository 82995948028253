import {
  Button,
  CustomSelect,
  Input,
  OptionType,
  Toggle,
  initOptionType,
  optionTypeSchema,
} from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  EmploymentTypeOptions,
  ExperienceEnvOptions,
  ExperienceLevelOptions,
  ExperienceYearOptions,
  SalaryCurrencyOptions,
  SalaryHourlyCurrencyOptions,
  SalaryHourlyRangeOptions,
  SalaryRange1Options,
  SalaryRange2Options,
  SalaryTermOptions,
} from "utils/options";
import { useEffect } from "react";

export interface ExpectationData {
  employmentType: OptionType;
  experienceLevel: OptionType;
  experienceEnv: OptionType;
  experienceYear: OptionType;
  location: string | undefined;
  locationRequired: boolean | undefined;
  salary: {
    term: OptionType;
    currency: OptionType;
    min: string;
    max: string;
  };
}

const initData: ExpectationData = {
  employmentType: initOptionType,
  experienceLevel: initOptionType,
  experienceEnv: initOptionType,
  experienceYear: initOptionType,
  location: "",
  locationRequired: false,
  salary: {
    term: initOptionType,
    currency: initOptionType,
    min: "",
    max: "",
  },
};

const schema = yup
  .object()
  .shape({
    employmentType: optionTypeSchema(),
    experienceLevel: optionTypeSchema(),
    experienceEnv: optionTypeSchema(),
    experienceYear: optionTypeSchema(),
    locationRequired: yup.boolean(),
    location: yup.string().when("locationRequired", {
      is: true,
      then: (schema) => schema.required("Required"),
    }),
    salary: yup
      .object({
        term: optionTypeSchema(),
        currency: optionTypeSchema(),
        min: yup
          .string()
          .required("Required")
          .matches(/[0-9]/, "Enter a valid amount"),
        max: yup
          .string()
          .required("Required")
          .matches(/[0-9]/, "Enter a valid amount")
          .test(
            "test-minmax",
            "Maximum amount should always be greater than the minimum amount",
            function check(max) {
              const min: any = this.parent.min;
              return parseInt(max) > parseInt(min);
            }
          ),
      })
      .required("Required"),
  })
  .required();

interface ExpectationFormProps {
  skip: () => void;
  back: () => void;
  submit: (data: ExpectationData) => void;
  expectation: ExpectationData | undefined;
  title: string;
}

const BasicExpectationForm: React.FC<ExpectationFormProps> = ({
  skip,
  back,
  submit,
  expectation,
  title,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<ExpectationData>({
    resolver: yupResolver(schema),
    defaultValues: expectation ?? initData,
  });

  useEffect(() => {
    expectation && reset(expectation);
  }, [expectation]);

  const currencyOptions =
    watch("salary.term").value === "hourly"
      ? SalaryHourlyCurrencyOptions
      : watch("salary.term").value === "monthly"
      ? SalaryCurrencyOptions
      : [];

  const rangeOptions =
    watch("salary.currency").value !== ""
      ? watch("salary.term").value === "monthly"
        ? SalaryHourlyCurrencyOptions.some(
            (item) => item.value === watch("salary.currency").value
          )
          ? SalaryRange1Options
          : SalaryRange2Options
        : SalaryHourlyRangeOptions
      : [];

  const onSubmit: SubmitHandler<ExpectationData> = (data) => submit(data);

  const isEmpty = JSON.stringify(watch()) === JSON.stringify(initData);

  useEffect(() => {
    if (isEmpty) localStorage.removeItem("expectationsIsFilled");
    else localStorage.setItem("expectationsIsFilled", "filled");
  }, [watch()]);

  return (
    <>
      <section className={styles.heading}>
        <h2 className={styles.ttl}>{title}</h2>
        <p className={styles.txt}>
          Evaluate how the candidate's salary expectations and past experience
          align with the objectives of the role.
        </p>
        <Button className={styles.skipBtn} type="outline" onClick={skip}>
          Skip
        </Button>
      </section>
      <form className={styles.form}>
        <CustomSelect
          label="Employment Type"
          placeholder="Select the employment type"
          options={EmploymentTypeOptions}
          onChange={(val) => setValue("employmentType", val)}
          validatorMessage={
            errors.employmentType?.value?.message?.toString() ?? ""
          }
          name={"employmentType"}
          value={watch("employmentType")}
        />
        <CustomSelect
          label="Experience Level"
          placeholder="Select the experience level"
          options={ExperienceLevelOptions}
          onChange={(val) => setValue("experienceLevel", val)}
          validatorMessage={
            errors.experienceLevel?.value?.message?.toString() ?? ""
          }
          name={"experienceLevel"}
          value={watch("experienceLevel")}
        />
        <CustomSelect
          label="Experience Environment"
          placeholder="Select the nature of work environment"
          options={ExperienceEnvOptions}
          onChange={(val) => setValue("experienceEnv", val)}
          validatorMessage={
            errors.experienceEnv?.value?.message?.toString() ?? ""
          }
          name={"experienceEnv"}
          value={watch("experienceEnv")}
        />
        <CustomSelect
          label="Years of experience"
          placeholder="Select the years of experience"
          options={ExperienceYearOptions}
          onChange={(val) => setValue("experienceYear", val)}
          validatorMessage={
            errors.experienceYear?.value?.message?.toString() ?? ""
          }
          name={"experienceYear"}
          value={watch("experienceYear")}
        />

        <div className={styles.salaryWrap}>
          <label>Salary Term</label>
          <CustomSelect
            placeholder="Term"
            options={SalaryTermOptions}
            onChange={(val) => {
              setValue("salary.term", val);
              setValue("salary.currency", initOptionType);
              setValue("salary.min", "");
              setValue("salary.max", "");
            }}
            validatorMessage={
              errors?.salary?.term?.value?.message?.toString() ?? ""
            }
            name={"salary.term"}
            value={watch("salary.term")}
            inputClass={styles.currency}
          />
          <CustomSelect
            placeholder="Currency"
            options={currencyOptions}
            onChange={(val) => {
              setValue("salary.currency", val);
              setValue("salary.min", "");
              setValue("salary.max", "");
            }}
            validatorMessage={
              errors?.salary?.currency?.value?.message?.toString() ?? ""
            }
            name={"salary.currency"}
            value={watch("salary.currency")}
            inputClass={styles.currency}
          />
        </div>
        <div className={styles.salaryWrap}>
          <label>
            Salary Range{" "}
            {watch("salary.currency.value") === ""
              ? ""
              : `(${watch("salary.currency.label")})`}
          </label>
          <Input
            placeholder="Min. salary"
            type="number"
            required
            validatorMessage={errors.salary?.min?.message}
            name="salary.min"
            register={register}
            value={watch("salary.min")}
            min={0}
          />
          <Input
            placeholder="Max. salary"
            type="number"
            required
            validatorMessage={errors.salary?.max?.message}
            name="salary.max"
            register={register}
            value={watch("salary.max")}
            min={0}
          />
        </div>
        {/* <div className={styles.locationWrap}>
          <div className={styles.locationWrap__label}>
            <p>Location</p>
            <Toggle
              className={styles.toggle}
              onClick={() =>
                setValue("locationRequired", !watch("locationRequired"))
              }
              checked={watch("locationRequired") ?? false}
            />
          </div>
          <Input
            // label="Location"
            placeholder="Type the location of the job"
            type="text"
            required
            validatorMessage={errors.location?.message}
            name="location"
            register={register}
            value={watch("locationRequired") ? watch("location") : ""}
            disabled={!watch("locationRequired")}
          />
        </div> */}
      </form>
      <div className={styles.btnSec}>
        <Button type="outline" onClick={back}>
          Previous
        </Button>
        <Button type="fill" onClick={handleSubmit(onSubmit)}>
          Next
        </Button>
      </div>
    </>
  );
};

export { BasicExpectationForm };
