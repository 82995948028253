import * as React from "react";
import { MainRouter } from "./router";

const App = () => {
  return (
    <>
      <MainRouter />
    </>
  );
};

export default App;
