import { reduxUserAction, UserData } from "../types";

const initalState: UserData = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  avatar: "",
  role: "",
  company: "",
  companyLogo: "",
  companyId: "",
  completedProfile: false,
  completedBusiness: false,
  phone: {
    code: "",
    number: "",
  },
  referral: "",
  industry: "",
  hiringFrequency: "",
  noOfEmployees: "",
};

const userReducer = (state = initalState, action: reduxUserAction) => {
  switch (action.type) {
    case "UPDATE_USER":
      return action?.payload;
    default:
      return state;
  }
};

export default userReducer;
