import { LogoWithText } from "assets";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { useState } from "react";

export type tabs = "home" | "pricing" | "candidates" | "faq" | "blog";

export interface NavbarProps {
  active: tabs;
}

interface NavItems {
  title: string;
  key: tabs;
  link: string;
}

const Navbar: React.FC<NavbarProps> = ({ active }) => {
  const [showNav, setShowNav] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 800);

  const screenSizeUpdate = () => {
    setMobile(window.innerWidth <= 800);
  };
  window.onresize = screenSizeUpdate;

  const navLinks: NavItems[] = [
    // {
    //   title: "Pricing",
    //   key: "pricing",
    //   link: Routes.pricing,
    // },
    {
      title: "Home",
      key: "home",
      link: Routes.home,
    },
    {
      title: "Candidates",
      key: "candidates",
      link: Routes.candidates,
    },
    {
      title: "FAQ",
      key: "faq",
      link: Routes.faq,
    },
    // {
    //   title: "Blog",
    //   key: "blog",
    //   link: Routes.blog,
    // },
  ];

  return (
    <header className={`${styles.navWrap} ${showNav ? styles.openMenu : ""}`}>
      <section className={`container ${styles.container}`}>
        <section
          className={`${styles.nav} ${active !== "home" ? styles.whiteBg : ""}`}
        >
          <Link
            onClick={() => setShowNav(false)}
            className={styles.logo}
            to={Routes.home}
          >
            <LogoWithText />
          </Link>
          {!mobile ? (
            <>
              <nav className={styles.navItems}>
                {navLinks.map((item) => (
                  <Link
                    className={item.key === active ? styles.active : ""}
                    key={item.key}
                    to={item.link}
                  >
                    {item.title}
                  </Link>
                ))}
              </nav>
              <div className={styles.authLinks}>
                <Link
                  style={{ borderRadius: 0, borderRight: "2px solid #d0d5dd" }}
                  to={Routes.demo}
                >
                  Request demo
                </Link>
                <Link to={Routes.signin}>Sign In</Link>
                <Link to={Routes.signup}>Start for free</Link>
              </div>
            </>
          ) : (
            ""
          )}
          <button
            onClick={() => setShowNav(!showNav)}
            className={`${styles.hamburger} ${
              showNav ? styles.closeMenuBtn : ""
            }`}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>
        </section>
        {showNav && mobile ? (
          <div className={styles.mobileNav}>
            <nav className={styles.navItems}>
              {navLinks.map((item) => (
                <Link
                  onClick={() => setShowNav(false)}
                  className={item.key === active ? styles.active : ""}
                  key={item.key}
                  to={item.link}
                >
                  {item.title}
                </Link>
              ))}
            </nav>
            <div className={styles.authLinks}>
              <Link to={Routes.demo}>Request demo</Link>
              <Link to={Routes.signin}>Sign In</Link>
              <Link to={Routes.signup}>Start for free</Link>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </header>
  );
};

export { Navbar };
