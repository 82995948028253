import {
  Button,
  ConfirmationModal,
  CustomSelect,
  ImageUploadWithDescription,
  Input,
  OptionType,
  Textarea,
  Toggle,
  initOptionType,
  optionTypeSchema,
} from "components";
import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import {
  AddIcon,
  ChevronRightIcon,
  CloseIcon,
  ImageIcon,
  InfoIcon,
  OptionIconCorrect,
  OptionIconIncorrect,
  TrashIcon,
} from "assets";
import { QuestionTypeOptions } from "utils/options";
import { useClickOutside } from "hooks";

interface DefaultQuestions {
  title: string;
  description: string;
}

interface CustomSubQuestion {
  question: string;
  questionType: OptionType;
  description?: string;
  required?: boolean;
  wordLimitRequired?: boolean;
  wordLimit?: string;
  points?: string;
  maxSelection?: string;
  image?: File | null;
  imageLink?: string | null;
  imageDescription?: string;
  options?: { value: string; isCorrect?: boolean }[];
  autoScore?: boolean;
}

interface CustomQuestions {
  theme: string;
  score?: string;
  hours: string;
  minutes: string;
  seconds: string;
  questions: CustomSubQuestion[];
}

export interface DomainData {
  questions: DefaultQuestions[] | CustomQuestions[];
}

interface DomainProps {
  skip: () => void;
  back: () => void;
  submitCustom: (data: CustomQuestionFormData) => void;
  submitDefault: (data: DefaultQuestionFormData) => void;
  initCustomData: CustomQuestionFormData | undefined;
  initDefaultData: DefaultQuestionFormData | undefined;
  title: string;
  handleUpdateCompletedMetrics: () => void;
}

const DomainKnowledgeForm: React.FC<DomainProps> = ({
  initCustomData,
  initDefaultData,
  skip,
  submitCustom,
  submitDefault,
  back,
  title,
  handleUpdateCompletedMetrics,
}) => {
  const [tab, setTab] = useState("default");
  const [prompt, setPrompt] = useState({
    show: false,
    tab: "",
  });

  useEffect(() => {
    setTab(initCustomData ? "custom" : "default");
  }, []);

  const handleTab = (tab) => {
    // check for filled
    // check for saved
    if (localStorage.getItem("domainIsFilled")) setPrompt({ show: true, tab });
    else continuePrompt(tab);
  };

  const continuePrompt = (tab) => {
    setTab(tab);
    handleUpdateCompletedMetrics();
  };

  return (
    <>
      <ConfirmationModal
        show={prompt.show}
        close={() => setPrompt({ show: false, tab: "" })}
        handleContinue={() => continuePrompt(prompt.tab)}
        title={`Override ${tab} questions`}
        text={`Are you sure you want to leave this tab? 
        You can only create one set of domain knowledge 
        questions so your previous responses will be overriden.`}
      />
      <section className={styles.heading}>
        <h2 className={styles.ttl}>{title}</h2>
        <p className={styles.txt}>
          Specify the operational knowledge and skills required to to excel in
          the role.
        </p>
        <Button className={styles.skipBtn} type="outline" onClick={skip}>
          Skip
        </Button>
      </section>
      <section className={styles.tabs}>
        <span
          className={tab === "default" ? styles.active : ""}
          onClick={() => handleTab("default")}
          role="button"
        >
          Use Default Questions
        </span>
        <span
          className={tab === "custom" ? styles.active : ""}
          onClick={() => handleTab("custom")}
          role="button"
        >
          Set Custom Questions
        </span>
      </section>
      <section>
        {tab === "default" ? (
          <DefaultQuestionForm
            initData={initDefaultData}
            back={back}
            submit={submitDefault}
          />
        ) : (
          <>
            <p className={styles.descrip}>
              To create your custom assessment input your section and create
              questions under each theme
            </p>

            <CustomQuestionForm
              initData={initCustomData}
              back={back}
              submit={submitCustom}
            />
          </>
        )}
      </section>
    </>
  );
};

export interface DefaultQuestionFormData {
  questions: DefaultQuestions[];
}

const defaultQSchema = yup
  .object({
    questions: yup
      .array()
      .of(
        yup
          .object({
            title: yup.string().required("Required"),
            description: yup.string().required("Required"),
          })
          .required()
      )
      .required("Required")
      .min(1, "At least one competence is required")
      .max(5, "No more than 5 competencies should be entered"),
  })
  .required();

const newThemeSchema = yup
  .object({
    theme: yup.string().required("Required"),
  })
  .required();

interface DefaultQuestionFormProps {
  submit: (data: DefaultQuestionFormData) => void;
  back: () => void;
  initData: DefaultQuestionFormData | undefined;
}

const DefaultQuestionForm: React.FC<DefaultQuestionFormProps> = ({
  submit,
  back,
  initData,
}) => {
  const [newComp, setNewComp] = useState("");
  const [active, setActive] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
    trigger,
  } = useForm<DefaultQuestionFormData>({
    resolver: yupResolver(defaultQSchema),
    defaultValues: initData ?? { questions: [] },
  });

  useEffect(() => {
    initData && reset(initData);
  }, [initData]);

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "questions",
  });

  const onSubmit: SubmitHandler<DefaultQuestionFormData> = (data) => {
    submit(data);
  };

  const onAppendNew = async () => {
    const handleAppend = () => {
      const index = watch(`questions`).length;
      append({
        title: newComp,
        description: "",
      });
      setNewComp("");
      setActive(`competence_${index}`);
    };
    if (fields.length > 0) {
      await trigger(`questions`, { shouldFocus: true })
        .then((res) => {
          if (res) {
            handleAppend();
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      handleAppend();
    }
  };

  const isEmpty = watch("questions").length === 0;

  useEffect(() => {
    if (isEmpty) localStorage.removeItem("domainIsFilled");
    else localStorage.setItem("domainIsFilled", "filled");
  }, [watch()]);

  return (
    <>
      <form className={styles.defaultForm}>
        <div className={styles.instruction}>
          <p> Identify 5 key technical competencies.</p>
          <p>
            We provide the question:{" "}
            <span>
              “Tell us about a challenge you used this skill to solve, how you
              approached it, and the impact it made”
            </span>{" "}
            to assess the candidate's practical understanding.
          </p>
        </div>
        <div className={styles.info}>
          <InfoIcon />
          <p>
            Save time and ensure consistency by auto-scoring your candidate's
            responses when you view reports
          </p>
        </div>
        {fields.map((_, index) => {
          const parentError = errors.questions && errors.questions[index];
          return (
            <Question
              onClick={(x) => {
                setActive(x ?? `competence_${index}`);
              }}
              active={active === `competence_${index}`}
              count={index + 1}
              onRemove={() => {
                remove(index);
              }}
              competence={{
                value: watch(`questions.${index}.title`),
                error: parentError?.title?.message ?? "",
                name: `questions.${index}.title`,
              }}
              description={{
                value: watch(`questions.${index}.description`),
                error: parentError?.description?.message ?? "",
                name: `questions.${index}.description`,
              }}
              register={register}
            />
          );
        })}
        <div className={styles.newTheme}>
          <Input
            placeholder="Input competence to ask default question"
            type="text"
            required
            validatorMessage={""}
            name={"newComp"}
            register={register}
            value={newComp}
            parentClassName={styles.inputWrap}
            onChange={(e) => setNewComp(e.target.value)}
            disabled={fields.length === 5}
          />
          <Button
            disabled={newComp === "" || fields.length === 5}
            type="fill"
            onClick={onAppendNew}
          >
            <AddIcon />
            <span>Add</span>
          </Button>
        </div>
        <div className={styles.btnSec}>
          <Button type="outline" onClick={back}>
            Previous
          </Button>
          <Button type="fill" onClick={handleSubmit(onSubmit)}>
            Next
          </Button>
        </div>
      </form>
    </>
  );
};
interface QuestionProps {
  active: boolean;
  onClick: (x?) => void;
  count: number;
  onRemove: () => void;
  competence: {
    value: string;
    error: string;
    name: string;
  };
  description: {
    value: string;
    error: string;
    name: string;
  };
  register;
}
const Question: React.FC<QuestionProps> = ({
  count,
  onRemove,
  description,
  competence,
  register,
  active,
  onClick,
}) => {
  const ref = useRef(null);
  useClickOutside(ref, () => onClick(-1));

  return (
    <>
      {!active ? (
        <div
          onClick={() => onClick()}
          className={`${styles.competence} ${styles.unfocused}`}
        >
          <TrashIcon
            className={styles.removeBtn}
            role="button"
            onClick={onRemove}
          />
          <p className={styles.competence__count}>{count}</p>
          <div>
            <p className={styles.competence__ttl}>{competence.value}</p>
            <p className={styles.competence__info}>{description.value}</p>
          </div>
        </div>
      ) : (
        <div ref={ref} className={`${styles.defaultQ} ${styles.focused}`}>
          <TrashIcon
            className={styles.removeBtn}
            role="button"
            onClick={onRemove}
          />
          <Input
            placeholder="Input a competence to ask a question"
            type="text"
            required
            validatorMessage={competence.error}
            name={competence.name}
            register={register}
            value={competence.value}
            parentClassName={styles.inputWrap}
          />
          <Textarea
            placeholder="Add a description to clarify the meaning of this competence"
            type="text"
            required
            validatorMessage={description.error}
            name={description.name}
            register={register}
            value={description.value}
            parentClassName={styles.inputWrap}
            className={styles.defaultQ__descrip}
          />
        </div>
      )}
    </>
  );
};

const customQSchema = yup
  .object({
    questions: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            theme: yup.string().required("Required"),
            score: yup.string(),
            hours: yup
              .string()
              .required("Required")
              .matches(/[0-9]/, "Enter a valid number"),
            minutes: yup
              .string()
              .required("Required")
              .matches(/[0-9]/, "Enter a valid number"),
            seconds: yup
              .string()
              .required("Required")
              .matches(/[0-9]/, "Enter a valid number"),
            questions: yup
              .array()
              .of(
                yup.object().shape({
                  options: yup
                    .array()
                    .of(
                      yup.object({
                        value: yup.string().required("Add a value"),
                        isCorrect: yup.boolean(),
                      })
                    )
                    .when("questionType.value", {
                      is: (val) => val === "multiple_choice",
                      then: (schema) =>
                        schema
                          .required("Required")
                          .test(
                            "has-correct-answer",
                            "No answer has been chosen",
                            (options) =>
                              options.some(
                                (option) => option.isCorrect === true
                              )
                          ),
                    })
                    .when("questionType.value", {
                      is: (val) => val === "single_choice",
                      then: (schema) =>
                        schema
                          .required("Required")
                          .test(
                            "has-correct-answer",
                            "No answer has been chosen",
                            (options) =>
                              options.some(
                                (option) => option.isCorrect === true
                              )
                          )
                          .test(
                            "select-one-answer",
                            "You can only select one correct answer for a drop-down",
                            (options) =>
                              options.filter(
                                (option) => option.isCorrect === true
                              ).length === 1
                          ),
                    }),
                  wordLimit: yup.string().when("wordLimitRequired", {
                    is: true,
                    then: (schema) =>
                      schema
                        .required("Required")
                        .matches(/[0-9]/, "Enter a valid number"),
                  }),
                  points: yup.string().when("questionType.value", {
                    is: (val) => val !== "open_ended",
                    then: (schema) =>
                      schema
                        .required("Required")
                        .matches(/[0-9]/, "Enter a valid number"),
                  }),
                  maxSelection: yup.string().when("questionType.value", {
                    is: "multiple_choice",
                    then: (schema) =>
                      schema
                        .required("Required")
                        .matches(/[0-9]/, "Enter a valid number"),
                  }),
                  question: yup.string().required("Required"),
                  questionType: optionTypeSchema(),
                  description: yup.string(),
                  required: yup.boolean(),
                  autoScore: yup.boolean(),
                  wordLimitRequired: yup.boolean(),
                  image: yup.mixed(),
                  imageDescription: yup.string(),
                })
              )
              .required(),
            // .min(1, "Add at least one question"),
          })
          .required()
      )
      .required(),
  })
  .required();

export interface CustomQuestionFormData {
  questions: CustomQuestions[];
}

interface CustomQuestionFormProps {
  submit: (data: CustomQuestionFormData) => void;
  back: () => void;
  initData: CustomQuestionFormData | undefined;
}

const CustomQuestionForm: React.FC<CustomQuestionFormProps> = ({
  back,
  submit,
  initData,
}) => {
  const [expand, setExpand] = useState<number[]>([]);
  const [active, setActive] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    trigger,
    reset,
  } = useForm<CustomQuestionFormData>({
    resolver: yupResolver(customQSchema),
    defaultValues: initData ?? { questions: [] },
  });

  useEffect(() => {
    initData && reset(initData);
  }, [initData]);

  const { append, remove, fields, update } = useFieldArray({
    control: control,
    name: "questions",
  });

  const {
    register: registerNew,
    handleSubmit: handleSubmitNew,
    formState: { errors: errorsNew },
    watch: watchNew,
    setValue: setValueNew,
  } = useForm<{ theme: string }>({
    resolver: yupResolver(newThemeSchema),
    defaultValues: { theme: "" },
  });

  const initSubQuestion: CustomSubQuestion = {
    question: "",
    wordLimit: "",
    required: false,
    questionType: initOptionType,
    description: "",
  };

  const onAppendNew: SubmitHandler<{ theme: string }> = async () => {
    await trigger(`questions`, { shouldFocus: true })
      .then((res) => {
        if (res) {
          append({
            theme: watchNew("theme"),
            questions: [initSubQuestion],
            score: "",
            hours: "",
            minutes: "",
            seconds: "",
          });
          setValueNew(`theme`, "");
          setActive(`${watch(`questions`).length - 1}_0`);
          setExpand([...expand, watch("questions").length - 1]);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const onSubmit: SubmitHandler<CustomQuestionFormData> = (data) => {
    submit(data);
  };

  const hide = (idx) => {
    const value = [...expand];

    const index = value.findIndex((object) => {
      return object === idx;
    });
    const prevList = [...value];
    prevList.splice(index, 1);

    setExpand([...prevList]);
  };

  const showOrHide = (index) => {
    expand.filter((item) => item === index).length > 0
      ? hide(index)
      : setExpand([...expand, index]);
  };

  const onRemove = (idx) => {
    remove(idx);
    hide(idx);
  };

  const onAppendQuestion = async ({
    idx,
    value,
  }: {
    idx: number;
    value: CustomQuestions;
  }) => {
    await trigger(`questions.${idx}.questions`, { shouldFocus: true })
      .then((res) => {
        if (res) {
          update(idx, value);
          setActive(`${idx}_${watch(`questions`)[idx].questions.length - 1}`);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getScore = (index) => {
    let totalScore = 0;
    const scores = watch(`questions.${index}.questions`)
      .map((item) => item.points)
      .filter((item) => item !== "");
    scores.map((num) => (totalScore = totalScore + parseInt(num ?? "")));
    return totalScore;
  };

  const isEmpty = watch("questions").length === 0;

  useEffect(() => {
    if (isEmpty) localStorage.removeItem("domainIsFilled");
    else localStorage.setItem("domainIsFilled", "filled");
  }, [watch()]);

  return (
    <>
      <form className={styles.customForm}>
        {fields.map((item, index) => (
          <fieldset>
            <div className={styles.ctrl}>
              <ChevronRightIcon
                className={`${styles.chevron} ${
                  expand.filter((item) => item === index).length > 0
                    ? styles.active
                    : ""
                }`}
                onClick={() => showOrHide(index)}
                role="button"
              />
              <CloseIcon
                onClick={() => onRemove(index)}
                className={styles.close}
                role="button"
              />
            </div>
            <section>
              <div className={styles.quesHd}>
                <Input
                  placeholder="Specify question theme (e.g., Python)."
                  type="text"
                  required
                  validatorMessage={
                    errors?.questions
                      ? errors?.questions[index]?.theme?.message ?? ""
                      : ""
                  }
                  name={`questions.${index}.theme`}
                  register={register}
                  value={watch(`questions.${index}.theme`)}
                  parentClassName={styles.inputWrap}
                />

                <div className={styles.scoreAndTimer}>
                  <Input
                    label="Total Score:"
                    placeholder=""
                    type="number"
                    required
                    validatorMessage={
                      errors?.questions
                        ? errors?.questions[index]?.score?.message ?? ""
                        : ""
                    }
                    name={`questions.${index}.score`}
                    register={register}
                    value={getScore(index).toString()}
                    parentClassName={styles.scoreWrap}
                    disabled
                  />
                  <p>Total time:</p>
                  <Input
                    label="hours"
                    placeholder=""
                    type="number"
                    required
                    validatorMessage={
                      errors?.questions
                        ? errors?.questions[index]?.hours?.message ?? ""
                        : ""
                    }
                    name={`questions.${index}.hours`}
                    register={register}
                    value={watch(`questions.${index}.hours`)}
                    parentClassName={styles.timeWrap}
                  />
                  <Input
                    label="mins"
                    placeholder=""
                    type="number"
                    required
                    validatorMessage={
                      errors?.questions
                        ? errors?.questions[index]?.minutes?.message ?? ""
                        : ""
                    }
                    name={`questions.${index}.minutes`}
                    register={register}
                    value={watch(`questions.${index}.minutes`)}
                    parentClassName={styles.timeWrap}
                  />
                  <Input
                    label="secs"
                    placeholder=""
                    type="number"
                    required
                    validatorMessage={
                      errors?.questions
                        ? errors?.questions[index]?.seconds?.message ?? ""
                        : ""
                    }
                    name={`questions.${index}.seconds`}
                    register={register}
                    value={watch(`questions.${index}.seconds`)}
                    parentClassName={styles.timeWrap}
                  />
                </div>
              </div>

              <p>
                Question count:{" "}
                <span>
                  {item.questions.filter((item) => item.question !== "").length}
                </span>
              </p>
              {expand.filter((item) => item === index).length > 0 ? (
                <>
                  {item.questions.map((item2, index2) => {
                    const parentError =
                      errors.questions && errors.questions[index];

                    const error =
                      parentError?.questions && parentError?.questions[index2]
                        ? parentError?.questions[index2]
                        : undefined;

                    return (
                      <CustomQuestion
                        onClick={(x) => setActive(x ?? `${index}_${index2}`)}
                        active={active === `${index}_${index2}`}
                        count={index2 + 1}
                        description={{
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.description`
                            ) ?? "",
                          error: error?.description?.message?.toString() ?? "",
                          name: `questions.${index}.questions.${index2}.description`,
                        }}
                        imageDescription={{
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.imageDescription`
                            ) ?? "",
                          handleChange: (x) =>
                            setValue(
                              `questions.${index}.questions.${index2}.imageDescription`,
                              x
                            ),
                        }}
                        image={{
                          link:
                            watch(
                              `questions.${index}.questions.${index2}.imageLink`
                            ) ?? null,
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.image`
                            ) ?? null,
                          handleChange: (x) =>
                            setValue(
                              `questions.${index}.questions.${index2}.image`,
                              x
                            ),
                        }}
                        wordLimit={{
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.wordLimit`
                            ) ?? "",
                          error: error?.wordLimit?.message?.toString() ?? "",
                          name: `questions.${index}.questions.${index2}.wordLimit`,
                        }}
                        point={{
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.points`
                            ) ?? "",
                          error: error?.points?.message?.toString() ?? "",
                          name: `questions.${index}.questions.${index2}.points`,
                        }}
                        options={{
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.options`
                            ) ?? [],
                          name: `questions.${index}.questions.${index2}.options`,
                          valueError:
                            error?.options && error?.options?.map
                              ? error?.options?.map(
                                  (item) => item?.value?.message ?? ""
                                )
                              : [],
                          error: error?.options?.message?.toString() ?? "",
                          handleChange: (val) =>
                            setValue(
                              `questions.${index}.questions.${index2}.options`,
                              val
                            ),
                          handleAddOption: (val) =>
                            setValue(
                              `questions.${index}.questions.${index2}.options`,
                              [...val, { value: "", isCorrect: false }]
                            ),
                        }}
                        questionRequired={{
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.required`
                            ) ?? false,
                          handleChange: (val) =>
                            setValue(
                              `questions.${index}.questions.${index2}.required`,
                              val
                            ),
                        }}
                        autoScore={{
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.autoScore`
                            ) ?? false,
                          handleChange: (val) =>
                            setValue(
                              `questions.${index}.questions.${index2}.autoScore`,
                              val
                            ),
                        }}
                        wordLimitRequired={{
                          value: watch(
                            `questions.${index}.questions.${index2}.wordLimitRequired`
                          ),
                          handleChange: (val) =>
                            setValue(
                              `questions.${index}.questions.${index2}.wordLimitRequired`,
                              val
                            ),
                        }}
                        question={{
                          value: watch(
                            `questions.${index}.questions.${index2}.question`
                          ),
                          error: error?.question?.message?.toString() ?? "",
                          name: `questions.${index}.questions.${index2}.question`,
                        }}
                        type={{
                          value: watch(
                            `questions.${index}.questions.${index2}.questionType`
                          ),
                          error:
                            error?.questionType?.value?.message?.toString() ??
                            "",
                          name: `questions.${index}.questions.${index2}.questionType`,
                          handleChange: (val) =>
                            setValue(
                              `questions.${index}.questions.${index2}.questionType`,
                              val
                            ),
                        }}
                        maximumSelection={{
                          value:
                            watch(
                              `questions.${index}.questions.${index2}.maxSelection`
                            ) ?? "",
                          error: error?.maxSelection?.message?.toString() ?? "",
                          name: `questions.${index}.questions.${index2}.maxSelection`,
                          handleChange: (val) =>
                            setValue(
                              `questions.${index}.questions.${index2}.maxSelection`,
                              val
                            ),
                        }}
                        register={register}
                        onRemove={() => {
                          const prev = [
                            ...watch(`questions.${index}.questions`),
                          ];
                          prev.splice(index2, 1);

                          update(index, {
                            ...watch(`questions.${index}`),
                            questions: [...prev],
                          });
                        }}
                      />
                    );
                  })}
                </>
              ) : (
                ""
              )}
              <Button
                className={styles.addBtn}
                type="fill"
                onClick={() =>
                  onAppendQuestion({
                    idx: index,
                    value: {
                      ...watch(`questions.${index}`),
                      questions: [
                        ...watch(`questions.${index}`).questions,
                        initSubQuestion,
                      ],
                    },
                  })
                }
              >
                <AddIcon />
              </Button>
            </section>
          </fieldset>
        ))}
        <div className={styles.newTheme}>
          <Input
            placeholder="Specify question theme (e.g., Python)."
            type="text"
            required
            validatorMessage={errorsNew?.theme?.message ?? ""}
            name={"theme"}
            register={registerNew}
            value={watchNew("theme")}
            parentClassName={styles.inputWrap}
          />

          <Button
            disabled={watchNew("theme") === ""}
            className={styles.btn}
            type="fill"
            onClick={handleSubmitNew(onAppendNew)}
          >
            <AddIcon />
            Add
          </Button>
        </div>

        <div className={styles.btnSec}>
          <Button type="outline" onClick={back}>
            Previous
          </Button>
          <Button type="fill" onClick={handleSubmit(onSubmit)}>
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

interface CustomQuestionProps {
  imageDescription: {
    value: string;
    handleChange: (x: string) => void;
  };
  image: {
    link?: string | null;
    value: File | null;
    handleChange: (x: File) => void;
  };
  description: {
    value: string;
    error: string;
    name: string;
  };
  wordLimit: {
    value: string;
    error: string;
    name: string;
  };
  point: {
    value: string;
    error: string;
    name: string;
  };
  maximumSelection: {
    value: string;
    error: string;
    name: string;
    handleChange: (val: string) => void;
  };
  options: {
    value: { value: string; isCorrect?: boolean }[];
    valueError: string[];
    error: string;
    name: string;
    handleChange: (val: { value: string; isCorrect?: boolean }[]) => void;
    handleAddOption: (val: { value: string; isCorrect?: boolean }[]) => void;
  };
  wordLimitRequired: {
    value: boolean | undefined;
    handleChange: (val: boolean) => void;
  };
  autoScore: {
    value: boolean | undefined;
    handleChange: (val: boolean) => void;
  };
  questionRequired: {
    value: boolean;
    handleChange: (val: boolean) => void;
  };
  question: {
    value: string;
    error: string;
    name: string;
  };
  type: {
    value: OptionType;
    error: string;
    name: string;
    handleChange: (val: OptionType) => void;
  };
  register;
  count: number;
  onRemove: () => void;
  active: boolean;
  onClick: (x?) => void;
}

const CustomQuestion: React.FC<CustomQuestionProps> = ({
  description,
  question,
  type,
  register,
  wordLimit,
  count,
  onRemove,
  wordLimitRequired,
  questionRequired,
  onClick,
  active,
  point,
  maximumSelection,
  options,
  imageDescription,
  image,
  autoScore,
}) => {
  const [show, setShow] = useState(false);
  const correct = options.value.filter((item) => item.isCorrect).length;
  const maxSelectionOptions: OptionType[] = new Array(correct)
    .fill("")
    .map((_, index) => ({ label: `${index + 1}`, value: `${index + 1}` }));

  const ref = useRef(null);
  useClickOutside(ref, () => onClick(-1));

  return (
    <>
      <ImageUploadWithDescription
        show={show}
        close={() => setShow(false)}
        submit={(data) => {
          data.description && imageDescription.handleChange(data.description);
          data.image && image.handleChange(data.image);
        }}
        initData={{
          description: imageDescription.value,
          image: image.value,
        }}
        imageLink={image.link}
      />
      <section
        // ref={ref}
        onClick={() => onClick()}
        className={`${styles.customQuestion} ${
          !active ? styles.customQuestion__unfocused : ""
        }`}
      >
        <div className={styles.customCount}>
          <p>{count}</p>
          <TrashIcon
            className={styles.removeBtn}
            role="button"
            onClick={onRemove}
          />
        </div>
        <div className={styles.questionWrap}>
          <Textarea
            placeholder="Type your question here"
            type="text"
            required
            validatorMessage={question.error}
            name={question.name}
            register={register}
            value={question.value}
            parentClassName={styles.inputWrap}
          />
          <CustomSelect
            label=""
            placeholder="Select type..."
            options={QuestionTypeOptions}
            onChange={type.handleChange}
            validatorMessage={type.error}
            name={type.name}
            value={type.value}
            parentClassName={styles.selectWrap}
          />
        </div>
        {type.value.value === "open_ended" && (
          <div className={styles.open_ended}>
            <p>Applicant inputs answer</p>
            {wordLimitRequired && <p>0/{wordLimit.value ?? 0} words</p>}
          </div>
        )}
        <Textarea
          placeholder="Description (Optional)"
          required
          validatorMessage={description.error}
          name={description.name}
          register={register}
          value={description.value}
          parentClassName={styles.description}
        />
        {type.value.value !== "open_ended" && type.value.value !== "" && (
          <div className={styles.options}>
            <p>Choose the correct answers</p>
            {options.value.map((item, index) => {
              const isSingleChoice = type.value.value === "single_choice";
              const disable =
                options.value.some((item) => item.isCorrect) &&
                isSingleChoice &&
                !item.isCorrect;

              const handleSelect = () => {
                if (disable) return;

                const prev = options.value.map((item, idx2) =>
                  idx2 === index
                    ? { isCorrect: !item.isCorrect, value: item.value }
                    : item
                );
                options.handleChange(prev);
              };
              return (
                <div
                  className={`${styles.option} ${
                    isSingleChoice ? styles.option__radio : ""
                  }`}
                >
                  {!item.isCorrect ? (
                    <OptionIconIncorrect
                      role="button"
                      onClick={handleSelect}
                      className={`${styles.option__correct} ${
                        disable ? styles["option__correct--disabled"] : ""
                      }`}
                    />
                  ) : (
                    <OptionIconCorrect
                      role="button"
                      onClick={handleSelect}
                      className={`${styles.option__correct} ${
                        disable ? styles["option__correct--disabled"] : ""
                      }`}
                    />
                  )}
                  <Input
                    placeholder=""
                    type="text"
                    required
                    validatorMessage={options.valueError[index]}
                    name={`${options.name}.${index}.value`}
                    register={register}
                    value={item.value}
                    parentClassName={styles.option__value}
                  />
                  <TrashIcon
                    onClick={() => {
                      const prev = [...options.value];
                      prev.splice(index, 1);
                      options.handleChange(prev);
                    }}
                    role="button"
                    className={styles.option__remove}
                  />
                </div>
              );
            })}
            <p className={styles.error}>{options.error}</p>
            {type.value.value !== "open_ended" && type.value.value !== "" ? (
              <Button
                className={styles.addOptions}
                type="transparent"
                onClick={() => options.handleAddOption(options.value)}
              >
                <AddIcon /> Add options
              </Button>
            ) : (
              ""
            )}
          </div>
        )}
        <div className={styles.others}>
          <button
            onClick={(e) => {
              e.preventDefault();
              setShow(true);
            }}
            className={`${styles.imageUpload} ${
              image.value ? "" : styles["imageUpload--hide"]
            }`}
          >
            <span>
              {image.value
                ? image.value?.name
                : image.link
                ? "Image"
                : "Upload image"}
            </span>{" "}
            <ImageIcon />
          </button>
          <div className={styles.toggleWrap}>
            <span>Required</span>
            <Toggle
              onClick={() =>
                questionRequired.handleChange(!questionRequired.value)
              }
              checked={questionRequired.value}
            />
          </div>
          {type.value.value === "open_ended" && (
            <div className={styles.toggleWrap}>
              <span>Auto-score</span>
              <Toggle
                onClick={() => autoScore.handleChange(!autoScore.value)}
                checked={autoScore?.value ?? false}
              />
            </div>
          )}
          {type.value.value === "open_ended" && (
            <>
              <div className={styles.toggleWrap}>
                <span>Word limit</span>
                <Toggle
                  onClick={() =>
                    wordLimitRequired.handleChange(!wordLimitRequired.value)
                  }
                  checked={wordLimitRequired.value ?? false}
                />
              </div>
              {wordLimitRequired.value && (
                <Input
                  placeholder=""
                  type="number"
                  required
                  validatorMessage={wordLimit.error}
                  name={wordLimit.name}
                  register={register}
                  value={wordLimit.value}
                  parentClassName={styles.wordLimit}
                  disabled={!wordLimitRequired.value}
                />
              )}
            </>
          )}
          {type.value.value !== "open_ended" && (
            <div className={`${styles.toggleWrap} ${styles.points}`}>
              <span>Points</span>
              <Input
                placeholder=""
                type="number"
                required
                validatorMessage={point.error}
                name={point.name}
                register={register}
                value={point.value}
                parentClassName={styles.wordLimit}
              />
            </div>
          )}
          {type.value.value === "multiple_choice" && (
            <div className={styles.toggleWrap}>
              <span>Maximum selection</span>
              <CustomSelect
                label=""
                placeholder=""
                options={maxSelectionOptions}
                onChange={(val) => maximumSelection.handleChange(val.value)}
                validatorMessage={maximumSelection.error}
                name={maximumSelection.name}
                value={
                  maxSelectionOptions.find(
                    (item) => item.value === maximumSelection.value
                  ) ?? initOptionType
                }
                parentClassName={styles.maximumSelectionWrap}
                inputClass={styles.maximumSelection}
                disabled={maxSelectionOptions.length === 0}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export { DomainKnowledgeForm };
