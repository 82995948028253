import {
  SparklesIcon,
  createAssessmentMockup2,
  inviteCandidatesMockup,
  reportMockup3,
  reportMockup4,
} from "assets";
import styles from "./styles.module.scss";

const HowItWorks = () => {
  const list = [
    {
      image: createAssessmentMockup2,
      title: "1. Define Performance Expectations",
      text: `Curate operationally relevant metrics covering technical, soft, personality, work ethic, and cultural fit skills to assess overall candidate fit accurately.`,
    },
    {
      image: inviteCandidatesMockup,
      title: "2. Administer Assessments",
      text: `Your defined criteria are simulated into an application link for candidates, sending invitations seamlessly. Our intuitive system helps curate responses from potential hires, initiating their journey to demonstrate suitability for your organization.`
    },
    {
      image: reportMockup3,
      title: "3. Filter Talent with Actionable Insights",
      text: `Access comprehensive insights and data-driven analytics from candidate assessments. Precision provides a clear and concise summary of each candidate's strengths and areas for improvement, empowering you to make informed decisions.`,
    },
    {
      image: reportMockup4,
      title: "4. Hire the Best Candidate",
      text: `Identify the cream of the crop with confidence. Precision empowers you to select the candidates who align perfectly with your criteria, ensuring that you're bringing the best onboard. Say goodbye to hiring worries and hello to top-tier talent.`,
    },
  ];

  return (
    <>
      <section className={styles.howBg}>
        <div className={`container ${styles.how}`}>
          <div data-aos="slide-up" className={styles.content}>
            <p className={styles.content__tag}>
              <SparklesIcon /> how it works
            </p>
            <p className={styles.content__ttl}>
              We've made it incredibly easy for you to{" "}
              <span>predict performance.</span>
            </p>
            <p className={styles.content__txt}>
              Focus on your getting business out while Precision does the heavy
              lifting for you
            </p>
          </div>
          <div className={styles.benefits}>
            {list.map((item) => (
              <div key={item.title} className={styles.benefit}>
                <img className={styles.benefit__img} src={item.image} alt="" />
                <div className={styles.benefit__content}>
                  <p className={styles.benefit__ttl}>{item.title}</p>
                  <p className={styles.benefit__txt}>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export { HowItWorks };
