import { AssessmentOverviewData, AssessmentOverviewUI } from "features";
import { useEffect } from "react";

interface AssessmentOverviewProps {
  assessment: AssessmentOverviewData;
  handleStart: () => void;
  candidate: string | undefined;
}

const AssessmentOverview: React.FC<AssessmentOverviewProps> = ({
  assessment,
  handleStart,
  candidate,
}) => {
  useEffect(() => {
    localStorage.removeItem("secondsLeft");
    localStorage.removeItem("answered");
    localStorage.removeItem("candidate");
    localStorage.removeItem("assessmentId");
  }, []);
  return (
    <>
      <AssessmentOverviewUI
        candidate={candidate}
        assessment={assessment}
        handleStart={handleStart}
      />
    </>
  );
};

export { AssessmentOverview };
