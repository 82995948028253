import { candidateCompleteAssessmentService } from "api";
import { Loader } from "components";
import { AssessmentCompleteUI } from "features";
import { useApiRequest, useToast } from "hooks";
import { ReactNode, useEffect, useMemo, useState } from "react";

const AssessmentComplete = ({
  title,
  text,
}: {
  title?: string;
  text?: ReactNode | string;
}) => {
  const [isComplete, setIsComplete] = useState(false);
  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const candidate = localStorage.getItem("assessmentId");

  useEffect(() => {
    if (title && text) setIsComplete(true);
    else setIsComplete(localStorage.getItem("isComplete") === "true");
  }, [title, text]);

  useMemo(() => {
    if (response?.status === 200) {
      const data = response.data.data;
      setIsComplete(true);
      localStorage.removeItem("secondsLeft");
      localStorage.removeItem("answered");
      localStorage.removeItem("candidate");
      localStorage.removeItem("assessmentId");
      localStorage.setItem("isComplete", "true");
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.message ??
          "Failed to complete assessment, please reload the page again or contact support if the error persists",
        type: "error",
        timeOut: 15000,
      });
    }
  }, [response, error]);

  const handleSubmit = (data) => {
    candidate &&
      run(
        candidateCompleteAssessmentService({
          ...data,
          response_id: candidate,
          malpractice: false,
        })
      );
  };

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <AssessmentCompleteUI
        text={text}
        title={title}
        submit={handleSubmit}
        isComplete={isComplete}
      />
    </>
  );
};

export { AssessmentComplete };
