import { Button } from "components";
import styles from "./styles.module.scss";

export interface AssessmentPreviewData {
  title: string;
  startDate: string;
  endDate: string;
  time: number; //in minutes
  total: number;
  instruction: string;
  metrics: string[];
  id: string;
  _id: string;
}

export interface AssessmentPreviewProps {
  assessment: AssessmentPreviewData;
  handleStart: () => void;
}

const AssessmentPreviewUI: React.FC<AssessmentPreviewProps> = ({
  assessment,
  handleStart,
}) => {
  const { time, title, total, startDate, endDate, metrics, instruction } =
    assessment;
  return (
    <>
      <h1 className={styles.ttl}>{title}</h1>
      <div className={styles.info}>
        <p>
          <span>Start Date:</span> <span>{startDate}</span>
        </p>
        <p>
          <span>End Date:</span> <span>{endDate}</span>
        </p>
        <p>
          <span>Total time:</span>
          <span>{time} minutes</span>
        </p>
        <p>
          <span>Total question:</span> <span>{total}</span>
        </p>
      </div>
      <div className={styles.descrip}>{instruction}</div>
      <div className={styles.instructions}>
        <p>Instructions</p>
        <ol>
          <li>
            Format: The test consists of multiple-choice and open-ended
            questions.
          </li>
          <li>
            Honesty: Please refrain from using any external resources or
            assistance during the assessment.
          </li>
          <li>
            Accuracy: Provide the response that best reflects your skills,
            knowledge, and experience. There are no right or wrong answers.
          </li>
          <li>
            Completeness: Answer all questions to the best of your abilities.
            Partially completed tests may affect your overall assessment.
          </li>
          <li>
            Submitting: Once you have completed the assessment, review your
            answers carefully before submitting. Double-check for any unanswered
            questions or mistakes.
          </li>
          <li>
            <b>
              Disqualifications: To prevent an automatic disqualification,
              please do not exit the full screen mode until your assessment is
              complete.
            </b>
          </li>
        </ol>
        <p>
          Remember, there are no right or wrong answers. Your performance will
          be evaluated based on your individual strengths and suitability for
          the role.
        </p>
      </div>
      <div>
        <p className={styles.areas}>
          Questions in this assessment covers the following areas
        </p>
        <div className={styles.sections}>
          {metrics.map((metric, index) => (
            <span key={`metric_${index}`}>{metric}</span>
          ))}
        </div>
        <Button className={styles.btn} type="fill" onClick={handleStart}>
          Start Assessment
        </Button>
      </div>
    </>
  );
};

export { AssessmentPreviewUI };
