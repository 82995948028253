import { StarIconGrey } from "assets";
import styles from "./styles.module.scss";

interface RatingProps {
  count: number;
  value: number;
  onChange: (x: number) => void;
  className?: string;
  starClassName?: string;
  startAt: number;
}

const StarRating: React.FC<RatingProps> = ({
  count,
  className,
  onChange,
  value,
  starClassName,
  startAt,
}) => {
  const rating = (idx) => (startAt === 1 ? startAt + idx : idx);

  return (
    <div className={`${styles.container} ${className ?? ""}`}>
      {new Array(count).fill("").map((item, idx) => (
        <StarIconGrey
          role="button"
          onClick={() => onChange(rating(idx))}
          className={`${rating(idx) <= value ? styles.fill : ""} ${
            starClassName ?? ""
          }`}
        />
      ))}
    </div>
  );
};

export { StarRating };
