import {
  FileIcon,
  AlarmClockCheckIcon,
  CheckCircleIcon,
  DesktopIcon,
  FileCheckIcon,
  HeadphoneIcon,
  HourglassIcon,
  ThumbsUpIcon,
  WrenchIcon,
  mobileCandidateAssessment1,
  supportMemojiImg,
  mobileCandidateAssessment2,
  HandDrawnArrow,
  avatarGroup,
  mobileCandidateAssessment3,
  mobileCandidateAssessment4,
} from "assets";
import styles from "./styles.module.scss";

const Assessment = () => {
  const gettingStarted: InfoData = {
    title: "Getting Started",
    items: [
      {
        Icon: FileIcon,
        title: "Understanding the Instructions",
        text: "Familiarize yourself with the assessment guidelines for a smooth experience.",
      },
      {
        Icon: ThumbsUpIcon,
        title: "Answering with Integrity",
        text: "Respond honestly to all questions.",
      },
      {
        Icon: WrenchIcon,
        title: "Reflecting Your Skills",
        text: "Choose responses that authentically represent your abilities.",
      },
      {
        Icon: FileCheckIcon,
        title: "Completing the Assessment",
        text: "Ensure you respond to all questions.",
      },
    ],
  };

  const assessmentDetails: InfoData = {
    title: "Assessment Details",
    items: [
      {
        Icon: WrenchIcon,
        title: "Assessment Coverage",
        text: "Be prepared for questions related to technical knowledge, soft skills, personality, work culture, and expectations.",
      },
      {
        Icon: AlarmClockCheckIcon,
        title: "Timing",
        text: "Assessments typically take 15-30 minutes, depending on the employer's specifications.",
      },
    ],
  };

  const support: InfoData = {
    title: "Technical Support & Assistance",
    items: [
      {
        Icon: DesktopIcon,
        title: "Assessment Environment",
        text: "We recommend taking the assessment on a laptop, although mobile devices are also supported.",
      },
      {
        Icon: HourglassIcon,
        title: "Assessment Timeout",
        text: "If your assessment times out before submission, don't fret; it will be automatically submitted.",
      },
      {
        Icon: CheckCircleIcon,
        title: "Submission and Beyond",
        text: "Your assessment will be shared with the employer after submission for review.",
      },
      {
        Icon: HeadphoneIcon,
        title: "Technical Support",
        text: "Don’t fret reach out via the support chat icon in case of network disruptions.",
      },
    ],
  };
  return (
    <>
      <section className={`container ${styles.wrapper}`}>
        <div className={styles.header}>
          <h3 className={styles.header__ttl}>
            Maximize Your Precision Assessment Time
          </h3>
          <p className={styles.header__txt}>
            What you need to know when you’re ready to begin your assessment{" "}
          </p>
        </div>
        <div>
        <div data-aos="slide-right" className={styles.infoSec}>
          <Info {...gettingStarted} />
          <div className={styles.gettingStartedImg}>
            <p className={styles.gettingStartedImg__txt}>
              Join hundreds of Recruiters using <span>Precision</span>
            </p>
            <div className={styles.gettingStartedImg__avatars}>
              <HandDrawnArrow /> <img src={avatarGroup} /> <HandDrawnArrow />
            </div>
            <picture>
              <source className={styles.gettingStartedImg__img}
                media="(min-width: 600px)"
                srcSet={mobileCandidateAssessment1}
              />
              <img
                className={styles.gettingStartedImg__img}
                src={mobileCandidateAssessment3}
                alt=""
              />
            </picture>
          </div>
        </div>
        <div data-aos="slide-left" className={styles.infoSec}>
          <div className={styles.assessmentDetailsImg}>
            <picture>
              <source
                media="(min-width: 600px)"
                srcSet={mobileCandidateAssessment2}
              />
              <img className={styles.assessmentDetailsImg__img} src={mobileCandidateAssessment4} alt="" />
            </picture>
          </div>
          <Info {...assessmentDetails} />
        </div>
        <div data-aos="slide-right" className={styles.infoSec}>
          <Info {...support} />
          <div className={styles.supportImg}>
            <img src={supportMemojiImg} alt="" />
            <p>
              Hello Demi. <br /> How can we help?
            </p>
          </div>
        </div>
        </div>
      </section>
    </>
  );
};

interface InfoData {
  title: string;
  items: {
    title: string;
    text: string;
    Icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
  }[];
}

const Info: React.FC<InfoData> = ({ title, items }) => {
  return (
    <div className={styles.content}>
      <p className={styles.content__ttl}>{title}</p>
      {items.map(({ text, title, Icon }) => (
        <div className={styles.content__item}>
          <div>
            <Icon />
          </div>
          <p>
            <span>{title}:</span> {text}
          </p>
        </div>
      ))}
    </div>
  );
};
export { Assessment };
