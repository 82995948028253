import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, Input } from "components";
import { useEffect } from "react";

export interface TitleData {
  title: string;
}

const initTitle: TitleData = {
  title: "",
};

const schema = yup.object({
  title: yup.string().required("Required"),
});

interface TitleFormProps {
  submit: (data: TitleData) => void;
  initData: TitleData | undefined;
}

const TitleForm = ({ submit, initData }: TitleFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<TitleData>({
    resolver: yupResolver(schema),
    defaultValues: initData ?? initTitle,
  });

  useEffect(() => {
    initData && reset(initData);
  }, [initData]);

  const onSubmit: SubmitHandler<TitleData> = (data) => {
    submit(data);
  };

  return (
    <>
      <p className={styles.req}>Required*</p>
      <form className={styles.form}>
        <Input
          label="Assessment Title"
          placeholder="Provide a name for this role"
          type="text"
          required
          validatorMessage={errors.title?.message}
          name="title"
          register={register}
          value={watch("title")}
          parentClassName={styles.inputWrap}
        />

        <Button
          className={styles.btn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </Button>
      </form>
    </>
  );
};

export { TitleForm };
