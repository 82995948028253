import styles from "./styles.module.scss";

const MetricLegend = () => {
  return (
    <>
      <section className={styles.legend}>
        <div
          className={`${styles.legend__item} ${styles[`legend__item--green`]}`}
        >
          <p>
            Excellent: <span>100 - 90%</span>
          </p>
        </div>
        <div
          className={`${styles.legend__item} ${styles[`legend__item--blue`]}`}
        >
          <p>
            Effective: <span>89 - 70%</span>
          </p>
        </div>
        <div
          className={`${styles.legend__item} ${styles[`legend__item--orange`]}`}
        >
          <p>
            Average: <span>69 - 50%</span>
          </p>
        </div>
        <div
          className={`${styles.legend__item} ${styles[`legend__item--red`]}`}
        >
          <p>
            Needs Growth: <span>49% & below</span>
          </p>
        </div>
      </section>
    </>
  );
};

export { MetricLegend };
