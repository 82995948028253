import { Button, OptionType } from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useEffect } from "react";

interface personalitySetData {
  set1: string;
  set2: string;
  set3: string;
  set4: string;
}

export interface PersonalityData {
  best: personalitySetData;
  nextBest: personalitySetData;
  manageable: personalitySetData;
}

const initData: PersonalityData = {
  best: {
    set1: "",
    set2: "",
    set3: "",
    set4: "",
  },
  nextBest: {
    set1: "",
    set2: "",
    set3: "",
    set4: "",
  },
  manageable: {
    set1: "",
    set2: "",
    set3: "",
    set4: "",
  },
};

const personalitySchema = yup
  .object({
    set1: yup.string().required("Required"),
    set2: yup.string().required("Required"),
    set3: yup.string().required("Required"),
    set4: yup.string().required("Required"),
  })
  .required();

const schema = yup
  .object({
    best: personalitySchema,
    nextBest: personalitySchema,
    manageable: personalitySchema,
  })
  .required("Required");

interface PersonalityProps {
  skip: () => void;
  back: () => void;
  submit: (data: PersonalityData) => void;
  personality: PersonalityData | undefined;
  title: string;
}

const PersonalityForm: React.FC<PersonalityProps> = ({
  skip,
  back,
  submit,
  personality,
  title,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<PersonalityData>({
    resolver: yupResolver(schema),
    defaultValues: personality ?? initData,
  });

  useEffect(() => {
    personality && reset(personality);
  }, [personality]);

  const set1Options: OptionType[] = [
    { label: "Sociable", value: "sociable" },
    { label: "Flexible", value: "flexible" },
    { label: "Autonomous", value: "autonomous" },
    { label: "Outcome driven", value: "outcome_driven" },
  ];
  const set2Options: OptionType[] = [
    { label: "Practical", value: "practical" },
    { label: "Creative", value: "creative" },
  ];
  const set3Options: OptionType[] = [
    { label: "Critical thinking", value: "critical_thinking" },
    { label: "Emphatic", value: "empathetic" },
  ];
  const set4Options: OptionType[] = [
    { label: "Planning", value: "planning" },
    { label: "Improvising", value: "improvising" },
  ];

  const personalityDataSet1 = [
    {
      id: `best.set1`,
      list: set1Options,
      onChange: (val) => setValue("best.set1", val),
      value: watch("best.set1"),
      error: errors.best?.set1,
    },
    {
      id: `best.set2`,
      list: set2Options,
      onChange: (val) => setValue("best.set2", val),
      value: watch("best.set2"),
      error: errors.best?.set2,
    },
    {
      id: `best.set3`,
      list: set3Options,
      onChange: (val) => setValue("best.set3", val),
      value: watch("best.set3"),
      error: errors.best?.set3,
    },
    {
      id: `best.set4`,
      list: set4Options,
      onChange: (val) => setValue("best.set4", val),
      value: watch("best.set4"),
      error: errors.best?.set4,
    },
  ];

  const personalityDataSet2 = [
    {
      id: `nextBest.set1`,
      list: set1Options,
      onChange: (val) => setValue("nextBest.set1", val),
      value: watch("nextBest.set1"),
      error: errors.nextBest?.set1,
    },
    {
      id: `nextBest.set2`,
      list: set2Options,
      onChange: (val) => setValue("nextBest.set2", val),
      value: watch("nextBest.set2"),
      error: errors.nextBest?.set2,
    },
    {
      id: `nextBest.set3`,
      list: set3Options,
      onChange: (val) => setValue("nextBest.set3", val),
      value: watch("nextBest.set3"),
      error: errors.nextBest?.set3,
    },
    {
      id: `nextBest.set4`,
      list: set4Options,
      onChange: (val) => setValue("nextBest.set4", val),
      value: watch("nextBest.set4"),
      error: errors.nextBest?.set4,
    },
  ];

  const personalityDataSet3 = [
    {
      id: `manageable.set1`,
      list: set1Options,
      onChange: (val) => setValue("manageable.set1", val),
      value: watch("manageable.set1"),
      error: errors.manageable?.set1,
    },
    {
      id: `manageable.set2`,
      list: set2Options,
      onChange: (val) => setValue("manageable.set2", val),
      value: watch("manageable.set2"),
      error: errors.manageable?.set2,
    },
    {
      id: `manageable.set3`,
      list: set3Options,
      onChange: (val) => setValue("manageable.set3", val),
      value: watch("manageable.set3"),
      error: errors.manageable?.set3,
    },
    {
      id: `manageable.set4`,
      list: set4Options,
      onChange: (val) => setValue("manageable.set4", val),
      value: watch("manageable.set4"),
      error: errors.manageable?.set4,
    },
  ];

  const onSubmit: SubmitHandler<PersonalityData> = (data) => submit(data);

  const isEmpty = JSON.stringify(watch()) === JSON.stringify(initData);

  useEffect(() => {
    if (isEmpty) localStorage.removeItem("personalityIsFilled");
    else localStorage.setItem("personalityIsFilled", "filled");
  }, [watch()]);

  return (
    <>
      <section className={styles.heading}>
        <h2 className={styles.ttl}>{title}</h2>
        <p className={styles.txt}>
          Define crucial personality traits for effective operational delivery
          in this role across the following categories:
        </p>
        <Button className={styles.skipBtn} type="outline" onClick={skip}>
          Skip
        </Button>
      </section>
      <form className={styles.container}>
        <div className={styles.personalitySet}>
          <p>Best</p>
          {personalityDataSet1.map((item, idx) => (
            <PersonalitySet {...item} key={`${item.id}_${idx}`} />
          ))}
        </div>
        <div className={styles.personalitySet}>
          <p>Next Best</p>
          {personalityDataSet2.map((item, idx) => (
            <PersonalitySet {...item} key={`${item.id}_${idx}`} />
          ))}
        </div>
        <div className={styles.personalitySet}>
          <p>Manageable</p>
          {personalityDataSet3.map((item, idx) => (
            <PersonalitySet {...item} key={`${item.id}_${idx}`} />
          ))}
        </div>
      </form>
      <div className={styles.btnSec}>
        <Button type="outline" onClick={back}>
          Previous
        </Button>
        <Button type="fill" onClick={handleSubmit(onSubmit)}>
          Next
        </Button>
      </div>
    </>
  );
};

const PersonalitySet = ({ id, list, onChange, value, error }) => {
  return (
    <fieldset className={`${styles.personality} ${error ? styles.red : ""}`}>
      {list.map((item3, idx) => (
        <label key={`${id}_${idx}`}>
          <input
            onChange={() => onChange(item3.value)}
            id={id}
            type="radio"
            checked={item3.value === value}
          />
          {item3.label}
        </label>
      ))}
    </fieldset>
  );
};

export { PersonalityForm };
