import * as React from "react";
import styles from "./styles.module.scss";
import { placeholderAvatar } from "assets";

interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  className?: string;
  parentClassName?: string;
  validatorMessage: string | undefined;
  label?: string;
  showRequired?: boolean;
  max?: number | string;
  value?: string;
  instruction?: string;
  image?: {
    value: string;
    description?: string;
  };
}

const TextareaDK: React.FC<TextareaProps> = (props) => {
  const {
    className,
    parentClassName,
    validatorMessage,
    label,
    showRequired,
    max,
    value = "",
    name,
    instruction,
    image,
  } = props;
  return (
    <div
      className={`${styles.inputWrapper} ${parentClassName} ${
        validatorMessage ? styles.error : ""
      }`}
    >
      {label && (
        <label className={styles.label}>
          {label}
          {showRequired ? <span className={styles.req}>*</span> : ""}
          {instruction ? (
            <p className={styles.instruction}>{instruction}</p>
          ) : (
            ""
          )}
        </label>
      )}
      {image ? (
        <div className={styles.imgWrap}>
          <img src={image.value} alt="" />
          {image.description ? <p>Img Description: {image.description}</p> : ""}
        </div>
      ) : (
        ""
      )}
      <textarea
        {...props}
        className={`${styles.textarea} ${className}`}
        name={name}
      />
      {max ? (
        <p className={styles.length}>
          {value.length}/{max}
        </p>
      ) : (
        ""
      )}
      {validatorMessage && (
        <small className={styles.message}>{validatorMessage}</small>
      )}
    </div>
  );
};

export { TextareaDK };
