import {
  AssessmentIcon,
  CloseIcon,
  HamburgerMenuIcon,
  LogoutIcon,
  OverviewIcon,
  ReportIcon,
  SettingIcon,
  SubscriptionIcon,
  logoWithText,
} from "assets";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "router";
import { tabs } from "..";
import { useState } from "react";
import { useAppSelector } from "state/hooks";
import { Signout } from "pages";

interface SidebarProps {
  active: tabs;
}

const SideBar: React.FC<SidebarProps> = ({ active }) => {
  const mobileWidth = 900;
  const [mobile, setMobile] = useState(
    window.innerWidth <= mobileWidth ? true : false
  );
  const [showNav, setShowNav] = useState(false);
  const [logout, setLogout] = useState(false);
  const { firstName, lastName, avatar } = useAppSelector((state) => state.user);
  const plan = useAppSelector((state) => state.plan);
  const navigate = useNavigate();

  const screenSizeUpdate = () => {
    if (window.innerWidth <= mobileWidth) {
      setMobile(true);
    } else if (window.innerWidth > mobileWidth) {
      setMobile(false);
      setShowNav(false);
    }
  };

  window.addEventListener("resize", screenSizeUpdate);
  const Navtabs = [
    {
      label: "Dashboard",
      key: "dashboard",
      Icon: OverviewIcon,
      path: Routes.employerDashbord,
    },
    {
      label: "Create Assessment",
      key: "assessment",
      Icon: AssessmentIcon,
      path: Routes.employerAssessments,
    },
    {
      label: "Track & View reports",
      key: "report",
      Icon: ReportIcon,
      path: Routes.employerReports,
    },
    {
      label: "Profile & Settings",
      key: "settings",
      Icon: SettingIcon,
      path: Routes.settings,
    },
    {
      label: "Plans & Billings",
      key: "billings",
      Icon: SubscriptionIcon,
      path: Routes.billings,
    },
  ];

  const handleTopup = () => navigate(`${Routes.billings}?topup=true`);
  const handleUpgrade = () => navigate(Routes.billings);

  const noActivePlan = plan.status !== "active";

  const handleLogout = () => {
    mobile && setShowNav(false);
    setLogout(true);
  };

  return (
    <>
      <Signout show={logout} close={() => setLogout(false)} />
      <aside
        style={{ zIndex: mobile && showNav ? "5000000000" : "20" }}
        className={`${styles.sidebar} ${showNav ? styles.openMenu : ""}`}
      >
        <div className={styles.logoSec}>
          <img className={styles.logo} src={logoWithText} />
          {!showNav ? (
            <HamburgerMenuIcon onClick={() => setShowNav(true)} role="button" />
          ) : (
            <CloseIcon
              className={styles.close}
              onClick={() => setShowNav(false)}
              role="button"
            />
          )}
        </div>
        <nav className={styles.nav}>
          {Navtabs.map(({ key, label, path, Icon }, idx) => (
            <Link
              key={idx}
              to={noActivePlan ? Routes.billings : path}
              className={
                active === key
                  ? styles.active
                  : noActivePlan
                  ? styles.disabled
                  : ""
              }
              onClick={() => {
                setShowNav(false);
                localStorage.removeItem("assessmentId");
              }}
            >
              <Icon /> {label}
            </Link>
          ))}
        </nav>
        {!mobile || (mobile && showNav) ? (
          <div className={styles.plan}>
            <div className={styles.plan__header}>
              <p className={styles.plan__header__ttl}>
                {plan.plan.replaceAll("_", " ")}
              </p>
              <span
                className={`${styles.plan__header__status} ${
                  styles[`plan__header__status--${plan.status}`]
                }`}
              >
                {plan.status}
              </span>
            </div>
            {plan.status === "active" ? (
              <>
                <div className={styles.plan__info}>
                  <p>Role count</p>
                  <div className={styles.plan__info__scale}>
                    <div>
                      <div
                        style={{
                          width: `${
                            (plan.roles.used / plan.roles.total) * 100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <p>
                      <b>{plan.roles.used}</b> of {plan.roles.total}
                    </p>
                  </div>
                </div>
                <div className={styles.plan__info}>
                  <p>Candidate slots</p>
                  <div className={styles.plan__info__scale}>
                    <div>
                      <div
                        style={{
                          width: `${
                            (plan.candidates.used / plan.candidates.total) * 100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <p>
                      <b>{plan.candidates.used} </b> of {plan.candidates.total}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {plan.status === "expired" ? (
              <p className={styles.plan__txt}>
                Subscription has expired. Renew or Upgrade your plan.
              </p>
            ) : plan.status === "terminated" ? (
              <p className={styles.plan__txt}>
                You canceled your subscription. Subscribe to a new plan.
              </p>
            ) : (
              ""
            )}
            <div className={styles.plan__btns}>
              <button disabled={plan.status !== "active"} onClick={handleTopup}>
                Top-up slots
              </button>
              <button onClick={handleUpgrade}>Upgrade plan</button>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className={styles.user}>
          {avatar === "" ? "" : <img src={avatar} alt="avatar" />}
          <button>
            {firstName} {lastName}
            <span>Log out</span>
          </button>
          <LogoutIcon onClick={handleLogout} role="button" />
        </div>
      </aside>
    </>
  );
};

export { SideBar };
