import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, RadioInput } from "components";
import { QualityOptions, TraitOptions } from "./options";

interface WorkQualitiesData {
  quality: string;
  trait: string;
}

const initData: WorkQualitiesData = {
  quality: "",
  trait: "",
};

const schema = yup
  .object()
  .shape({
    quality: yup.string().required("Required"),
    trait: yup.string().required("Required"),
  })
  .required();

interface AssessmentWorkQualitiesProps {
  submit: (data: WorkQualitiesData) => void;
}

const AssessmentWorkQualitiesUI: React.FC<AssessmentWorkQualitiesProps> = ({
  submit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<WorkQualitiesData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const onSubmit: SubmitHandler<WorkQualitiesData> = (data) => submit(data);

  return (
    <>
      <form>
        <RadioInput
          register={register}
          name={"quality"}
          validatorMessage={errors.quality?.message}
          label="1.  There are nine qualities listed below, pick only the one that best describes you: "
          instruction="Pick just one"
          options={QualityOptions}
          type="radio"
        />
        <RadioInput
          register={register}
          name={"trait"}
          validatorMessage={errors.trait?.message}
          label="2. Out of the nine traits mentioned below pick the one most like you:"
          instruction="Pick just one"
          options={TraitOptions}
          type="radio"
        />
      </form>
      <Button
        className={styles.nextBtn}
        type="fill"
        onClick={handleSubmit(onSubmit)}
      >
        Next
      </Button>
    </>
  );
};

export { AssessmentWorkQualitiesUI };
