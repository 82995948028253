import { Button, Input, Modal, Textarea } from "components";
import styles from "./styles.module.scss";
import { CloseIconCircle, TrashIcon } from "assets";
import { useEffect, useState } from "react";
import { getBase64 } from "helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";

interface ImageData {
  image: File | any;
  description: string | undefined;
}

const schema = yup
  .object({
    description: yup.string(),
    image: yup.mixed().required("Image is required"),
  })
  .required();

interface ImageUploadProps {
  show: boolean;
  close: () => void;
  submit: (data: ImageData) => void;
  initData: ImageData;
  imageLink?: string | null;
}

const ImageUploadWithDescription: React.FC<ImageUploadProps> = ({
  show,
  close,
  submit,
  initData,
  imageLink,
}) => {
  const [prevImg, setPrevImg] = useState<string | null>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<ImageData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  useEffect(() => {
    reset(initData);
    setPrevImg(imageLink);
  }, [initData, imageLink]);

  const handleChange = (e) => {
    e.target.files && setValue("image", e.target.files[0]);
  };

  const onSubmit: SubmitHandler<ImageData> = async (data) => {
    submit(data);
    close();
  };

  const image = watch("image");

  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <CloseIconCircle
          className={styles.close}
          role="button"
          onClick={close}
        />
        <h1 className={styles.ttl}>Add Image </h1>
        <div>
          <label
            onChange={handleChange}
            htmlFor={"image"}
            className={styles.inputWrap}
          >
            <input id={"image"} type="file" accept=".png, .jpg, .jpeg" />
            <p>Browse</p>
            <p>Supported types: PNG, JPG, JPEG</p>
          </label>
          {(image || prevImg) && (
            <div className={styles.uploadedImage}>
              <img src={image ? URL.createObjectURL(image) : prevImg ?? ""} />
              <p>{image?.name ?? "Image"}</p>
              {!prevImg && (
                <TrashIcon
                  role="button"
                  onClick={() => {
                    setValue("image", null);
                  }}
                />
              )}
            </div>
          )}
          <p className={styles.error}>{errors.image?.message?.toString()}</p>
        </div>
        <Textarea
          label="Image description"
          placeholder="Enter a text that gives more context"
          register={register}
          name="description"
          validatorMessage={errors.description?.message}
          className={styles.description}
        />
        <Button
          disabled={image === null}
          className={styles.btn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Modal>
    </>
  );
};

export { ImageUploadWithDescription };
