import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, RadioInput } from "components";
import { SatisfactionOptions } from "./options";

interface WorkMotivatorsData {
  satisfaction: string[] | undefined;
}

const initData: WorkMotivatorsData = {
  satisfaction: [],
};

const schema = yup.object().shape({
  satisfaction: yup.array().of(yup.string().required("Required")),
});

interface PreviewWorkMotivatorsProps {
  submit: (data: string[]) => void;
}

const PreviewWorkMotivatorsUI: React.FC<PreviewWorkMotivatorsProps> = ({
  submit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<WorkMotivatorsData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const onSubmit: SubmitHandler<WorkMotivatorsData> = (data) =>
    submit(data?.satisfaction ?? []);

  return (
    <>
      <form>
        <RadioInput
          register={register}
          name={"satisfaction"}
          validatorMessage={errors.satisfaction?.message}
          label="3. Which of these words best describes the satisfaction you hope to derive from this role in your present career journey?"
          instruction="Select 3 that apply"
          options={SatisfactionOptions}
          type="checkbox"
          disabled={watch("satisfaction")?.length === 3}
          checkValue={watch("satisfaction")}
        />
      </form>
      <Button
        className={styles.nextBtn}
        type="fill"
        onClick={handleSubmit(onSubmit)}
      >
        Next
      </Button>
    </>
  );
};

export { PreviewWorkMotivatorsUI };
