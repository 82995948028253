import { emptyAssessmentsImg } from "assets";
import styles from "./styles.module.scss";
import { useEffect } from "react";

const PreviewCompleteUI = () => {
  useEffect(() => {
    localStorage.removeItem("secondsLeft");
    localStorage.removeItem("answered");
    localStorage.removeItem("candidate");
  }, []);
  return (
    <>
      <section className={styles.container}>
        <img src={emptyAssessmentsImg} />
        <h1>Assessment submitted successfully</h1>
        <p>
          <span>What’s next?</span> The assessment will be evaluated by your
          test administrator
        </p>
      </section>
    </>
  );
};

export { PreviewCompleteUI };
