import {
  Button,
  CustomPhoneInput,
  CustomSelect,
  Input,
  initOptionType,
  optionTypeSchema,
} from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  EmploymentTypeOptions,
  ExperienceEnvOptions,
  ExperienceLevelOptions,
  ExperienceYearOptions,
} from "utils/options";
import { PdfIcon } from "assets";
import { useEffect } from "react";

export interface OptionTypeOptional {
  label?: string;
  value?: any;
}

export interface BasicFitData {
  name: string;
  portfolio: string;
  number: { code: string; number: string; value: string };
  resume: FileList | any;
  isBasicFit: boolean | undefined;
  requiresLocation: boolean | undefined;
  requiresEnv: boolean | undefined;
  location: string | undefined;
  experienceLevel: OptionTypeOptional;
  experienceYears: OptionTypeOptional;
  employmentType: OptionTypeOptional;
  salaryExpectation: string | undefined;
  workEnvironment: OptionTypeOptional;
}

const initData: BasicFitData = {
  name: "",
  portfolio: "",
  number: { code: "", number: "", value: "" },
  resume: null,
  isBasicFit: false,
  requiresLocation: false,
  requiresEnv: false,
  location: "",
  experienceLevel: initOptionType,
  experienceYears: initOptionType,
  employmentType: initOptionType,
  salaryExpectation: "",
  workEnvironment: initOptionType,
};

const isFile = (value: any): value is File => value instanceof File;

export const optionTypeSchema2 = () =>
  yup.object({
    label: yup.string().required("Required"),
    value: yup.string().required("Required"),
  });

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Required"),
    portfolio: yup.string().required("Required").url("Enter a valid url"),
    number: yup
      .object({
        code: yup.string().required("Required"),
        number: yup.string().required("Required"),
        value: yup.string().required("Required"),
      })
      .required(),
    isBasicFit: yup.boolean(),
    requiresEnv: yup.boolean(),
    requiresLocation: yup.boolean(),
    location: yup.string().when("requiresLocation", {
      is: true,
      then: (schema) => schema.required("Required"),
    }),
    resume: yup
      .mixed()
      .required("Resume is required")
      .test("fileSize", "File is too large", (value) =>
        value === null
          ? true
          : value && isFile(value[0]) && value[0].size <= 1048576 * 2
      ),
    experienceLevel: yup.object().when("isBasicFit", {
      is: true,
      then: (schema) => optionTypeSchema().required("Required"),
    }),
    experienceYears: yup.object().when("isBasicFit", {
      is: true,
      then: (schema) => optionTypeSchema().required("Required"),
    }),
    employmentType: yup.object().when("isBasicFit", {
      is: true,
      then: (schema) => optionTypeSchema().required("Required"),
    }),
    salaryExpectation: yup.string().when("isBasicFit", {
      is: true,
      then: (schema) =>
        schema.matches(/[0-9]/, "Enter a valid number").required("Required"),
    }),
    workEnvironment: yup.object().when("requiresEnv", {
      is: true,
      then: (schema) => optionTypeSchema().required("Required"),
    }),
  })
  .required();

interface AssessmentBasicFitProps {
  submit: (data: BasicFitData) => void;
  hasBasicExpectation: boolean;
  salaryData:
    | {
        currency: string;
        term: string;
      }
    | undefined;
  requiresLocation: boolean;
  requiresEnv: boolean;
}
const AssessmentBasicFitUI: React.FC<AssessmentBasicFitProps> = ({
  submit,
  hasBasicExpectation,
  salaryData,
  requiresLocation,
  requiresEnv,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<BasicFitData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  useEffect(() => {
    setValue("isBasicFit", hasBasicExpectation);
    setValue("requiresLocation", requiresLocation);
    setValue("requiresEnv", requiresEnv);
  }, [hasBasicExpectation, requiresEnv, requiresLocation]);

  const onSubmit: SubmitHandler<BasicFitData> = (data) => {
    submit(data);
  };

  const resume: File = watch("resume") ? watch("resume")[0] : null;

  return (
    <>
      <form className={styles.form}>
        <Input
          label="Full name"
          placeholder="e.g. John Doe"
          type="text"
          required
          validatorMessage={errors.name?.message}
          name="name"
          register={register}
          value={watch("name")}
          parentClassName={styles.inputWrap}
        />
        <div className={styles.phoneWrap}>
          <CustomPhoneInput
            label="Phone number"
            placeholder="Your phone number"
            validatorMessage={errors.number?.value?.message?.toString() ?? ""}
            onChange={({ code, number, value }) =>
              setValue("number", { code, number, value })
            }
            value={watch("number.value")}
            parentClassName={styles.inputWrap}
          />
          <p className={styles.phoneTxt}>
            This may be used to contact you if you need assistance
          </p>
        </div>
        {watch("isBasicFit") ? (
          <>
            {requiresLocation ? (
              <Input
                label="Location"
                placeholder="e.g. Lagos, Nigeria"
                type="text"
                required
                validatorMessage={errors.location?.message}
                name="location"
                register={register}
                value={watch("location")}
                parentClassName={styles.inputWrap}
              />
            ) : (
              ""
            )}
            <CustomSelect
              label="Experience Level"
              placeholder="Select the experience level"
              options={ExperienceLevelOptions}
              onChange={(val) => setValue("experienceLevel", val)}
              validatorMessage={
                errors.experienceLevel?.value?.message?.toString() ?? ""
              }
              name={"experienceLevel"}
              value={{
                label: watch("experienceLevel").label ?? "",
                value: watch("experienceLevel").value ?? "",
              }}
              parentClassName={styles.inputWrap}
            />
            <CustomSelect
              label="Years of Experience"
              placeholder="Select years of experience"
              options={ExperienceYearOptions}
              onChange={(val) => setValue("experienceYears", val)}
              validatorMessage={
                errors.experienceYears?.value?.message?.toString() ?? ""
              }
              name={"experienceYears"}
              value={{
                label: watch("experienceYears").label ?? "",
                value: watch("experienceYears").value ?? "",
              }}
              parentClassName={styles.inputWrap}
            />
          </>
        ) : (
          ""
        )}
        <Input
          label="Portfolio/LinkedIn or relevant link"
          placeholder="Input link here"
          type="url"
          required
          validatorMessage={errors.portfolio?.message}
          name="portfolio"
          register={register}
          value={watch("portfolio")}
          parentClassName={styles.inputWrap}
        />

        {watch("isBasicFit") ? (
          <>
            <CustomSelect
              label="What type of employment are you looking for?"
              placeholder="Select the employment type"
              options={EmploymentTypeOptions}
              onChange={(val) => setValue("employmentType", val)}
              validatorMessage={
                errors.employmentType?.value?.message?.toString() ?? ""
              }
              name={"employmentType"}
              value={{
                label: watch("employmentType").label ?? "",
                value: watch("employmentType").value ?? "",
              }}
              parentClassName={styles.inputWrap}
            />
            <Input
              label={
                salaryData
                  ? `Salary Expectations in (${salaryData.currency}) ${salaryData.term} `
                  : "Salary Expectations"
              }
              placeholder="Enter your salary expectations"
              type="number"
              required
              validatorMessage={errors.salaryExpectation?.message}
              name="salaryExpectation"
              register={register}
              value={watch("salaryExpectation")}
              parentClassName={styles.inputWrap}
            />
            {watch("requiresEnv") ? (
              <CustomSelect
                label="Based on your previous employment history, what types of work environments have you experienced?"
                placeholder="Select the employment type"
                options={ExperienceEnvOptions.filter(
                  (item) => item.value !== "not_important"
                )}
                onChange={(val) => setValue("workEnvironment", val)}
                validatorMessage={
                  errors.workEnvironment?.value?.message?.toString() ?? ""
                }
                name={"workEnvironment"}
                value={{
                  label: watch("workEnvironment").label ?? "",
                  value: watch("workEnvironment").value ?? "",
                }}
                parentClassName={styles.inputWrap}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <div
          className={`${styles.fileWrap} ${resume ? styles.uploaded : ""} ${
            errors.resume?.message ? styles.error : ""
          }`}
        >
          <p className={styles.label}>Upload your resume/CV</p>
          <label htmlFor="resume">
            <p>
              Click to <span>{resume ? "Change" : "Upload"}</span>
            </p>
            {!resume && <p className={styles.limit}>Max 2MB</p>}
            {resume && (
              <div className={styles.file}>
                <PdfIcon /> <span>{resume.name}</span>
              </div>
            )}
            <Input
              id="resume"
              label=""
              placeholder=""
              type="file"
              required
              validatorMessage={errors.resume?.message?.toString()}
              name="resume"
              register={register}
              accept=".pdf"
              parentClassName={styles.fileInput}
            />
          </label>
          {errors.resume?.message && (
            <p className={styles.errorMsg}>
              {errors.resume?.message?.toString()}
            </p>
          )}
        </div>
      </form>
      <Button
        className={styles.nextBtn}
        type="fill"
        onClick={handleSubmit(onSubmit)}
      >
        Next
      </Button>
    </>
  );
};

export { AssessmentBasicFitUI };
