import { PreferenceData, reduxPreferenceAction } from "../types";

export const updatePreference = (
  data: PreferenceData
): reduxPreferenceAction => {
  return {
    type: "UPDATE_PREFERENCE",
    payload: data,
  };
};
