/*
=================================
CONTACT US SERVICES
=================================
*/

import { contactUsURL, postRequest } from "api";

export interface ContactUsRequestData {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  message: string;
}

/**
 * Update user service
 * @returns axios promise
 */

export const contactUsService = (data: ContactUsRequestData) => {
  const requestData = {
    url: contactUsURL(),
    data,
  };
  return postRequest(requestData);
};
