import { PreviewPersonalityUI } from "features";

interface AssessmentPersonalityProps {
  callback: (metric: string) => void;
}

const AssessmentPersonality: React.FC<AssessmentPersonalityProps> = ({
  callback,
}) => {
  const submit = (data) => {
    const answered = parseInt(localStorage.getItem("answered") ?? "0");
    localStorage.setItem("answered", `${answered + 8}`);

    callback("personality");
  };

  return (
    <>
      <PreviewPersonalityUI submit={submit} />
    </>
  );
};

export { AssessmentPersonality };
