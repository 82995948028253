import { emptyAssessmentsImg } from "assets";
import styles from "./styles.module.scss";

const AssessmentMalpracticeUI = () => {
  return (
    <>
      <section className={styles.container}>
        <img src={emptyAssessmentsImg} />
        <h1>Assessment Terminated</h1>
        <p>
          Sorry your assessment was terminated due to a violation of the
          assessment instructions, thank you for applying
        </p>
      </section>
    </>
  );
};

export { AssessmentMalpracticeUI };
