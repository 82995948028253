import {
  AssessmentIcon,
  BriefcaseIcon,
  ChevronRightIcon,
  EditIcon,
  GameIcon,
  SubscriptionIcon,
  placeholderLogo,
} from "assets";
import styles from "./styles.module.scss";
import { ReactNode, useEffect, useState } from "react";
import {
  Button,
  CustomSelect,
  GraphProps,
  LineGraph,
  OptionType,
} from "components";
import { AssessmentStatuses } from "types";

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const data = [10, 12, 13, 45, 132, 12, 10, 0, 9, 8, 67, 109];

const breakdown = [
  {
    title: "Nov",

    abandoned: 5,
    completed: 6,
  },
];

interface StatCardProps {
  Icon: ReactNode;
  value: string;
  text: string;
  id: "active" | "talent" | "created" | "subscription";
}

const StatCard: React.FC<StatCardProps> = ({ Icon, value, text, id }) => {
  return (
    <div className={styles.stat}>
      <div className={`${styles.stat__icon} ${styles[id]}`}>{Icon} </div>
      <div className={styles.stat__data}>
        <p className={styles.stat__value}>{value}</p>
        <p className={styles.stat__text}>{text}</p>
      </div>
    </div>
  );
};

interface ActivityData {
  title: string;
  start: { date: string; time: string };
  status: AssessmentStatuses;
  completed: number;
  abandoned: number;
  deadline: string;
}

interface EmployerOverviewUIProps {
  statData:
    | {
        activeAssessments: number;
        totalTalents: number;
        createdAssessments: number;
        daysLeft: number;
        plan: string;
      }
    | undefined;
  activites: ActivityData[];
  createAssessment: () => void;
  graphData: GraphProps;
  period: {
    value: string;
    onChange: (val: string) => void;
  };
  handleLogo: () => void;
  company: string;
  handleNameChange: (name: string) => void;
  logo: string;
}

const EmployerOverviewUI: React.FC<EmployerOverviewUIProps> = ({
  statData,
  activites,
  createAssessment,
  graphData,
  period,
  handleLogo,
  company,
  handleNameChange,
  logo,
}) => {
  const [name, setName] = useState(company);

  useEffect(() => {
    setName(company);
  }, [company]);

  const stats: StatCardProps[] = [
    {
      Icon: <BriefcaseIcon />,
      value: `${statData?.activeAssessments}`,
      id: "active",
      text: "Active Assessments",
    },
    {
      Icon: <GameIcon />,
      value: `${statData?.totalTalents}`,
      id: "talent",
      text: "Total Talent Assessed",
    },
    {
      Icon: <AssessmentIcon />,
      value: `${statData?.createdAssessments}`,
      id: "created",
      text: "Created Assessments",
    },
    {
      Icon: <SubscriptionIcon />,
      value: `${statData?.daysLeft}`,
      id: "subscription",
      text: `Days Left`,
    },
  ];

  const periodOptions: OptionType[] = [
    {
      label: "Past day",
      value: "past_day",
    },
    {
      label: "Past week",
      value: "past_week",
    },
    {
      label: "Past month",
      value: "past_month",
    },
    {
      label: "Past year",
      value: "past_year",
    },
  ];
  const youtubePlaylist =
    "https://www.youtube.com/playlist?list=PL2o6r0eT1M7dnpWZePjXP5B_UnVUrvEaR";

  const tutorial1 =
    "https://www.youtube.com/embed/nktqFZgvbLk?si=wsEDkUmO46Q2rwDK";
  const tutorial2 =
    "https://www.youtube.com/embed/t1E97CEp-lc?si=wE10JUYLkDXmj_0N";
  const tutorial3 =
    "https://www.youtube.com/embed/ImrFNJ2ihaM?si=tiLO2mELyRRB2R7r";
  const tutorial4 =
    "https://www.youtube.com/embed/tI3IQIruNwc?si=AY5_ULRcJ_S-Oclz";
  const tutorial5 =
    "https://www.youtube.com/embed/5ZiHwqHlGOI?si=0IHuk6GS-ukKO_k1";

  const [tutorial, setTutorial] = useState(0);

  const tutorials = [tutorial1, tutorial2, tutorial3, tutorial4, tutorial5];

  return (
    <>
      <main className={styles.container}>
        {statData && (
          <section className={styles.statList}>
            {stats.map((stat, idx) => (
              <StatCard key={idx} {...stat} />
            ))}
          </section>
        )}
        <section className={styles.overview}>
          <div className={styles.report}>
            <div className={styles.secHeading}>
              <p className={styles.ttl}>Report Insights</p>
              <CustomSelect
                onChange={(val) => period.onChange(val.value)}
                validatorMessage={""}
                name={""}
                placeholder={"Select period"}
                options={periodOptions}
                value={
                  periodOptions.find((item) => item.value === period.value) ?? {
                    label: "All",
                    value: "",
                  }
                }
                inputClass={styles.secHeading__select}
                parentClassName={styles.secHeading__selectWrap}
              />
            </div>
            <LineGraph {...graphData} />
          </div>
          <div className={styles.tutorial}>
            <p className={styles.ttl}>Tutorial</p>
            <p className={styles.txt}>
              Maximize Precision. Watch the demo tutorials below.
              {/* <a target="_blank" href={youtubePlaylist}>
                here
              </a> */}
            </p>
            <div>
              <ChevronRightIcon
                role="button"
                onClick={() =>
                  setTutorial((prev) =>
                    prev > 0 ? prev - 1 : tutorials.length - 1
                  )
                }
              />
              <iframe
                style={{ width: "100%", borderRadius: "8px" }}
                width="560"
                height="315"
                src={tutorials[tutorial]}
                title="Create an Assessment with Precision"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <ChevronRightIcon
                role="button"
                onClick={() =>
                  setTutorial((prev) =>
                    prev === tutorials.length - 1 ? 0 : prev + 1
                  )
                }
              />
            </div>
          </div>
          <div className={styles.activity}>
            <p className={styles.ttl}>Recent Assessment Activity</p>
            <div>
              <div className={styles.activity__heading}>
                <p>Assessment</p>
                <p>Start date</p>
                <p>Status</p>
                <p>Completed</p>
                <p>Abandoned</p>
                <p>Deadline</p>
              </div>
              {activites.length > 0 ? (
                <div className={styles.activity__body}>
                  {activites.map((assessment, idx) => (
                    <div key={idx} className={styles.activity__body__item}>
                      <p>
                        <span>Assessment</span> {assessment.title}
                      </p>
                      <p>
                        <span>Start date</span>
                        {assessment.start.date} <br />
                        {assessment.start.time} (GMT)
                      </p>
                      <p className={styles[assessment.status]}>
                        <span>Status</span>
                        {assessment.status}
                      </p>
                      <p>
                        <span>Completed</span>
                        {assessment.completed}
                      </p>
                      <p>
                        <span>Abandoned</span>
                        {assessment.abandoned}
                      </p>
                      <p>
                        <span>Deadline</span>
                        {assessment.deadline}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.activity__empty}>
                  <p>You haven’t assessed any candidates yet</p>
                  <Button type="fill" onClick={createAssessment}>
                    Create Assessment
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.profile}>
            <p className={styles.ttl}>Company Profile</p>
            <div className={styles.profile__logo}>
              <img src={logo} alt="" />
              <EditIcon
                role="button"
                onClick={handleLogo}
                className={styles.profile__upload}
              />
            </div>

            <div className={styles.profile__name}>
              <label>Company Name*</label>
              <div>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Enter company name"
                />

                <Button
                  disabled={name === "" || name === company}
                  type="fill"
                  onClick={() => handleNameChange(name)}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export { EmployerOverviewUI };
