import { editTitleService, startAssessmentService } from "api";
import { Loader } from "components";
import { TitleData, TitleForm } from "features";
import { getError } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useEffect, useMemo, useState } from "react";

interface AssessmentTitleProps {
  callback: (data: TitleData) => void;
  title: TitleData | undefined;
}

const AssessmentTitle: React.FC<AssessmentTitleProps> = ({
  callback,
  title: initTitle,
}) => {
  const [title, setTitle] = useState<TitleData | undefined>(initTitle);
  const [isEdit, setEdit] = useState(false);
  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");

  useEffect(() => {
    setTitle(initTitle);
  }, [initTitle]);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const onSubmit = (data: TitleData) => {
    if (initTitle && initTitle.title === data.title) {
      callback(initTitle);
      return;
    }
    setTitle(data);
    if (localStorage.getItem("assessmentId")) {
      run(editTitleService({ assessment: id, title: data.title }));
      setEdit(true);
    } else {
      run(startAssessmentService(data.title));
      setEdit(false);
    }
  };

  useMemo(() => {
    if (response?.status === 200) {
      !isEdit && localStorage.setItem("assessmentId", response.data.data.id);
      title && callback(title);
    } else if (error) {
      openToast({
        show: true,
        ...getError({
          error,
          heading: "Sorry",
          text:
            error?.response?.data?.error?.message ??
            `Failed to ${
              isEdit ? "update assessment title" : "initiate assessment"
            }, please try again later`,
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <TitleForm submit={onSubmit} initData={title} />
    </>
  );
};
export { AssessmentTitle };
