import { CombinedState, combineReducers } from "redux";
import {
  allPlansActions,
  AllPlansData,
  planActions,
  PlanData,
  preferenceActions,
  PreferenceData,
  toastActions,
  ToastData,
  userActions,
  UserData,
} from "../types";
import toastReducer from "./toast";
import userReducer from "./user";
import planReducer from "./plan";
import allPlansReducer from "./plans";
import preferenceReducer from "./preference";

const allReducers = combineReducers({
  toast: toastReducer,
  user: userReducer,
  plan: planReducer,
  plans: allPlansReducer,
  preference: preferenceReducer,
});

export type RootReducerState =
  | CombinedState<{
      toast: ToastData;
      user: UserData;
      plan: PlanData;
      plans: AllPlansData[];
      preference: PreferenceData;
    }>
  | undefined;

export type RootReducerAction =
  | CombinedState<{
      toast: toastActions;
      user: userActions;
      plan: planActions;
      plans: allPlansActions;
      preference: preferenceActions;
    }>
  | any;

const rootReducer = (state: RootReducerState, action: RootReducerAction) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return allReducers(state, action);
};

export { rootReducer };
