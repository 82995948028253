import { ToggleIconDisabled, ToggleIconEnabled } from "assets";
import styles from "./styles.module.scss";

interface ToggleProps {
  onClick: () => void;
  checked: boolean;
  className?: string;
}

const Toggle = ({ checked, onClick, className }: ToggleProps) => {
  return (
    <>
      <span
        role="button"
        onClick={onClick}
        className={`${styles.switch} ${className}`}
      >
        {!checked ? <ToggleIconDisabled /> : <ToggleIconEnabled />}
      </span>
    </>
  );
};

export { Toggle };
