import { Button, Input, Modal } from "components";
import styles from "./styles.module.scss";
import { CloseIconCircle, TrashIcon } from "assets";
import { useState } from "react";
import { getBase64 } from "helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";

interface PasswordData {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const initPasswordData: PasswordData = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};
const schema = yup.object({
  oldPassword: yup
    .string()
    .required("Required")
    .min(8, "Password should be at least 8 characters long")
    .matches(/[A-Z]/, "Password should contain an uppercase character")
    .matches(/[a-z]/, "Password should contain an lowercase character")
    .matches(/[0-9]/, "Password should contain at least one number"),
  password: yup
    .string()
    .required("Required")
    .min(8, "Password should be at least 8 characters long")
    .matches(/[A-Z]/, "Password should contain an uppercase character")
    .matches(/[a-z]/, "Password should contain an lowercase character")
    .matches(/[0-9]/, "Password should contain at least one number"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

interface ChangePasswordModalProps {
  show: boolean;
  close: () => void;
  submit: (data: PasswordData) => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  show,
  close,
  submit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<PasswordData>({
    resolver: yupResolver(schema),
    defaultValues: initPasswordData,
  });

  const onSubmit: SubmitHandler<PasswordData> = (data) => {
    reset();
    submit(data);
  };

  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <CloseIconCircle
          className={styles.close}
          role="button"
          onClick={close}
        />
        <h1 className={styles.ttl}>Set New Password</h1>

        <form className={styles.form}>
          <Input
            label="Old Password"
            placeholder="Enter old password"
            type="password"
            required
            validatorMessage={errors.oldPassword?.message}
            name="oldPassword"
            register={register}
            value={watch("oldPassword")}
            showRequired
          />
          <Input
            label="New Password"
            placeholder="Enter new password"
            type="password"
            required
            validatorMessage={errors.password?.message}
            name="password"
            register={register}
            value={watch("password")}
            showRequired
          />
          <Input
            label="Confirm Password"
            placeholder="Confirm password"
            type="password"
            required
            validatorMessage={errors.confirmPassword?.message}
            name="confirmPassword"
            register={register}
            value={watch("confirmPassword")}
            showRequired
          />

          <Button
            className={styles.btn}
            type="fill"
            onClick={handleSubmit(onSubmit)}
          >
            Update Password
          </Button>
        </form>
      </Modal>
    </>
  );
};

export { ChangePasswordModal };
