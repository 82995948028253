import { Button, Input, Modal } from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";

interface ForgotData {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().email("Enter a valid email").required("Required"),
  })
  .required();

interface ForgotPasswordUIProps {
  submit: (email: string) => void;
  success: boolean;
  handleLogin: () => void;
}

const ForgotPasswordUI: React.FC<ForgotPasswordUIProps> = ({
  submit,
  success,
  handleLogin,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ForgotData>({
    resolver: yupResolver(schema),
    defaultValues: { email: "" },
  });

  const onSubmit: SubmitHandler<ForgotData> = (data) => submit(data.email);

  return (
    <>
      <h1 className={styles.ttl}>
        {success ? "Instructions have been sent" : "Forgot Password"}
      </h1>
      <p className={styles.txt}>
        {success
          ? "Please check your inbox and click the received link to rest your password."
          : "Enter the email address you registered with and we'll send you instructions to reset your password."}
      </p>
      {success ? (
        <>
          <div className={styles.success}>
            <p>Didn’t receive the email? Check your spam or click the button</p>
            <Button
              className={styles.btn}
              type="fill"
              onClick={() => submit(watch("email"))}
            >
              Resend
            </Button>
          </div>
        </>
      ) : (
        <>
          <form>
            <Input
              label="Work email"
              placeholder="example@gmail.com"
              type="email"
              required
              validatorMessage={errors.email?.message}
              name="email"
              register={register}
              value={watch("email")}
              showRequired
            />
            <Button
              className={styles.btn}
              type="fill"
              onClick={handleSubmit(onSubmit)}
            >
              Reset password
            </Button>
          </form>
          <Button
            className={styles.backBtn}
            type="transparent"
            onClick={handleLogin}
          >
            Back to login
          </Button>
        </>
      )}
    </>
  );
};

export { ForgotPasswordUI };
