import { createExpectationRequestData, createExpectationService } from "api";
import { Loader } from "components";
import { ExpectationData, BasicExpectationForm } from "features";
import { useApiRequest, useToast } from "hooks";
import { useEffect, useMemo, useState } from "react";

interface BasicExpectationProps {
  callback: (data: ExpectationData) => void;
  expectation: ExpectationData | undefined;
  skip: () => void;
  back: () => void;
  title: string;
}

const BasicExpectation: React.FC<BasicExpectationProps> = ({
  callback,
  expectation: initExpectation,
  skip,
  back,
  title,
}) => {
  const [expectation, setData] = useState<ExpectationData | undefined>(
    initExpectation
  );
  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");
  useEffect(() => {
    setData(initExpectation);
  }, [initExpectation]);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: reponse, requestStatus, error } = useApiRequest({});

  const onSubmit = (data: ExpectationData) => {
    setData(data);

    const submitData: createExpectationRequestData = {
      assessment: id,
      employment_type: data.employmentType.value,
      experience_level: data.experienceLevel.value,
      experience_environment: data.experienceEnv.value,
      years_of_experience: data.experienceYear.value,
      location: data.locationRequired && data.location ? data.location : null,
      salary_range_term: data.salary.term.value,
      salary_range_currency: data.salary.currency.value,
      min_salary_range_amount: data.salary.min,
      max_salary_range_amount: data.salary.max,
    };
    run(createExpectationService(submitData));
  };

  useMemo(() => {
    if (reponse?.status === 201) {
      expectation && callback(expectation);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to submit your basic expectations, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [reponse, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <BasicExpectationForm
        expectation={expectation}
        submit={onSubmit}
        back={back}
        skip={skip}
        title={title}
      />
    </>
  );
};
export { BasicExpectation };
