import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import { Input } from "components/form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { topupPricing } from "utils/pricing";

interface TopupData {
  candidates: string;
}

const schema = yup
  .object({
    candidates: yup
      .string()
      .required("Required")
      .matches(/\d/, "Enter a valid number"),
  })
  .required();

interface Props {
  show: boolean;
  close: () => void;
  handleBuy: (candidates: number) => void;
}

const TopupModal: React.FC<Props> = ({ show, close, handleBuy }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<TopupData>({
    resolver: yupResolver(schema),
    defaultValues: { candidates: undefined },
  });

  const onSubmit: SubmitHandler<TopupData> = (data) => {
    handleBuy(parseInt(data.candidates));
  };

  const candidates = watch("candidates");

  const amount = topupPricing.costPerCandidate * parseInt(candidates);
  return (
    <>
      <Modal contentClassName={styles.modal} show={show} close={close}>
        <h1 className={styles.ttl}>Top-up Candidate Slots</h1>
        <p className={styles.txt1}>
          Assess more candidates, without subscribing for a new plan within your
          active subscription.{" "}
        </p>
        <p className={styles.txt2}>${topupPricing.costPerCandidate} per candidate</p>
        <Input
          label="How many more candidates do you want?"
          placeholder={"Enter the number of candidates"}
          name={"candidates"}
          validatorMessage={errors.candidates?.message}
          register={register}
          type="number"
        />
        <p className={styles.txt3}>${candidates ? amount.toFixed(2) : 0}</p>
        <div className={styles.btnSec}>
          <button className={styles.btnSec__close} onClick={close}>
            Cancel
          </button>
          <button
            className={styles.btnSec__upgrade}
            onClick={handleSubmit(onSubmit)}
          >
            Buy Now
          </button>
        </div>
      </Modal>
    </>
  );
};

export { TopupModal };
