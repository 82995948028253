import { ArrowRightIcon } from "assets";
import styles from "./styles.module.scss";

const PrivacyPolicyUI = () => {
  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      let pos = element.style.position;
      let top = element.style.top;
      element.style.position = "relative";
      element.style.top = "-120px";
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      element.style.top = top;
      element.style.position = pos;
    }
  };

  const navItems = [
    {
      label: "Introduction",
      value: "introduction",
    },
    {
      label: "Our Principles",
      value: "principles",
    },
    {
      label: "Collecting and using your data",
      value: "collecting",
    },
    {
      label: "Usage Data",
      value: "usage-data",
    },
    {
      label: "Tracking technologies and Cookies",
      value: "tracking",
    },
    {
      label: "Use of your Personal Data",
      value: "personal-data-use",
    },
    {
      label: "Retention of Your Personal Data",
      value: "personal-data-retention",
    },
    {
      label: "Transfer of Data",
      value: "data-transfer",
    },
    {
      label: "Data Disclosure",
      value: "data-disclosure",
    },
    {
      label: "Data Security",
      value: "data-security",
    },
    {
      label: "Data Processing",
      value: "data-processing",
    },
    {
      label: "Child Privacy",
      value: "child-privacy",
    },
    {
      label: "Your Rights",
      value: "your-rights",
    },
    {
      label: "Website links",
      value: "website",
    },
    {
      label: "Policy Changes",
      value: "changes",
    },
    {
      label: "Contact us",
      value: "contact",
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <section className={`container ${styles.header}`}>
          <h1 className={styles.header__ttl}>Privacy Policy</h1>
          <p className={styles.header__txt}>
            Our Privacy Policy was last updated on 21st February 2024.
          </p>
        </section>
        <section className={`container ${styles.body}`}>
          <div className={styles.nav}>
            <ArrowRightIcon className={styles.nav__arrow} />
            {navItems.map(({ label, value }) => (
              <button onClick={() => handleScrollTo(value)}>{label}</button>
            ))}
          </div>
          <div>
            <section id="introduction" className={styles.section}>
              <p className={styles.section__ttl}>Introduction</p>
              <p>
                This Privacy Policy (“Policy”) explains how we collect, use,
                disclose, and protect your information including any nonpublic
                personal information.
              </p>
              <p>
                This Policy applies to information we collect when you use our
                website (​​https://yudimy.com/), and sharing practices of Yudimy
                and her by-products (collectively “Services”) when we provide
                and/or host on our servers for products, apps, and services.
                This privacy policy may be changed from time to time. If we make
                changes, we will notify you by revising the date at the top of
                the policy, and in some cases, we may provide you with
                additional notice (such as by adding a statement to the home
                pages of our website or by sending you an email notification).
              </p>
              <p>
                We encourage you to review the privacy policy whenever you
                interact with us to stay informed about our information
                practices and the ways you can help protect your privacy. This
                privacy policy applies to the Services provided by Yudimy (“we”
                or “us”) and applies to customer users (individually referred to
                as “you”) of our websites, applications, and other online
                services to which this Privacy Policy is posted. This Privacy
                Policy is part of our Terms of Service. If you do not agree with
                every provision of this Privacy Policy and our Terms of Service,
                you may not access or use our Services. We are committed to the
                protection of your privacy while you use our website, services,
                and applications.
              </p>
            </section>
            <section id="principles" className={styles.section}>
              <p className={styles.section__ttl}>Our Principles</p>

              <ul>
                <p>
                  YUDIMY has designed this policy to be consistent with the
                  following principles:
                </p>
                <li>
                  Privacy policies should be human-readable and easy to find.
                </li>
                <li>
                  Data collection, storage, and processing should be simplified
                  as much as possible to enhance security, ensure consistency,
                  and make the practices easy for users to understand.
                </li>
                <li>
                  Data practices should meet the reasonable expectations of
                  users.
                </li>
              </ul>
            </section>
            <section id="collecting" className={styles.section}>
              <p className={styles.section__ttl}>
                Collecting and Using Your Data
              </p>
              <p className={styles.section__subTtl}>Types of Data Collected</p>
              <p className={styles.section__subTtl}>Personal Data</p>
              <p>
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. 
              </p>
              <ul>
                <li>
                  Personally identifiable information may include but is not
                  limited to
                </li>
                <li>Email address </li>
                <li>First name and last name</li>
                <li>Phone number </li>
                <li>Address, State, Province, ZIP/Postal code, City </li>
              </ul>
            </section>
            <section id="usage-data" className={styles.section}>
              <p className={styles.section__ttl}>Usage Data </p>
              <p>
                Usage Data is collected automatically when using the Service. 
                <br />
                Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data. 
              </p>
              <p>
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data. 
              </p>
              <p>
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </p>
            </section>
            <section id="tracking" className={styles.section}>
              <p className={styles.section__ttl}>
                Tracking Technologies and Cookies 
              </p>
              <p>
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. 
              </p>
              <p>
                Tracking technologies used are beacons, tags, and scripts to
                collect and track information and to improve and analyze Our
                Service. The technologies We use may include: 
              </p>
              <p>
                Cookies or Browser Cookies. A cookie is a small file placed on
                Your Device. You can instruct Your browser to refuse all Cookies
                or to indicate when a Cookie is being sent. However, if You do
                not accept Cookies, You may not be able to use some parts of our
                Service. Unless you have adjusted Your browser setting so that
                it will refuse Cookies, our Service may use Cookies.
              </p>
              <p>
                Web Beacons. Certain sections of our Service and our emails may
                contain small electronic files known as web beacons (also
                referred to as clear gifs, pixel tags, and single-pixel gifs)
                that permit the Company, for example, to count users who have
                visited those pages or opened an email and for other related
                website statistics (for example, recording the popularity of a
                certain section and verifying system and server integrity).
              </p>
              <p>Cookies can be "Persistent" or "Session" Cookies. </p>
              <p>
                Persistent Cookies remain on Your personal computer or mobile
                device when You go offline, while Session Cookies are deleted as
                soon as You close Your web browser. We use both Session and
                Persistent Cookies for the purposes set out below: 
              </p>

              <ul>
                <p>Necessary / Essential Cookies</p>
                <li>Type: Session Cookies</li>
                <li> Administered by: Us </li>
                <li>
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services. 
                </li>
              </ul>
              <ul>
                <p>Cookies Policy / Notice Acceptance Cookies </p>
                <li>Type: Persistent Cookies</li>
                <li>Administered by: Us </li>
                <li>
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website. 
                </li>
              </ul>
              <ul>
                <p>Functionality Cookies </p>
                <li>Type: Persistent Cookies </li>
                <li>Administered by: Us </li>
                <li>
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  having to re-enter your preferences every time You use the
                  Website. 
                </li>
              </ul>
              <ul>
                <p>Tracking and Performance Cookies</p>
                <li>Type: Persistent Cookies </li>
                <li>Administered by: Third-Parties </li>
                <li>
                  Purpose: These Cookies are used to track information about
                  traffic to the Website and how users use the Website. The
                  information gathered via these Cookies may directly or
                  indirectly identify you as an individual visitor. This is
                  because the information collected is typically linked to a
                  pseudonymous identifier associated with the device you use to
                  access the Website. 
                </li>
              </ul>

              <p>
                We may also use these Cookies to test new pages, features or new
                functionality of the Website to see how our users react to them.
                For more information about the cookies we use and your choices
                regarding cookies, please visit our Cookies Policy or the
                Cookies section of our Privacy Policy. 
              </p>
            </section>
            <section id="personal-data-use" className={styles.section}>
              <p className={styles.section__ttl}>Use of Your Personal Data </p>
              <ul>
                <p>
                  The Company may use Personal Data for the following purposes: 
                </p>
                <li>
                  To provide and maintain our Service, including monitoring the
                  usage of our Service. 
                </li>
                <li>
                  To manage Your Account: to manage Your registration as a user
                  of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are
                  available to You as a registered user. 
                </li>
                <li>
                  For the performance of a contract: the development,
                  compliance, and undertaking of the purchase contract for the
                  products, items, or services You have purchased or of any
                  other contract with Us through the Service. 
                </li>
                <li>
                  To contact You: To contact You by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products, or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation. 
                </li>
                <li>
                  To provide You with news, special offers, and general
                  information about other goods, services, and events that we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </li>
                <li>
                  To manage Your requests: To attend and manage Your requests to
                  Us. 
                </li>
                <li>
                  For business transfers: We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred. 
                </li>
                <li>
                  For other purposes: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns,
                  and evaluating and improving our Service, products, services,
                  marketing, and your experience.
                </li>
              </ul>
              <p>
                We may share Your personal information in the following
                situations: 
              </p>
              <ul>
                <li>
                  With Service Providers: We may share Your personal information
                  with Service Providers to monitor and analyze the use of our
                  Service, for payment processing, to contact You. 
                </li>
                <li>
                  For business transfers: We may share or transfer Your personal
                  information in connection with, or during negotiations of, any
                  merger, sale of Company assets, financing, or acquisition of
                  all or a portion of Our business to another company. 
                </li>
                <li>
                  With Affiliates: We may share Your information with Our
                  affiliates, in which case we will require those affiliates to
                  honor this Privacy Policy. Affiliates include Our parent
                  company and any other subsidiaries, joint venture partners, or
                  other companies that We control or that are under common
                  control with Us. 
                </li>
                <li>
                  With business partners: We may share Your information with Our
                  business partners to offer You certain products, services, or
                  promotions. 
                </li>
                <li>
                  With other users: When you share personal information or
                  otherwise interact in public areas with other users, such
                  information may be viewed by all users and may be publicly
                  distributed outside.
                </li>
                <li>
                   With Your consent: We may disclose Your personal information
                  for any other purpose with Your consent.
                </li>
              </ul>
            </section>
            <section id="personal-data-retention" className={styles.section}>
              <p className={styles.section__ttl}>
                Retention of Your Personal Data 
              </p>
              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and
                policies. 
              </p>
              <p>
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period,
                except when this data is used to strengthen the security or to
                improve the functionality of Our Service, or We are legally
                obligated to retain this data for longer periods. 
              </p>
            </section>
            <section id="data-transfer" className={styles.section}>
              <p className={styles.section__ttl}>
                Transfer of Your Personal Data 
              </p>
              <p>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country, or
                other governmental jurisdiction where the data protection laws
                may differ from those from Your jurisdiction. 
              </p>
              <p>
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer. 
              </p>
              <p>
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information. 
              </p>
            </section>
            <section id="data-disclosure" className={styles.section}>
              <p className={styles.section__ttl}>
                Disclosure of Your Personal Data
              </p>
              <p className={styles.section__subTtl}>Business Transactions </p>
              <p>
                If the Company is involved in a merger, acquisition, or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
              <p className={styles.section__subTtl}>Law enforcement </p>
              <p>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency) or any member of our company, or its
                subsidiary products or services, as defined under the Companies
                and Allied Matters Act 2020. Authorized employees of Yudimy
                Services Limited only to resolve issues associated with the use
                of our products.
              </p>
              <ul>
                <p>
                  Other legal requirements 
                  <br />
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to: 
                </p>
                <li>Comply with a legal obligation </li>
                <li>
                  Protect and defend the rights or property of the Company 
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service 
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public 
                </li>
                <li>Protect against legal liability </li>
              </ul>
            </section>
            <section id="data-security" className={styles.section}>
              <p className={styles.section__ttl}>
                Security of Your Personal Data 
              </p>
              <p>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. Where you have
                chosen a password that allows you to access certain parts of the
                website and mobile application, you are responsible for keeping
                the password confidential. Therefore, while we strive to use
                commercially acceptable means to protect Your Personal Data, We
                cannot guarantee its absolute security. 
              </p>
            </section>
            <section id="data-processing" className={styles.section}>
              <p className={styles.section__ttl}>
                Detailed Information on the Processing of Your Personal Data 
              </p>
              <p>
                The Service Providers We use may have access to Your Personal
                Data. These third-party vendors collect, store, use, process,
                and transfer information about Your activity on Our Service in
                accordance with their Privacy Policies. 
              </p>

              <p className={styles.section__subTtl}>Analytics</p>
              <p>
                We may use third-party Service providers to monitor and analyze
                the use of our Service which includes but is not limited to
                Google Analytics, Mix Panel, Amplitude, Heatmaps.
              </p>
            </section>
            <section id="child-privacy" className={styles.section}>
              <p className={styles.section__ttl}>Children's Privacy</p>
              <p>
                Our Service is not intended for individuals under the age of 13,
                and we do not knowingly collect personally identifiable
                information from anyone under this age. If you are a parent or
                guardian and you become aware that your child has provided us
                with Personal Data, please contact us immediately. If we
                discover that we have collected Personal Data from anyone under
                the age of 13 without verified parental consent, we will take
                prompt steps to delete that information from our servers.
              </p>
              <p>
                In cases where consent is required as a legal basis for
                processing your information, and if your country mandates
                parental consent, we may request consent from your parent or
                guardian before collecting and using that information.
              </p>
            </section>
            <section id="your-rights" className={styles.section}>
              <p className={styles.section__ttl}>Your Rights </p>
              <ul>
                <p>
                  As outlined by law, you possess certain rights concerning the
                  information we retain about you. Below are some key highlights
                  of these rights:
                </p>
                <li>
                  You have the right to provide or withdraw consent to this
                  Privacy Policy. However, please note that certain features or
                  services may require your consent for functionality, and
                  withdrawing consent may affect your ability to use those
                  features or services.
                </li>
                <li>
                  You have the right to request access to the Personal Data we
                  hold about you, subject to applicable legal restrictions and
                  our policies regarding confidentiality and data protection.
                  Upon such a request, we will provide you with relevant details
                  to the extent permissible under the law and our policies,
                  ensuring compliance with privacy regulations and safeguarding
                  sensitive information.
                </li>
                <li>
                  You have the right to request correction of any inaccuracies
                  in the information we hold about you. However, we reserve the
                  right to verify the accuracy of the requested changes and may
                  require supporting documentation for verification.
                </li>
                <li>
                  Under certain circumstances, you have the right to request the
                  deletion of the information stored about you within our
                  database.
                </li>
              </ul>
            </section>
            <section id="website" className={styles.section}>
              <p className={styles.section__ttl}>Links to Other Websites</p>
              <p>
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third-party link, You will be
                directed to that third-party's site. We strongly advise You to
                review the Privacy Policy of every site You visit. We have no
                control over and assume no responsibility for the content,
                privacy policies, or practices of any third-party sites or
                services. 
              </p>
            </section>
            <section id="changes" className={styles.section}>
              <p className={styles.section__ttl}>
                Changes to this Privacy Policy 
              </p>
              <p>
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page. 
              </p>
              <p>
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Privacy Policy. 
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
            </section>
            <section id="contact" className={styles.section}>
              <p className={styles.section__ttl}>Contact Us </p>
              <p>
                If you have any questions about this Privacy Policy or our
                privacy practices, you can contact us at:{" "}
                <a href="mailto:ask@yudimy.com">ask@yudimy.com</a>.
              </p>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export { PrivacyPolicyUI };
