import { OptionType } from "components";
import timezones from "timezones-list";

export const EmploymentTypeOptions: OptionType[] = [
  {
    label: "Full Time",
    value: "fulltime",
  },
  {
    label: "Part Time",
    value: "parttime",
  },
  {
    label: "Contract",
    value: "contract",
  },
  {
    label: "Remote",
    value: "remote",
  },
  {
    label: "Internship",
    value: "internship",
  },
];

export const ExperienceLevelOptions: OptionType[] = [
  {
    label: "Entry Level",
    value: "entry",
  },
  {
    label: "Associate Level",
    value: "associate",
  },
  {
    label: "Mid-level",
    value: "mid",
  },
  {
    label: "Senior/Executive Level",
    value: "senior",
  },
];

export const ExperienceEnvOptions: OptionType[] = [
  {
    label: "Freelance",
    value: "freelance",
  },
  {
    label: "Startup",
    value: "startup",
  },
  {
    label: "Growing Business",
    value: "growing_business",
  },
  {
    label: "Established Organisation",
    value: "established_organisation",
  },
  {
    label: "Large Corporate",
    value: "large_corporations",
  },
  {
    label: "Not Important",
    value: "not_important",
  },
];

export const ExperienceYearOptions: OptionType[] = [
  {
    label: "0-1",
    value: "0_1",
  },
  {
    label: "1-3",
    value: "1_3",
  },
  {
    label: "3-5",
    value: "3_5",
  },
  {
    label: "6-8",
    value: "6_8",
  },
  {
    label: "9-10",
    value: "8_10",
  },
  {
    label: "10 +",
    value: "OVER_10",
  },
];

export const SalaryTermOptions: OptionType[] = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Hourly",
    value: "hourly",
  },
];

export const SalaryCurrencyOptions: OptionType[] = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "NGN",
    value: "NGN",
  },
  {
    label: "INR",
    value: "INR",
  },
  {
    label: "GHS",
    value: "GHS",
  },
  {
    label: "KES",
    value: "KES",
  },
];

export const SalaryHourlyCurrencyOptions: OptionType[] = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "EUR",
    value: "EUR",
  },
];

export const SalaryRange1Options: OptionType[] = [
  {
    label: "Below 500",
    value: "0-500",
  },
  {
    label: "500 - 1,000",
    value: "500-1000",
  },
  {
    label: "1,000 - 2,000",
    value: "1000-2000",
  },
  {
    label: "2,000 - 3,000",
    value: "2000-3000",
  },
  {
    label: "3,000 - 5,000",
    value: "3000-5000",
  },
  {
    label: "5,000 - 7,500",
    value: "5000-7500",
  },
  {
    label: "7,500 - 10,000",
    value: "7500-10000",
  },
  {
    label: "Above 10,000",
    value: ">10000",
  },
];

export const SalaryRange2Options: OptionType[] = [
  {
    label: "Below 50,000",
    value: "<50000",
  },
  {
    label: "50,000 - 100,000",
    value: "50000-100000",
  },
  {
    label: "100,000 - 200,000",
    value: "100000-200000",
  },
  {
    label: "200,000 - 300,000",
    value: "200000-300000",
  },
  {
    label: "300,000 - 500,000",
    value: "300000-500000",
  },
  {
    label: "500,000 - 750,000",
    value: "500000-750000",
  },
  {
    label: "750,000-1,000,000",
    value: "750000-1000000",
  },
  {
    label: "Above 1,000,000",
    value: ">1000000",
  },
];

export const SalaryHourlyRangeOptions: OptionType[] = [
  {
    label: "10 - 20",
    value: "10 - 20",
  },
  {
    label: "20 - 40",
    value: "20 - 40",
  },
  {
    label: "40 - 60",
    value: "40 - 60",
  },
  {
    label: "60 - 100",
    value: "60 - 100",
  },
  {
    label: "100 - 150",
    value: "100 - 150",
  },
  {
    label: "150 +",
    value: "150 +",
  },
];

export const talentFactorOptions: OptionType[] = [
  {
    label: "Can be ambassadors/evangelists for the organization",
    value: "status",
  },
  {
    label: "Can work independently",
    value: "autonomy",
  },
  {
    label: "Follow already laid out routine and processes",
    value: "stability",
  },
  {
    label: "Are driven to multiply revenue (theirs and the organizations)",
    value: "financial_gain",
  },
  {
    label: "Take ownership of their job function and professional development",
    value: "mastery",
  },
  {
    label:
      "Are passionate about their careers and making a difference with their work",
    value: "dedication",
  },
  {
    label: "Are charismatic at leading or controlling people and resources",
    value: "power",
  },
  {
    label:
      "Can work independently and be accountable to different stakeholders",
    value: "teamwork",
  },
  {
    label:
      "Work to be recognized for their contribution to organizational growth",
    value: "recognition",
  },
];

export const qualitiesOptions: OptionType[] = [
  {
    label: "Principled",
    value: "principled",
  },
  {
    label: "Supportive",
    value: "supportive",
  },
  {
    label: "Competitive",
    value: "competitive",
  },
  {
    label: "Idealistic",
    value: "idealistic",
  },
  {
    label: "Innovative",
    value: "innovative",
  },
  {
    label: "Responsible",
    value: "responsible",
  },
  {
    label: "Adventurous",
    value: "adventurous",
  },
  {
    label: "Assertive",
    value: "assertive",
  },
  {
    label: "Adaptive",
    value: "adaptive",
  },
];

export const skillOptions: {
  label: string;
  value: string;
  description: string;
}[] = [
  {
    label: "Agile work ethic",
    value: "agility_work_ethic",
    description:
      "Doing things quickly and efficiently to get the best results.",
  },
  {
    label: "Administration",
    value: "administration",
    description: "Organizing and managing tasks with multiple details.",
  },
  {
    label: "Empathy",
    value: "empathy",
    description:
      "Skilled in empathetic communication, fostering a comfortable environment for others to share their concerns and experiences.",
  },
  {
    label: "Creative thinking",
    value: "creative_thinking",
    description:
      "The ability to think out of the box, bringing fresh perspectives to situations or devising a novel approach to resolving challenges.",
  },

  {
    label: "Research",
    value: "research",
    description:
      "Seeking possibilities for improvement with the ability to understand complex issues and discover new paths of getting things done.",
  },
  {
    label: "Eloquence",
    value: "eloquence",
    description:
      "Exceptional communication abilities both speaking and written. Communicating point to another clearly, concisely and confidently.",
  },
  {
    label: "Leadership & Management",
    value: "leadership_and_management",
    description:
      "A good grasp of inspiring and leading people to deliver on goals.",
  },

  {
    label: "Persuasion",
    value: "persuasion",
    description:
      "Ability to listen to people and bring them on board with your thoughts or ideas.",
  },
  {
    label: "Technology Curiosity",
    value: "technology_curiosity",
    description:
      "Keeping up-to-date and aware of technology trends. Captivated by adopting or creating technology that makes life easier.",
  },
  {
    label: "Learning Curiosity",
    value: "learning_curiosity",
    description: "Figuring out how things work in any given domain.",
  },
  {
    label: "Analytical thinking",
    value: "analytical_thinking",
    description:
      "Extracting key information from situations to develop workable solutions.",
  },
];

export const QuestionTypeOptions: OptionType[] = [
  {
    label: "Open-ended",
    value: "open_ended",
  },
  {
    label: "Drop-down",
    value: "single_choice",
  },
  {
    label: "Checklist",
    value: "multiple_choice",
  },
];

export const timeZoneOptions: OptionType[] = timezones.map((item) => ({
  label: item.label,
  value: item.label,
}));

export const bucketOptions: OptionType[] = [
  {
    label: "Consider",
    value: "consider",
  },
  {
    label: "Interview",
    value: "to_interview",
  },
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: "Reject",
    value: "reject",
  },
];

export const howOptions: OptionType[] = [
  {
    label: "LinkedIn",
    value: "LinkedIn",
  },
  {
    label: "Instagram",
    value: "Instagram",
  },
  {
    label: "Twitter",
    value: "Twitter",
  },
  {
    label: "Referral",
    value: "referral",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const frequencyOptions: OptionType[] = [
  {
    label: "1 - 5 hire annually",
    value: "1 - 5 hire annually",
  },
  {
    label: "6 - 5 hire annually",
    value: "6 - 5 hire annually",
  },
  {
    label: "16 - 30 hire annually",
    value: "16 - 30 hire annually",
  },
  {
    label: "30 - 50 hire annually",
    value: "30 - 50 hire annually",
  },
  {
    label: "50+ hire annually",
    value: "50+ hire annually",
  },
];

export const employeeOptions: OptionType[] = [
  {
    label: "1-15",
    value: "1-15",
  },
  {
    label: "16-50",
    value: "16-50",
  },
  {
    label: "51-100",
    value: "51-100",
  },
  {
    label: "1000+",
    value: "1000+",
  },
];
