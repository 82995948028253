import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "components/button";
import {
  CustomSelect,
  initOptionType,
  Input,
  OptionType,
  optionTypeSchema,
} from "components/form";
import { useForm, SubmitHandler } from "react-hook-form";
import { timeZoneOptions } from "utils/options";

export interface ExtendFormData {
  timeZone: OptionType;
  end: { date: string; time: string };
}

const initData: ExtendFormData = {
  timeZone: initOptionType,
  end: { date: "", time: "" },
};

const schema = yup.object({
  timeZone: optionTypeSchema(),
  end: yup.object({
    date: yup.string().required("Required"),
    time: yup.string().required("Required"),
  }),
});

interface Props {
  show: boolean;
  close: () => void;
  submit: (data: ExtendFormData) => void;
  type: string;
}

const ExtendDeadlineModal: React.FC<Props> = ({
  show,
  close,
  submit,
  type,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<ExtendFormData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const onSubmit: SubmitHandler<ExtendFormData> = (data) => submit(data);

  const today: Date = new Date();

  const endDateMin: Date = today;
  endDateMin.setDate(endDateMin.getDate() + 1);

  const formatDate = (date?) => {
    const val = date
      ? new Date(date).toLocaleDateString()
      : new Date().toLocaleDateString();

    const dateSplit = val.split("/");

    return `${dateSplit[2]}-${
      parseInt(dateSplit[0]) > 9 ? dateSplit[0] : `0${dateSplit[0]}`
    }-${parseInt(dateSplit[1]) > 9 ? dateSplit[1] : `0${dateSplit[1]}`}`;
  };
  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <h1 className={styles.ttl}>
          {type === "extend"
            ? "Extend Assessment Deadline"
            : type === "re-open"
            ? "Re-open Assessment"
            : ""}
        </h1>
        <p className={styles.txt}>
          Give your candidates more time to take the assessment
        </p>
        <form className={styles.form}>
          <CustomSelect
            label="Time Zone"
            placeholder="Specify a time zone"
            options={timeZoneOptions}
            onChange={(val) => setValue("timeZone", val)}
            validatorMessage={errors.timeZone?.value?.message?.toString() ?? ""}
            name={"timeZone"}
            value={watch("timeZone")}
            parentClassName={styles.timezone}
          />
          <Input
            label="Date"
            placeholder=""
            type="date"
            required
            validatorMessage={errors.end?.date?.message}
            name="end.date"
            register={register}
            value={watch("end.date")}
            parentClassName={styles.inputWrap}
            min={formatDate(endDateMin)}
          />
          <Input
            label="Time"
            placeholder=""
            type="time"
            required
            validatorMessage={errors.end?.time?.message}
            name="end.time"
            register={register}
            value={watch("end.time")}
            parentClassName={styles.inputWrap}
            min={"07:00"}
            max={"17:00"}
          />
        </form>
        <div className={styles.btnSec}>
          <Button className={styles.cancel} type={"outline"} onClick={close}>
            Cancel
          </Button>
          <Button
            className={styles.submit}
            type={"fill"}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { ExtendDeadlineModal };
