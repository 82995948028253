import { createPersonalityRequestData, createPersonalityService } from "api";
import { Loader } from "components";
import { PersonalityData, PersonalityForm } from "features";
import { useToast, useApiRequest } from "hooks";
import { useState, useEffect, useMemo } from "react";

interface PersonalityProps {
  callback: (data: PersonalityData) => void;
  personality: PersonalityData | undefined;
  skip: () => void;
  back: () => void;
  title: string;
}

const Personality: React.FC<PersonalityProps> = ({
  callback,
  personality: initPersonality,
  skip,
  back,
  title,
}) => {
  const [personality, setData] = useState<PersonalityData | undefined>(
    initPersonality
  );
  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");
  useEffect(() => {
    setData(initPersonality);
  }, [initPersonality]);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const onSubmit = (data: PersonalityData) => {
    setData(data);

    const submitData: createPersonalityRequestData = {
      assessment: id,
      best: {
        "1": data.best.set1,
        "2": data.best.set2,
        "3": data.best.set3,
        "4": data.best.set4,
      },
      next_best: {
        "1": data.nextBest.set1,
        "2": data.nextBest.set2,
        "3": data.nextBest.set3,
        "4": data.nextBest.set4,
      },
      least_best: {
        "1": data.manageable.set1,
        "2": data.manageable.set2,
        "3": data.manageable.set3,
        "4": data.manageable.set4,
      },
    };
    run(createPersonalityService(submitData));
  };

  useMemo(() => {
    if (response?.status === 201) {
      personality && callback(personality);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to submit your personality expectations, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <PersonalityForm
        personality={personality}
        submit={onSubmit}
        back={back}
        skip={skip}
        title={title}
      />
    </>
  );
};
export { Personality };
