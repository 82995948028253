import { Button, Modal } from "components";
import styles from "./styles.module.scss";
import { bucketOptions } from "utils/options";
import { useEffect, useState } from "react";

interface AutoScorePromptModalProps {
  show: boolean;
  close: () => void;
  handleSubmit: (buckets: string[]) => void;
}

const AutoScorePromptModal: React.FC<AutoScorePromptModalProps> = ({
  show,
  close,
  handleSubmit,
}) => {
  const [selected, setSelected] = useState<string[]>([]);

  const handleContinue = () => {
    handleSubmit(selected);
    setSelected([]);
  };

  const handleCheck = (bucket) => {
    if (selected.find((item) => item === bucket)) {
      setSelected((prev) => prev.filter((item) => item !== bucket));
    } else {
      setSelected((prev) => [...prev, bucket]);
    }
  };

  const buckets = [{ label: "Assessed", value: "assessed" }, ...bucketOptions];

  return (
    <Modal
      position={"centered"}
      close={close}
      show={show}
      contentClassName={styles.modal}
    >
      <h1 className={styles.ttl}>Auto-score Candidates</h1>
      <p className={styles.txt}>
        Tick the buckets that contain the candidates you want to auto-score
        below:
      </p>
      <div className={styles.checkList}>
        {buckets.map((bucket) => (
          <label className={styles.checkWrap} htmlFor={bucket.value}>
            <input
              onClick={() => handleCheck(bucket.value)}
              checked={
                selected.find((item) => item === bucket.value) ? true : false
              }
              id={bucket.value}
              type="checkbox"
            />
            <span>{bucket.label}</span>
          </label>
        ))}
      </div>
      <Button
        className={styles.btn}
        disabled={selected.length === 0}
        type="fill"
        onClick={handleContinue}
      >
        Continue
      </Button>
    </Modal>
  );
};

export { AutoScorePromptModal };
