import styles from "./styles.module.scss";
import { useForm } from "react-hook-form";
import { TextareaDK } from "./components/textarea";
import { RadioInputDK } from "./components/radio";
import { Button, OptionType } from "components";
import { SubmitDomainKnowledgeData } from "api";

export interface PreviewDynamicInputProps {
  name: string;
  label: string;
  type: "open_ended" | "single_choice" | "multiple_choice";
  _id: number;
  options?: OptionType[];
  instruction?: string;
  image?: {
    value: string;
    description?: string;
  };
}

interface DynamicFormProps {
  inputs: PreviewDynamicInputProps[];
  submit: (data: SubmitDomainKnowledgeData[]) => void;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ inputs, submit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleCheckBoxChange = (name, prev, value) => {
    const index = prev.findIndex((item) => item.value === value.value);

    if (index >= 0) {
      const val = [...prev];
      val.splice(index, 1);
      setValue(name, [...val]);
    } else {
      setValue(name, [...prev, value]);
    }
  };

  const renderFormControl = (field: PreviewDynamicInputProps) => {
    switch (field.type) {
      case "open_ended":
        return (
          <TextareaDK
            key={field.name}
            validatorMessage={errors[field.name]?.message?.toString()}
            {...register(field.name)}
            onChange={handleInputChange}
            {...field}
          />
        );
      case "single_choice":
        return (
          <RadioInputDK
            key={field.name}
            validatorMessage={errors[field.name]?.message?.toString()}
            {...register(field.name)}
            {...field}
            onChange={handleInputChange}
            name={field.name}
            options={field.options ?? []}
            type="radio"
          />
        );
      case "multiple_choice":
        return (
          <RadioInputDK
            key={field.name}
            validatorMessage={errors[field.name]?.message?.toString()}
            {...register(field.name)}
            {...field}
            options={field.options ?? []}
            disabled={watch(field.name)?.length >= 2}
            checkValue={watch(field.name)}
            handleChange={handleCheckBoxChange}
            type="checkbox"
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = (data) => {
    submit([]);
  };

  return (
    <form className={styles.form}>
      {inputs.map((field) => renderFormControl(field))}
      <Button
        className={styles.nextBtn}
        type="fill"
        onClick={handleSubmit(onSubmit)}
      >
        Submit
      </Button>
    </form>
  );
};

const PreviewDomainKnowledgeUI: React.FC<DynamicFormProps> = ({
  inputs,
  submit,
}) => {
  return (
    <>
      <p className={styles.txt}>
        These questions are designed to assess your operational knowledge and
        skills. Please provide an honest evaluation of your abilities. If there
        are areas where you haven't faced any challenges or have no experience,
        simply input 'Not Applicable.
      </p>
      <DynamicForm inputs={inputs} submit={submit} />
    </>
  );
};

export { PreviewDomainKnowledgeUI };
