import { editInstructionRequestData, editInstructionService } from "api";
import { Loader } from "components";
import { InstructionData, InstructionUI } from "features";
import { useApiRequest, useToast } from "hooks";
import { useMemo, useState } from "react";

interface InstructionProps {
  back: () => void;
  callback: (data: InstructionData) => void;
  instruction: InstructionData;
  areas: string[];
  logo: string;
}

const Instruction: React.FC<InstructionProps> = ({
  back,
  callback,
  instruction: initInstruction,
  areas,
  logo,
}) => {
  const [instruction, setData] = useState<InstructionData | undefined>(
    initInstruction
  );
  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const onSubmit = (data: InstructionData) => {
    setData(data);

    const submitData: editInstructionRequestData = {
      assessment: id,
      title: data.title,
      intro_html: data.instruction,
    };
    run(editInstructionService(submitData));
  };

  useMemo(() => {
    if (response?.status === 200) {
      instruction && callback(instruction);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to submit your assessment instructions, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <InstructionUI
        areas={areas}
        back={back}
        submit={onSubmit}
        initData={instruction}
        logo={logo}
      />
    </>
  );
};

export { Instruction };
