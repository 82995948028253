import { AssessmentPreviewData, AssessmentPreviewUI } from "features";

interface AssessmentOverviewProps {
  assessment: AssessmentPreviewData;
  handleStart: () => void;
}

const AssessmentOverview: React.FC<AssessmentOverviewProps> = ({
  assessment,
  handleStart,
}) => {
  return (
    <>
      <AssessmentPreviewUI assessment={assessment} handleStart={handleStart} />
    </>
  );
};

export { AssessmentOverview };
