import {
  Question,
  createDomainKnowledgeRequestData,
  createDomainKnowledgeService,
} from "api";
import { Loader } from "components";
import {
  DomainKnowledgeForm,
  CustomQuestionFormData,
  DefaultQuestionFormData,
} from "features";
import { getBase64 } from "helpers";
import { useToast, useApiRequest } from "hooks";
import { useEffect, useMemo, useState } from "react";

interface DomainKnowledgeProps {
  callbackCustom: (data: CustomQuestionFormData) => void;
  callbackDefault: (data: DefaultQuestionFormData) => void;
  skip: () => void;
  back: () => void;
  customQ: CustomQuestionFormData | undefined;
  defaultQ: DefaultQuestionFormData | undefined;
  title: string;
  handleUpdateCompletedMetrics: () => void;
}

const DomainKnowledge: React.FC<DomainKnowledgeProps> = ({
  callbackCustom,
  callbackDefault,
  customQ: initCustomQ,
  defaultQ: initDefaultQ,
  skip,
  back,
  title,
  handleUpdateCompletedMetrics,
}) => {
  const [customQ, setCustom] = useState<CustomQuestionFormData | undefined>(
    initCustomQ
  );
  const [defaultQ, setDefault] = useState<DefaultQuestionFormData | undefined>(
    initDefaultQ
  );
  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");

  useEffect(() => {
    setCustom(initCustomQ);
    setDefault(initDefaultQ);
  }, [initCustomQ, initDefaultQ]);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const onSubmitDefault = (data: DefaultQuestionFormData) => {
    setDefault(data);

    const submitData: createDomainKnowledgeRequestData = {
      assessment: id,
      domain_knowledge: {
        use_custom: false,
        question_groups: data.questions.map((question) => question),
      },
    };

    run(createDomainKnowledgeService(submitData));
  };
  const onSubmitCustom = async (data: CustomQuestionFormData) => {
    setCustom(data);

    const submitData: createDomainKnowledgeRequestData = {
      assessment: id,
      domain_knowledge: {
        use_custom: true,
        question_groups: await Promise.all(
          data.questions.map(async (question) => {
            const processedQuestions: Question[] = await Promise.all(
              question.questions.map(async (ques) => {
                const data: Question = {
                  content: ques.question,
                  type: ques.questionType.value,
                  description: ques.description,
                  required: ques.required ?? false,
                  // autoScore: ques.autoScore ?? false,
                };

                switch (ques.questionType.value) {
                  case "multiple_choice":
                    data.points_obtainable = parseInt(ques.points ?? "");
                    data.options = ques.options?.map((item) => ({
                      content: item.value,
                      correct: item.isCorrect ?? false,
                    }));
                    data.maximum_selections = parseInt(ques.maxSelection ?? "");

                    break;
                  case "single_choice":
                    data.points_obtainable = parseInt(ques.points ?? "");
                    data.options = ques.options?.map((item) => ({
                      content: item.value,
                      correct: item.isCorrect ?? false,
                    }));
                    break;
                  case "open_ended":
                    data.word_limit = parseInt(ques.wordLimit ?? "");
                    break;
                  
                    break;
                  default:
                    break;
                }

                if (ques.image) {
                  await getBase64(ques.image).then((res: any) => {
                    data.image = res;
                    data.image_description = ques.imageDescription;
                  });
                }

                return data;
              })
            );
            return {
              title: question.theme,
              duration_hours: parseInt(question.hours),
              duration_minutes: parseInt(question.minutes),
              duration_seconds: parseInt(question.seconds),
              questions: processedQuestions,
            };
          })
        ),
      },
    };

    run(createDomainKnowledgeService(submitData));
  };

  useMemo(() => {
    if (response?.status === 200) {
      customQ && callbackCustom(customQ);
      defaultQ && callbackDefault(defaultQ);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to submit your domain knowledge questions, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <DomainKnowledgeForm
        title={title}
        initCustomData={customQ}
        initDefaultData={defaultQ}
        back={back}
        skip={skip}
        submitCustom={onSubmitCustom}
        submitDefault={onSubmitDefault}
        handleUpdateCompletedMetrics={handleUpdateCompletedMetrics}
      />
    </>
  );
};
export { DomainKnowledge };
