import * as React from "react";
import styles from "./styles.module.scss";
import { OptionType } from "components";
import { placeholderAvatar } from "assets";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  parentClassName?: string;
  validatorMessage: string | undefined;
  label?: string;
  instruction?: string;
  value?: string;
  checkValue?: OptionType[];
  showRequired?: boolean;
  options: OptionType[];
  handleChange?: (x, y, z) => void;
  image?: {
    value: string;
    description?: string;
  };
}

const RadioInputDK: React.FC<InputProps> = (props) => {
  const {
    className,
    parentClassName,
    validatorMessage,
    label,
    showRequired,
    options,
    disabled,
    checkValue = [],
    instruction,
    value,
    name,
    handleChange,
    onChange,
    type,
    checked,
    image,
  } = props;

  return (
    <div
      className={`${styles.inputWrapper} ${parentClassName} ${
        validatorMessage ? styles.error : ""
      }`}
    >
      {label && (
        <p className={styles.question}>
          {label}
          {showRequired ? <span className={styles.req}>*</span> : ""}
          {instruction ? (
            <p className={styles.instruction}>{instruction}</p>
          ) : (
            ""
          )}
        </p>
      )}
      {image ? (
        <div className={styles.imgWrap}>
          <img src={image.value} alt="" />
          {image.description ? <p>Img Description: {image.description}</p> : ""}
        </div>
      ) : (
        ""
      )}

      {options.map((option, index) => (
        <label key={index} className={styles.label} htmlFor={option.value}>
          <div className={styles.optionWrap}>
            <input
              {...props}
              className={`${className} ${styles.input}`}
              id={option.value}
              value={option.value}
              onChange={(e) =>
                type === "checkbox"
                  ? handleChange && handleChange(name, checkValue, option)
                  : onChange && onChange(e)
              }
              checked={
                type === "radio"
                  ? checked
                  : checkValue?.find((item) => item.value === option.value)
                  ? true
                  : false
              }
              disabled={
                disabled &&
                !checkValue?.find((item) => item.value === option.value)
              }
            />
            <span>{option.label}</span>
          </div>
        </label>
      ))}

      {validatorMessage && (
        <small className={styles.message}>{validatorMessage}</small>
      )}
    </div>
  );
};

export { RadioInputDK };
