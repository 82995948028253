import { startCandidateAssessmentService } from "api";
import { Loader } from "components";
import { StartAssessmentUI } from "features";
import { useApiRequest, useToast } from "hooks";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { AssessmentProgress } from "..";

interface StartAssessmentProps {
  show: boolean;
  close: () => void;
  id: string;
  resume: (data: AssessmentProgress) => void;
}

const StartAssessment: React.FC<StartAssessmentProps> = ({
  show,
  close,
  id,
  resume,
}) => {
  const [clear, setClear] = useState(false);
  const navigate = useNavigate();

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const handleStart = (email) => {
    run(startCandidateAssessmentService({ email, assessment: id }));
  };

  useMemo(() => {
    if (response?.status === 201) {
      const res = response.data.data;
      setClear((prev) => !prev);

      localStorage.removeItem("isComplete")
      localStorage.setItem("candidate", JSON.stringify(res.candidate));
      const data = res.response_data;

      if (!data) {
        localStorage.setItem("answered", "1");
        navigate(Routes.assessmentBasic(id));
      } else {
        if (data.status === "completed") {
          openToast({
            show: true,
            heading: "Oops!",
            text: "It looks like you have already completed this assessment, the assessment can't be retaken.",
            type: "success",
            timeOut: 5000,
          });
        } else {
          localStorage.setItem("candidateAssessment", JSON.stringify(data));
          localStorage.setItem("answered", `${data.questions_answered}`); // set number of answered questions
          localStorage.setItem(
            "secondsLeft",
            `${data.duration_minutes_left * 60}`
          );
          localStorage.setItem("assessmentId", `${data.id}`);
          resume({
            basic_expectations: true,
            work_motivators: data.progress.work_motivators ?? false,
            work_qualities: data.progress.work_qualities ?? false,
            work_style: data.progress.work_style ?? false,
            personality: data.progress.personality ?? false,
            soft_skill: data.progress.soft_skill ?? false,
            domain_knowledge: data.progress.domain_knowledge ?? false,
          });
        }
      }

      close();
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to initiate assessment, please try again or contact support",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <StartAssessmentUI
        submit={handleStart}
        show={show}
        close={close}
        clear={clear}
      />
    </>
  );
};

export { StartAssessment };
