import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input, Textarea } from "components";
import styles from "./styles.module.scss";
import { ContactUsRequestData } from "api/services/contact";
import { useEffect } from "react";

interface ContactData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  agree: boolean;
}

const initContact: ContactData = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  message: "",
  agree: false,
};

const schema = yup.object({
  email: yup.string().email("Enter a valid email").required("Required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  message: yup.string().required("Required"),
  agree: yup
    .boolean()
    .required("You have to agree to continue")
    .oneOf([true], "You have to agree to continue"),
});

export interface ContactFormProps {
  submit: (data: ContactUsRequestData) => void;
  clear: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ submit, clear }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<ContactData>({
    resolver: yupResolver(schema),
    defaultValues: initContact,
  });

  useEffect(() => {
    reset();
  }, [clear]);

  const onSubmit: SubmitHandler<ContactData> = (data) => {
    const submitData: ContactUsRequestData = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phone,
      message: data.message,
    };

    submit(submitData);
  };

  return (
    <form className={styles.form}>
      <Input
        label="First Name"
        placeholder="Please input"
        type="text"
        required
        validatorMessage={errors.firstName?.message}
        name="firstName"
        register={register}
        value={watch("firstName")}
        parentClassName={styles.halfInput}
      />
      <Input
        label="Last Name"
        placeholder="Please input"
        type="text"
        required
        validatorMessage={errors.lastName?.message}
        name="lastName"
        register={register}
        value={watch("lastName")}
        parentClassName={styles.halfInput}
      />
      <Input
        label="Phone Number"
        placeholder="+000"
        type="text"
        required
        validatorMessage={errors.phone?.message}
        name="phone"
        register={register}
        value={watch("phone")}
        parentClassName={styles.inputWrap}
      />
      <Input
        label="Email Address"
        placeholder="Please input"
        type="email"
        required
        validatorMessage={errors.email?.message}
        name="email"
        register={register}
        value={watch("email")}
        parentClassName={styles.inputWrap}
      />
      <Textarea
        label="Message"
        placeholder="Hi! We are xyz..."
        required
        validatorMessage={errors.message?.message}
        name="message"
        register={register}
        value={watch("message")}
        showRequired
        parentClassName={`${styles.fullWidth} ${styles.inputWrap}`}
      />
      <label
        className={`${styles.fullWidth} ${styles.checkWrap}`}
        htmlFor="policy"
      >
        <input
          onChange={() => setValue("agree", !watch("agree"))}
          checked={watch("agree")}
          type="checkbox"
          id="policy"
        />{" "}
        <span>I agree with Precision’s Privacy Policy</span>
      </label>
      {errors.agree?.message && (
        <p className={styles.error}>{errors.agree?.message}</p>
      )}
      <div className={styles.fullWidth}>
        <button className={styles.btn} onClick={handleSubmit(onSubmit)}>
          Submit
        </button>
      </div>
    </form>
  );
};

export { ContactForm };
