import { createWorkCultureRequestData, createWorkCultureService } from "api";
import { Loader } from "components";
import { WorkCultureData, WorkCultureForm } from "features";
import { useApiRequest, useToast } from "hooks";
import { useEffect, useMemo, useState } from "react";

interface WorkCultureProps {
  callback: (data: WorkCultureData) => void;
  culture: WorkCultureData | undefined;
  skip: () => void;
  back: () => void;
  title: string;
}

const WorkCulture: React.FC<WorkCultureProps> = ({
  callback,
  culture: initCulture,
  skip,
  back,
  title,
}) => {
  const [culture, setData] = useState<WorkCultureData | undefined>(initCulture);
  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");
  useEffect(() => {
    setData(initCulture);
  }, [initCulture]);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: reponse, requestStatus, error } = useApiRequest({});

  const onSubmit = (data: WorkCultureData) => {
    setData(data);

    const submitData: createWorkCultureRequestData = {
      assessment: id,
      motivators: data.factors.map((item) => item.value),
      qualities: data.qualities.map((item) => item.value),
      ratings: {
        priority: data.prioritize,
        visualizing: data.innovative,
        coordinating: data.collaborative,
        planning: data.efficient,
      },
    };
    run(createWorkCultureService(submitData));
  };

  useMemo(() => {
    if (reponse?.status === 201) {
      culture && callback(culture);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to submit your work culture expectations, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [reponse, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <WorkCultureForm
        culture={culture}
        submit={onSubmit}
        back={back}
        skip={skip}
        title={title}
      />
    </>
  );
};
export { WorkCulture };
