import { OptionType } from "components";

export const SatisfactionOptions: OptionType[] = [
  {
    label: "Wealth",
    value: "financial_gain",
  },
  {
    label: "Independence",
    value: "autonomy",
  },
  {
    label: "Collaboration",
    value: "teamwork",
  },
  {
    label: "Meaningful Work",
    value: "dedication",
  },
  {
    label: "Better Earning",
    value: "stability",
  },
  {
    label: "Build Capacity",
    value: "mastery",
  },
  {
    label: "Authority to impact decisions",
    value: "power",
  },
  {
    label: "Reputation",
    value: "status",
  },
  {
    label: "Acknowledgement",
    value: "recognition",
  },
];
