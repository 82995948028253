import { PreviewBasicFitUI } from "features";

interface AssessmentBasicFitProps {
  hasBasicExpectation: boolean;
  callback: (metric: string) => void;
}

const AssessmentBasicFit: React.FC<AssessmentBasicFitProps> = ({
  hasBasicExpectation,
  callback,
}) => {
  const submit = async () => {
    const answered = parseInt(localStorage.getItem("answered") ?? "0");

    hasBasicExpectation
      ? localStorage.setItem("answered", `${answered + 10}`)
      : localStorage.setItem("answered", `${answered + 4}`);

    callback("basic_expectations");
  };

  return (
    <>
      <PreviewBasicFitUI
        submit={submit}
        hasBasicExpectation={hasBasicExpectation}
      />
    </>
  );
};

export { AssessmentBasicFit };
