import { Routes } from "router";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { experiencePrecisionImg } from "assets";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const ExperiencePrecision = () => {
  useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 1000,
      offset: 100,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <section className={`container ${styles.section}`}>
        <div data-aos="slide-right" className={styles.content}>
          <h4 className={styles.content__ttl}>
            Experience <span>Precision's</span> Impact on Your Hiring Process
          </h4>
          <p className={styles.content__txt}>Start your free trial today</p>
          <Link className={styles.content__cta} to={Routes.signup}>
            Start for free
          </Link>
        </div>
        <img data-aos="slide-left" src={experiencePrecisionImg} className={styles.img} alt="" />
      </section>
    </>
  );
};

export { ExperiencePrecision };
