import { SigninUI } from "features";
import { ForgotPassword } from "../forgotPassword";
import { useEffect, useMemo, useState } from "react";
import { useApiRequest, useFetchPlans, useFetchUser, useToast } from "hooks";
import { loginService } from "api";
import { Loader } from "components";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { resetStore } from "state";
import { useAppDispatch } from "state/hooks";

const Signin = () => {
  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();
  const { getUser } = useFetchUser();
  const dispatch = useAppDispatch();
  const { getPlans } = useFetchPlans();

  // API Request Hooks
  const {
    run: runLogin,
    data: loginResponse,
    requestStatus: loginStatus,
    error: loginError,
  } = useApiRequest({});

  useEffect(() => {
    if (localStorage.getItem("logout")) {
      localStorage.clear();
      dispatch(resetStore());
    }
  }, []);

  const handleLogin = (data) => {
    localStorage.removeItem("precisionAccess");
    localStorage.removeItem("precisionRefresh");
    localStorage.removeItem("precisionId");
    runLogin(loginService(data));
  };

  useMemo(() => {
    if (loginResponse?.status === 200) {
      localStorage.setItem("precisionAccess", loginResponse.data.access_token);
      localStorage.setItem(
        "precisionRefresh",
        loginResponse.data.refresh_token
      );
      localStorage.setItem("precisionId", loginResponse.data.user.id);

      const user = loginResponse.data.user;

      const incompleteProfile =
        user.first_name === null ||
        user.last_name === null ||
        user.referral === null ||
        user.phone_number === null;

      const incompleteBusiness = user.company_info === null;

      getUser();
      getPlans();

      openToast({
        show: true,
        heading: "Login successful!",
        text: "Redirecting you to your dashboard...",
        type: "success",
        timeOut: 1000,
        callback: () => {
          navigate(
            `${Routes.employerDashbord}${
              incompleteProfile
                ? "?onboarding=true&incomplete=personal"
                : incompleteBusiness
                ? "?onboarding=true&incomplete=business"
                : ""
            }`
          );
        },
      });
    } else if (loginError) {
      const error = loginError?.response?.data;
      openToast({
        show: true,
        heading: "Sorry",
        text: error?.detail[0] ?? "Failed to login, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [loginResponse, loginError]);

  const showLoader = loginStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />

      <SigninUI
        sumbit={handleLogin}
        handleForgot={() => navigate(Routes.forgotPassword)}
      />
    </>
  );
};

export { Signin };
