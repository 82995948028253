import { Button } from "components";
import styles from "./styles.module.scss";
import { getDateTime } from "helpers";

export interface AssessmentOverviewData {
  title: string;
  startDate: string;
  endDate: string;
  time: number; //in minutes
  isLive: boolean;
  total: number;
  instruction: string;
  metrics: string[];
  id: string;
  _id: string;
  employer: { name: string; logo: string };
}

export interface AssessmentOverviewProps {
  assessment: AssessmentOverviewData;
  handleStart: () => void;
  candidate: string | undefined;
}

const AssessmentOverviewUI: React.FC<AssessmentOverviewProps> = ({
  assessment,
  handleStart,
  candidate,
}) => {
  const {
    time,
    title,
    total,
    startDate,
    endDate,
    isLive,
    metrics,
    instruction,
  } = assessment;

  return (
    <>
      <section className={styles.header}>
        <h1 className={styles.ttl}>{title}</h1>
        {candidate ? (
          <p className={styles.txt}>
            Hi, {candidate.replaceAll("%20", " ")} welcome to your assessment.{" "}
          </p>
        ) : (
          ""
        )}
      </section>
      <div className={styles.info}>
        <p>
          <span>Start Date:</span>{" "}
          <span>{getDateTime(startDate).dateString}</span>
        </p>
        <p>
          <span>End Date:</span> <span>{endDate}</span>
        </p>
        <p>
          <span>Total time:</span>
          <span>{time} minutes</span>
        </p>
        <p>
          <span>Total question:</span> <span>{total}</span>
        </p>
      </div>
      <div className={styles.descrip}>{instruction}</div>
      <div className={styles.instructions}>
        <p>Instructions</p>
        <ol>
          <li>
            Format: The test consists of multiple-choice and open-ended
            questions.
          </li>
          <li>
            Honesty: Please refrain from using any external resources or
            assistance during the assessment.
          </li>
          <li>
            Accuracy: Provide the response that best reflects your skills,
            knowledge, and experience. There are no right or wrong answers.
          </li>
          <li>
            Completeness: Answer all questions to the best of your abilities.
            Partially completed tests may affect your overall assessment.
          </li>
          <li>
            Submitting: Once you have completed the assessment, review your
            answers carefully before submitting. Double-check for any unanswered
            questions or mistakes.
          </li>
          <li>
            <b>
              Disqualifications: To prevent an automatic disqualification,
              please do not exit the full screen mode until your assessment is
              complete.
            </b>
          </li>
        </ol>
        <p>
          Remember, there are no right or wrong answers. Your performance will
          be evaluated based on your individual strengths and suitability for
          the role.
        </p>
      </div>
      <div>
        <p className={styles.areas}>
          Questions in this assessment covers the following areas
        </p>
        <div className={styles.sections}>
          {metrics.map((metric, index) => (
            <span key={`metric_${index}`}>{metric}</span>
          ))}
        </div>
        <Button
          disabled={!isLive}
          className={styles.btn}
          type="fill"
          onClick={handleStart}
        >
          Start Assessment
        </Button>
      </div>
    </>
  );
};

export { AssessmentOverviewUI };
