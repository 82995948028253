import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Routes } from "router";
import { useToast } from "hooks";
import axios from "axios";
import { useState } from "react";
import { Loader } from "components";

const SCRIPT_API_URL = process.env.REACT_APP_SCRIPT_API_URL;
const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { openToast } = useToast();

  const handleSubscribe = () => {
    setLoading(true);
    const data = new FormData();
    data.append("Time", new Date().toUTCString());
    data.append("Email", email);

    if (!SCRIPT_API_URL) return;

    axios
      .post(SCRIPT_API_URL, data)
      .then(() => {
        setError("");
        setEmail("");
        openToast({
          show: true,
          type: "success",
          heading: "Great!",
          text: "You have subscribed to our newsletter!",
        });
      })
      .catch(() => {
        openToast({
          show: true,
          type: "error",
          heading: "Sorry!",
          text: "Failed to subscribe to newsletter, please try again later",
          timeOut: 5000,
        });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setError("");
    if (regex.test(email)) {
      // submit
      handleSubscribe();
    } else {
      // error
      setError("Enter a valid email");
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <footer className={styles.footerBg}>
        <section className={`container ${styles.footer}`}>
          <div className={styles.sec1}>
            <p className={styles.sec1__txt}>
              Transform Your Hiring Process with Precision Assessment AI
            </p>
            <div className={styles.sec1__newsletter}>
              <p className={styles.sec1__newsletter__ttl}>Stay up to date</p>
              <p className={styles.sec1__newsletter__txt}>
                Don’t miss out on our latest news
              </p>
              <div className={styles.sec1__newsletter__inputWrap}>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                  type="email"
                  value={email}
                  placeholder="Enter email"
                />
                <button onClick={handleSubmit} disabled={loading}>
                  {loading ? "loading..." : "Subscribe"}
                </button>
              </div>
              <p className={styles.error}>{error}</p>
            </div>
          </div>
          <div className={styles.sec2}>
            <div className={styles.sec2__links}>
              <Link to={Routes.pricing}>Pricing</Link>
              <Link to={Routes.contact}>Contact Us</Link>
              {/* <Link to={Routes.pricing}>Blog</Link> */}
              <Link to={Routes.privacyPolicy}>Privacy Policy</Link>
              <Link to={Routes.termsOfUse}>Terms of Use</Link>
            </div>
            <p>© 2024 Precision. All Rights Reserved.</p>
          </div>
        </section>
      </footer>
    </>
  );
};

export { Footer };
