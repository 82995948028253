import { fetchAssessmentForReportsService } from "api";
import { Loader, ReportTableItem } from "components";
import { ReportsUI } from "features";
import { getDateTime, getDaysBetween } from "helpers";
import { useToast, useApiRequest, useDebounce } from "hooks";
import { Navbar } from "layout";
import { InviteCandidate } from "pages";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";

const Reports = () => {
  // States
  const [pages, setPages] = useState({
    total: 0,
    count: 0,
    current: 1,
  });
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });
  const [invite, setInvite] = useState({ show: false, id: "" });

  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(search, 500);

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const fetchAssessments = ({ page, from, to }: { page?; from?; to? }) =>
    run(
      fetchAssessmentForReportsService({
        page: page ?? pages.current,
        status,
        search,
        dateFrom: from ?? dates.from,
        dateTo: to ?? dates.to,
      })
    );

  useEffect(() => {
    fetchAssessments({});
  }, [debouncedSearchTerm, status]);

  const assessments = useMemo<ReportTableItem[]>(() => {
    if (response?.status === 200) {
      setPages({
        ...pages,
        total: Math.ceil(response.data.count / 8),
        count: response.data.count,
      });

      return response.data.results.map((item) => ({
        id: item.id,
        title: item.title,
        start: {
          date: getDateTime(item.start_date).date,
          time: getDateTime(item.start_date).time,
        },
        deadline:
          getDaysBetween(item.end_date).days > 0
            ? `${getDaysBetween(item.end_date).days} ${
                getDaysBetween(item.end_date).days > 1 ? "days" : "day"
              }`
            : item.status === "closed"
            ? "Expired"
            : "< 1 day",
        total: item.candidate_summary.total,
        completed: item.candidate_summary.completed,
        abandoned: item.candidate_summary.abandoned,
        status: item.status,
      }));
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Unable to fetch assessments, please refresh the page or try again later",
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [response, error]);

  const handlePages = (page) => {
    fetchAssessments({ page });

    setPages((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const handleDates = ({ from, to }) => {
    if ((from === "" && to === "") || (from !== "" && to !== ""))
      fetchAssessments({ from, to });

    setDates({ from, to });
  };

  const createAssessment = () => {
    navigate(Routes.employerCreateAssessment);
  };

  const handleView = ({ id, role }) => {
    navigate(Routes.employerReportRoleID(id));
  };

  const handleInvite = (id, status) => {
    if (status === "closed") {
      openToast({
        show: true,
        type: "warning",
        heading: "This assessment is closed",
        text: "Sorry, you can't send an invitation for a closed assessment.",
      });
    } else setInvite({ id, show: true });
  };
  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <InviteCandidate
        title="Invite Candidates"
        {...invite}
        close={() => setInvite({ show: false, id: "" })}
      />
      <Navbar
        search={{
          value: search,
          placeholder: "Search",
          handleChange: setSearch,
        }}
        title={"Track & View Reports"}
      />
      <ReportsUI
        status={{
          value: status,
          onChange: setStatus,
        }}
        dates={{
          ...dates,
          onChange: handleDates,
        }}
        pagination={{
          handleChange: handlePages,
          ...pages,
        }}
        handleView={handleView}
        createAssessment={createAssessment}
        assessments={assessments}
        handleInvite={handleInvite}
      />
    </>
  );
};

export { Reports };
