import {
  Button,
  CustomSelect,
  EmptyTable,
  OptionType,
  ReportTable,
  ReportTableItem,
  Table,
  TableHeaderItemProps,
} from "components";
import styles from "./styles.module.scss";
import { emptyReport1 } from "assets";

const tableHeaderTitles: TableHeaderItemProps[] = [
  { title: "Assessment" },
  { title: "Start Date" },
  { title: "Status" },
  { title: "Total Candidates" },
  { title: "Completed" },
  { title: "Abandoned" },
  { title: "Deadline" },
  { title: "" },
];

interface ReportsProps {
  createAssessment: () => void;
  handleView: ({ id, role }: { id: string; role: string }) => void;
  pagination: {
    handleChange: (page) => void;
    total: number;
    current: number;
    count: number;
  };
  status: {
    value: string;
    onChange: (status: string) => void;
  };
  dates: {
    from: string;
    to: string;
    onChange: ({ from, to }: { from: string; to: string }) => void;
  };
  assessments: ReportTableItem[];
  handleInvite: (id: string, status: string) => void;
}

const ReportsUI: React.FC<ReportsProps> = ({
  createAssessment,
  handleView,
  pagination,
  status,
  dates,
  assessments,
  handleInvite,
}) => {
  const statusOptions: OptionType[] = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Closed",
      value: "closed",
    },
    {
      label: "Paused",
      value: "paused",
    },
  ];
  return (
    <>
      <section className={styles.filterSec}>
        <CustomSelect
          label="Status"
          onChange={(val) => status.onChange(val.value)}
          validatorMessage={""}
          name={""}
          placeholder={"Select status"}
          options={statusOptions}
          value={
            statusOptions.find((item) => item.value === status.value) ?? {
              label: "All",
              value: "",
            }
          }
          inputClass={styles.select}
          parentClassName={styles.selectWrap}
        />
        <div className={styles.dateFilter}>
          <label>
            <span>From</span>
            <input
              onChange={(e) =>
                dates.onChange({ ...dates, from: e.target.value })
              }
              value={dates.from}
              placeholder="From"
              type="date"
            />
          </label>
          <label>
            <span>To</span>
            <input
              onChange={(e) => dates.onChange({ ...dates, to: e.target.value })}
              value={dates.to}
              placeholder="To"
              type="date"
            />
          </label>

          {dates.from !== "" || dates.to !== "" ? (
            <Button
              disabled={dates.from === "" && dates.to === ""}
              type="outline"
              onClick={() => dates.onChange({ from: "", to: "" })}
              className={styles.reset}
            >
              Reset
            </Button>
          ) : (
            ""
          )}
        </div>
      </section>
      <Table
        tableHeaderTitles={tableHeaderTitles}
        tableBody={
          <ReportTable
            tableBodyItems={assessments}
            handleView={handleView}
            tableBodyItemClassName={""}
            tableBodyRowClassName={styles.tableRow}
            pagination={{
              current: pagination.current,
              total: pagination.total,
              handleChange: pagination.handleChange,
              count: pagination.count,
              length: 8,
            }}
            handleInvite={handleInvite}
          />
        }
        customTableClasses={{
          tableContainerClassName: styles.tableWrap,
          tableHeaderClassName: styles.tableHeader,
          tableHeaderItemClassName: styles.tableHeaderItem,
        }}
        emptyTable={{
          show: assessments.length === 0,
          element: (
            <EmptyTable
              image={emptyReport1}
              text="You haven’t assessed any candidates yet"
              button={{
                text: "Create Assessment",
                onClick: createAssessment,
              }}
            />
          ),
        }}
      />
    </>
  );
};

export { ReportsUI };
