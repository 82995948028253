/*
=================================
EMPLOYER ASSESSMENT SERVICES
=================================
*/

import {
  Base64,
  activateAssessmentURL,
  createDomainKnowledgeURL,
  createExpectationURL,
  createPersonalityURL,
  createSoftSkillsURL,
  createWorkCultureURL,
  extendDeadlineURL,
  fetchAssessmentActivityURL,
  fetchAssessmentDomainKnowledgeURL,
  fetchAssessmentInfoURL,
  fetchAssessmentSoftSkillURL,
  fetchAssessmentsURL,
  fetchDomainKnowledgeURL,
  fetchDomainKnowledgeUnauthURL,
  fetchExpectationURL,
  fetchPersonalityURL,
  fetchSoftSkillsURL,
  fetchWorkCultureURL,
  getRequest,
  inviteCandidatesCSVURL,
  inviteCandidatesURL,
  patchRequest,
  postRequest,
  startAssessmentURL,
  updateAssessmentURL,
} from "api";

/**
 * Start assessment service
 * @returns axios promise
 */

export const startAssessmentService = (title: string) => {
  const requestData = {
    url: startAssessmentURL(),
    data: { title },
  };
  return postRequest(requestData);
};

export interface createExpectationRequestData {
  assessment: number;
  employment_type: string;
  experience_level: string;
  experience_environment: string;
  years_of_experience: string;
  location: string | null;
  salary_range_term: string;
  salary_range_currency: string;
  min_salary_range_amount: string;
  max_salary_range_amount: string;
}

/**
 * Create basic expectations service
 * @returns axios promise
 */

export const createExpectationService = (
  data: createExpectationRequestData
) => {
  const requestData = {
    url: createExpectationURL(),
    data,
  };
  return postRequest(requestData);
};

export interface createWorkCultureRequestData {
  assessment: number;
  motivators: string[];
  qualities: string[];
  ratings: {
    priority: number;
    visualizing: number;
    coordinating: number;
    planning: number;
  };
}

/**
 * Create basic expectations service
 * @returns axios promise
 */

export const createWorkCultureService = (
  data: createWorkCultureRequestData
) => {
  const requestData = {
    url: createWorkCultureURL(),
    data,
  };
  return postRequest(requestData);
};

export interface createPersonalityRequestData {
  assessment: number;
  best: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
  };
  next_best: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
  };
  least_best: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
  };
}

/**
 * Create basic expectations service
 * @returns axios promise
 */

export const createPersonalityService = (
  data: createPersonalityRequestData
) => {
  const requestData = {
    url: createPersonalityURL(),
    data,
  };
  return postRequest(requestData);
};

export interface createSoftSkillsRequestData {
  assessment: number;
  soft_skills: {
    agility_work_ethic?: number;
    administration?: number;
    analytical_thinking?: number;
    research?: number;
    leadership_and_management?: number;
    eloquence?: number;
    empathy?: number;
    creative_thinking?: number;
    learning_curiosity?: number;
    persuasion?: number;
    technology_curiosity?: number;
  };
}

/**
 * Create basic expectations service
 * @returns axios promise
 */

export const createSoftSkillsService = (data: createSoftSkillsRequestData) => {
  const requestData = {
    url: createSoftSkillsURL(),
    data,
  };
  return postRequest(requestData);
};

interface Option {
  content: string;
  correct: boolean;
}

export interface Question {
  content: string;
  type: "multiple_choice" | "single_choice" | "open_ended";
  description?: string;
  required: boolean;
  points_obtainable?: number;
  maximum_selections?: number;
  options?: Option[];
  word_limit?: number;
  image?: string;
  image_description?: string;
}

interface QuestionGroup {
  title: string;
  description?: string;
  duration_hours?: number;
  duration_minutes?: number;
  duration_seconds?: number;
  questions?: Question[];
}

export interface createDomainKnowledgeRequestData {
  assessment: number;
  domain_knowledge: {
    use_custom: boolean;
    question_groups: QuestionGroup[];
  };
}

/**
 * Create domain knowledge service
 * @returns axios promise
 */

export const createDomainKnowledgeService = (
  data: createDomainKnowledgeRequestData
) => {
  const requestData = {
    url: createDomainKnowledgeURL(),
    data,
  };
  return postRequest(requestData);
};

export interface editInstructionRequestData {
  assessment: number;
  title: string;
  intro_html: string;
}

/**
 * Edit assessment instruction service
 * @returns axios promise
 */

export const editInstructionService = ({
  assessment,
  title,
  intro_html,
}: editInstructionRequestData) => {
  const requestData = {
    url: updateAssessmentURL(assessment),
    data: {
      title,
      intro_html,
    },
  };
  return patchRequest(requestData);
};

export interface editTitleRequestData {
  assessment: number;
  title: string;
}

/**
 * Edit assessment title service
 * @returns axios promise
 */

export const editTitleService = ({
  assessment,
  title,
}: editTitleRequestData) => {
  const requestData = {
    url: updateAssessmentURL(assessment),
    data: { title },
  };
  return patchRequest(requestData);
};

export interface activateAssessmentRequestData {
  assessment: number;
  start_date: string;
  end_date: string;
}

/**
 * Activate assessment service
 * @returns axios promise
 */

export const activateAssessmentService = (
  data: activateAssessmentRequestData
) => {
  const requestData = {
    url: activateAssessmentURL(),
    data,
  };
  return postRequest(requestData);
};

export interface fetchAssessmentsRequestData {
  status: string;
  page: string;
  search: string;
  dateFrom: string;
  dateTo: string;
}

/**
 * Fetch assessments service
 * @returns axios promise
 */

export const fetchAssessmentsService = (data: fetchAssessmentsRequestData) => {
  const requestData = {
    url: fetchAssessmentsURL(data),
  };
  return getRequest(requestData);
};

/**
 * Fetch basic expectations service
 * @returns axios promise
 */

export const fetchExpectationService = (id: string) => {
  const requestData = {
    url: fetchExpectationURL(id),
  };
  return getRequest(requestData);
};

/**
 * Fetch work culture service
 * @returns axios promise
 */

export const fetchWorkCultureService = (id: string) => {
  const requestData = {
    url: fetchWorkCultureURL(id),
  };
  return getRequest(requestData);
};

/**
 * Fetch personality service
 * @returns axios promise
 */

export const fetchPersonalityService = (id: string) => {
  const requestData = {
    url: fetchPersonalityURL(id),
  };
  return getRequest(requestData);
};

/**
 * Fetch soft skills service
 * @returns axios promise
 */

export const fetchSoftSkillsService = (id: string) => {
  const requestData = {
    url: fetchSoftSkillsURL(id),
  };
  return getRequest(requestData);
};

/**
 * Fetch domain knowledge service
 * @returns axios promise
 */

export const fetchDomainKnowledgeService = (id: string) => {
  const requestData = {
    url: fetchDomainKnowledgeURL(id),
  };
  return getRequest(requestData);
};

/**
 * Fetch domain knowledge unauth service
 * @returns axios promise
 */

export const fetchDomainKnowledgeUnauthService = (id: string) => {
  const requestData = {
    url: fetchDomainKnowledgeUnauthURL(id),
  };
  return getRequest(requestData);
};

/**
 * Fetch assessment info service
 * @returns axios promise
 */

export const fetchAssessmentInfoService = (id: string) => {
  const requestData = {
    url: fetchAssessmentInfoURL(id),
  };
  return getRequest(requestData);
};

/**
 * Fetch assessment soft skill expectation service
 * @returns axios promise
 */

export const fetchAssessmentSoftSkillService = (id: string) => {
  const requestData = {
    url: fetchAssessmentSoftSkillURL(id),
  };
  return getRequest(requestData);
};

/**
 * Fetch assessment domain knowledge expectation service
 * @returns axios promise
 */

export const fetchAssessmentDomainKnowledgeService = (id: string) => {
  const requestData = {
    url: fetchAssessmentDomainKnowledgeURL(id),
  };
  return getRequest(requestData);
};

export interface updateAssessmentRequestData {
  assessment: string;
  status: string;
}

/**
 * Update assessment service
 * @returns axios promise
 */

export const updateAssessmentService = ({
  assessment,
  status,
}: updateAssessmentRequestData) => {
  const requestData = {
    url: updateAssessmentURL(assessment),
    data: {
      status,
    },
  };
  return patchRequest(requestData);
};

/**
 * Fetch assessment activity service
 * @returns axios promise
 */

export const fetchAssessmentActivityService = () => {
  const requestData = {
    url: fetchAssessmentActivityURL(),
  };
  return getRequest(requestData);
};

export interface inviteCandidatesRequestData {
  email: string;
  full_name: string;
}

/**
 * Invite candidates service
 * @returns axios promise
 */

export const inviteCandidatesService = (
  id: string,
  data: inviteCandidatesRequestData[]
) => {
  const requestData = {
    url: inviteCandidatesURL(id),
    data,
  };
  return postRequest(requestData);
};

/**
 * Invite candidates via csv upload service
 * @returns axios promise
 */

export const inviteCandidatesCSVService = (id: string, data: FormData) => {
  const requestData = {
    url: inviteCandidatesCSVURL(id),
    data,
  };
  return postRequest(requestData);
};

interface extendDeadlineReqData {
  end_date: string;
  assessment_id: number;
}

/**
 * Extend assessment deadline service
 * @returns axios promise
 */

export const extendDeadlineService = (data: extendDeadlineReqData) => {
  const requestData = {
    url: extendDeadlineURL(),
    data,
  };
  return postRequest(requestData);
};
