import { Toggle } from "components";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { TickIcon } from "assets";

const Plans = () => {
  const [cycle, setCycle] = useState("monthly");

  const yearlyDiscount = 10;

  const payg = 30;
  const regular = 128;
  const pro = 255;

  const plans: PlanData[] = [
    {
      title: "Pay As You Go",
      description: "For anyone into occasional hiring",
      price: `$${payg}`,
      duration: "role",
      features: ["Assess up to 100 candidates per role", "30 days access"],
    },
    {
      title: "Regular",
      description: "For growth stage companies.",
      price:
        cycle === "monthly"
          ? `$${regular}`
          : `$${Math.ceil(
              (regular * 12 * (100 - yearlyDiscount)) / 100
            ).toLocaleString()}`,
      duration: cycle === "monthly" ? "month" : "year",
      features: [
        "Access to 5 roles",
        "Assess up to 750 candidates",

        "AI scoring available at $0.06 per question",
      ],
      isPopular: true,
    },
    {
      title: "Pro",
      description: "Perfect for fast paced hiring ",
      price:
        cycle === "monthly"
          ? `$${pro}`
          : `$${Math.ceil(
              (pro * 12 * (100 - yearlyDiscount)) / 100
            ).toLocaleString()}`,
      duration: cycle === "monthly" ? "month" : "year",
      features: [
        "Access to 10 roles",
        "Assess up to 1500 candidates",

        "AI scoring available at $0.06 per question",
      ],
    },
  ];
  return (
    <>
      <section className={`container ${styles.wrapper}`}>
        <div className={styles.heading}>
          <h1 className={styles.heading__ttl}>Pricing</h1>
          <p className={styles.heading__txt}>
            Free to get started, with flexible plans that scale with your team.
          </p>
          <div className={styles.heading__cycle}>
            <span
              className={
                cycle === "monthly" ? styles.heading__cycle__active : ""
              }
            >
              Monthly
            </span>
            <Toggle
              onClick={() =>
                setCycle((prev) =>
                  prev === "monthly" ? "annually" : "monthly"
                )
              }
              checked={cycle === "monthly"}
            />
            <span
              className={
                cycle === "annually" ? styles.heading__cycle__active : ""
              }
            >
              Annual <b>-{yearlyDiscount}%</b>
            </span>
          </div>
        </div>
        <div data-aos="slide-up" className={styles.plans}>
          {plans.map((item) => (
            <Plan key={item.title} {...item} />
          ))}
        </div>
        <div data-aos="slide-up" className={styles.starter}>
          <div>
            <p className={styles.starter__ttl}>Starter</p>
            <p className={styles.starter__txt}>
              Experience Precision for Free! Gain complete access with our
              14-day Free Trial
            </p>
          </div>
          <Link className={styles.starter__link} to={Routes.signup}>
            SIGNUP FOR FREE
          </Link>
        </div>
        <div
          data-aos="slide-up"
          className={`${styles.starter} ${styles.custom}`}
        >
          <div>
            <p className={styles.starter__ttl}>Custom plan</p>
            <p className={styles.starter__txt}>
              We’ll customize a plan based on the volume of roles and candidates
              slots you need
            </p>
          </div>
          <Link className={styles.custom__link} to={Routes.contact}>
            CONTACT SALES
          </Link>
        </div>
      </section>
    </>
  );
};

interface PlanData {
  title: string;
  description: string;
  price: string;
  duration: string;
  features: string[];
  isPopular?: boolean;
}

const Plan: React.FC<PlanData> = ({
  title,
  description,
  duration,
  price,
  features,
  isPopular,
}) => {
  return (
    <div className={styles.plan}>
      <p className={styles.plan__ttl}>
        {title} {isPopular ? <span>Popular</span> : ""}
      </p>
      <p className={styles.plan__txt}>{description}</p>
      <div className={styles.plan__price}>
        <p className={styles.plan__price__amount}>{price}</p>
        <span className={styles.plan__price__duration}>/ {duration}</span>
      </div>
      <p className={styles.plan__txt2}>$0.6 for every additional candidate</p>
      <Link className={styles.plan__link} to={Routes.signup}>
        GET STARTED
      </Link>

      <p className={styles.plan__txt3}>Key features:</p>
      <div className={styles.plan__features}>
        {features.map((item) => (
          <p>
            <TickIcon />
            {item}
          </p>
        ))}
      </div>
    </div>
  );
};

export { Plans };
