import { ReactNode } from "react";
import { Footer } from "./footer";
import { Navbar, NavbarProps } from "./navbar";
import styles from "./styles.module.scss";
import { BetaBanner } from "./betaBanner";
import { Support } from "layout/support";

interface LandingLayoutProps extends NavbarProps {
  children: ReactNode;
}

const LandingLayout: React.FC<LandingLayoutProps> = ({ children, active }) => {
  return (
    <>
      <Navbar active={active} />
      <main className={styles.main}>{children}</main>
      <Support text={"Chat with us"} />
      <BetaBanner />
      <Footer />
    </>
  );
};

export { LandingLayout };
