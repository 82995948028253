import { PricingUI } from "features";

const Pricing = () => {
  return (
    <>
      <PricingUI />
    </>
  );
};
export { Pricing };
