import {
  ActivatedAssessmentTable,
  ActivatedAssessmentTableItem,
  Button,
  DraftAssessmentTable,
  DraftAssessmentTableItem,
  EmptyTable,
  Table,
  TableHeaderItemProps,
  TemplateAssessmentTable,
  TemplateAssessmentTableItem,
} from "components";
import styles from "./styles.module.scss";
import { AddIcon, emptyAssessmentsImg } from "assets";

const tableHeaderTitles: TableHeaderItemProps[] = [
  { title: "Assessment Title" },
  { title: "Start Date" },
  { title: "End Date" },
  { title: "Status" },
  { title: "" },
];

const draftTableHeaderTitles: TableHeaderItemProps[] = [
  { title: "Assessment Title" },
  { title: "Date Created" },
  { title: "Metrics set" },
  { title: "" },
];

const templateTableHeaderTitles: TableHeaderItemProps[] = [
  { title: "Assessment Title" },
  { title: "Level" },
  { title: "Metrics set" },
  { title: "" },
];

const template: TemplateAssessmentTableItem = {
  id: "#P123456789",
  title: "Account Manager Graduate Trainee ",
  level: "Entry-level",
  metrics: "Personality, Work culture, Soft skills",
};

interface AssessmentProps {
  createAssessment: () => void;
  activatedAssessments: ActivatedAssessmentTableItem[];
  draftAssessments: DraftAssessmentTableItem[];
  pagination: {
    handleChange: (page) => void;
    total: number;
    current: number;
    count: number;
  };
  status: {
    value: string;
    onChange: (status: string) => void;
  };
  dates: {
    from: string;
    to: string;
    onChange: ({ from, to }: { from: string; to: string }) => void;
  };
  handleEdit: (id) => void;
  updateAssessment: ({ id, status }) => void;
  handleInvite: (id: string, status: string) => void;
  handleDuplicate: (id) => void;
  handleExtend: (id) => void;
  handleReopen: (id) => void;
}

const AssessmentsUI: React.FC<AssessmentProps> = ({
  createAssessment,
  activatedAssessments,
  pagination,
  status,
  draftAssessments,
  dates,
  handleEdit,
  updateAssessment,
  handleInvite,
  handleDuplicate,
  handleExtend,
  handleReopen,
}) => {
  const tabs = [
    {
      title: "Active",
      id: "active",
    },
    {
      title: "Paused",
      id: "paused",
    },
    {
      title: "Scheduled",
      id: "scheduled",
    },
    {
      title: "Closed",
      id: "closed",
    },
    {
      title: "Drafts",
      id: "draft",
    },
    // {
    //   title: "Templates",
    //   id: "templates",
    // },
  ];

  return (
    <>
      <Button
        className={styles.createBtn}
        type="fill"
        onClick={createAssessment}
      >
        <AddIcon />
        Create Assessment
      </Button>
      <section className={styles.container}>
        <div className={styles.tabs}>
          {tabs.map((item, index) => (
            <span
              role="button"
              key={index}
              onClick={() =>
                item.id === "templates"
                  ? console.log(item.id)
                  : status.onChange(item.id)
              }
              className={item.id === status.value ? styles.active : ""}
            >
              {item.title}
            </span>
          ))}
        </div>
        <div className={styles.dateFilter}>
          <label>
            <span>From</span>
            <input
              onChange={(e) =>
                dates.onChange({ ...dates, from: e.target.value })
              }
              value={dates.from}
              placeholder="From"
              type="date"
            />
          </label>
          <label>
            <span>To</span>
            <input
              onChange={(e) => dates.onChange({ ...dates, to: e.target.value })}
              value={dates.to}
              placeholder="To"
              type="date"
            />
          </label>

          {dates.from !== "" || dates.to !== "" ? (
            <Button
              disabled={dates.from === "" && dates.to === ""}
              type="outline"
              onClick={() => dates.onChange({ from: "", to: "" })}
              className={styles.reset}
            >
              Reset
            </Button>
          ) : (
            ""
          )}
        </div>
        {status.value === "active" ||
        status.value === "scheduled" ||
        status.value === "closed" ||
        status.value === "paused" ? (
          <Table
            tableHeaderTitles={tableHeaderTitles}
            tableBody={
              <ActivatedAssessmentTable
                tableBodyItems={activatedAssessments}
                handleStatus={updateAssessment}
                handleInvite={handleInvite}
                tableBodyItemClassName={""}
                handleDuplicate={handleDuplicate}
                handleExtend={handleExtend}
                handleReopen={handleReopen}
                tableBodyRowClassName={styles.tableRow}
                pagination={{
                  current: pagination.current,
                  total: pagination.total,
                  handleChange: pagination.handleChange,
                  count: pagination.count,
                  length: 8,
                }}
              />
            }
            customTableClasses={{
              tableContainerClassName: styles.tableWrap,
              tableHeaderClassName: styles.tableHeader,
              tableHeaderItemClassName: styles.tableHeaderItem,
            }}
            emptyTable={{
              show: activatedAssessments.length === 0,
              element: (
                <EmptyTable
                  image={emptyAssessmentsImg}
                  text={
                    status.value === "active"
                      ? "You don't have any active assessments"
                      : status.value === "scheduled"
                      ? "You haven't scheduled any assessments"
                      : "There are no closed assessments yet"
                  }
                  button={{
                    text: "Create Assessment",
                    onClick: createAssessment,
                  }}
                />
              ),
            }}
          />
        ) : status.value === "draft" ? (
          <Table
            tableHeaderTitles={draftTableHeaderTitles}
            tableBody={
              <DraftAssessmentTable
                tableBodyItems={draftAssessments}
                handleView={handleEdit}
                tableBodyItemClassName={""}
                tableBodyRowClassName={styles.draftTableRow}
                pagination={{
                  current: pagination.current,
                  total: pagination.total,
                  handleChange: pagination.handleChange,
                  count: pagination.count,
                  length: 8,
                }}
              />
            }
            customTableClasses={{
              tableContainerClassName: styles.tableWrap,
              tableHeaderClassName: styles.draftTableHeader,
              tableHeaderItemClassName: styles.tableHeaderItem,
            }}
            emptyTable={{
              show: draftAssessments.length === 0,
              element: (
                <EmptyTable
                  image={emptyAssessmentsImg}
                  text="You don't have any draft assessments"
                  button={{
                    text: "Create Assessment",
                    onClick: createAssessment,
                  }}
                />
              ),
            }}
          />
        ) : (
          <Table
            tableHeaderTitles={templateTableHeaderTitles}
            tableBody={
              <TemplateAssessmentTable
                tableBodyItems={new Array(10).fill(template)}
                handleUse={(id) => {}}
                tableBodyItemClassName={""}
                tableBodyRowClassName={styles.draftTableRow}
                pagination={{
                  current: pagination.current,
                  total: pagination.total,
                  handleChange: pagination.handleChange,
                  count: pagination.count,
                  length: 8,
                }}
              />
            }
            customTableClasses={{
              tableContainerClassName: styles.tableWrap,
              tableHeaderClassName: styles.draftTableHeader,
              tableHeaderItemClassName: styles.tableHeaderItem,
            }}
            emptyTable={{
              show: false,
              element: (
                <EmptyTable
                  image={emptyAssessmentsImg}
                  text={"There are no templates"}
                  button={{
                    text: "Create Assessment",
                    onClick: createAssessment,
                  }}
                />
              ),
            }}
          />
        )}
      </section>
    </>
  );
};

export { AssessmentsUI };
