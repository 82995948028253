/*
=================================
AUTH SERVICES
=================================
*/

import {
  forgotPasswordURL,
  getRequest,
  loginURL,
  logoutURL,
  postRequest,
  refreshTokenURL,
  resendVerificationURL,
  resetPasswordURL,
  signupURL,
  verifyEmailURL,
} from "api";

export interface SignupRequestData {
  email: string;
  objective: string;
  password1: string;
  password2: string;
}
/**
 * Signup  service
 * @returns axios promise
 */

export const signupService = (data: SignupRequestData) => {
  const requestData = {
    url: signupURL(),
    data,
  };
  return postRequest(requestData);
};

interface refreshTokenData {
  refresh: string;
}
/**
 * refresh JWT service
 * @returns axios promise
 */

export const refreshTokenService = (data: refreshTokenData) => {
  const requestData = {
    url: refreshTokenURL(),
    data,
  };
  return postRequest(requestData);
};

interface LoginRequestData {
  email: string;
  password: string;
}
/**
 * Login service
 * @returns axios promise
 */

export const loginService = (data: LoginRequestData) => {
  const requestData = {
    url: loginURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Logout service
 * @returns axios promise
 */

export const logoutService = () => {
  const requestData = {
    url: logoutURL(),
  };
  return postRequest(requestData);
};

export interface verifyEmailRequestData {
  email: string;
  token: string;
}

/**
 * Verify email service
 * @returns axios promise
 */

export const verifyEmailService = (data: verifyEmailRequestData) => {
  const requestData = {
    url: verifyEmailURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Forgot password service
 * @returns axios promise
 */

export const forgotPasswordService = (email: string) => {
  const requestData = {
    url: forgotPasswordURL({ email }),
  };
  return getRequest(requestData);
};

export interface resetPasswordRequestData {
  email: string;
  token: string;
  password: string;
}

/**
 * Reset password service
 * @returns axios promise
 */

export const resetPasswordService = (data: resetPasswordRequestData) => {
  const requestData = {
    url: resetPasswordURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Resend signup verification mail service
 * @returns axios promise
 */

export const resendVerificationService = (email: string) => {
  const requestData = {
    url: resendVerificationURL({ email }),
  };
  return getRequest(requestData);
};
