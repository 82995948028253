import styles from "./styles.module.scss";

const DemoUI = () => {
  return (
    <>
      <section className={styles.main}>
        <section className={`container ${styles.wrapper}`}>
          <div className={styles.header}>
            <h1 className={styles.header__ttl}>Request a Demo</h1>
            <p className={styles.header__txt}>
              Get a free live one-on-one demo of Precision. You can choose to do
              it yourself as we guide you or we show it to you while you do it
              yourself later.
            </p>
          </div>
        </section>
        <iframe
          height={1200}
          style={{ width: "100%", border: "none", backgroundColor: "#fff" }}
          src="https://calendly.com/askyudimy/30min "
        />
      </section>
    </>
  );
};

export { DemoUI };
