import { Button, Modal } from "components";
import styles from "./styles.module.scss";
import { CloseIconCircle, TrashIcon } from "assets";
import { useState } from "react";
import { getBase64 } from "helpers";

interface ImageUploadProps {
  show: boolean;
  close: () => void;
  submit: (image: string) => void;
  type: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  show,
  close,
  submit,
  type,
}) => {
  const [image, setImage] = useState<File | null>(null);

  const handleChange = (e) => {
    e.target.files && setImage(e.target.files[0]);
  };

  const onSubmit = async () => {
    if (image) {
      await getBase64(image).then((res: any) => {
        setImage(null);
        submit(res);
      });
    }
  };
  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <CloseIconCircle
          className={styles.close}
          role="button"
          onClick={close}
        />
        <h1 className={styles.ttl}>Change Image </h1>
        <p className={styles.txt}>Upload your {type} below</p>
        <label
          onChange={handleChange}
          htmlFor={type}
          className={styles.inputWrap}
        >
          <input id={type} type="file" accept=".png, .jpg, .jpeg" />
          <p>Browse</p>
          <p>Supported types: PNG, JPG, JPEG</p>
        </label>
        {image && (
          <div className={styles.uploadedImage}>
            <img src={image ? URL.createObjectURL(image) : ""} />
            <p>{image.name}</p>

            <TrashIcon role="button" onClick={() => setImage(null)} />
          </div>
        )}
        <Button
          disabled={image === null}
          className={styles.btn}
          type="fill"
          onClick={onSubmit}
        >
          Save
        </Button>
      </Modal>
    </>
  );
};

export { ImageUpload };
