import { PreviewWorkMotivatorsUI } from "features";

interface AssessmentWorkMotivatorsProps {
  callback: (metric: string) => void;
}

const AssessmentWorkMotivators: React.FC<AssessmentWorkMotivatorsProps> = ({
  callback,
}) => {
  const submit = (data) => {
    const answered = parseInt(localStorage.getItem("answered") ?? "0");
    localStorage.setItem("answered", `${answered + 1}`);

    callback("work_motivators");
  };

  return (
    <>
      <PreviewWorkMotivatorsUI submit={submit} />
    </>
  );
};

export { AssessmentWorkMotivators };
