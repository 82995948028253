import { fetchPlansService } from "api";
import { useApiRequest, useToast } from "hooks";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AllPlansData, updatePlanList } from "state";
import { useAppDispatch } from "state/hooks";

const useFetchPlans = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const getPlans = () => run(fetchPlansService());

  useMemo(() => {
    if (requestStatus.isPending || requestStatus.isIdle) return;
    if (response?.status === 200) {
      const data = response.data.data;

      const plans: AllPlansData[] = [
        {
          title: "Pay As You Go",
          key: "pay_as_you_go",
          fee: data.pay_as_you_go.cost_in_dollar,
          candidates: data.pay_as_you_go.candidate,
          roles: data.pay_as_you_go.role,
          nairaFee: data.pay_as_you_go.cost_in_naira,
          autoScoringCredit: data.pay_as_you_go.auto_scoring_credit,
        },
        {
          title: "Regular",
          key: "regular",
          fee: data.regular.cost_in_dollar,
          candidates: data.regular.candidate,
          roles: data.regular.role,
          nairaFee: data.regular.cost_in_naira,
          autoScoringCredit: data.regular.auto_scoring_credit,
        },
        {
          title: "Pro",
          key: "pro",
          fee: data.pro.cost_in_dollar,
          candidates: data.pro.candidate,
          roles: data.pro.role,
          nairaFee: data.pro.cost_in_naira,
          autoScoringCredit: data.pro.auto_scoring_credit,
        },
      ];

      dispatch(updatePlanList(plans));
    }
  }, [response, error]);

  return {
    getPlans,
    loading: requestStatus.isPending,
  };
};

export { useFetchPlans };
