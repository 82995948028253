import { fetchAssessmentsService, updateAssessmentService } from "api";
import {
  ActivatedAssessmentTableItem,
  DraftAssessmentTableItem,
  Loader,
} from "components";
import { AssessmentsUI } from "features";
import { getDateTime, getError } from "helpers";
import { useApiRequest, useDebounce, useToast } from "hooks";
import { Navbar } from "layout";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { InviteCandidate } from "../inviteCandidates";
import { ExtendDeadline } from "../extendDeadline";

const Assessments = () => {
  // States
  const [pages, setPages] = useState({
    total: 0,
    count: 0,
    current: 1,
  });
  const [status, setStatus] = useState("active");
  const [search, setSearch] = useState("");
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });
  const [invite, setInvite] = useState({ show: false, id: "" });
  const [extend, setExtend] = useState({ show: false, id: "", type: "" });

  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(search, 500);

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});
  const {
    run: runUpdate,
    data: updateResponse,
    requestStatus: updateStatus,
    error: updateError,
  } = useApiRequest({});

  const fetchAssessments = ({ page, from, to }: { page?; from?; to? }) =>
    run(
      fetchAssessmentsService({
        page: page ?? pages.current,
        status,
        search,
        dateFrom: from ?? dates.from,
        dateTo: to ?? dates.to,
      })
    );

  useEffect(() => {
    fetchAssessments({});
  }, [debouncedSearchTerm, status]);

  const activatedAssessments = useMemo<ActivatedAssessmentTableItem[]>(() => {
    if (response?.status === 200) {
      setPages({
        ...pages,
        total: Math.ceil(response.data.count / 8),
        count: response.data.count,
      });

      return response.data.results.map((item) => ({
        id: item.id,
        title: item.title,
        start: {
          date: getDateTime(item.start_date).date,
          time: getDateTime(item.start_date).time,
        },
        end: {
          date: getDateTime(item.end_date).date,
          time: getDateTime(item.end_date).time,
        },
        link: `${window.location.origin}${Routes.assessmentOverview(
          item.assessment_id
        )}`,
        previewLink: `${
          window.location.origin
        }${Routes.employerPreviewAssessment(item.id)}`,
        status: item.status,
      }));
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Unable to fetch assessments, please refresh the page or try again later",
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [response, error]);

  const draftAssessments = useMemo<DraftAssessmentTableItem[]>(() => {
    if (response?.status === 200) {
      setPages({
        ...pages,
        total: Math.ceil(response.data.count / 8),
        count: response.data.count,
      });

      return response.data.results.map((item) => ({
        id: item.id,
        title: item.title,
        created: {
          date: getDateTime(item.created_at).date,
          time: getDateTime(item.created_at).time,
        },
        metrics: item.metrics_set.join(", ").replaceAll("_", " "),
      }));
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Unable to fetch assessments, please refresh the page or try again later",
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [response, error]);

  const handlePages = (page) => {
    fetchAssessments({ page });

    setPages((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const handleDates = ({ from, to }) => {
    if ((from === "" && to === "") || (from !== "" && to !== ""))
      fetchAssessments({ page: 1, from, to });

    setPages((prev) => ({
      ...prev,
      current: 1,
    }));
    setDates({ from, to });
  };

  const createAssessment = () => {
    navigate(Routes.employerCreateAssessment);
  };

  const editAssessment = (id) => {
    navigate(Routes.employerEditAssessmentID(id));
  };

  const updateAssessment = ({ id, status }) => {
    runUpdate(updateAssessmentService({ assessment: id, status }));
  };

  useMemo(() => {
    if (updateResponse?.status === 200) {
      fetchAssessments({});
      openToast({
        show: true,
        heading: "",
        text: updateResponse?.data?.message ?? "Updated successfully!",
        type: "success",
        timeOut: 3000,
      });
    } else if (updateError) {
      openToast({
        show: true,
        ...getError({
          error: updateError,
          heading: "Sorry",
          text: "Unable to update assessment, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [updateResponse, updateError]);

  const handleInvite = (id, status) => {
    if (status === "closed") {
      openToast({
        show: true,
        type: "warning",
        heading: "This assessment is closed",
        text: "Sorry, you can't send an invitation for a closed assessment.",
      });
    } else setInvite({ id, show: true });
  };

  const showLoader = requestStatus.isPending || updateStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <InviteCandidate
        title="Invite Candidates"
        {...invite}
        close={() => setInvite({ show: false, id: "" })}
      />
      <ExtendDeadline
        {...extend}
        close={() => setExtend({ show: false, id: "", type: "" })}
        callback={() => fetchAssessments({})}
      />
      <Navbar
        title={"Create Assessments"}
        search={{
          value: search,
          handleChange: setSearch,
          placeholder: "Search",
        }}
      />
      <AssessmentsUI
        pagination={{
          handleChange: handlePages,
          ...pages,
        }}
        createAssessment={createAssessment}
        activatedAssessments={activatedAssessments}
        draftAssessments={draftAssessments}
        status={{
          value: status,
          onChange: setStatus,
        }}
        dates={{
          ...dates,
          onChange: handleDates,
        }}
        handleEdit={editAssessment}
        updateAssessment={updateAssessment}
        handleInvite={handleInvite}
        handleDuplicate={console.log}
        handleExtend={(id) => setExtend({ show: true, id, type: "extend" })}
        handleReopen={(id) => setExtend({ show: true, id, type: "re-open" })}
      />
    </>
  );
};

export { Assessments };
