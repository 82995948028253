import { ExperiencePrecision } from "components";
import { FAQs } from "./faqs";
import { Features } from "./features";
import { Plans } from "./plans";
import styles from "./styles.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const PricingUI = () => {
  
  useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 1000,
      offset: 100,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <section className={styles.bg}>
        <Plans />
        <Features />
        <FAQs />
        <ExperiencePrecision />
      </section>
    </>
  );
};

export { PricingUI };
