import * as React from "react";
import styles from "./styles.module.scss";
import { UseFormRegister } from "react-hook-form";

interface InputProps {
  dataTestID?: string;
  className?: string;
  parentClassName?: string;
  name: string;
  id?: string;
  validatorMessage: string | undefined;
  label?: string;
  register: UseFormRegister<any>;
  value?: string;
  showRequired?: boolean;
  length: number;
  startAt: number;
  leftMost: string;
  rightMost: string;
}

const InputScale: React.FC<InputProps> = ({
  dataTestID,
  className,
  parentClassName,
  name,
  id,
  validatorMessage,
  label,
  register,
  value,
  showRequired,
  length,
  leftMost,
  rightMost,
  startAt,
}) => {
  return (
    <div
      className={`${styles.inputWrapper} ${parentClassName} ${
        validatorMessage ? styles.error : ""
      }`}
    >
      {label && (
        <p className={styles.question}>
          {label}
          {showRequired ? <span className={styles.req}>*</span> : ""}
        </p>
      )}

      <section className={styles.scaleWrap}>
        <p className={styles.tag}>{leftMost}</p>
        <fieldset className={styles.fieldset}>
          {new Array(length).fill("0").map((option, index) => (
            <label
              htmlFor={`${name}_${index}`}
              key={index}
              className={styles.label}
            >
              <input
                data-testid={dataTestID}
                className={`${styles.input}`}
                type={"radio"}
                id={`${name}_${index}`}
                {...register(name)}
                value={startAt === 0 ? index : startAt === 1 ? startAt + index : startAt - index}
              />
              <span className={`${styles.mark}`}></span>
            </label>
          ))}
        </fieldset>
        <p className={styles.tag}>{rightMost}</p>
      </section>

      {validatorMessage && (
        <small className={styles.message}>{validatorMessage}</small>
      )}
    </div>
  );
};

export { InputScale };
