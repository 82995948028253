export const dollarPricing = {
  costPerCredit: 0.06,
  costPerCandidate: 0.4,
  payg: 30,
  regular: 128,
  pro: 255,
  yearlyDiscountPercent: 10,
};
export const calcRoleCost = (roles) => (roles > 5 ? roles * 14 : roles * 15);
export const topupPricing = {
  costPerCredit: 0.06,
  costPerCandidate: 0.6,
  costPerDay: 1.5,
};
