import {
  BillingHistoryTable,
  BillingHistoryTableItem,
  Button,
  EmptyTable,
  Table,
  TableHeaderItemProps,
} from "components";
import styles from "./styles.module.scss";
import { emptyAssessmentsImg } from "assets";

const tableHeaderTitles: TableHeaderItemProps[] = [
  { title: "Item" },
  { title: "Date" },
  { title: "Amount" },
  { title: "Status" },
  { title: "" },
];

export interface BillingHistoryProps {
  bills: BillingHistoryTableItem[];
  dates: {
    from: string;
    to: string;
    onChange: ({ from, to }: { from: string; to: string }) => void;
  };
  pagination: {
    handleChange: (page) => void;
    total: number;
    current: number;
    count: number;
  };
}
const History: React.FC<BillingHistoryProps> = ({
  bills,
  pagination,
  dates,
}) => {
  return (
    <>
      <section className={styles.container}>
        <div className={styles.dateFilter}>
          <label>
            <span>From</span>
            <input
              onChange={(e) =>
                dates.onChange({ ...dates, from: e.target.value })
              }
              value={dates.from}
              placeholder="From"
              type="date"
            />
          </label>
          <label>
            <span>To</span>
            <input
              onChange={(e) => dates.onChange({ ...dates, to: e.target.value })}
              value={dates.to}
              placeholder="To"
              type="date"
            />
          </label>

          {dates.from !== "" || dates.to !== "" ? (
            <Button
              disabled={dates.from === "" && dates.to === ""}
              type="outline"
              onClick={() => dates.onChange({ from: "", to: "" })}
              className={styles.reset}
            >
              Reset
            </Button>
          ) : (
            ""
          )}
        </div>
        <Table
          tableHeaderTitles={tableHeaderTitles}
          tableBody={
            <BillingHistoryTable
              tableBodyItems={bills}
              handleDownload={(id) => {}}
              tableBodyItemClassName={""}
              tableBodyRowClassName={styles.tableRow}
              pagination={{
                current: pagination.current,
                total: pagination.total,
                handleChange: pagination.handleChange,
                count: pagination.count,
                length: 8,
              }}
            />
          }
          customTableClasses={{
            tableContainerClassName: styles.tableWrap,
            tableHeaderClassName: styles.tableHeader,
            tableHeaderItemClassName: styles.tableHeaderItem,
          }}
          emptyTable={{
            show: false,
            element: (
              <EmptyTable
                image={emptyAssessmentsImg}
                text={"You haven't made any payments"}
              />
            ),
          }}
        />
      </section>
    </>
  );
};
export { History };
