import { Button, Input, Textarea } from "components";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { placeholderLogo } from "assets";

export interface InstructionData {
  instruction: string;
  title: string;
}

const initInstruction: InstructionData = {
  instruction: ``,
  title: "",
};

interface InstructionProps {
  back: () => void;
  submit: (data: InstructionData) => void;
  initData: InstructionData | undefined;
  areas: string[];
  logo: string;
}

const schema = yup.object({
  instruction: yup
    .string()
    .required("Required")
    .test(
      "word-length",
      "You have exceeded the word limit",
      (instruction) => instruction.trim().split(/[\n\s]+/).length <= 100
    ),
  title: yup.string().required("Required"),
});

const InstructionUI: React.FC<InstructionProps> = ({
  back,
  submit,
  initData,
  areas,
  logo,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<InstructionData>({
    resolver: yupResolver(schema),
    defaultValues: initData ?? initInstruction,
  });

  useEffect(() => {
    initData && reset(initData);
  }, [initData]);

  const onSubmit: SubmitHandler<InstructionData> = (data) => submit(data);

  return (
    <>
      <img src={logo} alt="logo" className={styles.logo} />
      <h1 className={styles.ttl}>
        <Input
          label=""
          placeholder="Provide a title for this job role"
          type="text"
          required
          validatorMessage={errors.title?.message}
          name="title"
          register={register}
          value={watch("title")}
          parentClassName={styles.title}
        />
      </h1>
      <div className={styles.info}>
        <p>
          <span>Start Date:</span> <span>Not defined yet</span>
        </p>
        <p>
          <span>End Date:</span> <span>Not defined yet</span>
        </p>
        {/* <p>
          <span>Total time:</span>
          <span>20 minutes</span>
        </p>
        <p>
          <span>Total question:</span> <span>29</span>
        </p> */}
      </div>
      <div style={{ position: "relative" }}>
        <Textarea
          placeholder={"Enter instruction"}
          name={"instruction"}
          validatorMessage={errors.instruction?.message}
          register={register}
          required
          parentClassName={styles.instruction}
          max={100}
        />
        <p className={styles.wordLimit}>
          {
            watch("instruction")
              .trim()
              .split(/[\n\s]+/).length
          }{" "}
          /100 words
        </p>
      </div>
      <div className={styles.instructions}>
        <p>Instructions</p>
        <ol>
          <li>
            Format: The test consists of multiple-choice and open-ended
            questions.
          </li>
          <li>
            Honesty: Please refrain from using any external resources or
            assistance during the assessment.
          </li>
          <li>
            Accuracy: Provide the response that best reflects your skills,
            knowledge, and experience. There are no right or wrong answers.
          </li>
          <li>
            Completeness: Answer all questions to the best of your abilities.
            Partially completed tests may affect your overall assessment.
          </li>
          <li>
            Submitting: Once you have completed the assessment, review your
            answers carefully before submitting. Double-check for any unanswered
            questions or mistakes.
          </li>
          <li>
            Disqualifications: To prevent an automatic disqualification, please
            do not exit the full screen mode until your assessment is complete.
          </li>
        </ol>
        <p>
          Remember, there are no right or wrong answers. Your performance will
          be evaluated based on your individual strengths and suitability for
          the role.
        </p>
      </div>
      {areas.length > 0 ? (
        <div>
          <p className={styles.areas}>
            Questions in this assessment covers the following areas
          </p>
          <div className={styles.sections}>
            {areas.map((area) => (
              <span>{area}</span>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.btnSec}>
        <Button type="outline" onClick={back}>
          Previous
        </Button>
        <Button type="fill" onClick={handleSubmit(onSubmit)}>
          Next
        </Button>
      </div>
    </>
  );
};

export { InstructionUI };
