import { SparklesIcon, placeholderAvatar } from "assets";
import styles from "./styles.module.scss";

const Testimonials = () => {
  const testimonials: TestimonialData[] = [
    {
      review: `Precision is an insightful tool that sifts through the clutter of LinkedIn applications to pinpoint the talent that aligns perfectly with our team's needs, all without exceeding our budget constraints.`,
      name: "Bonike Ayanbadejo",
      role: "HR Director",
      type: "business",
    },
    {
      review:
        "It is unique, detailed, and pragmatic in its approach to understanding the candidate's personality, leadership abilities, work style, and thought process. Kudos! ",
      name: "Oluwaseun Damilare Victor ",
      role: "Head of Operations Candidate",
      type: "candidate",
    },
    {
      review: `Precision consistently delivers results whenever we're tasked with selecting the most effective talent. The reports provided are not only insightful but also candid, providing us with a clear understanding of what to expect from working with this talent.`,
      name: "Sayo Odunsi",
      role: "Principal, 360° Marketing",
      type: "business",
    },
    {
      review: `Hiring competent individuals can be daunting, but with Precision, we swiftly identify top candidates who are the perfect fit. Their tools and processes expedite the selection of the best-suited candidates, saving us time and effort.`,
      name: "Ayo Dawodu",
      role: "CEO, Loystar",
      type: "business",
    },
    {
      review:
        "The application process is really thorough, it helps to understand and know each applicant on a personal note. ",
      name: "Adaeze Annastecia Igboanude",
      role: "Full Stack Developer Candidate",
      type: "candidate",
    },
    {
      review:
        "Precision offers talent intelligence in a straightforward yet highly effective manner. By utilizing this screening tool, we've spared ourselves the hassle of engaging with unsuitable candidates. The insights provided offer a comprehensive view of talent effectiveness.",
      name: "David Idibe",
      role: "Dezyncle",
      type: "business",
    },
  ];
  return (
    <>
      <section className={`container ${styles.wrapper}`}>
        <div className={styles.heading}>
          <p className={styles.heading__tag}>
            <SparklesIcon /> TESTIMONIALS
          </p>
          <h2 className={styles.heading__ttl}>
            For Leaders Who Mean <span>Business</span>
          </h2>
        </div>
        <div data-aos="fade-in" className={styles.testimonials}>
          {testimonials.map((item) => (
            <TestimonialItem {...item} key={item.name} />
          ))}
        </div>
      </section>
    </>
  );
};

interface TestimonialData {
  review: string;
  name: string;
  role: string;
  type: "business" | "candidate";
}

const TestimonialItem: React.FC<TestimonialData> = ({
  review,
  role,
  name,
  type,
}) => {
  return (
    <div className={styles.testimonial}>
      <p className={styles.testimonial__ttl}>
        Precision for {type === "business" ? "Businesses" : "Candidates"}
      </p>
      <p className={styles.testimonial__txt}>{review}</p>
      <div className={styles.testimonial__profile}>
        {/* <img src={placeholderAvatar} alt="" /> */}
        <div>
          <p className={styles.testimonial__name}>{name}</p>
          <p className={styles.testimonial__org}>{role}</p>
        </div>
      </div>
    </div>
  );
};

export { Testimonials };
