import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export interface ArrangeData {
  id: string;
  title: string;
}

const reorder = (list, startIndex, endIndex) => {
  const result: any = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface InputProps {
  list: ArrangeData[];
  label: string;
  instruction: string;
  onChange: (list: ArrangeData[]) => void;
  error: string;
}

const InputOrder: React.FC<InputProps> = ({
  list,
  label,
  onChange,
  instruction,
  error,
}) => {
  const [state, setState] = useState(list);
  const [mobile, setMobile] = useState(window.innerWidth <= 900);

  const screenSizeUpdate = () => {
    setMobile(window.innerWidth <= 900);
  };

  window.addEventListener("resize", screenSizeUpdate);

  useEffect(() => {
    setState(list);
  }, [list]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newlist: any = reorder(
      state,
      result.source.index,
      result.destination.index
    );

    setState(newlist);
    onChange(newlist);
  };

  return (
    <>
      <div className={styles.container}>
        <label className={styles.question}> {label}</label>
        <section className={styles.list}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              direction={mobile ? "vertical" : "horizontal"}
              droppableId="list"
            >
              {(provided) => (
                <div
                  className={styles.droppable}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {state.map((item, index) => (
                    <Draggable
                      index={index}
                      key={`drag-${item.id}`}
                      draggableId={item.id}
                    >
                      {(provided) => (
                        <div
                          className={styles.column}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <span className={styles.item}>{item.title}</span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </section>
        <p className={styles.instruction}> {instruction}</p>
        {error && <p className={styles.errorMessage}> {error}</p>}
      </div>
    </>
  );
};

export { InputOrder };
