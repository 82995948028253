import { TermsOfUseUI } from "features";

const TermsOfUse = () => {
  return (
    <>
      <TermsOfUseUI />
    </>
  );
};
export { TermsOfUse };
