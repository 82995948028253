import { OptionType } from "components";

export const SocialOptions: OptionType[] = [
  {
    label: "Spending time with a small group of close friends",
    value: "A",
  },
  {
    label: "Being alone, observing the event, and going along unnoticed",
    value: "B",
  },
  {
    label: "Spending time with the larger group and meeting new people",
    value: "C",
  },
  {
    label:
      "Actively seeking out opportunities to assert your presence by making your voice/opinion known",
    value: "D",
  },
];

export const ProblemSolvingOptions: OptionType[] = [
  {
    label:
      "Brainstorming ideas and collaborating with a small group of people you trust",
    value: "A",
  },
  {
    label:
      "Thinking through problems on your own and coming up with your own solutions",
    value: "B",
  },
  {
    label:
      "Engaging with a diverse group of people and gathering their input and feedback",
    value: "C",
  },
  {
    label:
      "Taking charge and making decisions based on your own expertise and intuition",
    value: "D",
  },
];
