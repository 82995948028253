import styles from "./styles.module.scss";

interface MetricHeadingProps {
  heading: string;
}

const MetricHeading: React.FC<MetricHeadingProps> = ({ heading }) => {
  return (
    <div className={styles.heading}>
      <p>{heading}</p>
    </div>
  );
};
export { MetricHeading };
