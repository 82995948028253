import { Button, Modal } from "components";
import styles from "./styles.module.scss";

interface EmailVerificationModalProps {
  show: boolean;
  close: () => void;
  resend: () => void;
  email: string;
}

const EmailVerificationPromptModal: React.FC<EmailVerificationModalProps> = ({
  show,
  close,
  resend,
  email,
}) => {
  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <h1 className={styles.ttl}>Verify your email</h1>
        <p className={styles.ttl}>{email}</p>
        <p className={styles.txt}>
          We sent you a verification email
          <br /> Check your inbox and click the link to verify your email.
          <br />
          <br />
          It can take up to 2 minutes to receive this email. Be sure to check
          your spam folder too.
        </p>
        <Button className={styles.btn} type="fill" onClick={resend}>
          Resend
        </Button>
      </Modal>
    </>
  );
};

export { EmailVerificationPromptModal };
