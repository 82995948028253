import styles from "./styles.module.scss";

const BetaBanner = () => {
  return (
    <>
      <section className={styles.bg}>
        <div className={styles.container}>
          <span>Join our beta</span>
          <span>Join our beta</span>
          <span>Join our beta</span>
          <span>Join our beta</span>
          <span>Join our beta</span>
          <span>Join our beta</span>
        </div>
      </section>
    </>
  );
};

export { BetaBanner };
