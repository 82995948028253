import { ComparisonData, Comparisons, EmptyTable, ScoreBar } from "components";
import styles from "./styles.module.scss";
import { emptyAssessmentsImg } from "assets";
import { MetricHeading, MetricLegend, MetricOverview } from "../components";

export interface SoftSkillsProps {
  isFullReport: boolean;
  skills: ComparisonData[] | undefined;
  score: number | undefined;
}

const SoftSkills: React.FC<SoftSkillsProps> = ({
  isFullReport,
  skills,
  score,
}) => {
  if (score === undefined || !skills)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Soft Skills" /> : ""}
        <section className={styles.container}>
          <div className={styles.sec1}>
            <MetricOverview
              description={
                "Provides insights into the intangible yet essential skills enabling the individual to deliver high-quality work and performance."
              }
              score={score}
            />
            <MetricLegend />
          </div>
          <Comparisons data={skills} />
        </section>
      </section>
    </>
  );
};

export { SoftSkills };
