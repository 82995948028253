import { Button, Modal } from "components";
import styles from "./styles.module.scss";
import { ReactNode } from "react";

interface ConfirmationModalProps {
  show: boolean;
  close: () => void;
  handleContinue: () => void;
  title: string;
  text: string | ReactNode;
  isRed?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  close,
  handleContinue,
  title,
  text,
  isRed,
}) => {
  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <h1 className={styles.ttl}>{title}</h1>
        <p className={styles.txt}>{text}</p>
        <div className={styles.btnSec}>
          <Button type={"outline"} onClick={close}>
            No, cancel
          </Button>
          <Button
            className={isRed ? styles.red : ""}
            type={"fill"}
            onClick={() => {
              close();
              handleContinue();
            }}
          >
            Yes, continue
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { ConfirmationModal };
