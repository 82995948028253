import { candidatesHeroImg } from "assets";
import styles from "./styles.module.scss";

const HeroSection = () => {
  return (
    <>
      <section className={`container ${styles.hero}`}>
        <div className={styles.content} >
          <h1 className={styles.content__ttl}>Navigating Your <span>Assessment</span> Journey</h1>
          <p data-aos="slide-up" className={styles.content__txt}>
            Your Precision assessment for your desired employer and role is
            designed to illuminate your abilities and help you stand out. Our
            mission is to create a win-win scenario, matching the right talents
            with the right career opportunities.
          </p>
        </div>
        <img data-aos="slide-left" className={styles.img} src={candidatesHeroImg} alt="" />
      </section>
    </>
  );
};

export { HeroSection };
