import { PreferenceData, reduxPreferenceAction } from "../types";

const initalState: PreferenceData = {
  currency: "USD",
};

const preferenceReducer = (
  state = initalState,
  action: reduxPreferenceAction
) => {
  switch (action.type) {
    case "UPDATE_PREFERENCE":
      return action?.payload;
    default:
      return state;
  }
};

export default preferenceReducer;
