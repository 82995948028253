/*
=================================
BILLING SERVICES
=================================
*/

import {
  buyPlanURL,
  cancelPlanURL,
  deleteRequest,
  extendAccessURL,
  fetchBillingHistoryURL,
  fetchCardURL,
  fetchPlansURL,
  fetchPlanURL,
  getRequest,
  postRequest,
  topupScoringCreditsURL,
  topupURL,
  updateCardURL,
} from "api";

export interface BuyPlanRequestData {
  plan: "custom" | "regular" | "pro" | "pay_as_you_go";
  billing_period: "annual" | "monthly" | "as_you_go";
  role?: number;
  extra_candidate?: number;
  callback_url: string;
}

/**
 * Buy plan service
 * @returns axios promise
 */

export const buyPlanService = (data: BuyPlanRequestData) => {
  const requestData = {
    url: buyPlanURL(),
    data,
  };
  return postRequest(requestData);
};

interface fetchBillingHistoryParams {
  dateTo: string;
  dateFrom: string;
  page: string;
}
/**
 * Fetch billing history service
 * @returns axios promise
 */

export const fetchBillingHistoryService = (
  params: fetchBillingHistoryParams
) => {
  const requestData = {
    url: fetchBillingHistoryURL(),
    config: { params },
  };
  return getRequest(requestData);
};

export interface extendAccessRequestData {
  days: number;
  callback_url: string;
}
/**
 * Extend access service
 * @returns axios promise
 */

export const extendAccessService = (data: extendAccessRequestData) => {
  const requestData = {
    url: extendAccessURL(),
    data,
  };
  return postRequest(requestData);
};

export interface topupRequestData {
  candidates: number;
  callback_url: string;
}
/**
 * Topup service
 * @returns axios promise
 */

export const topupService = (data: topupRequestData) => {
  const requestData = {
    url: topupURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Fetch plan info service
 * @returns axios promise
 */

export const fetchPlanService = () => {
  const requestData = {
    url: fetchPlanURL(),
  };
  return getRequest(requestData);
};

/**
 * Cancel plan service
 * @returns axios promise
 */

export const cancelPlanService = () => {
  const requestData = {
    url: cancelPlanURL(),
  };
  return deleteRequest(requestData);
};

/**
 * Fetch card service
 * @returns axios promise
 */

export const fetchCardService = () => {
  const requestData = {
    url: fetchCardURL(),
  };
  return getRequest(requestData);
};

/**
 * Update card service
 * @returns axios promise
 */

export const updateCardService = () => {
  const requestData = {
    url: updateCardURL(),
  };
  return getRequest(requestData);
};

export interface topupCreditsRequestData {
  credits: number;
  callback_url: string;
}

/**
 * Topup autoscoring credits service
 * @param {Object} data - The props object.
 * @param {number} data.candidates - The number of credits
 * @param {string} data.callback_url - The callback url after payment
 * @returns axios promise
 */

export const topupScoringCreditsService = (data: topupCreditsRequestData) => {
  const requestData = {
    url: topupScoringCreditsURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Fetching all plans service
 * @returns axios promise
 */

export const fetchPlansService = () => {
  const requestData = {
    url: fetchPlansURL(),
  };
  return getRequest(requestData);
};
