import { Modal } from "components/modal";
import styles from "./styles.module.scss";

interface Props {
  show: boolean;
  close: () => void;
}

const ExtendAccessUnavailableModal: React.FC<Props> = ({ show, close }) => {
  return (
    <>
      <Modal contentClassName={styles.modal} show={show} close={close}>
        <h1 className={styles.ttl}>Extend Access</h1>
        <p className={styles.txt}>
          This feature is exclusively available to Month-to-Month Regular and
          Pro plan users. It's not included in your current subscription plan.
          You can choose to repurchase or upgrade to a standard plan to continue
          accessing services.
        </p>
        <div className={styles.btnSec}>
          <button className={styles.btnSec__close} onClick={close}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export { ExtendAccessUnavailableModal };
