import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import { Input } from "components/form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { calcRoleCost, dollarPricing } from "utils/pricing";
import { AllPlansData } from "state";

interface CustomPlanData {
  candidates: string | undefined;
  roles: string;
}

const schema = yup
  .object({
    candidates: yup.string().matches(/\d/, {
      message: "Enter a valid number",
      excludeEmptyString: true,
    }),
    roles: yup
      .string()
      .required("Required")
      .matches(/\d/, "Enter a valid number")
      .test(
        "roles",
        "A custom plan must contain more than 10 roles",
        (value) => parseInt(value) > 10
      ),
  })
  .required();

interface Props {
  show: boolean;
  close: () => void;
  handleBuy: (data: AllPlansData) => void;
}

const CustomPlanModal: React.FC<Props> = ({ show, close, handleBuy }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CustomPlanData>({
    resolver: yupResolver(schema),
    defaultValues: { candidates: undefined, roles: undefined },
  });

  const candidates =
    watch("candidates") !== "" ? parseInt(watch("candidates") ?? "0") : 0;
  const roles = watch("roles") !== "" ? parseInt(watch("roles") ?? "0") : 0;

  const candidateCost = candidates
    ? dollarPricing.costPerCandidate * candidates
    : 0;
  const roleCost = calcRoleCost(roles);

  const cost = candidateCost + roleCost;

  const onSubmit: SubmitHandler<CustomPlanData> = (data) => {
    handleBuy({
      title: "Custom",
      key: "custom",
      candidates: 150,
      extraCandidates: candidates,
      roles: roles,
      fee: cost,
      nairaFee: cost * 1000,
      autoScoringCredit: 0
    });
  };

  return (
    <>
      <Modal contentClassName={styles.modal} show={show} close={close}>
        <h1 className={styles.ttl}>Custom Plan Request</h1>
        <p className={styles.txt1}>
          Get a custom a plan based on the volume of roles and candidates slots
          you need
        </p>

        <div className={styles.inputWrap}>
          <Input
            label="Number of roles"
            placeholder={"Enter the number of roles"}
            name={"roles"}
            validatorMessage={errors.roles?.message}
            register={register}
            type="number"
          />
          <p>{150 * roles} standard candidates slots</p>
        </div>
        <div className={styles.inputWrap}>
          <Input
            label="If the standard candidate slots isn't enough, add more below"
            placeholder={"Enter the number of candidates"}
            name={"candidates"}
            validatorMessage={errors.candidates?.message}
            register={register}
            type="number"
          />
          <p>${dollarPricing.costPerCandidate} per candidate</p>
        </div>

        <p className={styles.txt3}>
          ${cost.toFixed(2)}
          <span>monthly subscription</span>
        </p>
        <div className={styles.btnSec}>
          <button className={styles.btnSec__close} onClick={close}>
            Cancel
          </button>
          <button
            className={styles.btnSec__upgrade}
            onClick={handleSubmit(onSubmit)}
          >
            Buy Now
          </button>
        </div>
      </Modal>
    </>
  );
};

export { CustomPlanModal };
