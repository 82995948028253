import {
  candidatePersonalityRequestData,
  candidatePersonalityService,
} from "api";
import { Loader } from "components";
import { AssessmentPersonalityUI } from "features";
import { getError, handleAssessmentTimer } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useMemo, useState } from "react";

interface AssessmentPersonalityProps {
  callback: (metric: string) => void;
}
const AssessmentPersonality: React.FC<AssessmentPersonalityProps> = ({
  callback,
}) => {
  // State
  const [startTime, setStartTime] = useState<number>(0);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const submit = (data) => {
    const candidate = localStorage.getItem("assessmentId");
    if (!candidate) return;
    
    const secondsLeft = parseInt(localStorage.getItem("secondsLeft") ?? "0");

    const submitData: candidatePersonalityRequestData = {
      id: candidate,
      data: {
        question1: {
          option: data.social,
          rating: data.socialRating,
        },
        question2: {
          option: data.problemSolving,
          rating: data.problemSolvingRating,
        },
        question3: data.innovation,
        question4: data.drive,
        question5: data.judge,
        question6: data.othersNeeds,
        question7: data.improvise,
        question8: data.productive,
        minutes_left: Math.ceil(secondsLeft / 60),
      },
    };

    // Set start time
    setStartTime(new Date().getTime());
    run(candidatePersonalityService(submitData));
  };

  useMemo(() => {
    if (response?.status === 200) {
      const data = response.data.data;

      const answered = parseInt(localStorage.getItem("answered") ?? "0");
      localStorage.setItem("answered", `${answered + 8}`);

      handleAssessmentTimer(startTime);

      callback("personality");
    } else if (error) {
      handleAssessmentTimer(startTime);
      openToast({
        show: true,
        ...getError({
          error,
          text: "Failed to submit, please try again or contact support",
          heading: "Sorry",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <AssessmentPersonalityUI submit={submit} />
    </>
  );
};

export { AssessmentPersonality };
