/*
=================================
BILLING URLS
=================================
*/

/**
 * Buy plan url
 *
 * @returns url string
 *
 */

export const buyPlanURL = () => `/billing/start/`;

/**
 * Fetch billing history url
 *
 * @returns url string
 *
 */

export const fetchBillingHistoryURL = () => `/billing/transactions/history/`;

/**
 * Extend access url
 *
 * @returns url string
 *
 */

export const extendAccessURL = () => `/billing/extend-access/`;

/**
 * Topup url
 *
 * @returns url string
 *
 */

export const topupURL = () => `/billing/top-up/candidates/`;

/**
 * Fetch plan info url
 *
 * @returns url string
 *
 */

export const fetchPlanURL = () => `/billing/plan/`;

/**
 * Cancel plan url
 *
 * @returns url string
 *
 */

export const cancelPlanURL = () => `/billing/terminate/`;

/**
 * Fetch card url
 *
 * @returns url string
 *
 */

export const fetchCardURL = () => `/billing/cards/`;

/**
 * Update card url
 *
 * @returns url string
 *
 */

export const updateCardURL = () => `/billing/cards/update/`;

/**
 * Topup autoscoring credits url
 *
 * @returns url string
 *
 */

export const topupScoringCreditsURL = () => `/billing/top-up/auto-scoring-credits/`;

/**
 * Fetching all plans url
 *
 * @returns url string
 *
 */

export const fetchPlansURL = () => `/billing/plans/`;

