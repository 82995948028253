import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface ComparisonBarData {
  expectationStr?: string;
  result: number;
  leftLabel?: string;
  rightLabel?: string;
}

interface ComparisonBarProps extends ComparisonBarData {
  className?: string;
}

const ComparisonBar = ({
  result,
  className,
  expectationStr,
  leftLabel,
  rightLabel,
}: ComparisonBarProps) => {
  const getColor = (value: number) => {
    let res = "";
    if (value >= 90) res = "green";
    else if (value >= 70) res = "blue";
    else if (value >= 50) res = "orange";
    else res = "red";

    return res;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.comparisonBar} ${className}`}>
          <div
            style={{ width: `${result}%` }}
            className={`${styles.comparisonBar__bar} ${
              styles[`bg-${getColor(result)}`]
            }`}
          ></div>
          <div className={styles.comparisonBar__bar__white}></div>
        </div>
        <div className={styles.info}>
          <div className={`${styles.info__item} ${styles[getColor(result)]}`}>
            <p className={styles.info__item__txt1}>{result}%</p>
            <p className={styles.info__item__txt2}>
              {leftLabel ?? "Candidate’s Result"}
            </p>
          </div>
          <div className={styles.info__item}>
            <p className={styles.info__item__txt1}>{expectationStr}</p>
            <p className={styles.info__item__txt2}>
              {rightLabel ?? "Expectation"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export interface ComparisonData extends ComparisonBarData {
  title: string;
  description?: string | ReactNode;
  body?: ReactNode;
}

interface ComparisonProps {
  data: ComparisonData[];
}

const Comparisons: React.FC<ComparisonProps> = ({ data }) => {
  return (
    <div className={styles.comparison}>
      {data.map((item) => (
        <div className={styles.comparison__body}>
          <p className={styles.comparison__body__title}>{item.title}</p>
          <ComparisonBar {...item} />
          {item.description ? (
            <p className={styles.comparison__body__description}>
              {item.description}
            </p>
          ) : (
            item.body ?? ""
          )}
        </div>
      ))}
    </div>
  );
};

export { ComparisonBar, Comparisons };
