import { InfoIcon } from "assets";
import styles from "./styles.module.scss";

interface ToolTipProps {
  text: string;
  title: string;
  className?: string;
  position?: "left" | "right" | "middle";
  iconSize?: "small" | "normal";
}

const ToolTip = ({
  text,
  title,
  className,
  position,
  iconSize = "normal",
}: ToolTipProps) => {
  return (
    <div className={`${styles.info} ${styles[`${position}`]} ${className}`}>
      <InfoIcon width={iconSize === "small" ? 20 : 24} />
      <div>
        <p>{title}</p>
        <p>{text}</p>
      </div>
    </div>
  );
};

export { ToolTip };
