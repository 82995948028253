import {
  BuyPlanRequestData,
  buyPlanService,
  cancelPlanService,
  extendAccessService,
  fetchBillingHistoryService,
  fetchCardService,
  topupService,
  updateCardService,
} from "api";
import {
  BillingHistoryTableItem,
  ConfirmationModal,
  CustomPlanModal,
  ExtendAccessModal,
  ExtendAccessUnavailableModal,
  Loader,
} from "components";
import { BillingsUI } from "features";
import { getDateTime, getError } from "helpers";
import { useApiRequest, useFetchPlan, useFetchPlans, useToast } from "hooks";
import { Navbar } from "layout";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "state/hooks";
import { TopupCandidates } from "./topupCandidates";
import { TopupAutoScoring } from "./topupAutoScore";
import { AllPlansData } from "state";

const Billings = () => {
  const [view, setView] = useState("plan");
  const [extendUnavailable, setExtendUnavailable] = useState(false);
  const [extend, setExtend] = useState(false);
  const [topup, setTopup] = useState(false);
  const [topupCredits, setTopupCredits] = useState(false);
  const [customPlan, setCustomPlan] = useState(false);
  const [planData, setPlanData] = useState<AllPlansData>({
    title: "",
    key: "pay_as_you_go",
    roles: 0,
    candidates: 0,
    fee: 0,
    nairaFee: 0,
    autoScoringCredit: 0
  });
  const [pages, setPages] = useState({
    total: 0,
    count: 0,
    current: 1,
  });
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });
  const [load, setLoad] = useState(false);
  const [cancelPlan, setCancelPlan] = useState(false);
  const [changeCard, setChangeCard] = useState(false);

  // Hooks
  const { openToast } = useToast();
  const { getPlan, loading } = useFetchPlan();
  const { getPlans, loading: loadingPlans } = useFetchPlans();

  const plan = useAppSelector((state) => state.plan);
  const [searchParams, setSearchParams] = useSearchParams();

  // API Request Hooks
  const {
    run: runBuyPlan,
    data: buyPlanResponse,
    requestStatus: buyPlanStatus,
    error: buyPlanError,
  } = useApiRequest({});
  const {
    run: runHistory,
    data: historyResponse,
    requestStatus: historyStatus,
    error: historyError,
  } = useApiRequest({});
  const {
    run: runExtend,
    data: extendResponse,
    requestStatus: extendStatus,
    error: extendError,
  } = useApiRequest({});
  const {
    run: runCancel,
    data: cancelResponse,
    requestStatus: cancelStatus,
    error: cancelError,
  } = useApiRequest({});
  const {
    run: runFetchCard,
    data: fetchCardResponse,
    requestStatus: fetchCardStatus,
    error: fetchCardError,
  } = useApiRequest({});
  const {
    run: runSaveCard,
    data: saveCardResponse,
    requestStatus: saveCardStatus,
    error: saveCardError,
  } = useApiRequest({});

  const fetchHistory = ({ page, from, to }: { page?; from?; to? }) =>
    runHistory(
      fetchBillingHistoryService({
        page: page ?? pages.current,
        dateFrom: from ?? dates.from,
        dateTo: to ?? dates.to,
      })
    );

  const handleFetchCard = () => {
    runFetchCard(fetchCardService());
  };

  useEffect(() => {
    if (view === "plan" || view === "history") {
      fetchHistory({ page: 1 });
      getPlan();
    }
  }, [view]);

  useEffect(() => {
    setTopup(searchParams.get("topup") ? true : false);
    setSearchParams();
    handleFetchCard();
    getPlans()
  }, []);

  const buyPlan = (data: BuyPlanRequestData) => {
    runBuyPlan(buyPlanService(data));
  };

  const handleExtendAccess = (days: number) => {
    runExtend(
      extendAccessService({ days, callback_url: window.location.href })
    );
  };

  const handleCancelPlan = () => {
    runCancel(cancelPlanService());
  };

  const handleSaveCard = () => {
    runSaveCard(updateCardService());
  };

  useMemo(() => {
    if (buyPlanStatus.isIdle || buyPlanStatus.isPending) return;
    if (buyPlanResponse?.status === 200) {
      const data = buyPlanResponse.data.data.data;

      openToast({
        show: true,
        heading: "Your purchase request has been initiated successfully",
        text: "Redirecting you to the payment in 3...2...1",
        type: "success",
        timeOut: 3500,
      });

      if (data.authorization_url) {
        setLoad(true);
        setTimeout(() => {
          window.location.assign(data.authorization_url);
        }, 2000);
      }
    } else if (buyPlanError) {
      openToast({
        show: true,
        ...getError({
          error: buyPlanError,
          heading: "Sorry",
          text: "Failed to buy plan, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [buyPlanResponse, buyPlanError]);

  const history = useMemo<BillingHistoryTableItem[]>(() => {
    if (historyResponse?.status === 200) {
      setPages({
        ...pages,
        total: Math.ceil(historyResponse.data.count / 8),
        count: historyResponse.data.count,
      });

      const data = historyResponse.data.results;

      return data.map((item) => ({
        id: item.id,
        title:
          item.purpose === "new_plan"
            ? item.plan?.plan_type?.replaceAll("_", " ")
            : item.purpose.replaceAll("_", " "),
        date: {
          date: getDateTime(item.created_at).date,
          time: getDateTime(item.created_at).time,
        },
        amount: `$${item.dollar_amount}`,
        status: item.status,
        invoice: item.invoice,
      }));
    } else if (historyError) {
      openToast({
        show: true,
        ...getError({
          error: historyError,
          heading: "Sorry",
          text: "Failed to fetch billing history, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [historyResponse, historyError]);

  useMemo(() => {
    if (extendResponse?.status === 200) {
      const data = extendResponse.data.data.data;

      const heading = data.authorization_url
        ? extendResponse.data.message ??
          "We have received your request to extend your access!"
        : extendResponse.data.data.message ?? "Failed to initiate";
      const text = data.authorization_url
        ? "Redirecting to payment in 3...2...1"
        : "";

      openToast({
        show: true,
        heading,
        text,
        type: "success",
        timeOut: 3500,
      });
      if (data.authorization_url) {
        setLoad(true);
        setTimeout(() => {
          window.location.assign(data.authorization_url);
          setExtend(false);
        }, 2000);
      }
    } else if (extendError) {
      openToast({
        show: true,
        ...getError({
          error: extendError,
          heading: "Sorry",
          text: "Failed to extend access, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [extendResponse, extendError]);

  useMemo(() => {
    if (cancelResponse?.status === 200) {
      openToast({
        show: true,
        heading: "Great",
        text: "You have successfully canceled your subscription",
        type: "success",
        timeOut: 3500,
      });
      setCancelPlan(false);
      getPlan();
    } else if (cancelError) {
      openToast({
        show: true,
        ...getError({
          error: cancelError,
          heading: "Sorry",
          text: "Failed to cancel subscription, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [cancelResponse, cancelError]);

  const card = useMemo<{ type: string; digits: string } | undefined>(() => {
    if (fetchCardResponse?.status === 200) {
      const data = fetchCardResponse.data.data;
      return {
        digits: data.last4,
        type: data.card_type.toLowerCase(),
      };
    } else if (fetchCardError) {
      openToast({
        show: true,
        ...getError({
          error: fetchCardError,
          heading: "Sorry",
          text: "Failed to fetch card details, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }

    return undefined;
  }, [fetchCardResponse, fetchCardError]);

  useMemo(() => {
    if (saveCardResponse?.status === 200) {
      const data = saveCardResponse.data.data.data;

      const heading = data.authorization_url
        ? saveCardResponse.data.message ??
          "We have received your request to change your card"
        : saveCardResponse.data.data.message ?? "Failed to initiate";
      const text = data.authorization_url
        ? "Redirecting to payment in 3...2...1"
        : "";
      openToast({
        show: true,
        heading,
        text,
        type: data.authorization_url ? "success" : "error",
        timeOut: 3500,
      });

      if (data.authorization_url) {
        setLoad(true);
        setTimeout(() => {
          window.location.assign(data.authorization_url);
          setTopup(false);
        }, 2000);
      }
    } else if (saveCardError) {
      openToast({
        show: true,
        ...getError({
          error: saveCardError,
          heading: "Sorry",
          text: "Failed to change card details, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }

    return "";
  }, [saveCardResponse, saveCardError]);

  const handleDates = ({ from, to }) => {
    if ((from === "" && to === "") || (from !== "" && to !== ""))
      fetchHistory({ page: 1, from, to });

    setPages((prev) => ({
      ...prev,
      current: 1,
    }));
    setDates({ from, to });
  };

  const handlePages = (page) => {
    fetchHistory({ page });

    setPages((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const showLoader =
    buyPlanStatus.isPending ||
    historyStatus.isPending ||
    extendStatus.isPending ||
    loading ||
    load ||
    cancelStatus.isPending ||
    fetchCardStatus.isPending ||
    saveCardStatus.isPending ||
    loadingPlans;

  return (
    <>
      <Loader loading={showLoader} />
      <ExtendAccessUnavailableModal
        show={extendUnavailable}
        close={() => setExtendUnavailable(false)}
      />
      <ExtendAccessModal
        show={extend}
        close={() => setExtend(false)}
        handleBuy={handleExtendAccess}
      />
      <TopupCandidates
        show={topup}
        close={() => setTopup(false)}
        openToast={openToast}
        callback={() => {
          setLoad(true);
        }}
      />
      <TopupAutoScoring
        show={topupCredits}
        close={() => setTopupCredits(false)}
        openToast={openToast}
        callback={() => {
          setLoad(true);
        }}
      />
      <ConfirmationModal
        show={cancelPlan}
        close={() => setCancelPlan(false)}
        handleContinue={handleCancelPlan}
        title={"Cancel Subscription"}
        text={
          "Are you sure you want to cancel your subscription? You will not be able to access your data until you subscribe to a plan"
        }
      />
      <ConfirmationModal
        show={changeCard}
        close={() => setChangeCard(false)}
        handleContinue={handleSaveCard}
        title={"Change Card"}
        text={
          "Are you sure you want to change your card? You will be charged the equivalent of 1 candidate which will be added to your account upon your next subscription."
        }
      />
      <CustomPlanModal
        show={customPlan}
        close={() => setCustomPlan(false)}
        handleBuy={(plan) => {
          setCustomPlan(false);
          setView("payment");
          setPlanData(plan);
        }}
      />
      <Navbar title={"Plans & Billings"} />
      <BillingsUI
        plan={{
          handleExtendAccess: () => setExtend(true),
          handleExtendAccessUnavailable: () => setExtendUnavailable(true),
          handleTopup: () => setTopup(true),
          handleCustomPlan: () => setCustomPlan(true),
          handleBuyPlan: (plan) => {
            setView("payment");
            setPlanData(plan);
          },
          planData: {
            plan: plan.plan,
            rolesAllowed: plan.roles.total,
            rolesUsed: plan.roles.used,
            candidatesAllowed: plan.candidates.total,
            candidatesUsed: plan.candidates.used,
            billingCycle: plan.billingCycle,
            status: plan.status,
            daysLeft: plan.daysLeft,
            amount: plan.amount,
          },
          handleCancelSub: () => setCancelPlan(true),
          card,
          handleSaveCard: () => setChangeCard(true),
          autoScoring: {
            handleTopupCredits: () => setTopupCredits(true),
            creditLeft: 50,
            creditUsed: 20,
          },
        }}
        view={view}
        handleView={setView}
        payment={{
          handleCancel: () => setView("plan"),
          plan: planData,
          handlePayment: buyPlan,
        }}
        history={{
          bills: history,
          pagination: {
            handleChange: handlePages,
            ...pages,
          },
          dates: {
            ...dates,
            onChange: handleDates,
          },
        }}
      />
    </>
  );
};

export { Billings };
