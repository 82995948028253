import { AssessmentPreviewData, PreviewAssessmentLayout } from "features";
import { queryObject } from "helpers";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AssessmentBasicFit } from "./basicFit";
import { AssessmentWorkQualities } from "./workQualities";
import { AssessmentWorkStyle } from "./workStyle";
import { AssessmentPersonality } from "./personality";
import { AssessmentSoftSkills } from "./softSkills";
import { AssessmentDomainKnowledge } from "./domainKnowledge";
import { AssessmentOverview } from "./overview";
import { StartAssessment } from "./startAssessment";
import { getDateTime } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useParams } from "react-router-dom";
import { fetchAssessmentInfoService } from "api";
import { Loader } from "components";
import { Routes } from "router";
import { AssessmentWorkMotivators } from "./workMotivators";
import { AssessmentComplete } from "./complete";

const initAssessment: AssessmentPreviewData = {
  title: "",
  startDate: "",
  endDate: "",
  time: 0,
  total: 0,
  instruction: "",
  metrics: [],
  id: "",
  _id: "",
};

interface metrics {
  metric: string;
  answered: boolean;
}

const PreviewAssessment = () => {
  const [start, setStart] = useState(false);
  const [metrics, setMetrics] = useState<metrics[]>([]);

  const { search } = useLocation();
  const navigate = useNavigate();
  const query = queryObject(search);
  const isOverview = Object.keys(query).length === 0;
  const hideProgress = !query.metric && !isOverview;

  const { id } = useParams();

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const fetchAssessment = () => id && run(fetchAssessmentInfoService(id));

  useEffect(() => {
    fetchAssessment();
  }, [id]);

  const assessment = useMemo<AssessmentPreviewData>(() => {
    if (response?.status === 200) {
      const data = response.data;

      const metrics: metrics[] = data.metrics_set.map((item) => ({
        metric: item,
        answered: false,
      }));

      const workCultureIndex = metrics.findIndex(
        ({ metric }) => metric === "work_culture"
      );

      if (workCultureIndex >= 0) {
        const culture = [
          {
            metric: "work_style",
            answered: false,
          },
          {
            metric: "work_motivators",
            answered: false,
          },
          {
            metric: "work_qualities",
            answered: false,
          },
        ];
        metrics.splice(workCultureIndex, 1, ...culture);
      }
      setMetrics(metrics);

      const assessment: AssessmentPreviewData = {
        title: data.title,
        startDate: getDateTime(data.start_date).dateString,
        endDate: getDateTime(data.end_date).dateString,
        time: data.duration_in_minutes, //in minutes
        total: data.no_of_questions,
        instruction: data.intro_html,
        metrics: data.metrics_set.map((item) => item.replaceAll("_", " ")),
        id: data.assessment_id,
        _id: data.id,
      };

      localStorage.setItem("assessment", JSON.stringify(assessment));
      return assessment;
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to fetch assessment information",
        type: "error",
        timeOut: 5000,
      });
    }
    return initAssessment;
  }, [response, error]);

  const handleNext = (metric: string) => {
    const newList = metrics.map((item) =>
      item.metric === metric ? { metric, answered: true } : item
    );
    setMetrics(newList);
    const next = newList.find((metric) => !metric.answered);
    goToNext(next);
  };

  const goToNext = (next: metrics | undefined) => {
    if (next) {
      switch (next.metric) {
        case "basic_expectations":
          navigate(Routes.employerPreviewBasic(id));
          break;
        case "work_motivators":
          navigate(Routes.employerPreviewWorkMotivators(id));
          break;
        case "work_qualities":
          navigate(Routes.employerPreviewWorkQualities(id));
          break;
        case "work_style":
          navigate(Routes.employerPreviewWorkStyle(id));
          break;
        case "personality":
          navigate(Routes.employerPreviewPersonality(id));
          break;
        case "soft_skill":
          navigate(Routes.employerPreviewSoftskills(id));
          break;
        case "domain_knowledge":
          navigate(Routes.employerPreviewDomainKnowledge(id));
          break;
        default:
          navigate(Routes.employerPreviewAssessment(id));
          break;
      }
    } else {
      navigate(Routes.employerPreviewComplete(id));
    }
  };

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <StartAssessment
        show={start}
        close={() => setStart(false)}
        id={id ?? ""}
      />
      <PreviewAssessmentLayout
        length={assessment.total}
        isOverview={isOverview || hideProgress}
        duration={assessment.time}
      >
        {isOverview ? (
          <AssessmentOverview
            assessment={assessment}
            handleStart={() => setStart(true)}
          />
        ) : query.metric === "basic-fit" ? (
          <AssessmentBasicFit
            hasBasicExpectation={
              assessment.metrics.find((item) => item === "basic expectations")
                ? true
                : false
            }
            callback={handleNext}
          />
        ) : query.metric === "work-qualities" ? (
          <AssessmentWorkQualities callback={handleNext} />
        ) : query.metric === "work-motivators" ? (
          <AssessmentWorkMotivators callback={handleNext} />
        ) : query.metric === "work-style" ? (
          <AssessmentWorkStyle callback={handleNext} />
        ) : query.metric === "personality" ? (
          <AssessmentPersonality callback={handleNext} />
        ) : query.metric === "soft-skills" ? (
          <AssessmentSoftSkills callback={handleNext} />
        ) : query.metric === "domain-knowledge" ? (
          <AssessmentDomainKnowledge callback={handleNext} />
        ) : query.complete === "true" ? (
          <AssessmentComplete />
        ) : (
          ""
        )}
      </PreviewAssessmentLayout>
    </>
  );
};

export { PreviewAssessment };
