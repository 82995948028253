import * as React from "react";
import styles from "./styles.module.css";

const Loader = ({ loading }: { loading: boolean }) => {
  return (
    <>
      {loading ? (
        <div className={styles.preloader}>
          <Spinner />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const Spinner = () => {
  return <div className={styles.spinner}></div>;
};

export { Loader, Spinner };
