import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import {
  ArrangeData,
  Button,
  CustomSelect,
  Input,
  InputOrder,
  InputScale,
  RadioInput,
  StarRating,
  initOptionType,
} from "components";
import {
  AdministrationHumiliateOptions,
  RoutineOptions,
  SuccessfulManagerOptions,
  CriticalThinkingList,
  ResilienceOptions,
  EloquenceDailyOptions,
  EloquenceFearOptions,
  LeadershipExperienceOptions,
  LeadershipJusticeOptions,
  LeadershipOverwhelmingOptions,
  PersuasionOptions,
  PersuasionStressOptions,
  PersuasionTaskOptions,
  ProficiencyOptions,
  AnalyticalStatementOptions,
  AnalyticalThinkingList,
} from "./options";
import { useEffect, useState } from "react";
import { TrashIcon } from "assets";

export interface PreviewSoftSkillData {
  agility: {
    required?: boolean | undefined;
    compete?: number | undefined;
    vigor?: number | undefined;
  };
  administration: {
    required?: boolean | undefined;
    eventPlanning?: number | undefined;
    proofReading?: number | undefined;
    multiTasking?: number | undefined;
    recording?: number | undefined;
    appointment?: number | undefined;
    humiliate?: string | undefined;
  };
  empathy: {
    required?: boolean | undefined;
    relate?: number | undefined;
    desireToLearn?: number | undefined;
    smallTalk?: number | undefined;
    emotional?: number | undefined;
    successful?: string | undefined;
  };
  creativeThinking: {
    required?: boolean | undefined;
    list?: ArrangeData[] | undefined;
    routine?: string | undefined;
  };
  research: {
    required?: boolean | undefined;
    resilienceRating?: number | undefined;
    resilience?: string | undefined;
  };
  eloquence: {
    required?: boolean | undefined;
    daily?: string | undefined;
    dailyOther?: string | undefined;
    fear?: string | undefined;
  };
  leadership: {
    required?: boolean | undefined;
    experience?: string | undefined;
    overwhelm?: string[] | undefined;
    justice?: string | undefined;
    resources?: number | undefined;
    teach?: number | undefined;
  };
  persuasion: {
    required?: boolean | undefined;
    listen?: string | undefined;
    stress?: string[] | undefined;
    task?: string | undefined;
  };
  techCuriosity: {
    required?: boolean | undefined;
    preference?: number | undefined;
    tools?: { tool: string; proficiency: number }[] | undefined;
  };
  learningCuriosity: {
    required?: boolean | undefined;
    breakAndFix?: number | undefined;
    workings?: number | undefined;
  };
  analyticalThinking: {
    required?: boolean | undefined;
    statement?: string | undefined;
    statementRating?: number | undefined;
    list?: ArrangeData[] | undefined;
  };
}

const initData: PreviewSoftSkillData = {
  agility: { required: false, compete: 0, vigor: 0 },
  administration: {
    required: false,
    eventPlanning: 0,
    proofReading: 0,
    multiTasking: 0,
    recording: 0,
    appointment: 0,
    humiliate: "",
  },
  empathy: {
    required: false,
    relate: 0,
    desireToLearn: 0,
    smallTalk: 0,
    emotional: 0,
    successful: "",
  },
  creativeThinking: { required: false, list: [], routine: "" },
  research: { required: false, resilience: "", resilienceRating: 0 },
  eloquence: { required: false, daily: "", dailyOther: "", fear: "" },
  leadership: {
    required: false,
    experience: "",
    overwhelm: [],
    justice: "",
    resources: 0,
    teach: 0,
  },
  persuasion: { required: false, listen: "", stress: [], task: "" },
  techCuriosity: { required: false, preference: 0, tools: [] },
  learningCuriosity: { required: false, breakAndFix: 0, workings: 0 },
  analyticalThinking: {
    required: false,
    statement: "",
    statementRating: 0,
    list: [],
  },
};

const schema = yup
  .object()
  .shape({
    agility: yup.object().shape({
      required: yup.bool(),
      compete: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      vigor: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
    }),
    administration: yup.object().shape({
      required: yup.bool(),
      humiliate: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
      eventPlanning: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      proofReading: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      multiTasking: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      recording: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      appointment: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
    }),
    empathy: yup.object().shape({
      required: yup.bool(),
      successful: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
      relate: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      desireToLearn: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      smallTalk: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      emotional: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
    }),
    creativeThinking: yup.object().shape({
      required: yup.bool(),
      routine: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
      list: yup
        .array()
        .of(
          yup.object({
            id: yup.string().required(),
            title: yup.string().required(),
          })
        )
        .when("required", {
          is: true,
          then: (schema) => schema.required("Required").min(1, "Required"),
        }),
    }),
    research: yup.object().shape({
      required: yup.bool(),
      resilience: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
      resilienceRating: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
    }),
    eloquence: yup.object().shape({
      required: yup.bool(),
      daily: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
      dailyOther: yup.string().when("daily", {
        is: (val) => val === "other",
        then: (schema) => schema.required("Required"),
      }),
      fear: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
    }),
    leadership: yup.object().shape({
      required: yup.bool(),
      experience: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
      overwhelm: yup
        .array()
        .of(yup.string().required())
        .when("experience", {
          is: (val) => val === "yes",
          then: (schema) => schema.required("Required").min(1).max(3),
        }),
      justice: yup.string().when("experience", {
        is: (val) => val === "yes",
        then: (schema) => schema.required("Required"),
      }),
      resources: yup.number().when("experience", {
        is: (val) => val === "no",
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      teach: yup.number().when("experience", {
        is: (val) => val === "no",
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
    }),
    persuasion: yup.object().shape({
      required: yup.bool(),
      listen: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
      stress: yup
        .array()
        .of(yup.string().required())
        .when("listen", {
          is: (val) => val === "true",
          then: (schema) =>
            schema
              .required("Required")
              .min(1, "Select at least one")
              .max(3, "Select at most 3"),
        }),
      task: yup.string().when("listen", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
    }),
    techCuriosity: yup.object().shape({
      required: yup.bool(),
      preference: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      tools: yup
        .array()
        .of(
          yup.object({
            tool: yup.string().required(),
            proficiency: yup.number().required().min(1).max(7),
          })
        )
        .when("required", {
          is: true,
          then: (schema) =>
            schema
              .required("Required")
              .max(5, "You can only add 5 tools")
              .length(5, "Please add 5 tools"),
        }),
    }),
    learningCuriosity: yup.object().shape({
      required: yup.bool(),
      breakAndFix: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      workings: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
    }),
    analyticalThinking: yup.object().shape({
      required: yup.bool(),
      statement: yup.string().when("required", {
        is: true,
        then: (schema) => schema.required("Required"),
      }),
      statementRating: yup.number().when("required", {
        is: true,
        then: (schema) => schema.required("Required").min(1, "Required"),
      }),
      list: yup
        .array()
        .of(
          yup.object({
            id: yup.string().required(),
            title: yup.string().required(),
          })
        )
        .when("required", {
          is: true,
          then: (schema) => schema.required("Required").min(1, "Required"),
        }),
    }),
  })
  .required();

interface PreviewSoftSkillsProps {
  submit: (data) => void;
  skills: string[];
}

const PreviewSoftSkillsUI: React.FC<PreviewSoftSkillsProps> = ({
  submit,
  skills,
}) => {
  const [tools, setTools] = useState({ tool: "", proficiency: 0 });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<PreviewSoftSkillData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "techCuriosity.tools",
  });

  useEffect(() => {
    skills.map((skill) => {
      switch (skill) {
        case "agility_work_ethic":
          setValue("agility.required", true);
          break;
        case "analytical_thinking":
          setValue("analyticalThinking.required", true);
          break;
        case "administration":
          setValue("administration.required", true);
          break;
        case "empathy":
          setValue("empathy.required", true);
          break;
        case "creative_thinking":
          setValue("creativeThinking.required", true);
          break;
        case "research":
          setValue("research.required", true);
          break;
        case "eloquence":
          setValue("eloquence.required", true);
          break;
        case "learning_curiosity":
          setValue("learningCuriosity.required", true);
          break;
        case "leadership_and_management":
          setValue("leadership.required", true);
          break;
        case "persuasion":
          setValue("persuasion.required", true);
          break;
        case "technology_curiosity":
          setValue("techCuriosity.required", true);
          break;
        default:
          break;
      }
    });
  }, [skills]);

  const onSubmit: SubmitHandler<PreviewSoftSkillData> = (data) => submit(data);

  return (
    <>
      <form>
        {/* Work Ethic */}
        {watch("agility.required") ? (
          <>
            <InputScale
              register={register}
              name={"agility.compete"}
              validatorMessage={errors.agility?.compete?.message}
              label="When it comes to working, you have a deep desire to compete"
              length={7}
              startAt={6}
              leftMost="Always true"
              rightMost="Always false"
            />
            <InputScale
              register={register}
              name={"agility.vigor"}
              validatorMessage={errors.agility?.vigor?.message}
              label="You always approach work with vigor and dexterity"
              length={7}
              startAt={6}
              leftMost="Always true"
              rightMost="Always false"
            />
          </>
        ) : (
          ""
        )}

        {/*Administration*/}
        {watch("administration.required") ? (
          <>
            <div className={styles.admin}>
              <p className={styles.question}>
                Rate each of the activities listed based on how good you are at
                it naturally.
              </p>
              <label className={styles.adminRating}>
                <span>a. Event Planning</span>
                <StarRating
                  count={7}
                  startAt={1}
                  value={watch("administration.eventPlanning") ?? 0}
                  onChange={(x) => setValue("administration.eventPlanning", x)}
                />
              </label>
              <label className={styles.adminRating}>
                <span>b. Proof Reading</span>
                <StarRating
                  count={7}
                  startAt={1}
                  value={watch("administration.proofReading") ?? 0}
                  onChange={(x) => setValue("administration.proofReading", x)}
                />
              </label>
              <label className={styles.adminRating}>
                <span>c. Multi-tasking</span>
                <StarRating
                  count={7}
                  startAt={1}
                  value={watch("administration.multiTasking") ?? 0}
                  onChange={(x) => setValue("administration.multiTasking", x)}
                />
              </label>
              <label className={styles.adminRating}>
                <span>d. Recording & tracking</span>
                <StarRating
                  count={7}
                  startAt={1}
                  value={watch("administration.recording") ?? 0}
                  onChange={(x) => setValue("administration.recording", x)}
                />
              </label>
              <label className={styles.adminRating}>
                <span>e. Appointment setting & follow up</span>
                <StarRating
                  count={7}
                  startAt={1}
                  value={watch("administration.appointment") ?? 0}
                  onChange={(x) => setValue("administration.appointment", x)}
                />
              </label>
            </div>
            <RadioInput
              register={register}
              name={"administration.humiliate"}
              validatorMessage={errors.administration?.humiliate?.message}
              label="Which of the following would humiliate or frustrate you the most?"
              options={AdministrationHumiliateOptions}
              type="radio"
            />
          </>
        ) : (
          ""
        )}

        {/* Empathy */}
        {watch("empathy.required") ? (
          <>
            <p className={styles.empathyTtl}>
              Rate the level accuracy for each of these statements
            </p>
            <InputScale
              register={register}
              name={"empathy.relate"}
              validatorMessage={errors.empathy?.relate?.message}
              label="You can easily relate to other people's circumstances."
              length={7}
              startAt={7}
              leftMost="Agree"
              rightMost="Disagree"
              parentClassName={styles.scaleWrap}
            />
            <InputScale
              register={register}
              name={"empathy.desireToLearn"}
              validatorMessage={errors.empathy?.desireToLearn?.message}
              label="You have a strong desire to learn more about what makes individuals tick."
              length={7}
              startAt={7}
              leftMost="Agree"
              rightMost="Disagree"
              parentClassName={styles.scaleWrap}
            />
            <InputScale
              register={register}
              name={"empathy.smallTalk"}
              validatorMessage={errors.empathy?.smallTalk?.message}
              label="You like small talk and discussions."
              length={7}
              startAt={7}
              leftMost="Agree"
              rightMost="Disagree"
              parentClassName={styles.scaleWrap}
            />
            <InputScale
              register={register}
              name={"empathy.emotional"}
              validatorMessage={errors.empathy?.emotional?.message}
              label="You have a strong emotional side."
              length={7}
              startAt={7}
              leftMost="Agree"
              rightMost="Disagree"
              parentClassName={styles.scaleWrap}
            />
            <RadioInput
              register={register}
              name={"empathy.successful"}
              validatorMessage={errors.empathy?.successful?.message}
              label="Which of these makes you a successful manager?"
              instruction="Select an option"
              options={SuccessfulManagerOptions}
              type="radio"
            />
          </>
        ) : (
          ""
        )}

        {/* Research */}
        {watch("research.required") ? (
          <>
            <p className={styles.researchQuestion}>
              Which of the following statements do you consider true about
              resilience at work and to what extent do you demonstrate this:
              (this could be tested in the interview stage){" "}
            </p>
            <RadioInput
              register={register}
              name={"research.resilience"}
              validatorMessage={errors.research?.resilience?.message}
              label="Resilience is"
              options={ResilienceOptions}
              type="radio"
            />
            <div className={styles.resilenceRating}>
              <label>
                Rate the extent to which you demonstrate this professionally
              </label>
              <StarRating
                count={7}
                startAt={1}
                value={watch("research.resilienceRating") ?? 0}
                onChange={(x) => setValue("research.resilienceRating", x)}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {/* Eloquence */}
        {watch("eloquence.required") ? (
          <>
            <RadioInput
              register={register}
              name={"eloquence.daily"}
              validatorMessage={errors.eloquence?.daily?.message}
              label="A day does not pass without you"
              options={EloquenceDailyOptions}
              type="radio"
            />
            {watch("eloquence.daily") === "2" && (
              <Input
                placeholder="Type here"
                value={watch("eloquence.dailyOther")}
                validatorMessage={errors.eloquence?.dailyOther?.message}
                name={"eloquence.dailyOther"}
                register={register}
                parentClassName={styles.otherInput}
              />
            )}
            <RadioInput
              register={register}
              name={"eloquence.fear"}
              validatorMessage={errors.eloquence?.fear?.message}
              label="Which of the following are you afraid of?"
              options={EloquenceFearOptions}
              type="radio"
            />
          </>
        ) : (
          ""
        )}

        {/* Leadership */}
        {watch("leadership.required") ? (
          <>
            <RadioInput
              register={register}
              name={"leadership.experience"}
              validatorMessage={errors.leadership?.experience?.message}
              label="Have you served in a leadership or management position before?"
              options={LeadershipExperienceOptions}
              type="radio"
            />

            {watch("leadership.experience") === "yes" ? (
              <>
                <RadioInput
                  register={register}
                  name={"leadership.overwhelm"}
                  validatorMessage={errors.leadership?.overwhelm?.message}
                  label="Which part of people management or leadership frequently overwhelms you?"
                  instruction="Select the crucial three."
                  options={LeadershipOverwhelmingOptions}
                  type="checkbox"
                  disabled={watch("leadership.overwhelm")?.length === 3}
                  checkValue={watch("leadership.overwhelm")}
                />
                <RadioInput
                  register={register}
                  name={"leadership.justice"}
                  validatorMessage={errors.leadership?.justice?.message}
                  label="Which of the following statements best aligns with your view of justice?"
                  options={LeadershipJusticeOptions}
                  type="radio"
                />
              </>
            ) : watch("leadership.experience") === "no" ? (
              <>
                <InputScale
                  register={register}
                  name={"leadership.resources"}
                  validatorMessage={errors.leadership?.resources?.message}
                  label="You may be able to come up with the ideas/initiatives, but finding the resources to put them into action may be tough."
                  length={7}
                  startAt={6}
                  leftMost="Always true"
                  rightMost="Always false"
                />
                <InputScale
                  register={register}
                  name={"leadership.teach"}
                  validatorMessage={errors.leadership?.teach?.message}
                  label="You'd be exhausted if you were put in a position to teach or show someone how to do a series of tasks every day for six months."
                  length={7}
                  startAt={6}
                  leftMost="Always true"
                  rightMost="Always false"
                />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {/* Persuasion */}
        {watch("persuasion.required") ? (
          <>
            <RadioInput
              register={register}
              name={"persuasion.listen"}
              validatorMessage={errors.persuasion?.listen?.message}
              label="To persuade you must listen. True or False?"
              options={PersuasionOptions}
              type="radio"
            />

            {watch("persuasion.listen") === "true" ? (
              <>
                <RadioInput
                  register={register}
                  name={"persuasion.stress"}
                  validatorMessage={errors.persuasion?.stress?.message}
                  label="Which of the following activities would you typically stress yourself about?"
                  instruction="Select 3"
                  options={PersuasionStressOptions}
                  type="checkbox"
                  disabled={watch("persuasion.stress")?.length === 3}
                  checkValue={watch("persuasion.stress")}
                />
                <RadioInput
                  register={register}
                  name={"persuasion.task"}
                  validatorMessage={errors.persuasion?.task?.message}
                  label="After many failed attempts by predecessors, you've been tasked with persuading a group of individuals to adopt a new way. Given 60 minutes to win them over, which of the following would you do first?"
                  options={PersuasionTaskOptions}
                  type="radio"
                />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {/* Learning Curiosity */}
        {watch("learningCuriosity.required") ? (
          <>
            <InputScale
              register={register}
              name={"learningCuriosity.breakAndFix"}
              validatorMessage={errors.learningCuriosity?.breakAndFix?.message}
              label="You disintegrate things and fix them back to satisfy your curiosity"
              length={7}
              startAt={6}
              leftMost="Always true"
              rightMost="Always false"
            />
            <InputScale
              register={register}
              name={"learningCuriosity.workings"}
              validatorMessage={errors.learningCuriosity?.workings?.message}
              label="You are generally inquisitive about how things work"
              length={7}
              startAt={6}
              leftMost="Always true"
              rightMost="Always false"
            />
          </>
        ) : (
          ""
        )}

        {/* Creative Thinking */}
        {watch("creativeThinking.required") ? (
          <>
            <InputOrder
              label={`Sagar Corp has been using a method for the past five years, and while
          it is effective, it is not scalable for their next stage of growth.
          Arrange your approach to improving this process in order of
          significance as a professional engaged to make things better.`}
              instruction="Drag and drop to rearrange your desired approach."
              list={CriticalThinkingList}
              onChange={(x) => setValue("creativeThinking.list", x)}
              error={errors.creativeThinking?.list?.message ?? ""}
            />
            <RadioInput
              register={register}
              name={"creativeThinking.routine"}
              validatorMessage={errors.creativeThinking?.routine?.message}
              label="Choose one activity that you are likely to do on a frequent basis based on your daily routine:"
              options={RoutineOptions}
              type="radio"
            />
          </>
        ) : (
          ""
        )}

        {/* Analytical Thinking */}
        {watch("analyticalThinking.required") ? (
          <>
            <RadioInput
              register={register}
              name={"analyticalThinking.statement"}
              validatorMessage={errors.analyticalThinking?.statement?.message}
              label="Which of these statements are most accurate about you?"
              instruction="Choose and rate how operationally relevant that sentence is to you."
              options={AnalyticalStatementOptions}
              type="radio"
              value={watch("analyticalThinking.statement")}
              rating={{
                count: 7,
                startAt: 1,
                value: watch("analyticalThinking.statementRating") ?? 0,
                onChange: (x) =>
                  setValue("analyticalThinking.statementRating", x),
              }}
            />
            <InputOrder
              label={`You've been presented with a situation that requires you to investigate and determine the source of the problem. Please rank the approach you would use to examine the matter in order of priority from the list below.`}
              instruction="Drag and drop to rearrange your desired approach."
              list={AnalyticalThinkingList}
              onChange={(x) => setValue("analyticalThinking.list", x)}
              error={errors.analyticalThinking?.list?.message ?? ""}
            />
          </>
        ) : (
          ""
        )}

        {/* Technical Curiosity */}
        {watch("techCuriosity.required") ? (
          <>
            <InputScale
              register={register}
              name={"techCuriosity.preference"}
              validatorMessage={errors.techCuriosity?.preference?.message}
              label="You would prefer using an app to quickly get work done than do it manually first."
              length={7}
              startAt={6}
              leftMost="Always true"
              rightMost="Always false"
            />
            <section className={styles.techCuriosity}>
              <p className={styles.question}>
                Input five (5) work and productivity tools you are most familiar
                with and rate on a scale of how proficiently you use them. (This
                may be tested in the interview stage)
              </p>
              <div className={styles.techCuriosity__input}>
                <Input
                  placeholder="Type here"
                  value={tools.tool}
                  validatorMessage={""}
                  name={"tool"}
                  register={register}
                  onChange={(e) =>
                    setTools((prev) => ({ ...prev, tool: e.target.value }))
                  }
                />
                <CustomSelect
                  onChange={(x) =>
                    setTools((prev) => ({ ...prev, proficiency: x.value }))
                  }
                  validatorMessage={""}
                  name={`proficiency`}
                  placeholder={"Rate your proficiency"}
                  options={ProficiencyOptions}
                  value={
                    ProficiencyOptions.find(
                      (item) => item.value === tools.proficiency
                    ) ?? initOptionType
                  }
                />
                <Button
                  disabled={tools.tool === "" || tools.proficiency <= 0}
                  type="fill"
                  onClick={() => {
                    append(tools);
                    setTools({ tool: "", proficiency: 0 });
                  }}
                >
                  Add
                </Button>
              </div>
              {fields.length > 0 ? (
                <section className={styles.techCuriosity__tools}>
                  <div className={styles.techCuriosity__toolHd}>
                    <div>Productivity Tool</div>
                    <div>Proficiency Level</div>
                  </div>
                  {fields.map((item, index) => (
                    <div key={item.id} className={styles.techCuriosity__tool}>
                      <div>{item.tool}</div>
                      <div>{item.proficiency}</div>
                      <TrashIcon role="button" onClick={() => remove(index)} />
                    </div>
                  ))}
                </section>
              ) : (
                ""
              )}
              {errors.techCuriosity?.tools?.message ? (
                <p className={styles.errorMessage}>
                  {errors.techCuriosity?.tools?.message}
                </p>
              ) : (
                ""
              )}
            </section>
          </>
        ) : (
          ""
        )}
      </form>
      <Button
        className={styles.nextBtn}
        type="fill"
        onClick={() => onSubmit(watch())}
      >
        Next
      </Button>
    </>
  );
};

export { PreviewSoftSkillsUI };
