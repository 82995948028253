import { OptionType } from "components";

export const howOptions: OptionType[] = [
  {
    label: "LinkedIn",
    value: "LinkedIn",
  },
  {
    label: "Instagram",
    value: "Instagram",
  },
  {
    label: "X (Twitter)",
    value: "Twitter",
  },
  {
    label: "Referral",
    value: "referral",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const frequencyOptions: OptionType[] = [
  {
    label: "1 - 5 hire annually",
    value: "1 - 5 hire annually",
  },
  {
    label: "6 - 15 hire annually",
    value: "6 - 15 hire annually",
  },
  {
    label: "16 - 30 hire annually",
    value: "16 - 30 hire annually",
  },
  {
    label: "30 - 50 hire annually",
    value: "30 - 50 hire annually",
  },
  {
    label: "50+ hire annually",
    value: "50+ hire annually",
  },
];

export const employeeOptions: OptionType[] = [
  {
    label: "1-15",
    value: "1-15",
  },
  {
    label: "16-50",
    value: "16-50",
  },
  {
    label: "51-100",
    value: "51-100",
  },
  {
    label: "100+",
    value: "100+",
  },
];
