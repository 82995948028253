import { AllPlansData, reduxAllPlansActions } from "../types";

const initalState: AllPlansData[] = [];
const allPlansReducer = (state = initalState, action: reduxAllPlansActions) => {
  switch (action.type) {
    case "UPDATE_ALL_PLANS":
      return action?.payload;
    default:
      return state;
  }
};

export default allPlansReducer;
