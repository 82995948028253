import { resendVerificationService } from "api";
import { Loader } from "components";
import { EmailVerificationPromptModal } from "features";
import { useApiRequest, useToast } from "hooks";
import { useMemo } from "react";

interface OnboardingProps {
  show: boolean;
  close: () => void;
  email: string;
}

const EmailVerificationPrompt: React.FC<OnboardingProps> = ({
  show,
  close,
  email,
}) => {
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const handleResend = () => run(resendVerificationService(email));

  useMemo(() => {
    if (response?.status === 200) {
      openToast({
        show: true,
        heading: `We have resent the verification mail to ${email}.`,
        text: "The email may take a few minutes to arrive",
        type: "success",
        timeOut: 2000,
      });
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to resend the verification mail, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <EmailVerificationPromptModal
        email={email}
        show={show}
        close={close}
        resend={handleResend}
      />
    </>
  );
};

export { EmailVerificationPrompt };
