import { EmptyTable, ProgressBar, ScoreBar } from "components";
import styles from "./styles.module.scss";
import {
  CloseIconCircleFIll,
  TickIconCircleFill,
  emptyAssessmentsImg,
} from "assets";
import { useState } from "react";
import { MetricOverview, MetricExpectations, MetricHeading } from "../components";

interface MotivatorsComparisonData {
  metric: string;
  response: string;
  result: string;
}

interface WorkMotivatorsData {
  parameter: string;
  description: string;
  pros: string[];
  cons: string[];
  match: boolean;
}

export interface MotivatorsResultData {
  expectation: string;
  title: string;
  description: string;
  pros: string[];
  cons: string[];
  match: boolean;
}

export interface WorkMotivatorsProps {
  workMotivators: MotivatorsResultData[] | undefined;
  score: number | undefined;
  isFullReport: boolean;
}

const WorkMotivators: React.FC<WorkMotivatorsProps> = ({
  isFullReport,
  workMotivators,
  score,
}) => {
  const [active, setActive] = useState(0);
  const [mobile, setMobile] = useState(window.innerWidth <= 900);

  const screenSizeUpdate = () => {
    setMobile(window.innerWidth <= 900);
  };

  window.addEventListener("resize", screenSizeUpdate);

  if (!workMotivators || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  const motivators: MotivatorsComparisonData[] = workMotivators.map(
    (motivator) => ({
      metric: motivator.expectation,
      response: motivator.title,
      result: motivator.match ? "Match" : "Conflict",
    })
  );

  const details: WorkMotivatorsData[] = workMotivators.map((motivator) => ({
    parameter: motivator.title,
    match: motivator.match,
    description: motivator.description,
    pros: motivator.pros,
    cons: motivator.cons,
  }));

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Work Motivators" /> : ""}
        <section className={styles.container}>
          <div className={styles.sec1}>
            <MetricOverview
              description={
                "Factors that drive an individual's work habits and how they affect professional engagement and performance."
              }
              score={score}
            />
            <div className={styles.sec1__expectation}>
              <p>Expectation metrics:</p>
              <MetricExpectations
                data={workMotivators.map((item) => ({
                  title: item.expectation,
                  isMatch: item.match,
                }))}
              />
            </div>
          </div>

          <div className={styles.qualities}>
            <div className={styles.qualities__nav}>
              {details.map((item, index) => (
                <span
                  role="button"
                  className={active === index ? styles.active : ""}
                  onClick={() => setActive(index)}
                >
                  {item.parameter}
                </span>
              ))}
            </div>
            {details.map((item, index) => (
              <div className={styles.qualities__item}>
                <p className={styles.qualities__item__ttl1}>
                  Candidate’s Response:
                  {item.match ? (
                    <span className={styles.qualities__item__ttl1__green}>
                      Match <TickIconCircleFill />
                    </span>
                  ) : (
                    <span className={styles.qualities__item__ttl1__red}>
                      Conflict <CloseIconCircleFIll />
                    </span>
                  )}
                </p>

                <p
                  className={`${styles.qualities__item__ttl2} ${
                    styles[
                      `qualities__item__ttl2--${item.match ? "green" : "red"}`
                    ]
                  }`}
                >
                  {item.parameter}{" "}
                </p>
                <div>
                  <p>{item.description}</p>
                </div>
                <div>
                  <p className={styles.qualities__item__listTtl}>Pros</p>
                  <ul>
                    {item.pros.map((val) => (
                      <li>{val}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <p className={styles.qualities__item__listTtl}>Cons </p>
                  <ul>
                    {item.cons.map((val) => (
                      <li>{val}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>
    </>
  );
};

export { WorkMotivators };
