import { Button, Input, Modal } from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useEffect } from "react";

interface StartData {
  email: string | undefined;
}

const initData: StartData = {
  email: "",
};

const schema = yup.object().shape({
  email: yup.string(),
});

interface PreviewStartAssessmentProps {
  show: boolean;
  close: () => void;
  submit: (email: string) => void;
  clear: boolean;
}

const PreviewStartAssessmentUI: React.FC<PreviewStartAssessmentProps> = ({
  show,
  close,
  submit,
  clear,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<StartData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  useEffect(() => {
    reset(initData);
  }, [clear]);

  const onSubmit: SubmitHandler<StartData> = (data) => submit(data.email ?? "");

  return (
    <>
      <Modal
        contentClassName={styles.modal}
        show={show}
        close={close}
        position={"centered"}
      >
        <h1 className={styles.ttl}>Start Assessment</h1>
        <p className={styles.txt}>
          Enter your email address to begin or resume an assessment. Please
          ensure that you have read and understood the instructions before
          commencing this assessment. Goodluck!
        </p>
        <form>
          <Input
            label="Email address"
            placeholder="e.g. johndoe@gmail.com"
            type="email"
            required
            validatorMessage={errors.email?.message}
            name="email"
            register={register}
            value={watch("email")}
            showRequired
            parentClassName={styles.inputWrap}
          />
          <Button
            className={styles.btn}
            type={"fill"}
            onClick={handleSubmit(onSubmit)}
          >
            Start
          </Button>
        </form>
      </Modal>
    </>
  );
};

export { PreviewStartAssessmentUI };
