import { Button, OptionType, StarRating } from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { skillOptions } from "utils/options";
import { useEffect } from "react";

interface SkillData extends OptionType {
  rating: number;
}

export interface SoftSkillData {
  skills: SkillData[];
}

const initData: SoftSkillData = {
  skills: [],
};

const schema = yup
  .object({
    skills: yup
      .array()
      .of(
        yup
          .object({
            label: yup.string().required("Required"),
            value: yup.string().required("Required"),
            rating: yup.number().required("Required").min(1, "Select a rating"),
          })
          .required()
      )
      .required("Required")
      .length(5, "Please select 5 qualities"),
  })
  .required();

interface SoftSkillsProps {
  skip: () => void;
  back: () => void;
  submit: (data: SoftSkillData) => void;
  softskill: SoftSkillData | undefined;
  title: string;
}

const SoftSkillsForm: React.FC<SoftSkillsProps> = ({
  back,
  skip,
  submit,
  softskill,
  title,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<SoftSkillData>({
    resolver: yupResolver(schema),
    defaultValues: softskill ?? initData,
  });

  useEffect(() => {
    reset(softskill);
  }, [softskill]);

  const isChecked = (option: OptionType) => {
    const value = [...watch("skills")];

    return value.find((item) => item.value === option.value)?.value
      ? true
      : false;
  };

  const handleAdd = (val: SkillData) => {
    const value = [...watch("skills")];

    const index = value.findIndex((object) => {
      return object.value === val.value;
    });

    if (index === -1) {
      const prevList = [...value];
      setValue("skills", [...prevList, val]);
    } else {
      handleRemove(val);
    }
  };

  const handleAddRating = (val: SkillData) => {
    const value = [...watch("skills")];

    const index = value.findIndex((object) => {
      return object.value === val.value;
    });

    if (index !== -1) {
      const prevList = [...value];
      prevList.splice(index, 1, val);
      setValue("skills", [...prevList]);
    }
  };

  const handleRemove = (val) => {
    const value = [...watch("skills")];

    const index = value.findIndex((object) => {
      return object.value === val.value;
    });
    const prevList = [...value];
    prevList.splice(index, 1);
    setValue("skills", [...prevList]);
  };
  const onSubmit: SubmitHandler<SoftSkillData> = (data) => submit(data);

  const isEmpty = JSON.stringify(watch()) === JSON.stringify(initData);

  useEffect(() => {
    if (isEmpty) localStorage.removeItem("soft-skillsIsFilled");
    else localStorage.setItem("soft-skillsIsFilled", "filled");
  }, [watch()]);

  return (
    <>
      <section className={styles.heading}>
        <h2 className={styles.ttl}>{title}</h2>
        <p className={styles.txt}>
          Select 5 soft competencies related to operational delivery and rate
          their importance.
        </p>
        <Button className={styles.skipBtn} type="outline" onClick={skip}>
          Skip
        </Button>
      </section>
      <form>
        <fieldset className={styles.skillSet}>
          {skillOptions.map((item, idx) => (
            <div
              onClick={() => {}}
              className={`${styles.skill} ${
                watch("skills").length >= 5 && !isChecked(item)
                  ? styles.disable
                  : ""
              } ${
                Object.keys(errors).length > 0 &&
                isChecked(item) &&
                watch("skills").find((skill) => item.value === skill.value)
                  ?.rating === 0
                  ? styles.red
                  : ""
              }`}
            >
              <input
                onChange={() => {
                  if (!(watch("skills").length >= 5 && !isChecked(item))) {
                    handleAdd({ ...item, rating: 0 });
                  }
                }}
                checked={isChecked(item)}
                type="checkbox"
              />
              <span className={styles.ttl}>{item.label}</span>
              <span>{item.description}</span>
              {isChecked(item) && (
                <StarRating
                  className={styles.ratingSec}
                  count={5}
                  startAt={1}
                  value={
                    watch(`skills`).find((skill) => skill.value === item.value)
                      ?.rating ?? 0
                  }
                  onChange={(x) => handleAddRating({ ...item, rating: x })}
                />
              )}
            </div>
          ))}
        </fieldset>
        <p className={styles.error}>{errors.skills?.message}</p>
      </form>
      <div className={styles.btnSec}>
        <Button type="outline" onClick={back}>
          Previous
        </Button>
        <Button type="fill" onClick={handleSubmit(onSubmit)}>
          Next
        </Button>
      </div>
    </>
  );
};

export { SoftSkillsForm };
