import { LogoWithText } from "assets";
import styles from "./styles.module.scss";
import { getTimeBreakdown } from "helpers";
import { useAppSelector } from "state/hooks";

interface Props {
  children: any;
  isOverview?: boolean;
  length: number;
  duration: number;
}

const PreviewAssessmentLayout: React.FC<Props> = ({
  children,
  isOverview,
  length,
  duration,
}) => {
  const { companyLogo, company } = useAppSelector((state) => state.user);
  const answered = parseInt(localStorage.getItem("answered") ?? "0");
  const breakdown = getTimeBreakdown(duration);

  return (
    <>
      <main className={styles.main}>
        <header className={styles.header}>
          <section className={styles.container}>
            {!companyLogo ? (
              <h1>{company}</h1>
            ) : (
              <>
                <img
                  className={styles.logo}
                  src={companyLogo}
                  alt="employer logo"
                />
                <h1>{company}</h1>
              </>
            )}
          </section>
        </header>
        {!isOverview && (
          <section className={`${styles.container} ${styles.info}`}>
            <div className={styles.timer}>
              <div>
                <span>{breakdown.hours}</span>
                <span>hours</span>
              </div>
              <div>
                <span>{Math.floor(breakdown.minutes)}</span>
                <span>mins</span>
              </div>
              <div>
                <span>{breakdown.seconds}</span>
                <span>secs</span>
              </div>
            </div>
            <div className={styles.progressWrap}>
              <div className={styles.progress}>
                <div
                  style={{
                    width: `${(answered / length) * 100}%`,
                  }}
                  className={styles.progress__bar}
                ></div>
                <div className={styles.progress__bar__white}></div>
              </div>
              <p>Your progress</p>
            </div>
            <p className={styles.count}>
              {answered} of {length}
            </p>
          </section>
        )}
        <section
          className={`${styles.container} ${styles.body} ${
            isOverview ? styles.overview : ""
          }`}
        >
          {children}
        </section>
        <footer className={`${styles.footer} ${styles.container}`}>
          <span>Enabled by</span> <LogoWithText />{" "}
        </footer>
      </main>
    </>
  );
};

export { PreviewAssessmentLayout };
