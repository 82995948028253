import {
  inviteCandidatesCSVService,
  inviteCandidatesRequestData,
  inviteCandidatesService,
} from "api";
import { InviteCandidateModal, Loader } from "components";
import { getError } from "helpers";
import { useToast, useApiRequest } from "hooks";
import { useMemo } from "react";

interface Props {
  show: boolean;
  close: () => void;
  title: string;
  text?: string;
  id: string;
  links?: {
    preview: string;
    candidate: string;
  };
}

const InviteCandidate: React.FC<Props> = ({
  show,
  close,
  title,
  text,
  links,
  id,
}) => {
  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const {
    run: runInvite,
    data: inviteResponse,
    requestStatus: inviteStatus,
    error: inviteError,
  } = useApiRequest({});
  const {
    run: runInviteCSV,
    data: inviteCSVResponse,
    requestStatus: inviteCSVStatus,
    error: inviteCSVError,
  } = useApiRequest({});

  const handleInvite = (data: inviteCandidatesRequestData[]) =>
    runInvite(inviteCandidatesService(id, data));

  const handleInviteCSV = (data: FormData) =>
    runInviteCSV(inviteCandidatesCSVService(id, data));

  useMemo(() => {
    if (inviteResponse?.status === 200) {
      openToast({
        show: true,
        heading: inviteResponse.data?.message ?? "Successfully sent invites!",
        text: "",
        type: "success",
        timeOut: 2000,
      });
      close();
    } else if (inviteError) {
      openToast({
        show: true,
        ...getError({
          error: inviteError,
          text: "Failed to send invites, please try again later",
          heading: "Sorry",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [inviteResponse, inviteError]);

  useMemo(() => {
    if (inviteCSVResponse?.status === 200) {
      openToast({
        show: true,
        heading: "Successfully sent invites!",
        text: "",
        type: "success",
        timeOut: 2000,
      });
      close();
    } else if (inviteCSVError) {
      openToast({
        show: true,
        ...getError({
          error: inviteCSVError,
          text: "Failed to send invites, please try again later",
          heading: "Sorry",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [inviteCSVResponse, inviteCSVError]);

  const showLoader = inviteStatus.isPending || inviteCSVStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <InviteCandidateModal
        title={title}
        show={show}
        close={close}
        links={links}
        text={text}
        submit={handleInvite}
        submitCSV={handleInviteCSV}
      />
    </>
  );
};

export { InviteCandidate };
