import {
  CreateAssessmentHeader,
  CustomQuestionFormData,
  DefaultQuestionFormData,
  ExpectationData,
  PersonalityData,
  SoftSkillData,
  TabData,
  TitleData,
  WorkCultureData,
} from "features";
import { useEffect, useState } from "react";
import { AssessmentTitle } from "./title";
import { BasicExpectation } from "./basicExpectation";
import { WorkCulture } from "./workCulture";
import { Personality } from "./personality";
import { SoftSkills } from "./softSkills";
import { DomainKnowledge } from "./domainKnowledge";
import { ActivateAssessment } from "./activate";
import { Instruction } from "./instruction";
import { Navbar } from "layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetchAssessment } from "hooks";
import { ConfirmationModal, Loader } from "components";
import { useAppSelector } from "state/hooks";
import { Routes } from "router";

const defaultInstruction = `We appreciate your interest in this position and would like to assess your abilities in alignment with our requirements. This assessment is designed to evaluate your skills, knowledge, and suitability for a specific role within our organization.\nPlease read the instructions carefully before proceeding.`;

const CreateAssessment = () => {
  const [tab, setTab] = useState("title");
  const [skips, setSkips] = useState<string[]>([]);
  const [title, setTitle] = useState<TitleData | undefined>();
  const [expectations, setExpectations] = useState<
    ExpectationData | undefined
  >();
  const [culture, setCulture] = useState<WorkCultureData | undefined>();
  const [personality, setPersonality] = useState<PersonalityData | undefined>();
  const [softSkill, setSoftSkill] = useState<SoftSkillData | undefined>();
  const [customDomain, setCustomDomain] = useState<
    CustomQuestionFormData | undefined
  >();
  const [defaultDomain, setDefaultDomain] = useState<
    DefaultQuestionFormData | undefined
  >();
  const [instruction, setInstruction] = useState(defaultInstruction);
  const [areas, setAreas] = useState<string[]>([]);
  const params = useParams();
  const {
    loading,
    expectation,
    culture: workCulture,
    personality: assessmentPersonality,
    softskills,
    domain,
    info,
  } = useFetchAssessment(params.id);
  const { companyLogo } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const localId = localStorage.getItem("assessmentId");

    if (params.id) {
      localStorage.setItem("assessmentId", params.id);
      expectation.fetch();
      workCulture.fetch();
      assessmentPersonality.fetch();
      softskills.fetch();
      domain.fetch();
      info.fetch();
    } else if (localId && !pathname.includes("employer/assessments/edit")) {
      navigate(Routes.employerEditAssessmentID(localId));
      localStorage.removeItem("assessmentId");
    }
  }, [params.id]);

  useEffect(() => {
    if (loading) return;
    let metrics: string[] = [];
    if (expectation.data) {
      setExpectations(expectation.data);
      metrics.push("Basic expectations");
    }
    if (workCulture.data) {
      setCulture(workCulture.data);
      metrics.push("Work culture");
    }
    if (assessmentPersonality.data) {
      setPersonality(assessmentPersonality.data);
      metrics.push("Personality");
    }
    if (softskills.data) {
      setSoftSkill(softskills.data);
      metrics.push("Soft skills");
    }
    info.data && setTitle({ title: info.data.title });
    info.data &&
      setInstruction(
        info.data.instruction !== ""
          ? info.data.instruction
          : defaultInstruction
      );
    if (domain.customQ || domain.defaultQ) {
      if (domain.isCustom) {
        setCustomDomain(domain.customQ);
        setDefaultDomain(undefined);
      } else {
        setDefaultDomain(domain.defaultQ);
        setCustomDomain(undefined);
      }
      metrics.push("Domain knowledge");
    }

    setAreas(metrics);
  }, [loading]);

  const tabs: TabData[] = [
    {
      label: "Title",
      value: "title",
      isCompleted: title !== undefined,
    },
    {
      label: "Basic expectations",
      value: "expectations",
      isCompleted: expectations !== undefined,
    },
    {
      label: "Work culture",
      value: "work-culture",
      isCompleted: culture !== undefined,
    },
    {
      label: "Personality",
      value: "personality",
      isCompleted: personality !== undefined,
    },
    {
      label: "Soft skills",
      value: "soft-skills",
      isCompleted: softSkill !== undefined,
    },
    {
      label: "Domain knowledge",
      value: "domain",
      isCompleted: customDomain !== undefined || defaultDomain !== undefined,
    },
    {
      label: "Edit instruction",
      value: "preview",
      isCompleted: instruction !== defaultInstruction,
    },
    {
      label: "Activate Assessment",
      value: "activate",
      isCompleted: false,
    },
  ];

  const submitTitle = (data) => {
    setTitle(data);
    setTab("expectations");
  };

  const updateAreas = (metric) => {
    setAreas(() => {
      const newList = areas.find((item) => item === metric)
        ? areas
        : [...areas, metric];
      return newList;
    });
  };

  const submitExpectations = (data) => {
    setExpectations(data);
    setTab("work-culture");
    handleRemoveSkip("expectations", "Basic expectations");
    localStorage.removeItem("expectationsIsFilled");

    const metricKey = "Basic expectations";
    updateAreas(metricKey);
  };

  const submitWorkCulture = (data) => {
    setCulture(data);
    setTab("personality");
    handleRemoveSkip("work-culture", "Work culture");
    localStorage.removeItem("work-cultureIsFilled");

    const metricKey = "Work culture";
    updateAreas(metricKey);
  };

  const submitPersonality = (data) => {
    setPersonality(data);
    setTab("soft-skills");
    handleRemoveSkip("personality", "Personality");
    localStorage.removeItem("personalityIsFilled");

    const metricKey = "Personality";
    updateAreas(metricKey);
  };

  const submitSoftSkill = (data) => {
    setSoftSkill(data);
    setTab("domain");
    handleRemoveSkip("soft-skills", "Soft skills");
    localStorage.removeItem("soft-skillsIsFilled");

    const metricKey = "Soft skills";
    updateAreas(metricKey);
  };

  const submitDefaultDomain = (data) => {
    setDefaultDomain(data);
    setTab("preview");
    handleRemoveSkip("domain", "Domain knowledge");
    localStorage.removeItem("domainIsFilled");

    const metricKey = "Domain knowledge";

    if (!areas.includes(metricKey)) {
      params.id && domain.fetch();
      updateAreas(metricKey);
    }
  };

  const submitCustomDomain = (data) => {
    setCustomDomain(data);
    setTab("preview");
    handleRemoveSkip("domain", "Domain knowledge");
    localStorage.removeItem("domainIsFilled");

    const metricKey = "Domain knowledge";

    if (!areas.includes(metricKey)) {
      params.id && domain.fetch();
      updateAreas(metricKey);
    }
  };

  const submitInstruction = (data) => {
    setTitle({ title: data.title });
    setInstruction(data.instruction);
    setTab("activate");
  };

  const handleAddSkip = (val: string) => {
    const index = skips.findIndex((object) => {
      return object === val;
    });

    if (index === -1) {
      const prevList = [...skips];
      setSkips([val, ...prevList]);
    }
  };

  const handleRemoveSkip = (val, area) => {
    // Add to skip list
    const index = skips.findIndex((object) => {
      return object === val;
    });
    const prevList = [...skips];
    prevList.splice(index, 1);
    setSkips([...prevList]);

    // Remove from selected area
    const indexA = areas.findIndex((object) => {
      return object === val;
    });
    const prevListA = [...areas];
    prevListA.splice(indexA, 1);
    setAreas([...prevListA]);
  };

  const handleSkip = ({
    skippedTab,
    newTab,
    clearData,
  }: {
    skippedTab: string;
    newTab: string;
    clearData: (val: any) => void;
  }) => {
    clearData(undefined);
    handleAddSkip(skippedTab);
    setTab(newTab);
  };

  const [prompt, setPrompt] = useState({
    show: false,
    metricToSkip: "",
    nextMetric: "",
  });

  const skipBasicExpectation = () =>
    handleSkip({
      skippedTab: "expectations",
      newTab: "work-culture",
      clearData: setExpectations,
    });

  const continuePrompt = () => {
    setTab(prompt.nextMetric);
    localStorage.removeItem(`${prompt.metricToSkip}IsFilled`);
  };

  useEffect(() => {
    localStorage.removeItem("expectationsIsFilled");
    localStorage.removeItem("work-cultureIsFilled");
    localStorage.removeItem("personalityIsFilled");
    localStorage.removeItem("soft-skillsIsFilled");
    localStorage.removeItem("domainIsFilled");
  }, []);

  const tabAreas: string[] = areas.map((item) =>
    item === "Basic expectations"
      ? "expectations"
      : item === "Work culture"
      ? "work-culture"
      : item === "Soft skills"
      ? "soft-skills"
      : item === "Domain knowledge"
      ? "domain"
      : item === "Personality"
      ? "personality"
      : ""
  );

  const handleUpdateCompletedMetrics = () => {
    setAreas((prev) => prev.filter((item) => item !== "Domain knowledge"));
  };

  return (
    <>
      <Loader loading={loading} />
      <Navbar title={params.id ? "Edit Assessment" : "Create Assessments"} />
      <ConfirmationModal
        show={prompt.show}
        close={() =>
          setPrompt({ show: false, metricToSkip: "", nextMetric: "" })
        }
        handleContinue={continuePrompt}
        title={"Clear Responses"}
        text={`Are you sure you want to leave this tab? 
        Your responses for this metric have not been saved and will be lost.`}
      />
      <CreateAssessmentHeader
        tabs={tabs}
        skips={skips}
        handleChangeTab={(newTab) => {
          if (
            localStorage.getItem(`${tab}IsFilled`) &&
            !tabAreas.includes(tab)
          ) {
            setPrompt({ show: true, metricToSkip: tab, nextMetric: newTab });
          } else {
            setTab(newTab);
            localStorage.removeItem(`${tab}IsFilled`);
          }
        }}
        tab={tab}
        disableTabs={!title}
        isEdit={params.id !== undefined}
      >
        {tab === "title" ? (
          <AssessmentTitle title={title} callback={submitTitle} />
        ) : tab === "expectations" ? (
          <BasicExpectation
            expectation={expectations}
            callback={submitExpectations}
            back={() => setTab("title")}
            skip={() => {
              skipBasicExpectation();
            }}
            title={title?.title ?? ""}
          />
        ) : tab === "work-culture" ? (
          <WorkCulture
            culture={culture}
            callback={submitWorkCulture}
            back={() => setTab("expectations")}
            skip={() => {
              handleSkip({
                skippedTab: "work-culture",
                newTab: "personality",
                clearData: setCulture,
              });
            }}
            title={title?.title ?? ""}
          />
        ) : tab === "personality" ? (
          <Personality
            personality={personality}
            callback={submitPersonality}
            back={() => setTab("work-culture")}
            skip={() => {
              handleSkip({
                skippedTab: "personality",
                newTab: "soft-skills",
                clearData: setPersonality,
              });
            }}
            title={title?.title ?? ""}
          />
        ) : tab === "soft-skills" ? (
          <SoftSkills
            callback={submitSoftSkill}
            back={() => setTab("personality")}
            skip={() => {
              handleSkip({
                skippedTab: "soft-skills",
                newTab: "domain",
                clearData: setSoftSkill,
              });
            }}
            softskill={softSkill}
            title={title?.title ?? ""}
          />
        ) : tab === "domain" ? (
          <DomainKnowledge
            callbackCustom={submitCustomDomain}
            callbackDefault={submitDefaultDomain}
            back={() => setTab("soft-skills")}
            skip={() => {
              handleSkip({
                skippedTab: "domain",
                newTab: "preview",
                clearData: customDomain ? setCustomDomain : setDefaultDomain,
              });
            }}
            customQ={customDomain}
            defaultQ={defaultDomain}
            title={title?.title ?? ""}
            handleUpdateCompletedMetrics={handleUpdateCompletedMetrics}
          />
        ) : tab === "activate" ? (
          <ActivateAssessment
            title={title?.title ?? ""}
            back={() => setTab("preview")}
          />
        ) : tab === "preview" ? (
          <Instruction
            areas={areas}
            back={() => setTab("domain")}
            callback={submitInstruction}
            instruction={{
              title: title?.title ?? "",
              instruction,
            }}
            logo={companyLogo}
          />
        ) : (
          ""
        )}
      </CreateAssessmentHeader>
    </>
  );
};

export { CreateAssessment };
