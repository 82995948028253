import {
  ArrowRightIcon,
  PuzzleImg,
  overviewMockup,
  overviewMockup2,
} from "assets";
import styles from "./styles.module.scss";
import { Routes } from "router";
import { Link, useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className={styles.heroBg}>
        <PuzzleImg
          data-aos-duration="1000"
          data-aos="fade-down"
          className={styles.heroBg__img}
        />
        <div className={`container ${styles.hero}`}>
          <div className={styles.hero__content}>
            <h1 className={styles.hero__ttl}>
              Spot high quality talent, <span> faster.</span>
            </h1>
            <p className={styles.hero__txt}>
              Precision, is an AI filtering assessment empowering companies,
              talent agencies, recruiters and hiring teams filter for candidates
              with higher propensity to deliver results.
            </p>
            <button
              onClick={() => navigate(Routes.signup)}
              className={styles.hero__btn}
            >
              Start for free
            </button>
            <p
              data-aos-duration="1200"
              data-aos="slide-up"
              className={styles.hero__contact}
            >
              Experience Precision{" "}
              <Link to={Routes.demo}>
                Request demo <ArrowRightIcon />{" "}
              </Link>
            </p>
          </div>

          <picture>
            <source
              className={styles.hero__img}
              media="(min-width: 400px)"
              srcSet={overviewMockup}
            />
            <img
              data-aos="zoom-in"
              className={styles.hero__img}
              src={overviewMockup2}
              alt=""
            />
          </picture>
        </div>
      </section>
    </>
  );
};
export { HeroSection };
