import * as React from "react";
import styles from "./styles.module.scss";
import { UseFormRegister } from "react-hook-form";
import { OptionType } from "../select";
import { StarRating } from "components";

interface InputProps {
  dataTestID?: string;
  className?: string;
  parentClassName?: string;
  name: string;
  type: "radio" | "checkbox";
  id?: string;
  validatorMessage: string | undefined;
  label?: string;
  instruction?: string;
  register: UseFormRegister<any>;
  value?: string;
  checkValue?: string[];
  showRequired?: boolean;
  disabled?: boolean;
  options: OptionType[];
  rating?: {
    value: number;
    onChange: (x: number) => void;
    count: number;
    startAt: number;
  };
}

const RadioInput: React.FC<InputProps> = ({
  dataTestID,
  className,
  parentClassName,
  name,
  id,
  validatorMessage,
  label,
  register,
  value,
  showRequired,
  options,
  type,
  disabled,
  checkValue,
  rating,
  instruction,
}) => {
  return (
    <div
      className={`${styles.inputWrapper} ${parentClassName} ${
        validatorMessage ? styles.error : ""
      }`}
    >
      {label && (
        <p className={styles.question}>
          {label}
          {showRequired ? <span className={styles.req}>*</span> : ""}
          {instruction ? (
            <p className={styles.instruction}>{instruction}</p>
          ) : (
            ""
          )}
        </p>
      )}

      {options.map((option, index) => (
        <label key={index} className={styles.label} htmlFor={option.label}>
          <div className={styles.optionWrap}>
            <input
              data-testid={dataTestID}
              className={`${styles.input}`}
              type={type}
              id={option.label}
              {...register(name)}
              value={option.value}
              disabled={
                disabled && !checkValue?.find((item) => item === option.value)
              }
            />
            <span>{option.label}</span>
          </div>
          {rating && value === option.value && (
            <StarRating {...rating} className={styles.ratingSec} />
          )}
        </label>
      ))}

      {validatorMessage && (
        <small className={styles.message}>{validatorMessage}</small>
      )}
    </div>
  );
};

export { RadioInput };
