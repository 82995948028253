import { ToastData, updateToast } from "state";
import { useAppDispatch } from "state/hooks";

interface HookData extends ToastData {
  timeOut?: number;
  callback?: () => void;
}

const useToast = () => {
  const dispatch = useAppDispatch();

  const openToast = (data: HookData) => {
    const { show, heading, text, type, timeOut = 3000, callback } = data;
    dispatch(
      updateToast({
        show,
        heading,
        text,
        type,
      })
    );

    setTimeout(() => {
      dispatch(
        updateToast({
          show: false,
          heading: "",
          text: "",
          type: "info",
        })
      );

      callback && callback();
    }, timeOut);
  };

  return { openToast };
};

export { useToast };
