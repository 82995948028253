import {
  Button,
  CustomPhoneInput,
  CustomSelect,
  Input,
  initOptionType,
  optionTypeSchema,
} from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  EmploymentTypeOptions,
  ExperienceEnvOptions,
  ExperienceLevelOptions,
  ExperienceYearOptions,
} from "utils/options";
import { PdfIcon } from "assets";

interface OptionTypeOptional {
  label?: string;
  value?: any;
}

export interface PreviewBasicFitData {
  name: string | undefined;
  portfolio: string | undefined;
  number: {
    code?: string;
    number?: string;
    value?: string;
  };
  resume: FileList | any;
  isBasicFit: boolean | undefined;
  location: string | undefined;
  experienceLevel: OptionTypeOptional;
  experienceYears: OptionTypeOptional;
  employmentType: OptionTypeOptional;
  salaryExpectation: string | undefined;
  workEnvironment: OptionTypeOptional;
}

const initData: PreviewBasicFitData = {
  name: "",
  portfolio: "",
  number: { code: "", number: "", value: "" },
  resume: null,
  isBasicFit: false,
  location: "",
  experienceLevel: initOptionType,
  experienceYears: initOptionType,
  employmentType: initOptionType,
  salaryExpectation: "",
  workEnvironment: initOptionType,
};

const schema = yup.object().shape({
  name: yup.string(),
  portfolio: yup.string(),
  number: yup.object({
    code: yup.string(),
    number: yup.string(),
    value: yup.string(),
  }),
  isBasicFit: yup.boolean(),
  location: yup.string(),
  resume: yup.mixed().nullable(),
  experienceLevel: yup.object({ label: yup.string(), value: yup.string() }),
  experienceYears: yup.object({ label: yup.string(), value: yup.string() }),
  employmentType: yup.object({ label: yup.string(), value: yup.string() }),
  salaryExpectation: yup.string(),
  workEnvironment: yup.object({ label: yup.string(), value: yup.string() }),
});

interface PreviewBasicFitProps {
  submit: (data: PreviewBasicFitData) => void;
  hasBasicExpectation: boolean
}
const PreviewBasicFitUI: React.FC<PreviewBasicFitProps> = ({
  submit,hasBasicExpectation
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<PreviewBasicFitData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const onSubmit: SubmitHandler<PreviewBasicFitData> = (data) => {
    submit(data);
  };

  const resume: File = watch("resume") ? watch("resume")[0] : null;

  return (
    <>
      <form className={styles.form}>
        <Input
          label="Full name"
          placeholder="e.g. John Doe"
          type="text"
          required
          validatorMessage={errors.name?.message}
          name="name"
          register={register}
          value={watch("name")}
          showRequired
          parentClassName={styles.inputWrap}
        />
        <div className={styles.phoneWrap}>
          <CustomPhoneInput
            label="Phone number"
            placeholder="Your phone number"
            validatorMessage={errors.number?.value?.message?.toString() ?? ""}
            onChange={({ code, number, value }) =>
              setValue("number", { code, number, value })
            }
            showRequired
            value={watch("number.value")}
            parentClassName={styles.inputWrap}
          />
          <p className={styles.phoneTxt}>
            This may be used to contact you if you need assistance
          </p>
        </div>
        {hasBasicExpectation ? (
          <>
            <Input
              label="Location"
              placeholder="e.g. Lagos, Nigeria"
              type="text"
              required
              validatorMessage={errors.location?.message}
              name="location"
              register={register}
              value={watch("location")}
              parentClassName={styles.inputWrap}
            />
            <CustomSelect
              label="Experience Level"
              placeholder="Select the experience level"
              options={ExperienceLevelOptions}
              onChange={(val) => setValue("experienceLevel", val)}
              validatorMessage={
                errors.experienceLevel?.value?.message?.toString() ?? ""
              }
              name={"experienceLevel"}
              value={{
                label: watch("experienceLevel").label ?? "",
                value: watch("experienceLevel").value ?? "",
              }}
              parentClassName={styles.inputWrap}
            />
            <CustomSelect
              label="Years of Experience"
              placeholder="Select years of experience"
              options={ExperienceYearOptions}
              onChange={(val) => setValue("experienceYears", val)}
              validatorMessage={
                errors.experienceYears?.value?.message?.toString() ?? ""
              }
              name={"experienceYears"}
              value={{
                label: watch("experienceYears").label ?? "",
                value: watch("experienceYears").value ?? "",
              }}
              parentClassName={styles.inputWrap}
            />
          </>
        ) : (
          ""
        )}
        <Input
          label="Portfolio/LinkedIn or relevant link"
          placeholder="Input link here"
          type="url"
          required
          validatorMessage={errors.portfolio?.message}
          name="portfolio"
          register={register}
          value={watch("portfolio")}
          showRequired
          parentClassName={styles.inputWrap}
        />
        {watch("isBasicFit") ? (
          <>
            <CustomSelect
              label="Employment Type"
              placeholder="Select the employment type"
              options={EmploymentTypeOptions}
              onChange={(val) => setValue("employmentType", val)}
              validatorMessage={
                errors.employmentType?.value?.message?.toString() ?? ""
              }
              name={"employmentType"}
              value={{
                label: watch("employmentType.label") ?? "",
                value: watch("employmentType.value") ?? "",
              }}
              parentClassName={styles.inputWrap}
            />
            <Input
              label="Salary Expectations"
              placeholder="Enter your salary expectations"
              type="number"
              required
              validatorMessage={errors.salaryExpectation?.message}
              name="salaryExpectation"
              register={register}
              value={watch("salaryExpectation")}
              showRequired
              parentClassName={styles.inputWrap}
            />
            <CustomSelect
              label="Based on your previous employment history, what types of work environments have you experienced?"
              placeholder="Select the employment type"
              options={ExperienceEnvOptions}
              onChange={(val) => setValue("workEnvironment", val)}
              validatorMessage={
                errors.workEnvironment?.value?.message?.toString() ?? ""
              }
              name={"workEnvironment"}
              value={{
                label: watch("workEnvironment.label") ?? "",
                value: watch("workEnvironment.value") ?? "",
              }}
              parentClassName={styles.inputWrap}
            />
          </>
        ) : (
          ""
        )}
        <div
          className={`${styles.fileWrap} ${resume ? styles.uploaded : ""} ${
            errors.resume?.message ? styles.error : ""
          }`}
        >
          <p className={styles.label}>Upload your resume/CV</p>
          <label htmlFor="resume">
            <p>
              Click to <span>{resume ? "Change" : "Upload"}</span>
            </p>
            {!resume && <p className={styles.limit}>Max 2MB</p>}
            {resume && (
              <div className={styles.file}>
                <PdfIcon /> <span>{resume.name}</span>
              </div>
            )}
            <Input
              id="resume"
              label=""
              placeholder=""
              type="file"
              required
              validatorMessage={errors.resume?.message?.toString()}
              name="resume"
              register={register}
              accept=".pdf"
              showRequired
              parentClassName={styles.fileInput}
            />
          </label>
          <p className={styles.errorMsg}>
            {errors.resume?.message?.toString()}
          </p>
        </div>
      </form>
      <Button
        className={styles.nextBtn}
        type="fill"
        onClick={handleSubmit(onSubmit)}
      >
        Next
      </Button>
    </>
  );
};

export { PreviewBasicFitUI };
