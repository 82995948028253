import { activateAssessmentRequestData, activateAssessmentService } from "api";
import { ActivateAssessmentModal, ConfirmationModal, Loader } from "components";
import { ActivateFormData, ActivateAssessmentForm } from "features";
import { getError } from "helpers";
import { useToast, useApiRequest, useFetchPlan } from "hooks";
import { InviteCandidate } from "pages";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import timezones from "timezones-list";

interface ActivateAssessmentProps {
  back: () => void;
  title: string;
}

const ActivateAssessment: React.FC<ActivateAssessmentProps> = ({
  back,
  title,
}) => {
  const [showActivate, setShowActivate] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");
  const [data, setData] = useState<activateAssessmentRequestData>({
    assessment: 0,
    start_date: "",
    end_date: "",
  });

  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();
  const { getPlan } = useFetchPlan();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const getTimeZone = (zone) =>
    timezones.find((item) => item.label === zone)?.utc ?? "";

  const handleConfirm = (data: ActivateFormData) => {
    setConfirm(true);
    setData({
      assessment: id,
      start_date: `${data.start.date}T${data.start.time}${getTimeZone(
        data.timeZone.value
      )}`,
      end_date: `${data.end.date}T${data.end.time}${getTimeZone(
        data.timeZone.value
      )}`,
    });
  };

  const onSubmit = () => {
    setConfirm(false);
    run(activateAssessmentService(data));
  };

  const assessment = useMemo<{ id: string; _id: string }>(() => {
    if (response?.status === 200) {
      setShowActivate(true);
      localStorage.removeItem("assessmentId");
      getPlan();
      return {
        id: response.data.data.assessment_id,
        _id: response.data.data.id,
      };
    } else if (error) {
      openToast({
        show: true,
        ...getError({
          error,
          heading: "Sorry",
          text: "Failed to activate assessment, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
    return { id: "", _id: "" };
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  const candidateLink = `${window.location.origin}${Routes.assessmentOverview(
    assessment.id
  )}`;
  const previewLink = `${
    window.location.origin
  }${Routes.employerPreviewAssessment(assessment._id)}`;

  return (
    <>
      <Loader loading={showLoader} />
      <InviteCandidate
        show={showActivate}
        title="Invite Candidates and Preview Assessment"
        text="Copy the links below or invite candidates to your assessments"
        links={{
          preview: previewLink,
          candidate: candidateLink,
        }}
        id={assessment._id}
        close={() => {
          setShowActivate(false);
          navigate(Routes.employerAssessments);
        }}
      />
      <ConfirmationModal
        show={confirm}
        close={() => setConfirm(false)}
        title="Publish Assessment"
        text="Are you sure you want to publish this assessment?"
        handleContinue={onSubmit}
      />
      <ActivateAssessmentForm
        submit={handleConfirm}
        back={back}
        title={title}
      />
    </>
  );
};
export { ActivateAssessment };
