import { EmptyTable, ProgressBar, ScoreBar } from "components";
import styles from "./styles.module.scss";
import {
  ChevronRightIcon,
  CloseIconCircleFIll,
  TickIconCircleFill,
  emptyAssessmentsImg,
} from "assets";
import React, { useState } from "react";
import {
  MetricOverview,
  MetricLegend,
  MetricExpectations,
  MetricHeading,
} from "../components";

export interface WorkQualitiesData {
  title: string;
  characteristics: string[];
  behaviors: string[];
  match: boolean;
}

export interface WorkQualitiesProps {
  workQualities: WorkQualitiesData[] | undefined;
  score: number | undefined;
  isFullReport: boolean;
  expectation: { title: string; isMatch: boolean }[];
}

const WorkQualities: React.FC<WorkQualitiesProps> = ({
  isFullReport,
  score,
  workQualities,
  expectation,
}) => {
  if (!workQualities || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );
  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Workplace Qualities" /> : ""}
        <section className={styles.container}>
          <div className={styles.sec1}>
            <MetricOverview
              description={
                "Individuals' traits and attributes that contribute to a positive and thriving work environment."
              }
              score={score}
            />
            <div className={styles.sec1__expectation}>
              <p>Expectation metrics:</p>
              <MetricExpectations data={expectation} />
            </div>
          </div>
          <div>
            {workQualities.map((quality) => (
              <Quality {...quality} />
            ))}
          </div>
        </section>
      </section>
    </>
  );
};

const Quality: React.FC<WorkQualitiesData> = ({
  match,
  characteristics,
  behaviors,
  title,
}) => {
  const [show, setShow] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 600 ? true : false);

  const screenSizeUpdate = () => {
    if (window.innerWidth <= 600) {
      setMobile(true);
    } else if (window.innerWidth > 600) {
      setMobile(false);
    }
  };

  window.addEventListener("resize", screenSizeUpdate);

  return (
    <>
      <p className={styles.ttl}>
        {title}
        <span className={match ? styles.green : styles.red}>
          {match ? (
            <>
              Match <TickIconCircleFill />{" "}
            </>
          ) : (
            <>
              Conflict <CloseIconCircleFIll />
            </>
          )}

          <ChevronRightIcon
            className={`${styles.chevron} ${show ? styles.rotate : ""}`}
            role="button"
            onClick={() => setShow((prev) => !prev)}
          />
        </span>
      </p>
      {show || !mobile ? (
        <div className={styles.qualities}>
          <div className={styles.qualities__item}>
            <p className={styles.qualities__item__ttl}>Characteristics </p>
            <ul>
              {characteristics.map((val) => (
                <li>{val}</li>
              ))}
            </ul>
          </div>
          <div className={styles.qualities__item}>
            <p className={styles.qualities__item__ttl}>Behavior at work </p>
            <ul>
              {behaviors.map((val) => (
                <li>{val}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export { WorkQualities };
