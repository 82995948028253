import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import { Input } from "components/form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { dollarPricing } from "utils/pricing";

interface TopupData {
  credits: string;
}

const schema = yup
  .object({
    credits: yup
      .string()
      .required("Required")
      .matches(/\d/, "Enter a valid number"),
  })
  .required();

interface Props {
  show: boolean;
  close: () => void;
  handleBuy: (credits: number) => void;
}

const TopupAutoScoringCreditsModal: React.FC<Props> = ({
  show,
  close,
  handleBuy,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<TopupData>({
    resolver: yupResolver(schema),
    defaultValues: { credits: undefined },
  });

  const onSubmit: SubmitHandler<TopupData> = (data) => {
    handleBuy(parseInt(data.credits));
  };

  const credits = watch("credits");

  const amount = dollarPricing.costPerCredit * parseInt(credits);
  return (
    <>
      <Modal contentClassName={styles.modal} show={show} close={close}>
        <h1 className={styles.ttl}>Top-up Auto Scoring Credits</h1>
        <p className={styles.txt1}>
          Get more credits, without subscribing for a new plan within your
          active subscription.
        </p>
        <p className={styles.txt2}>${dollarPricing.costPerCredit} per credit</p>
        <Input
          label="How many more credits do you want?"
          placeholder={"Enter the number of credits"}
          name={"credits"}
          validatorMessage={errors.credits?.message}
          register={register}
          type="number"
        />
        <p className={styles.txt3}>${credits ? amount.toFixed(2) : 0}</p>
        <div className={styles.btnSec}>
          <button className={styles.btnSec__close} onClick={close}>
            Cancel
          </button>
          <button
            className={styles.btnSec__upgrade}
            onClick={handleSubmit(onSubmit)}
          >
            Buy Now
          </button>
        </div>
      </Modal>
    </>
  );
};

export { TopupAutoScoringCreditsModal };
