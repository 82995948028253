import { OptionType } from "components";

export const objectiveOptions: OptionType[] = [
  {
    label: "Prescreening applicants for fit pre-interviews",
    value: "prescreening",
  },
  {
    label: "Assessing potential candidates before hiring",
    value: "assessing",
  },
  {
    label: "Validating employee/team fit to assigned roles",
    value: "validating",
  },
  {
    label: "Evaluating culture fit for candidates/employees",
    value: "evaluating",
  },
];
