import { CopyLinkIcon, EllipsisIcon } from "assets";
import styles from "./styles.module.scss";
import { ReactNode, useRef, useState } from "react";
import { useClickOutside } from "hooks";

export interface DropdownMenuOption {
  text: string | ReactNode;
  onClick: () => void;
  key?: string;
}

interface DropdownMenuProps {
  options: DropdownMenuOption[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ options }) => {
  const [show, setShow] = useState(false);

  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setShow(false));

  return (
    <>
      <div ref={dropdownRef} className={styles.menu}>
        <EllipsisIcon
          onClick={() => setShow(true)}
          className={styles.menu__cta}
          role="button"
        />
        {show ? (
          <div className={styles.menu__options}>
            {options.map(({ text, onClick }) => (
              <button onClick={onClick}>{text}</button>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export { DropdownMenu };
