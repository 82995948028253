/*
=================================
CANDIDATE ASSESSMENT URLS
=================================
*/

import { appendParams } from "helpers";

/**
 * Fetch assessment information url
 *
 * @returns url string
 *
 */

export const fetchCandidateAssessmentInfoURL = (id) =>
  `/candidate/assessment/?${appendParams({
    assessment: id,
  })}`;

/**
 * Start assessment  url
 *
 * @returns url string
 *
 */

export const startCandidateAssessmentURL = () => `/candidate/check/`;

/**
 * Candidate basic fit url
 *
 * @returns url string
 *
 */

export const candidateBasicFitURL = () => `/candidate/start/`;

/**
 * Candidate personality url
 *
 * @returns url string
 *
 */

export const candidatePersonalityURL = (id) => `/candidate/personality/${id}/`;

/**
 * Candidate soft skill url
 *
 * @returns url string
 *
 */

export const candidateSoftSkillURL = (id) => `/candidate/soft-skill/${id}/`;

/**
 * Candidate work qualities url
 *
 * @returns url string
 *
 */

export const candidateWorkQualitiesURL = (id) =>
  `/candidate/work-qualities/${id}/`;

/**
 * Candidate work motivators url
 *
 * @returns url string
 *
 */

export const candidateWorkMotivatorsURL = (id) =>
  `/candidate/work-motivators/${id}/`;

/**
 * Candidate work style url
 *
 * @returns url string
 *
 */

export const candidateWorkStyleURL = (id) => `/candidate/work-style/${id}/`;

/**
 * Candidate domain knowledge  url
 *
 * @returns url string
 *
 */

export const candidateDomainKnowledgeURL = (id) =>
  `/candidate/domain-knowledge/${id}/`;

/**
 * Candidate complete assessment url
 *
 * @returns url string
 *
 */

export const candidateCompleteAssessmentURL = () => `/candidate/complete/`;

/**
 * Fetch basic expectation url
 *
 * @returns url string
 *
 */

export const fetchBasicFitURL = (id) => `/candidate/start/?assessment=${id}`;
