import styles from "./styles.module.scss";
import { Button, Input } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { useEffect } from "react";

export interface ResetData {
  password: string;
  confirmPassword: string;
}

const initResetData: ResetData = {
  password: "",
  confirmPassword: "",
};
const schema = yup.object({
  password: yup
    .string()
    .required("Required")
    .min(8, "Password should be at least 8 characters long")
    .matches(/[A-Z]/, "Password should contain an uppercase character")
    .matches(/[a-z]/, "Password should contain an lowercase character")
    .matches(/[0-9]/, "Password should contain at least one number")
    .matches(
      /[@#&$]/,
      "Password should contain at least special character (e.g. @, #, &, $)"
    ),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

interface SigninProps {
  sumbit: (data: ResetData) => void;
  clear: boolean;
}

const NewPasswordUI: React.FC<SigninProps> = ({ sumbit, clear }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<ResetData>({
    resolver: yupResolver(schema),
    defaultValues: initResetData,
  });

  useEffect(() => {
    reset(initResetData);
  }, [clear]);

  const onSubmit: SubmitHandler<ResetData> = (data) => {
    sumbit(data);
  };

  return (
    <>
      <h1 className={styles.ttl}>Set new password</h1>
      <p className={styles.txt}>
        Your new password must be different from previous used passwords
      </p>
      <form className={styles.form}>
        <Input
          label="Password"
          placeholder="Enter password"
          type="password"
          required
          validatorMessage={errors.password?.message}
          name="password"
          register={register}
          value={watch("password")}
          showRequired
        />
        <Input
          label="Confirm Password"
          placeholder="Confirm password"
          type="password"
          required
          validatorMessage={errors.confirmPassword?.message}
          name="confirmPassword"
          register={register}
          value={watch("confirmPassword")}
          showRequired
        />

        <Button
          className={styles.btn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Reset Password
        </Button>
      </form>

      <Link className={styles.signin} to={Routes.signin}>
        Login
      </Link>
    </>
  );
};

export { NewPasswordUI };
