/*
=================================
ASSESSMENT REPORT SERVICES
=================================
*/

import {
  axiosInstanceUnauth,
  fetchAssessmentForReportsURL,
  fetchReportURL,
  fetchReportUnauthURL,
  fetchRoleReportsURL,
  generateAIScoreURL,
  getRequest,
  moveReportURL,
  patchRequest,
  postRequest,
} from "api";

export interface fetchAssessmentForReportsRequestData {
  status: string;
  page: string;
  search: string;
  dateFrom: string;
  dateTo: string;
}

/**
 * Fetch assessments service
 * @returns axios promise
 */

export const fetchAssessmentForReportsService = (
  data: fetchAssessmentForReportsRequestData
) => {
  const requestData = {
    url: fetchAssessmentForReportsURL(data),
  };
  return getRequest(requestData);
};

export interface fetchRoleReportsURLRequestData {
  bucket: string; //"consider" | "assessed" | "reject";
  page: string;
  search: string;
  assessment: string;
  progress: string; // "started" | "abandoned" | "completed";
  lower_score: string;
  upper_score: string;
}

/**
 * Fetch reports for a role/assessment service
 * @returns axios promise
 */

export const fetchRoleReportsService = (
  data: fetchRoleReportsURLRequestData
) => {
  const requestData = {
    url: fetchRoleReportsURL(data),
  };
  return getRequest(requestData);
};

export interface moveReportRequestData {
  selected_responses: number[];
  new_bucket: string; //"consider" | "assessed" | "reject" | "to_interview";
}

/**
 * Move report to a bucket service
 * @returns axios promise
 */

export const moveReportService = (data: moveReportRequestData) => {
  const requestData = {
    url: moveReportURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Fetch candidate report service
 *
 * @returns axios promise
 */

export const fetchReportService = (id: string) => {
  const requestData = {
    url: fetchReportURL(id),
  };
  return getRequest(requestData);
};

interface AIScoringRequestData {
  assessment: number;
  responses?: number[];
  buckets?: string[];
  callback_url?: string;
}
/**
 * Generate AI scoring for report service
 *
 * @returns axios promise
 */

export const generateAIScoreService = (
  data: AIScoringRequestData,
  action?: string
) => {
  const requestData = {
    url: generateAIScoreURL(action),
    data,
  };
  return postRequest(requestData);
};

/**
 * Fetch candidate report service
 *
 * @returns axios promise
 */

export const fetchReportUnauthService = (id: string) => {
  return axiosInstanceUnauth.get(fetchReportUnauthURL(id));
};
