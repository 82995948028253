import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import styles from "./styles.module.scss";
import { useEffect } from "react";

ChartJS.unregister();
ChartJS.register(
  CategoryScale,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip
);
ChartJS.defaults.font.family = "Inter";
ChartJS.defaults.font.size = 13;

export interface GraphProps {
  data: number[];
  labels: string[];
  breakdown: { title: string; abandoned: number; completed: number }[];
}

const LineGraph: React.FC<GraphProps> = ({ data, labels, breakdown }) => {
  const lineData: ChartData<"line"> = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: data,
        backgroundColor: "#FF5BEF10",
        borderColor: "#FF5BEF",
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        pointHoverRadius: 6,
        pointBackgroundColor: "#fff",
        pointBorderColor: "#FF5BEF",
        pointHoverBackgroundColor: "#fff",
        pointBorderWidth: 2,
        pointHoverBorderWidth: 3,
        tension: 0.4,
      },
    ],
  };

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "white";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement("thead");

      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        tr.style.borderWidth = "0px";

        const th = document.createElement("th");
        th.style.borderWidth = "0px";
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement("tbody");
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];

        const span = document.createElement("span");
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "10px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.display = "inline-block";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = "0px";

        const td = document.createElement("td");
        td.style.borderWidth = "0px";

        // const text1 = document.createTextNode(`Total: ${test[0].total}`);
        const text2 = document.createTextNode(
          `Abandoned: ${
            breakdown.find((item) => item.title === titleLines[0])?.abandoned ??
            0
          }`
        );
        const text3 = document.createTextNode(
          `Completed: ${
            breakdown.find((item) => item.title === titleLines[0])?.completed ??
            0
          }`
        );

        // const p1 = document.createElement('p');
        const p2 = document.createElement("p");
        const p3 = document.createElement("p");

        // p1.appendChild(text1);
        p2.appendChild(text2);
        p3.appendChild(text3);

        // td.appendChild(span);
        // td.appendChild(p1);
        td.appendChild(p2);
        td.appendChild(p3);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };

  const lineOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: "rgba(241, 243, 246, 1)",
        },
        title: {
          display: false,
        },
        border: {
          color: "rgba(241, 243, 246, 1)",
        },
      },
      y: {
        grid: {
          display: true,
          color: "rgba(241, 243, 246, 1)",
        },
        title: {
          display: false,
        },
        ticks: {
          display: true,
        },
        border: {
          display: false,
        },
      },
    },
  };
  const lineConfig = {
    type: "line",
    data: lineData,
    options: lineOptions,
  };
  return (
    <>
      <section className={styles.graph}>
        <Line id="canvas" {...lineConfig} />
      </section>
    </>
  );
};
export { LineGraph };
