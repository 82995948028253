import { forgotPasswordService } from "api";
import { Loader } from "components";
import { ForgotPasswordUI } from "features";
import { useApiRequest, useToast } from "hooks";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const submit = (email: string) => {
    run(forgotPasswordService(email.replaceAll("+", "%2B")));
  };

  useMemo(() => {
    if (response?.status === 200) {
      setSuccess(true);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to request a password reset, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <ForgotPasswordUI
        success={success}
        submit={submit}
        handleLogin={() => navigate(Routes.signin)}
      />
    </>
  );
};

export { ForgotPassword };
