import { DoughnutChart } from "components";
import styles from "./styles.module.scss";

interface MetricOverviewProps {
  score: number;
  description: string;
}

const MetricOverview: React.FC<MetricOverviewProps> = ({
  score,
  description,
}) => {
  return (
    <section>
      <div className={styles.chart}  >
        <DoughnutChart score={score} cutout="50%" />
        <span
          className={`${styles.score} ${
            score >= 90
              ? styles.green
              : score >= 70
              ? styles.blue
              : score >= 50
              ? styles.orange
              : styles.red
          }`}
        >
          {score}%
        </span>
      </div>
      <p className={styles.description} >{description}</p>
    </section>
  );
};

export { MetricOverview };
