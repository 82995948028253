import { Button } from "components/button";
import styles from "./styles.module.scss";
import { ChevronRightIcon } from "assets";

export interface PaginationProps {
  className?: string;
  current: number;
  total: number;
  handleChange: (page: number) => void;
  count: number;
  length: number;
}

const Pagination: React.FC<PaginationProps> = ({
  count,
  current,
  className,
  handleChange,
  total,
  length,
}) => {
  const handleIncrease = () => {
    if (current < total) handleChange(current + 1);
  };

  const handleDecrease = () => {
    if (current > 1) handleChange(current - 1);
  };

  const start = current * length - length + 1;
  const end = current * length > count ? count : current * length;

  return (
    <>
      <section className={`${styles.pagination} ${className}`}>
        <p>
          Showing <b>{start}</b> to <b>{end}</b> of <b>{count}</b> items
        </p>
        <div>
          <Button
            disabled={current === 1 || total === 1}
            type={"outline"}
            onClick={handleDecrease}
          >
            <ChevronRightIcon />
          </Button>
          <Button
            disabled={current >= total}
            type={"fill"}
            onClick={handleIncrease}
          >
            <ChevronRightIcon />
          </Button>
        </div>
      </section>
    </>
  );
};

export { Pagination };
