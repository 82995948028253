import styles from "./styles.module.scss";
import { useForm } from "react-hook-form";
import { TextareaDK } from "./components/textarea";
import { RadioInputDK } from "./components/radio";
import { Button, OptionType } from "components";
import { SubmitDomainKnowledgeData } from "api";

// interface CandidateDomainKnowledgeQuestion {
//   question: string;
//   type: "text" | "radio" | "checkbox";
//   description?: string;
//   word_limit?: number;
//   maximum_selections?: number;
//   required?: boolean;
//   options: OptionType[];
// }

// interface ValidationsType {
//   required?: string | boolean;
//   length?:
//     | {
//         value: number;
//         message: string;
//       }
//     | boolean;
//   maxLength?:
//     | {
//         value: number;
//         message: string;
//       }
//     | boolean;
// }

export interface DynamicInputProps {
  name: string;
  label: string;
  type: "open_ended" | "single_choice" | "multiple_choice";
  _id: number;
  validation: any;
  options?: OptionType[];
  instruction?: string;
  image?: {
    value: string;
    description?: string;
  };
}

interface DynamicFormProps {
  inputs: DynamicInputProps[];
  submit: (data: SubmitDomainKnowledgeData[]) => void;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ inputs, submit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleCheckBoxChange = (name, prev, value) => {
    const index = prev.findIndex((item) => item.value === value.value);

    if (index >= 0) {
      const val = [...prev];
      val.splice(index, 1);
      setValue(name, [...val]);
    } else {
      setValue(name, [...prev, value]);
    }
  };

  const renderFormControl = (field: DynamicInputProps) => {
    switch (field.type) {
      case "open_ended":
        return (
          <TextareaDK
            key={field.name}
            validatorMessage={errors[field.name]?.message?.toString()}
            {...register(field.name, field.validation)}
            onChange={handleInputChange}
            max={field.validation?.maxLength?.value}
            value={watch(field.name)}
            {...field}
          />
        );
      case "single_choice":
        return (
          <RadioInputDK
            key={field.name}
            validatorMessage={errors[field.name]?.message?.toString()}
            {...register(field.name, field.validation)}
            {...field}
            onChange={handleInputChange}
            name={field.name}
            options={field.options ?? []}
            type="radio"
          />
        );
      case "multiple_choice":
        return (
          <RadioInputDK
            key={field.name}
            validatorMessage={errors[field.name]?.message?.toString()}
            {...register(field.name, { ...field.validation, type: "checkbox" })}
            {...field}
            options={field.options ?? []}
            disabled={
              watch(field.name)?.length >= field.validation.length?.value ?? 1
            }
            checkValue={watch(field.name)}
            handleChange={handleCheckBoxChange}
            type="checkbox"
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = (data) => {
    const submitData: SubmitDomainKnowledgeData[] = inputs.map((input) => ({
      question: input._id,
      type: input.type,
      response:
        input.type === "multiple_choice"
          ? data[input.name].map((item) => item.value)
          : input.type === "single_choice"
          ? parseInt(data[input.name])
          : data[input.name],
    }));

    submit(submitData);
  };

  return (
    <form className={styles.form}>
      {inputs.map((field) => renderFormControl(field))}
      <Button
        className={styles.nextBtn}
        type="fill"
        onClick={handleSubmit(onSubmit)}
      >
        Next
      </Button>
    </form>
  );
};

const AssessmentDomainKnowledgeUI: React.FC<DynamicFormProps> = ({
  inputs,
  submit,
}) => {
  return (
    <>
      <p className={styles.txt}>
        These questions are designed to assess your operational knowledge and
        skills. Please provide an honest evaluation of your abilities. If there
        are areas where you haven't faced any challenges or have no experience,
        simply input 'Not Applicable.
      </p>
      <DynamicForm inputs={inputs} submit={submit} />
    </>
  );
};

export { AssessmentDomainKnowledgeUI };
