import { PreviewStartAssessmentUI } from "features";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";

interface StartAssessmentProps {
  show: boolean;
  close: () => void;
  id: string;
}

const StartAssessment: React.FC<StartAssessmentProps> = ({
  show,
  close,
  id,
}) => {
  const [clear, setClear] = useState(false);
  const navigate = useNavigate();

  const handleStart = (email) => {
    navigate(Routes.employerPreviewBasic(id));
    localStorage.setItem("answered", "1")
    setClear((prev) => !prev);
    close()
  };

  return (
    <>
      <PreviewStartAssessmentUI
        submit={handleStart}
        show={show}
        close={close}
        clear={clear}
      />
    </>
  );
};

export { StartAssessment };
