import {
  Button,
  CustomPhoneInput,
  CustomSelect,
  Input,
  Modal,
  OptionType,
  initOptionType,
  optionTypeSchema,
} from "components";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { employeeOptions, frequencyOptions, howOptions } from "./utils";
import { TickIcon } from "assets";
import { CreateCompanyRequestData, UpdateUserRequestData } from "api";

export interface OnboardingModalProps {
  show: boolean;
  close: () => void;
  submitPersonal: (data: UpdateUserRequestData) => void;
  submitBusiness: (data: CreateCompanyRequestData) => void;
  stage: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  personalData: PersonalInfoData | undefined;
}

const OnboardingModalUI: React.FC<OnboardingModalProps> = ({
  show,
  close,
  submitPersonal,
  submitBusiness,
  stage,
  setStage,
  personalData,
}) => {
  const [personal, setPersonal] = useState<PersonalInfoData>(
    personalData ?? initPersonal
  );

  useEffect(() => {
    setPersonal(personalData ?? initPersonal);
  }, [personalData]);

  const goToTwo = (data: PersonalInfoData) => {
    setPersonal(data);

    const submitData: UpdateUserRequestData = {
      first_name: data.firstName,
      last_name: data.lastName,
      referral: data.other !== "" ? data.other : data.how.value,
      role: data.role,
      country_code: data.number.code,
      phone_number: data.number.number,
    };

    submitPersonal(submitData);
  };

  const submitAll = (data: CompanyInfoData) => {
    const submitData: CreateCompanyRequestData = {
      name: data.name,
      industry: data.industry,
      hiring_frequency: data.frequency.value,
      no_of_employees: data.employees.value,
    };

    submitBusiness(submitData);
  };

  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <div className={styles.steps}>
          <span
            role="button"
            onClick={() => setStage(1)}
            className={
              stage === 1
                ? styles.active
                : personal.firstName !== "" || personal.lastName !== ""
                ? styles.complete
                : ""
            }
          >
            {(!(personal.firstName !== "" || personal.lastName !== "") ||
              personalData) &&
            stage !== 1 ? (
              <TickIcon />
            ) : (
              "1"
            )}
          </span>
          <span
            role={personalData ? "button" : ""}
            onClick={() => setStage(personalData ? 2 : 1)}
            className={stage === 2 ? styles.active : ""}
          >
            2
          </span>
        </div>
        <h1 className={styles.ttl}>About {stage === 1 ? "You" : "Company"}</h1>
        {stage === 1 ? (
          <PersonalInfoForm init={personal} submit={goToTwo} />
        ) : (
          <CompanyInfoForm submit={submitAll} />
        )}
      </Modal>
    </>
  );
};

interface PersonalInfoData {
  firstName: string;
  lastName: string;
  role: string;
  number: { code: string; number: string; value: string };
  how: OptionType;
  other: string | undefined;
}

const initPersonal: PersonalInfoData = {
  firstName: "",
  lastName: "",
  role: "",
  number: { code: "", number: "", value: "" },
  how: initOptionType,
  other: undefined,
};

const personalSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  role: yup.string().required("Required"),
  number: yup.object({
    code: yup.string().required("Required"),
    number: yup.string().required("Required"),
    value: yup.string().required("Required"),
  }),
  how: optionTypeSchema(),
  other: yup.string().when("how", {
    is: (val) => val.value === "others" || val.value === "referral",
    then: (schema) => schema.required("Required"),
  }),
});

interface PersonalInfoProps {
  submit: (data: PersonalInfoData) => void;
  init: PersonalInfoData;
}
const PersonalInfoForm: React.FC<PersonalInfoProps> = ({ submit, init }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<PersonalInfoData>({
    resolver: yupResolver(personalSchema),
    defaultValues: init,
  });

  return (
    <form>
      <Input
        label="First name"
        placeholder="Your first name"
        type="text"
        required
        validatorMessage={errors.firstName?.message}
        name="firstName"
        register={register}
        value={watch("firstName")}
        showRequired
      />
      <Input
        label="Last name"
        placeholder="Your last name"
        type="text"
        required
        validatorMessage={errors.lastName?.message}
        name="lastName"
        register={register}
        value={watch("lastName")}
        showRequired
      />
      <Input
        label="Your job role"
        placeholder="Your job role at the company"
        type="text"
        required
        validatorMessage={errors.role?.message}
        name="role"
        register={register}
        value={watch("role")}
        showRequired
      />
      <CustomPhoneInput
        label="Phone number"
        placeholder="Your phone number"
        validatorMessage={errors.number?.value?.message?.toString() ?? ""}
        onChange={({ code, number, value }) =>
          setValue("number", { code, number, value })
        }
        showRequired
        value={watch("number.value")}
      />
      <p className={styles.phoneTxt}>
        This may be used to contact you if you need assistance
      </p>
      <CustomSelect
        menuPlacement="top"
        label="How did you hear about us?"
        placeholder="Select appropriate"
        options={howOptions}
        onChange={(val) => setValue("how", val)}
        validatorMessage={errors.how?.value?.message?.toString() ?? ""}
        name={"how"}
        value={watch("how")}
        showRequired
      />
      {(watch("how").value === "others" ||
        watch("how").value === "referral") && (
        <Input
          label={`How did you hear about us? (${watch("how").label})`}
          placeholder="How did you hear about us"
          type="text"
          required
          validatorMessage={errors.other?.message}
          name="other"
          register={register}
          value={watch("other")}
          showRequired
        />
      )}
      <Button className={styles.btn} type="fill" onClick={handleSubmit(submit)}>
        Update personal profile
      </Button>
    </form>
  );
};

interface CompanyInfoData {
  name: string;
  industry: string;
  frequency: OptionType;
  employees: OptionType;
}

const initCompany: CompanyInfoData = {
  name: "",
  industry: "",
  frequency: initOptionType,
  employees: initOptionType,
};

const companySchema = yup.object({
  name: yup.string().required("Required"),
  industry: yup.string().required("Required"),
  frequency: optionTypeSchema(),
  employees: optionTypeSchema(),
});

interface CompanyInfoProps {
  submit: (data: CompanyInfoData) => void;
}

const CompanyInfoForm: React.FC<CompanyInfoProps> = ({ submit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<CompanyInfoData>({
    resolver: yupResolver(companySchema),
    defaultValues: initCompany,
  });

  return (
    <form>
      <Input
        label="Company’s name"
        placeholder="Your name"
        type="text"
        required
        validatorMessage={errors.name?.message}
        name="name"
        register={register}
        value={watch("name")}
        showRequired
      />
      <Input
        label="Industry"
        placeholder="Mention the industry your organization operates in"
        type="text"
        required
        validatorMessage={errors.industry?.message}
        name="industry"
        register={register}
        value={watch("industry")}
        showRequired
      />
      <CustomSelect
        menuPlacement="top"
        label="Annual hiring frequency"
        placeholder="Select appropriate"
        options={frequencyOptions}
        onChange={(val) => setValue("frequency", val)}
        validatorMessage={errors.frequency?.value?.message?.toString() ?? ""}
        name={"frequency"}
        value={watch("frequency")}
        showRequired
      />
      <div className={styles.employees}>
        <label>Number of Employees</label>
        <div>
          {employeeOptions.map((item, index) => (
            <button
              className={
                watch("employees").value === item.value ? styles.selected : ""
              }
              onClick={(e) => {
                e.preventDefault();
                setValue("employees", item);
              }}
            >
              <span>{item.label}</span>
              Employees
            </button>
          ))}
          {errors.employees?.value?.message && (
            <p className={styles.errorMsg}>
              {errors.employees?.value?.message?.toString()}
            </p>
          )}
        </div>
      </div>
      <Button className={styles.btn} type="fill" onClick={handleSubmit(submit)}>
        Create company profile
      </Button>
    </form>
  );
};

export { OnboardingModalUI };
