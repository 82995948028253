import { ArrangeData, OptionType } from "components";

export const AdministrationHumiliateOptions: OptionType[] = [
  {
    label: "A poorly stitched dress",
    value: "a",
  },
  {
    label: "An untidy room",
    value: "b",
  },
  {
    label: "Arriving at a meet up late",
    value: "c",
  },
  {
    label: "Poorly spelt or pronounced words",
    value: "d",
  },
  {
    label: "Starting your day without a checklist",
    value: "e",
  },
];

export const SuccessfulManagerOptions: OptionType[] = [
  {
    label: "Ensuring results are generated consistently",
    value: "a",
  },
  {
    label:
      "Developing solutions that give the organization a competitive advantage",
    value: "b",
  },
  {
    label:
      "Taking into consideration and studying the needs of consumers, team members, and the firm",
    value: "c",
  },
  {
    label: "Caring about your employees as real people",
    value: "d",
  },
];

export const RoutineOptions: OptionType[] = [
  {
    label: "Improving the way things are done in a place",
    value: "a",
  },
  {
    label: "Ensuring people's needs are being heard",
    value: "b",
  },
  {
    label: "Making mundane things/activities better and enjoyable",
    value: "c",
  },
  {
    label: "Developing entirely new solutions or concepts.",
    value: "d",
  },
];

export const CriticalThinkingList: ArrangeData[] = [
  {
    title: "Research competitors",
    id: "5",
  },
  {
    title: "Test the existing process",
    id: "4",
  },
  {
    title: "Talk to people",
    id: "1",
  },
  {
    title: "Identify what can be done better",
    id: "7",
  },
  {
    title: "Do a need analysis",
    id: "6",
  },
  {
    title: "Understand the companies process",
    id: "3",
  },
  {
    title: "Listen for requirements",
    id: "2",
  },
];

export const ResilienceOptions: OptionType[] = [
  {
    label: "Trying new methods until you find an answer",
    value: "1",
  },
  {
    label:
      "Following your passion for a particular thing till you find the truth",
    value: "2",
  },
  {
    label: "Seeking possibilities for improvement against the odds",
    value: "3",
  },
  {
    label: "Finding a way to make complex things simple",
    value: "4",
  },
  {
    label:
      "Identifying and resolving problems hindering performance in the shortest time possible",
    value: "5",
  },
];

export const EloquenceDailyOptions: OptionType[] = [
  {
    label: "Learning a new word",
    value: "6",
  },
  {
    label: "Reading on a topic completely outside your normal interest",
    value: "5",
  },
  {
    label: "Jotting down or articulating an idea",
    value: "4",
  },
  {
    label: "Sharing your opinion on social media or in a conversation",
    value: "3",
  },
  {
    label: "Other",
    value: "2",
  },
];

export const EloquenceFearOptions: OptionType[] = [
  {
    label: "Sounding stupid when you communicate",
    value: "6",
  },
  {
    label:
      "Going to a meeting without adequate preparation of what you intend to say",
    value: "5",
  },
  {
    label: "Being asked to teach or address people impromptu",
    value: "4",
  },
  {
    label: "Can't relate to any",
    value: "3",
  },
];

export const LeadershipExperienceOptions: OptionType[] = [
  {
    label: "No, I haven’t",
    value: "no",
  },
  {
    label: "Yes, I have",
    value: "yes",
  },
];

export const LeadershipOverwhelmingOptions: OptionType[] = [
  {
    label: "Defining the direction of an idea",
    value: "1",
  },
  {
    label: "Allocating the resources for execution",
    value: "2",
  },
  {
    label:
      "Deciding on a course of action with multiple risk or pressure points",
    value: "3",
  },
  {
    label: "Managing people and inspiring them to achieve results",
    value: "4",
  },
  {
    label: "Being compassionate all the time",
    value: "5",
  },
  {
    label: "Communicating to multiple stakeholders",
    value: "6",
  },
  {
    label: "Delegating tasks and trusting they get done well",
    value: "7",
  },
  {
    label: "The burden of being a role model to team members",
    value: "8",
  },
];

export const LeadershipJusticeOptions: OptionType[] = [
  {
    label: "To ensure everyone wins",
    value: "a",
  },
  {
    label:
      "To uphold the highest form of ethics and standard in decision making",
    value: "b",
  },
  {
    label: "To be consistent in words and actions in diverse situations",
    value: "c",
  },
  {
    label: "To work with discretion, putting yourself in other people's shoes",
    value: "d",
  },
  {
    label: "To be frank and honest in your dealing",
    value: "e",
  },
];

export const PersuasionOptions: OptionType[] = [
  {
    label: "True",
    value: "true",
  },
  {
    label: "False",
    value: "false",
  },
];

export const PersuasionStressOptions: OptionType[] = [
  {
    label: "Getting someone to tell you something.",
    value: "1",
  },
  {
    label: "Getting someone to do something for you.",
    value: "2",
  },
  {
    label: "Getting someone to stop doing something.",
    value: "3",
  },
  {
    label: "Getting someone to understand something.",
    value: "4",
  },
  {
    label: "Getting someone to change what they believe about something.",
    value: "5",
  },
];

export const PersuasionTaskOptions: OptionType[] = [
  {
    label: "Listen & build rapport",
    value: "a",
  },
  {
    label: "Understand the kind of persons the stakeholders are",
    value: "b",
  },
  {
    label: "Pinpoint shared interests",
    value: "c",
  },
  {
    label: "Present a logical argument",
    value: "d",
  },
  {
    label: "Negotiate for a win-win situation",
    value: "e",
  },
];

export const ProficiencyOptions: OptionType[] = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
];

export const AnalyticalStatementOptions: OptionType[] = [
  {
    label:
      "As a student, you were likely to look for the missing links in what was being taught in class",
    value: "A",
  },
  {
    label: "You’re the most creative person in a team of people",
    value: "B",
  },
  {
    label:
      "You often ask the questions no one likes to ask, even if you don’t vocalise it",
    value: "C",
  },
  {
    label: "For you, innovation is about practical problem solving",
    value: "D",
  },
];

export const AnalyticalThinkingList: ArrangeData[] = [
  { title: "Examine & Scrutinize", id: "3" },
  { title: "Understand context", id: "1" },
  { title: "Collect Information", id: "2" },
  { title: "Identify your personal bias", id: "4" },
  { title: "Explain your conclusion", id: "6" },
  { title: "Draw conclusion", id: "5" },
];
