import { OptionType } from "components";
import styles from "./styles.module.scss";
import { Routes } from "router";
import { Link } from "react-router-dom";

export interface TabData extends OptionType {
  isCompleted: boolean;
}

interface CreateAssessmentHeaderProps {
  tabs: TabData[];
  children: any;
  tab: string;
  handleChangeTab: (tab: string) => void;
  skips: string[];
  disableTabs: boolean;
  isEdit?: boolean;
}

const CreateAssessmentHeader: React.FC<CreateAssessmentHeaderProps> = ({
  children,
  tabs,
  tab,
  handleChangeTab,
  skips,
  disableTabs,
  isEdit,
}) => {
  return (
    <>
      <p className={styles.txt1}>
        <Link
          onClick={() => localStorage.removeItem("assessmentId")}
          role="button"
          to={Routes.employerAssessments}
        >
          {isEdit ? "Edit" : "Create"} Assessment
        </Link>{" "}
        / Define role expectations{" "}
      </p>
      <p className={styles.txt2}>
        Set or modify assessment expectations for desired areas. Skip any
        metrics you don't want to define.
      </p>

      <section className={styles.container}>
        <section className={styles.tabs}>
          {tabs.map((item, idx) => (
            <div
              key={idx}
              role="button"
              onClick={() => !disableTabs && handleChangeTab(item.value)}
              className={`${item.value === tab ? styles.active : ""} ${
                item.isCompleted ? styles.isCompleted : ""
              } ${
                skips.find((skip) => item.value === skip)
                  ? styles.isSkipped
                  : ""
              } ${disableTabs ? styles.disableTab : ""} `}
            >
              <span>{item.label}</span>
            </div>
          ))}
        </section>
        <section>{children}</section>
      </section>
    </>
  );
};

export { CreateAssessmentHeader };
