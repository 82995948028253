import { NotificationIcon, SearchIcon } from "assets";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Notifications } from "../notification";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { useAppSelector } from "state/hooks";

interface NavbarProps {
  title: string;
  search?: {
    value: string;
    placeholder: string;
    handleChange: (val: string) => void;
  };
}

const Navbar: React.FC<NavbarProps> = ({ title, search }) => {
  const [notif, setNotif] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const { status, daysLeft } = useAppSelector((state) => state.plan);

  const text =
    status === "expired"
      ? "Your plan has expired. Renew or Upgrade your subscription to maintain uninterrupted access"
      : status === "terminated"
      ? "You don't have an active plan. Choose a plan to maintain uninterrupted access"
      : daysLeft < 5 && daysLeft > 0
      ? "Your current plan is expiring soon. Renew or Upgrade your subscription to maintain uninterrupted access"
      : null;

  return (
    <>
      <Notifications
        handleCount={setNotifCount}
        show={notif}
        close={() => setNotif(false)}
      />
      {text ? (
        <section
          className={`${styles.info} ${
            status !== "active" ? styles["info--red"] : ""
          } `}
        >
          <p>{text}</p>
          <Link to={Routes.billings}>See plans</Link>
        </section>
      ) : (
        ""
      )}
      <header className={`${styles.header} ${!search ? styles.noSearch : ""}`}>
        <h1 className={styles.ttl}>{title}</h1>
        <div>
          {search ? (
            <div className={styles.search}>
              <input
                onChange={(e) => search.handleChange(e.target.value)}
                value={search.value}
                placeholder={search.placeholder}
                type="search"
              />
              <SearchIcon />
            </div>
          ) : (
            ""
          )}
          <div className={styles.notification}>
            <NotificationIcon role="button" onClick={() => setNotif(true)} />
            {notifCount > 0 ? <p>{notifCount}</p> : ""}
          </div>
        </div>
      </header>
    </>
  );
};

export { Navbar };
