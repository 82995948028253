import styles from "./styles.module.scss";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
  ChartData,
  ChartOptions,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.unregister();

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Filler,
  CategoryScale,
  LinearScale,
  PointElement
);

interface DoughnutChartProps {
  score: number;
  className?: string;
}

const HalfDoughnutChart: React.FC<DoughnutChartProps> = ({
  score,
  className,
}) => {
  const chartData: ChartData<"doughnut"> = {
    datasets: [
      {
        data: [score, 100 - score],
        borderColor: ["#605BFF", "#F5F5FF"],
        backgroundColor: ["#605BFF", "#F5F5FF"],
        hoverOffset: 0,
        borderAlign: "inner",
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    responsive: true,
    cutout: "80%",
    animation: {
      animateRotate: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: false },
    },
    rotation: -90,
    circumference: 180,
  };

  const config = {
    type: "doughnut",
    data: chartData,
    options: options,
  };

  return (
    <>
      <section className={styles.container}>
        <Doughnut className={styles.chart} {...config} />
        <div className={styles.container__txtSec}>
          <p className={styles.container__txt1}>used</p>
          <p className={styles.container__txt2}>
            {score >= 100 ? 100 : score.toPrecision(2)}%
          </p>
        </div>
      </section>
    </>
  );
};

export { HalfDoughnutChart };
