import { Modal } from "components";
import styles from "./styles.module.scss";
import { EmailImg } from "assets";
import { Routes } from "router";
import { Link } from "react-router-dom";

interface NewPasswordSuccessModalProps {
  show: boolean;
  close: () => void;
}

const NewPasswordSuccessModal: React.FC<NewPasswordSuccessModalProps> = ({
  show,
  close,
}) => {
  return (
    <>
      <Modal
        position={"centered"}
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <EmailImg />
        <h1 className={styles.ttl}>Password Reset</h1>
        <p className={styles.txt}>Your password has been successfully reset</p>
        <Link className={styles.btn} to={Routes.signin}>
          Login
        </Link>
      </Modal>
    </>
  );
};

export { NewPasswordSuccessModal };
