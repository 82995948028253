import { Button } from "components/button";
import styles from "./styles.module.scss";

interface EmptyTableProps {
  image: string;
  text: string;
  button?: {
    text: string;
    onClick: () => void;
    className?: string;
  };
  className?: string;
}

const EmptyTable: React.FC<EmptyTableProps> = ({
  image,
  text,
  button,
  className,
}) => {
  return (
    <section className={`${styles.empty} ${className}`}>
      <img src={image} alt="" className={styles.empty__img} />
      <p className={styles.empty__txt}>{text} </p>
      {button ? (
        <Button
          className={`${styles.empty__btn} ${button.className}`}
          type="fill"
          onClick={button.onClick}
        >
          {button.text}
        </Button>
      ) : (
        ""
      )}
    </section>
  );
};

export { EmptyTable };
