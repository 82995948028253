import {
  Button,
  CustomSelect,
  Input,
  OptionType,
  initOptionType,
  optionTypeSchema,
} from "components";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { timeZoneOptions } from "utils/options";

export interface ActivateFormData {
  timeZone: OptionType;
  start: { date: string; time: string };
  end: { date: string; time: string };
}

const initData: ActivateFormData = {
  timeZone: initOptionType,
  start: { date: ``, time: "" },
  end: { date: "", time: "" },
};

const schema = yup.object({
  timeZone: optionTypeSchema(),
  start: yup.object({
    date: yup.string().required("Required"),
    time: yup.string().required("Required"),
  }),
  end: yup.object({
    date: yup.string().required("Required"),
    time: yup.string().required("Required"),
  }),
});

interface ActivateAssessmentProps {
  submit: (data: ActivateFormData) => void;
  back: () => void;
  title: string;
}

const ActivateAssessmentForm: React.FC<ActivateAssessmentProps> = ({
  submit,
  back,
  title,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<ActivateFormData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const onSubmit: SubmitHandler<ActivateFormData> = (data) => submit(data);

  const today: Date = new Date();
  const startDateMin: Date = new Date();

  const endDateMin: Date =
    watch("start.date") !== "" ? new Date(watch("start.date")) : today;
  endDateMin.setDate(endDateMin.getDate() + 1);
  startDateMin.setDate(startDateMin.getDate() - 1);

  const formatDate = (date?) => {
    const val = date
      ? new Date(date).toLocaleDateString()
      : new Date().toLocaleDateString();

    const dateSplit = val.split("/");

    return `${dateSplit[2]}-${
      parseInt(dateSplit[0]) > 9 ? dateSplit[0] : `0${dateSplit[0]}`
    }-${parseInt(dateSplit[1]) > 9 ? dateSplit[1] : `0${dateSplit[1]}`}`;
  };

  return (
    <>
      <section className={styles.heading}>
        <h2 className={styles.ttl}>{title}</h2>
      </section>
      <form className={styles.form}>
        <CustomSelect
          label="Time Zone"
          placeholder="Specify a time zone"
          options={timeZoneOptions}
          onChange={(val) => setValue("timeZone", val)}
          validatorMessage={errors.timeZone?.value?.message?.toString() ?? ""}
          name={"timeZone"}
          value={watch("timeZone")}
          parentClassName={styles.timezone}
        />
        <div className={styles.dateTime}>
          <label>Start Date</label>
          <fieldset>
            <Input
              placeholder=""
              type="date"
              required
              validatorMessage={errors.start?.date?.message}
              name="start.date"
              register={register}
              value={watch("start.date")}
              parentClassName={styles.inputWrap}
              min={formatDate()}
            />
            <Input
              placeholder=""
              type="time"
              required
              validatorMessage={errors.start?.time?.message}
              name="start.time"
              register={register}
              value={watch("start.time")}
              parentClassName={styles.inputWrap}
              min={"07:00"}
              max={"17:00"}
            />
          </fieldset>
        </div>
        <div className={styles.dateTime}>
          <label>End Date</label>
          <fieldset>
            <Input
              placeholder=""
              type="date"
              required
              validatorMessage={errors.end?.date?.message}
              name="end.date"
              register={register}
              value={watch("end.date")}
              parentClassName={styles.inputWrap}
              min={formatDate(endDateMin)}
            />
            <Input
              placeholder=""
              type="time"
              required
              validatorMessage={errors.end?.time?.message}
              name="end.time"
              register={register}
              value={watch("end.time")}
              parentClassName={styles.inputWrap}
              min={"07:00"}
              max={"17:00"}
            />
          </fieldset>
        </div>
      </form>
      <div className={styles.btnSec}>
        <Button type="outline" onClick={back}>
          Previous
        </Button>
        <Button type="fill" onClick={handleSubmit(onSubmit)}>
          Publish
        </Button>
      </div>
    </>
  );
};

export { ActivateAssessmentForm };
