import { Report } from "pages";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";

const FullReport = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleBack = () => {
    navigate(Routes.employerReport(id));
  };

  return (
    <>
      <Report handleBack={handleBack} isFullReport={true} />
    </>
  );
};

export { FullReport };
