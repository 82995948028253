import { fetchUserService } from "api";
import { useApiRequest, useToast } from "hooks";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { updateUser } from "state";
import { useAppDispatch } from "state/hooks";

const useFetchUser = () => {
  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const getUser = () => run(fetchUserService());

  useMemo(() => {
    if (requestStatus.isPending || requestStatus.isIdle) return;
    if (response?.status === 200) {
      const user = response.data;

      const incompleteProfile =
        user.first_name === null ||
        user.last_name === null ||
        user.referral === null ||
        user.phone_number === null;

      dispatch(
        updateUser({
          id: user.id,
          firstName: user.first_name ?? "",
          lastName: user.last_name ?? "",
          email: user.email,
          avatar: user.display_photo ?? "",
          company: user.company_info?.name,
          role: user.role,
          companyLogo: user.company_info?.company_logo,
          companyId: user.company_info?.id,
          completedProfile: !incompleteProfile,
          completedBusiness: user.company_info !== null,
          phone: {
            code: user.country_code ?? "",
            number: user.phone_number,
          },
          referral: user.referral,
          industry: user.company_info?.industry,
          hiringFrequency: user.company_info?.hiring_frequency,
          noOfEmployees: user.company_info?.no_of_employees,
        })
      );
    } else if (error) {
      navigate(Routes.signin);

      openToast({
        show: true,
        heading: "Please login again",
        text: "Failed to fetch user information",
        type: "error",
        timeOut: 2500,
      });
    }
  }, [response, error]);

  return {
    getUser,
    loading: requestStatus.isPending,
  };
};

export { useFetchUser };
