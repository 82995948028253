import {
  Pagination,
  PaginationProps,
  TableBody,
  TableBodyRow,
} from "components/";
import styles from "./styles.module.scss";
import { DocumentTextIcon } from "assets";

export interface TemplateAssessmentTableItem {
  id: string;
  title: string;
  level: string;
  metrics: string;
}

interface TableBodyProps {
  tableBodyItems: TemplateAssessmentTableItem[];
  handleUse: (id) => void;
  tableBodyItemClassName: string;
  tableBodyRowClassName: string;
  pagination?: PaginationProps;
}

const TemplateAssessmentTable: React.FC<TableBodyProps> = ({
  tableBodyItemClassName,
  tableBodyItems,
  handleUse,
  tableBodyRowClassName,
  pagination,
}) => {
  return (
    <>
      <TableBody customClassName={`${styles.tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            style={{ zIndex: tableBodyItems.length - index }}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Assessment Title</p>
              {item.title}
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Level</p>
              {item.level}
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Metrics set</p>
              {item.metrics}
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <button
                onClick={() => handleUse(item.id)}
                className={styles.copyBtn}
              >
                Use template
                <DocumentTextIcon />
              </button>
            </span>
          </TableBodyRow>
        ))}
      </TableBody>
      {pagination ? (
        <Pagination {...pagination} className={styles.pagination} />
      ) : (
        ""
      )}
    </>
  );
};

export { TemplateAssessmentTable };
