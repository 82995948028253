import {
  Pagination,
  PaginationProps,
  TableBody,
  TableBodyRow,
} from "components/";
import styles from "./styles.module.scss";

export interface BillingHistoryTableItem {
  id: string;
  title: string;
  date: { date: string; time: string };
  amount: string;
  status: "failed" | "successful";
  invoice: string;
}

interface TableBodyProps {
  tableBodyItems: BillingHistoryTableItem[];
  handleDownload: (id) => void;
  tableBodyItemClassName: string;
  tableBodyRowClassName: string;
  pagination?: PaginationProps;
}

const BillingHistoryTable: React.FC<TableBodyProps> = ({
  tableBodyItemClassName,
  tableBodyItems,
  handleDownload,
  tableBodyRowClassName,
  pagination,
}) => {
  return (
    <>
      <TableBody customClassName={`${styles.tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            style={{ zIndex: tableBodyItems.length - index }}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Assessment Title</p>
              {item.title}
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Date Created</p>
              <div className={styles.date}>
                <span>{item.date.date} </span>
                <span>{item.date.time}</span>
              </div>
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <p className={styles.label}>Amount</p>
              {item.amount}
            </span>
            <span className={`${tableBodyItemClassName} ${styles.statusWrap}`}>
              {/* <p className={styles.label}>Status</p> */}
              <span className={`${styles.status} ${styles[item.status]}`}>
                {item.status}
              </span>
            </span>
            <span className={`${tableBodyItemClassName}`}>
              <a target="_blank" href={item.invoice} className={styles.copyBtn}>
                Download
              </a>
            </span>
          </TableBodyRow>
        ))}
      </TableBody>
      {pagination ? (
        <Pagination {...pagination} className={styles.pagination} />
      ) : (
        ""
      )}
    </>
  );
};

export { BillingHistoryTable };
