import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { TickIconFill } from "assets";
import { BuyPlanRequestData } from "api";
import { ToolTip } from "components";
import { AllPlansData, updatePreference } from "state";
import { useDispatch } from "react-redux";
import { useAppSelector } from "state/hooks";

export interface PaymentProps {
  handleCancel: () => void;
  plan: AllPlansData;
  handlePayment: (data: BuyPlanRequestData) => void;
}

const Payment: React.FC<PaymentProps> = ({
  handleCancel,
  plan,
  handlePayment,
}) => {
  const dispatch = useDispatch();
  const { currency } = useAppSelector((state) => state.preference);

  const setCurrency = (currency: string) => {
    dispatch(updatePreference({ currency }));
  };
  const [cycle, setCycle] = useState<"annual" | "monthly" | "as_you_go">(
    "monthly"
  );

  const isPayg = plan.key === "pay_as_you_go";

  useEffect(() => {
    setCycle(isPayg ? "as_you_go" : "annual");
  }, [plan]);

  const fee = currency === "USD" ? plan.fee : plan.nairaFee;

  const cost =
    plan.key === "pay_as_you_go" ? fee : cycle === "monthly" ? fee : fee * 12;
  const costToPay =
    plan.key === "pay_as_you_go"
      ? fee
      : cycle === "monthly"
      ? fee
      : fee * 12 * 0.9;

  const candidates =
    plan.key === "pay_as_you_go"
      ? plan.candidates
      : cycle === "monthly"
      ? plan.candidates
      : plan.candidates * 12;

  const roles =
    plan.key === "pay_as_you_go"
      ? plan.roles
      : cycle === "monthly"
      ? plan.roles
      : plan.roles * 12;

  const pay = () => {
    const data: BuyPlanRequestData = {
      plan: plan.key,
      billing_period: cycle,
      callback_url: window.location.href,
    };

    if (plan.key === "custom") {
      data.role = plan.roles;
      data.extra_candidate = plan.extraCandidates;
    }

    handlePayment(data);
  };

  return (
    <>
      <section className={styles.container}>
        <section className={styles.body}>
          <div className={styles.header}>
            <h1>{plan.title}</h1>
            <div
              className={`${styles.header__tabs} ${styles["header__tabs--2"]}`}
            >
              <button
                onClick={() => setCurrency("USD")}
                className={currency === "USD" ? styles.active : ""}
              >
                USD
              </button>
              <button
                onClick={() => setCurrency("NGN")}
                className={currency === "NGN" ? styles.active : ""}
              >
                NGN
              </button>
            </div>
          </div>
          {!isPayg && (
            <div className={styles.cycle}>
              <p className={styles.cycle__ttl}>Billing cycle</p>
              <fieldset>
                <label>
                  <input
                    onClick={() => setCycle("monthly")}
                    checked={cycle === "monthly"}
                    type="radio"
                    name="cycle"
                  />
                  <p>Monthly</p>
                </label>
                <label>
                  <input
                    onClick={() => setCycle("annual")}
                    checked={cycle === "annual"}
                    type="radio"
                    name="cycle"
                  />
                  <p>Annually</p> <span>Save up to 10%</span>
                </label>
              </fieldset>
            </div>
          )}
          <div className={styles.info}>
            <p className={styles.info__txt}>
              {isPayg
                ? `Assess candidates on-demand for specific roles with no additional charges.`
                : `Streamline your candidate assessments with our Monthly
              Subscription plan. Assess candidates for more roles.`}
            </p>
            <div className={styles.info__fee}>
              <p>{getCost({ cost: cost, currency: currency })}</p>
              <span>
                per {isPayg ? "role" : cycle === "monthly" ? "month" : "annum"}{" "}
              </span>
            </div>
            <ul>
              <li>
                <TickIconFill /> Assess up to {candidates} candidates{" "}
                {isPayg ? "per role" : ""}
              </li>
              <li>
                <TickIconFill />{" "}
                {isPayg ? "30 days access" : `Up to ${roles} roles`}
              </li>
              {plan.autoScoringCredit && (
                <li>
                  <TickIconFill /> {plan.autoScoringCredit} free auto-scoring
                  credits{" "}
                  <ToolTip
                    title="Auto-scoring"
                    text={`Eliminate manual report review, activate this feature to
                  automate the scoring of open-ended responses for default
                  questions.`}
                    position="left"
                    iconSize="small"
                  />
                </li>
              )}
            </ul>
          </div>
          <div className={styles.breakdown}>
            <div className={styles.breakdown__roles}>
              <p className={styles.breakdown__roles__ttl}>
                {roles} {roles === 1 ? "role" : "roles"}
              </p>
              <div>
                <div className={styles.breakdown__roles__cost}>
                  {cycle === "annual" ? (
                    <p className={styles.breakdown__roles__cost__old}>
                      {getCost({ cost: cost, currency: currency })}
                    </p>
                  ) : (
                    ""
                  )}
                  <p>{getCost({ cost: costToPay, currency: currency })}</p>
                </div>
                {cycle === "annual" ? (
                  <p className={styles.breakdown__roles__cost__bonus}>
                    10% off
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={styles.breakdown__total}>
              <p className={styles.breakdown__total__ttl}>Subtotal</p>
              <p className={styles.breakdown__total__cost}>
                {getCost({ cost: costToPay, currency: currency })}
              </p>
            </div>
          </div>
          <div className={styles.btnSec}>
            <button className={styles.btnSec__close} onClick={handleCancel}>
              Cancel
            </button>
            <button className={styles.btnSec__upgrade} onClick={pay}>
              Proceed to payment
            </button>
          </div>
        </section>
      </section>
    </>
  );
};

const getCost = ({ cost, currency }) => {
  return `${currency === "USD" ? "$" : "₦"}${cost.toLocaleString()}`;
};

export { Payment };
