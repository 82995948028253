import { useFetchPlan, useFetchUser, useToast } from "hooks";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Routes } from "router";
import { useAppSelector } from "state/hooks";

/**
 * UNPROTECTED ROUTE COMPONENT
 *
 * ===============================================
 *
 * This component houses all routes and their respective layouts.
 * To add a route navigate to the route builder and add to the existing list.
 *
 *
 */

const ProtectedRoute: React.FC<{ children: any }> = ({ children }) => {
  const { completedBusiness, completedProfile } = useAppSelector(
    (state) => state.user
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { openToast } = useToast();
  const { getUser, loading } = useFetchUser();
  const { getPlan } = useFetchPlan();

  const checkUserToken = () => {
    const accessToken = localStorage.getItem("precisionAccess");
    const refreshToken = localStorage.getItem("precisionRefresh");
    if (
      !accessToken ||
      accessToken === "undefined" ||
      !refreshToken ||
      refreshToken === "undefined"
    ) {
      setIsLoggedIn(false);
      openToast({
        show: true,
        heading: "Session Not Found",
        text: "Please login to access your dashboard",
        type: "error",
        timeOut: 2500,
      });
      return navigate(Routes.signin);
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    if ((!completedBusiness || !completedProfile) && isLoggedIn && !loading)
      navigate(
        `${Routes.employerDashbord}?onboarding=true&incomplete=${
          !completedProfile ? "personal" : "business"
        }`
      );
  }, [pathname, isLoggedIn, completedProfile, completedBusiness]);

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  useEffect(() => {
    getUser();
    getPlan();
  }, []);

  return <>{isLoggedIn ? children : null}</>;
};

export { ProtectedRoute };
