/*
=================================
ACCOUNT URLS
=================================
*/

/**
 * Update personal profile url
 *
 * @returns url string
 *
 */

export const updateUserURL = () => `/auth/user/`;

/**
 * Create company profile url
 *
 * @returns url string
 *
 */

export const createCompanyURL = () => `/auth/company/`;

/**
 * Fetch user information url
 *
 * @returns url string
 *
 */

export const fetchUserURL = () => `/auth/user/`;

/**
 * Update company profile url
 *
 * @returns url string
 *
 */

export const updateCompanyURL = () => `/auth/company/`;

/**
 * Change password url
 *
 * @returns url string
 *
 */

export const changePasswordURL = () => `/auth/change-password/`;

/**
 * Fetch stats url
 *
 * @returns url string
 *
 */

export const fetchStatsURL = () => `/assessment/dashboard/overview-card/`;

/**
 * Fetch graph data url
 *
 * @returns url string
 *
 */

export const fetchGraphDataURL = (period: string) =>
  `/assessment/dashboard/graph/?period=${period}`;

/**
 * Fetch notifications url
 *
 * @returns url string
 *
 */

export const fetchNotifsURL = () => `/notifications/`;

/**
 * Clear all notifications url
 *
 * @returns url string
 *
 */

export const clearAllNotifsURL = () => `/notifications/clear/all/`;

/**
 * Read all notifications url
 *
 * @returns url string
 *
 */

export const readAllNotifsURL = () => `/notifications/read/all/`;

/**
 * Clear single notification url
 *
 * @returns url string
 *
 */

export const clearNotifURL = (id) => `/notifications/clear/${id}/`;

/**
 * Read single notification url
 *
 * @returns url string
 *
 */

export const readNotifURL = (id) => `/notifications/read/${id}/`;
