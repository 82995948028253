import {
  clearAllNotifsService,
  clearNotifService,
  fetchNotifsService,
  readAllNotifsService,
  readNotifService,
} from "api";
import { Loader, NotificationItemData, NotificationModal } from "components";
import { getError } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { title } from "process";
import { useEffect, useMemo } from "react";

interface Props {
  show: boolean;
  close: () => void;
  handleCount: (x: number) => void;
}

const Notifications: React.FC<Props> = ({ show, close, handleCount }) => {
  const { openToast } = useToast();

  // API Request Hooks
  const {
    run: runFetch,
    data: fetchResponse,
    requestStatus: fetchStatus,
    error: fetchError,
  } = useApiRequest({});
  const {
    run: runClearAll,
    data: clearAllResponse,
    requestStatus: clearAllStatus,
    error: clearAllError,
  } = useApiRequest({});
  const {
    run: runReadAll,
    data: readAllResponse,
    requestStatus: readAllStatus,
    error: readAllError,
  } = useApiRequest({});
  const {
    run: runClear,
    data: clearResponse,
    requestStatus: clearStatus,
    error: clearError,
  } = useApiRequest({});
  const {
    run: runRead,
    data: readResponse,
    requestStatus: readStatus,
    error: readError,
  } = useApiRequest({});

  const fetchNotifs = () => runFetch(fetchNotifsService());

  useEffect(() => {
    fetchNotifs();
  }, []);

  const notifications = useMemo<NotificationItemData[]>(() => {
    if (fetchResponse?.status === 200) {
      handleCount(fetchResponse.data.data.count);
      return fetchResponse.data.data.notifications.map((item) => ({
        text: item.content,
        title: item.title.replaceAll("_", " "),
        time: `${getTime(getDaysBetween(item.created_at))}`,
        isRead: item.read,
        id: item.id,
        assessment: item.assessment?.id ?? "",
        assessmentTitle: item.assessment?.title?.replaceAll("&", "and").replaceAll(" ", "_") ?? "",
      }));
    } else if (fetchError) {
      openToast({
        show: true,
        ...getError({
          error: fetchError,
          heading: "Sorry",
          text: "Unable to fetch notifications, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [fetchResponse, fetchError]);

  const handleClearAll = () => {
    runClearAll(clearAllNotifsService());
  };

  const handleReadAll = () => {
    runReadAll(readAllNotifsService());
  };

  const handleClear = (id) => {
    runClear(clearNotifService(id));
  };

  const handleRead = (id) => {
    runRead(readNotifService(id));
  };

  useMemo(() => {
    if (clearAllResponse?.status === 200) {
      fetchNotifs();
    } else if (clearAllError) {
      openToast({
        show: true,
        ...getError({
          error: clearAllError,
          heading: "Sorry",
          text: "Unable to clear all notifications, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [clearAllResponse, clearAllError]);

  useMemo(() => {
    if (readAllResponse?.status === 200) {
      fetchNotifs();
    } else if (readAllError) {
      openToast({
        show: true,
        ...getError({
          error: readAllError,
          heading: "Sorry",
          text: "Unable to mark all notifications as read, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [readAllResponse, readAllError]);

  useMemo(() => {
    if (clearResponse?.status === 200) {
      fetchNotifs();
    } else if (clearError) {
      openToast({
        show: true,
        ...getError({
          error: clearError,
          heading: "Sorry",
          text: "Unable to clear notification, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [clearResponse, clearError]);

  useMemo(() => {
    if (readResponse?.status === 200) {
      fetchNotifs();
    } else if (readError) {
      openToast({
        show: true,
        ...getError({
          error: readError,
          heading: "Sorry",
          text: "Unable to mark notification as read, please try again later",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [readResponse, readError]);

  const showLoader =
    (show && fetchStatus.isPending) ||
    clearAllStatus.isPending ||
    clearStatus.isPending ||
    readAllStatus.isPending ||
    readStatus.isPending;

  return (
    <>
      <Loader loading={!show && fetchStatus.isPending} />
      <NotificationModal
        show={show}
        close={close}
        notifications={notifications}
        handleClear={handleClear}
        handleClearAll={handleClearAll}
        handleRead={handleRead}
        handleReadAll={handleReadAll}
        isLoading={showLoader}
      />
    </>
  );
};

const getDaysBetween = (date) => {
  const today = new Date();
  const other = new Date(date);

  // To calculate the time difference of two dates in milliseconds
  const timeDiff = other.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  const seconds = Math.floor(Math.abs(timeDiff) / 1000);
  const minutes = Math.floor(Math.abs(timeDiff) / (1000 * 60));
  const hours = Math.floor(Math.abs(timeDiff) / (1000 * 60 * 60));
  const days = Math.floor(Math.abs(timeDiff) / (1000 * 60 * 60 * 24));

  return { days, hours, minutes, seconds };
};

const getTime = ({ days, hours, minutes, seconds }) => {
  const formatTime = (time) => String(time).padStart(2, "0");
  const formattedTime =
    days > 1
      ? formatTime(days)
      : hours > 1
      ? formatTime(hours)
      : formatTime(minutes);

  const unit =
    days > 1
      ? "days"
      : hours > 1
      ? "hours"
      : minutes > 1 || minutes === 0
      ? "minutes"
      : "minute";

  return `${formattedTime} ${unit} ago`;
};
export { Notifications };
