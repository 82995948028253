import * as React from "react";
import styles from "./styles.module.scss";
import Select, { MenuPlacement } from "react-select";
import { OptionType } from "./util";
import { ChevronRightIcon } from "assets";
import { useClickOutside } from "hooks";

interface CustomSelectProps {
  parentClassName?: string;
  inputClass?: string;
  dataTestID?: string;
  onChange: (e) => void;
  validatorMessage: string;
  name: string;
  placeholder: string;
  label?: string;
  id?: string;
  options: OptionType[];
  value: OptionType;
  isMulti?: boolean;
  disabled?: boolean;
  showRequired?: boolean;
  menuPlacement?: MenuPlacement;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  dataTestID,
  placeholder,
  parentClassName,
  name,
  validatorMessage,
  onChange,
  inputClass,
  label,
  id,
  options,
  value,
  isMulti,
  disabled,
  showRequired,
  menuPlacement,
}) => {
  const hasError = validatorMessage && (!value.value || value.value === "");
  return (
    <div
      className={`${styles.inputWrapper} ${parentClassName} ${
        hasError ? styles.error : ""
      } ${disabled ? styles.disabled: ""}`}
    >
      {label && (
        <label className={styles.label}>
          {label} {showRequired ? <span className={styles.req}>*</span> : ""}
        </label>
      )}
      <Select
        menuPlacement={menuPlacement}
        onChange={(x) => onChange(x)}
        placeholder={placeholder}
        data-testid={dataTestID}
        className={`${inputClass} ${styles.select}`}
        classNamePrefix="formSelect"
        id={id}
        name={name}
        options={options}
        value={value.value ? value : null}
        isMulti={isMulti}
        isDisabled={disabled}
      />
      {hasError && <small className={styles.message}>{validatorMessage}</small>}
    </div>
  );
};

interface CustomMultiSelectProps {
  parentClassName?: string;
  inputClass?: string;
  dataTestID?: string;
  onChange: (val: OptionType[]) => void;
  validatorMessage: string;
  name: string;
  placeholder: string;
  label?: string;
  id?: string;
  options: OptionType[];
  value: OptionType[];
  max?: number;
}

const CustomMultiSelect: React.FC<CustomMultiSelectProps> = ({
  dataTestID,
  placeholder,
  parentClassName,
  name,
  validatorMessage,
  onChange,
  inputClass,
  label,
  id,
  options,
  value,
  max,
}) => {
  const [show, setList] = React.useState(false);

  const dropdownRef = React.useRef(null);
  useClickOutside(dropdownRef, () => setList(false));

  const isChecked = (option: OptionType) => {
    return Array.isArray(value) &&
      value.find((item) => item.value === option.value)?.value
      ? true
      : false;
  };

  const handleAdd = (val: OptionType) => {
    const index = value.findIndex((object) => {
      return object.value === val.value;
    });

    if (index === -1) {
      const prevList = [...value];
      onChange([val, ...prevList]);
    } else {
      handleRemove(val);
    }
  };

  const handleRemove = (val) => {
    const index = value.findIndex((object) => {
      return object.value === val.value;
    });
    const prevList = [...value];
    prevList.splice(index, 1);
    onChange([...prevList]);
  };

  return (
    <div
      ref={dropdownRef}
      id={id}
      className={`${styles.inputWrapper} ${
        styles.multiSelectWrapper
      } ${parentClassName} ${
        validatorMessage && value.length === 0 ? styles.error : ""
      }`}
    >
      {label && <label className={styles.label}>{label}</label>}
      <div
        onClick={() => setList((prev) => !prev)}
        className={`${inputClass} ${styles.multiSelect}`}
      >
        <p>{placeholder}</p>
        <ChevronRightIcon role="button" />
      </div>
      {show ? (
        <div className={styles.optionsList}>
          {options.length > 0 ? (
            options.map((item, index) => (
              <label
                className={`${styles.checkParent} ${
                  max && value.length >= max && !isChecked(item)
                    ? styles.disable
                    : ""
                }`}
                htmlFor={item.value}
              >
                <input
                  onChange={() =>
                    max && value.length >= max && !isChecked(item)
                      ? {}
                      : handleAdd(item)
                  }
                  checked={isChecked(item)}
                  id={item.value}
                  type="checkbox"
                />
                <span>{item.label}</span>
              </label>
            ))
          ) : (
            <div className={styles.optionsList__noOptions}>No options</div>
          )}
        </div>
      ) : (
        ""
      )}
      {validatorMessage && (
        <small className={styles.message}>{validatorMessage}</small>
      )}
    </div>
  );
};

export { CustomSelect, CustomMultiSelect };
export * from "./util";
