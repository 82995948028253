import { changePasswordService } from "api";
import { ChangePasswordModal, Loader } from "components";
import { useToast, useApiRequest } from "hooks";
import { useMemo } from "react";

interface Props {
  show: boolean;
  close: () => void;
}

const ChangePassword: React.FC<Props> = ({ show, close }) => {
  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const submit = (data) => {
    run(
      changePasswordService({
        old_password: data.oldPassword,
        new_password: data.password,
      })
    );
  };

  useMemo(() => {
    if (response?.status === 200) {
      close();
      openToast({
        show: true,
        heading: "Password updated successfully!",
        text: "",
        type: "success",
        timeOut: 2000,
      });
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to change password, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <ChangePasswordModal show={show} close={close} submit={submit} />
    </>
  );
};

export { ChangePassword };
