import { CandidatesUI } from "features";

const Candidates = () => {
  return (
    <>
      <CandidatesUI />
    </>
  );
};
export { Candidates };
