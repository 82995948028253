import { OptionType } from "components";

export const QualityOptions: OptionType[] = [
  {
    label: "Principled",
    value: "principled",
  },
  {
    label: "Supportive",
    value: "supportive",
  },
  {
    label: "Competitive",
    value: "competitive",
  },
  {
    label: "Idealistic",
    value: "idealistic",
  },
  {
    label: "Innovative",
    value: "innovative",
  },
  {
    label: "Responsible",
    value: "responsible",
  },
  {
    label: "Adventurous",
    value: "adventurous",
  },
  {
    label: "Assertive",
    value: "assertive",
  },
  {
    label: "Adaptive",
    value: "adaptive",
  },
];

export const TraitOptions: OptionType[] = [
  {
    label: "I am a perfectionist",
    value: "principled",
  },
  {
    label: "I like to please people",
    value: "supportive",
  },
  {
    label: "I care about what people think of me",
    value: "competitive",
  },
  {
    label: "I don’t take nonsense",
    value: "idealistic",
  },
  {
    label: "I am a private person",
    value: "innovative",
  },
  {
    label: "I get worried easily",
    value: "responsible",
  },
  {
    label: "I live life the way I want",
    value: "adventurous",
  },
  {
    label: "I can be aggressive",
    value: "assertive",
  },

  {
    label: "I get stressed easily",
    value: "adaptive",
  },
];