import {
  createAssessmentMockup,
  reportMockup1,
  reportMockup2,
  reportsMockup,
} from "assets";
import styles from "./styles.module.scss";

const RightFit = () => {
  return (
    <>
      <section className={styles.rightFitBg}>
        <div className={`container ${styles.rightFit}`}>
          <h3
            data-aos-duration="800"
            data-aos="slide-up"
            className={styles.rightFit__ttl}
          >
            <span>Precision</span> enables businesses and teams easily find the
            right fit.
          </h3>

          <div className={styles.benefits}>
            <div data-aos="slide-up" className={styles.benefit}>
              <div className={styles.benefit__content}>
                {/* <p className={styles.benefit__tag}>Title</p> */}
                <p className={styles.benefit__ttl}>
                  Filter High Quality Talents
                </p>
                <p className={styles.benefit__txt}>
                  No more relying solely on inflated CVs or polished LinkedIn
                  profiles. Precision Match provides a 360-degree view of each
                  candidate's true technical skills, work ethic, career values,
                  and cultural fit.
                </p>
              </div>
              <img
                className={styles.benefit__img}
                src={createAssessmentMockup}
                alt=""
              />
            </div>
            <div data-aos="slide-up" className={styles.benefit}>
              <div className={styles.benefit__content}>
                {/* <p className={styles.benefit__tag}>Title</p> */}
                <p className={styles.benefit__ttl}>
                  Trust the Data, Not Your Gut
                </p>
                <p className={styles.benefit__txt}>
                  Ask better questions during interviews. Gain a clearer
                  perspective and increase alignment with your culture and
                  productivity expectations.
                </p>
              </div>
              <img className={styles.benefit__img} src={reportMockup1} alt="" />
            </div>
            <div data-aos="slide-up" className={styles.benefit}>
              <div className={styles.benefit__content}>
                {/* <p className={styles.benefit__tag}>Title</p> */}
                <p className={styles.benefit__ttl}>
                  Actionable Competence Insights
                </p>
                <p className={styles.benefit__txt}>
                  Understand candidates' alignment with your objectives. Our
                  platform offers a better way to identify top talent,
                  eliminating time-consuming reviews and limited interviews.
                </p>
              </div>
              <img className={styles.benefit__img} src={reportMockup2} alt="" />
            </div>
            <div data-aos="slide-up" className={styles.benefit}>
              <div className={styles.benefit__content}>
                {/* <p className={styles.benefit__tag}>Title</p> */}
                <p className={styles.benefit__ttl}>Cut Through the Clutter</p>
                <p className={styles.benefit__txt}>
                  Leverage people intelligence for informed decisions. Avoid
                  hiring candidates who talk the talk but don't walk the walk,
                  or those with exaggerated competencies. Say goodbye to talent
                  misalignment.
                </p>
              </div>
              <img className={styles.benefit__img} src={reportsMockup} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { RightFit };
