import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, InputScale } from "components";

interface WorkStyleData {
  productive: {
    liberty?: number | undefined;
    inCharge?: number | undefined;
    detail?: number | undefined;
    communicate?: number | undefined;
  };
  priority: {
    importance?: number | undefined;
    reason?: number | undefined;
    method?: number | undefined;
    stakeholders?: number | undefined;
  };
}

const initData: WorkStyleData = {
  productive: {
    liberty: 0,
    inCharge: 0,
    detail: 0,
    communicate: 0,
  },
  priority: {
    importance: 0,
    reason: 0,
    method: 0,
    stakeholders: 0,
  },
};

const schema = yup.object().shape({
  productive: yup.object({
    liberty: yup.number(),
    inCharge: yup.number(),
    detail: yup.number(),
    communicate: yup.number(),
  }),
  priority: yup.object({
    importance: yup.number(),
    reason: yup.number(),
    method: yup.number(),
    stakeholders: yup.number(),
  }),
});
interface PreviewWorkStyleProps {
  submit: (data: WorkStyleData) => void;
}
const PreviewWorkStyleUI: React.FC<PreviewWorkStyleProps> = ({ submit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WorkStyleData>({
    resolver: yupResolver(schema),
    defaultValues: initData,
  });

  const onSubmit: SubmitHandler<WorkStyleData> = (data) => submit(data);

  return (
    <>
      <form>
        <section className={styles.questionWrap}>
          <p className={styles.question}>
            In the following statements, rate how you are most productive. You
            are most productive when:
          </p>
          <InputScale
            register={register}
            name={"productive.liberty"}
            validatorMessage={errors.productive?.liberty?.message}
            label="You know exactly what needs to be done and you’re given the liberty to get work done without excessive interruptions."
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"productive.inCharge"}
            validatorMessage={errors.productive?.inCharge?.message}
            label="You are in charge of generating the ideas and strategies, painting a picture of the possible impact an idea might have. "
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"productive.detail"}
            validatorMessage={errors.productive?.detail?.message}
            label="You can detail all aspects of work to be done and meticulously implement
            "
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"productive.communicate"}
            validatorMessage={errors.productive?.communicate?.message}
            label="You have to communicate with, meet people and strike conversations or foster a collaborative environment for people to get work done.
            "
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
        </section>
        <section className={styles.questionWrap}>
          <p className={styles.question}>
            Which of these questions are likely to come first to your mind when
            you get a project brief?
            <span>
              Rank on a scale from most likely to ask to the least likely to ask
            </span>
          </p>
          <InputScale
            register={register}
            name={"priority.importance"}
            validatorMessage={errors.priority?.importance?.message}
            label="What is important to get done?"
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"priority.reason"}
            validatorMessage={errors.priority?.reason?.message}
            label="Why are we using this approach?"
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"priority.method"}
            validatorMessage={errors.priority?.method?.message}
            label="How are we going to get this done?"
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
          <InputScale
            register={register}
            name={"priority.stakeholders"}
            validatorMessage={errors.priority?.stakeholders?.message}
            label="Who are the stakeholders?"
            length={7}
            startAt={6}
            leftMost="Most likely"
            rightMost="Least likely"
            parentClassName={styles.scaleWrap}
          />
        </section>
        <Button
          className={styles.nextBtn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </Button>
      </form>
    </>
  );
};

export { PreviewWorkStyleUI };
