import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Routes } from "router";

/**
 * UNPROTECTED ROUTE COMPONENT
 *
 * ===============================================
 *
 * This component houses all routes and their respective layouts.
 * To add a route navigate to the route builder and add to the existing list.
 *
 *
 */

const UnprotectedRoute: React.FC<{ children: any }> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const rememberMe =
      localStorage.getItem("precisionRememberMe") &&
      localStorage.getItem("precisionAccess") &&
      localStorage.getItem("precisionRefresh");

    const isCandidatePage = pathname.startsWith(`/assessment`);
    const isPublicReport = pathname.startsWith(`/report`);

    if (rememberMe && !isCandidatePage && !isPublicReport)
      navigate(Routes.employerDashbord);
  }, [pathname]);

  return <>{children}</>;
};

export { UnprotectedRoute };
