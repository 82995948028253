import { useEffect } from "react";
import { SideBar } from "./sidebar";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { updatePreference } from "state";
import { Support } from "../support";

export type tabs =
  | "dashboard"
  | "dssessment"
  | "settings"
  | "billings"
  | "report";

interface LayoutProps {
  children: any;
  active: tabs;
}

const EmployerDashboardLayout: React.FC<LayoutProps> = ({
  children,
  active,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const currency = localStorage.getItem("preferred_currency") ?? "USD";

    dispatch(updatePreference({ currency }));
  }, []);
  return (
    <>
      <SideBar active={active} />
      <Support />
      <main className={styles.main}>{children}</main>
    </>
  );
};

export { EmployerDashboardLayout };
export * from "./navbar";
