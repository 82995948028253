import { DoughnutChart, EmptyTable, ScoreBar } from "components";
import styles from "./styles.module.scss";
import {
  CloseIconCircleFIll,
  TickIconCircleFill,
  emptyAssessmentsImg,
} from "assets";
import {
  EmploymentTypeOptions,
  ExperienceEnvOptions,
  ExperienceLevelOptions,
  ExperienceYearOptions,
} from "utils/options";
import { MetricHeading, MetricOverview } from "../components";

interface ExpectationResultData {
  parameter: string;
  metric: string;
  response: string;
  result: string;
}

interface ResultData {
  employer: string;
  candidate: string;
  isMatch: boolean;
}

export interface BasicExpectationResultData {
  experienceLevel: ResultData;
  experienceYears: ResultData;
  employmentPref: ResultData;
  salary: ResultData;
  experienceEnv: ResultData;
}

export interface BasicExpectationProps {
  isFullReport: boolean;
  result: BasicExpectationResultData | undefined;
  score: number | undefined;
}

const BasicExpectation: React.FC<BasicExpectationProps> = ({
  isFullReport,
  result,
  score,
}) => {
  if (!result || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );
  const expectation: ExpectationResultData[] = [
    {
      parameter: "Experience level",
      metric:
        ExperienceLevelOptions.find(
          (item) => item.value === result.experienceLevel.employer
        )?.label ?? "",
      response:
        ExperienceLevelOptions.find(
          (item) => item.value === result.experienceLevel.candidate
        )?.label ?? "",
      result: result.experienceLevel.isMatch ? "Match" : "Conflict",
    },
    {
      parameter: "Years of experience",
      metric:
        ExperienceYearOptions.find(
          (item) => item.value === result.experienceYears.employer
        )?.label ?? "",
      response:
        ExperienceYearOptions.find(
          (item) => item.value === result.experienceYears.candidate
        )?.label ?? "",

      result: result.experienceYears.isMatch ? "Match" : "Conflict",
    },
    {
      parameter: "Employment Preference",
      metric:
        EmploymentTypeOptions.find(
          (item) => item.value === result.employmentPref.employer
        )?.label ?? "",
      response:
        EmploymentTypeOptions.find(
          (item) => item.value === result.employmentPref.candidate
        )?.label ?? "",
      result: result.employmentPref.isMatch ? "Match" : "Conflict",
    },
    {
      parameter: "Salary Expectation",
      metric: result.salary.employer,
      response: result.salary.candidate,
      result: result.salary.isMatch ? "Match" : "Conflict",
    },
  ];

  if (
    result.experienceEnv.employer &&
    result.experienceEnv.employer !== "not_important"
  ) {
    expectation.push({
      parameter: "Experience Environment",
      metric:
        ExperienceEnvOptions.find(
          (item) => item.value === result.experienceEnv.employer
        )?.label ?? "",
      response:
        ExperienceEnvOptions.find(
          (item) => item.value === result.experienceEnv.candidate
        )?.label ?? "",
      result: result.experienceEnv.isMatch ? "Match" : "Conflict",
    });
  }

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Basic Expectations" /> : ""}
        <section className={styles.container}>
          <MetricOverview
            description={
              "Depicts the alignment between the Individuals' salary expectations and prior experience with the overall role objectives."
            }
            score={score}
          />
          <div className={styles.table}>
            <div className={styles.table__heading}>
              <p></p>
              <p>Expectation Metric</p>
              <p>Candidate’s Response</p>
              <p>Result</p>
            </div>
            {expectation.map((item, index) => (
              <div className={styles.table__body}>
                <p>{item.parameter}</p>
                <p>{item.metric}</p>
                <p>{item.response}</p>
                <p
                  className={`${styles.result} ${
                    styles[item.result.toLowerCase()]
                  }`}
                >
                  {item.result}{" "}
                  {item.result.toLowerCase() === "match" ? (
                    <TickIconCircleFill />
                  ) : (
                    <CloseIconCircleFIll />
                  )}
                </p>
              </div>
            ))}
          </div>
        </section>
      </section>
    </>
  );
};

export { BasicExpectation };
