import {
  DropdownMenu,
  DropdownMenuOption,
  Pagination,
  PaginationProps,
  TableBody,
  TableBodyRow,
} from "components/";
import styles from "./styles.module.scss";
import { PeopleIcon } from "assets";

export interface ReportTableItem {
  id: string;
  title: string;
  start: { date: string; time: string };
  deadline: string;
  total: number;
  completed: number;
  abandoned: number;
  status: "active" | "scheduled" | "closed " | "paused";
}

interface TableBodyProps {
  tableBodyItems: ReportTableItem[];
  handleView: ({ id, role }) => void;
  tableBodyItemClassName: string;
  tableBodyRowClassName: string;
  pagination?: PaginationProps;
  handleInvite: (id: string, status: string) => void;
}

const ReportTable: React.FC<TableBodyProps> = ({
  tableBodyItemClassName,
  tableBodyItems,
  handleView,
  tableBodyRowClassName,
  pagination,
  handleInvite,
}) => {
  return (
    <>
      <TableBody customClassName={`${styles.tableBody}`}>
        {tableBodyItems.map((item, index) => {
          const options: DropdownMenuOption[] = [
            {
              text: (
                <>
                  <PeopleIcon /> Invite candidates
                </>
              ),
              onClick: () => handleInvite(item.id, item.status),
              key: "invite",
            },
          ];

          return (
            <TableBodyRow
              key={`body ${index}`}
              style={{ zIndex: tableBodyItems.length - index }}
              customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
            >
              <span
                role="button"
                onClick={() => handleView({ id: item.id, role: item.title })}
                className={`${tableBodyItemClassName} ${styles.title}`}
              >
                <p className={styles.label}>Assessment Title</p>
                {item.title}
              </span>
              <span className={`${tableBodyItemClassName} ${styles.date}`}>
                <p className={styles.label}>Start Date</p>
                <div className={styles.dateBreakdown}>
                  <span>{item.start.date} </span>
                  <span>{item.start.time}</span>
                </div>
              </span>
              <span
                className={`${tableBodyItemClassName} ${styles.statusWrap}`}
              >
                <p className={`${styles.status} ${styles[item.status]}`}>
                  {item.status}
                </p>
              </span>
              <span className={`${tableBodyItemClassName}`}>
                <p className={styles.label}>Total Candidates</p>
                {item.total}
              </span>
              <span className={`${tableBodyItemClassName}`}>
                <p className={styles.label}>Completed</p>
                {item.completed}
              </span>
              <span
                className={`${tableBodyItemClassName} ${styles.rightAligned}`}
              >
                <p className={styles.label}>Abandoned</p>
                {item.abandoned}
              </span>
              <span
                className={`${tableBodyItemClassName} ${styles.deadlineWrap}`}
              >
                <p className={`${styles.deadline} ${styles[item.status]}`}>
                  <p className={styles.label}>Deadline</p>
                  {item.deadline}
                </p>
              </span>
              <span className={`${tableBodyItemClassName} ${styles.menuSec}`}>
                <DropdownMenu options={options} />
                <div className={styles.mobileMenu}>
                  <button onClick={() => handleInvite(item.id, item.status)}>
                    <PeopleIcon />
                    Invite candidates
                  </button>
                </div>
              </span>
            </TableBodyRow>
          );
        })}
      </TableBody>
      {pagination ? (
        <Pagination {...pagination} className={styles.pagination} />
      ) : (
        ""
      )}
    </>
  );
};

export { ReportTable };
