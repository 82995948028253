import {
  fetchAssessmentSoftSkillService,
  fetchCandidateSoftSkillService,
} from "api";
import { Loader } from "components";
import { PreviewSoftSkillsUI } from "features";
import { useApiRequest, useToast } from "hooks";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

interface AssessmentSoftSkillsProps {
  callback: (metric: string) => void;
}

const AssessmentSoftSkills: React.FC<AssessmentSoftSkillsProps> = ({
  callback,
}) => {
  // Hooks
  const { openToast } = useToast();
  const { id } = useParams();

  // API Request Hooks
  const {
    run: runFetch,
    data: fetchResponse,
    requestStatus: fetchStatus,
    error: fetchError,
  } = useApiRequest({});

  const fetchExpectations = (id) => {
    runFetch(fetchAssessmentSoftSkillService(id));
  };

  useEffect(() => {
    if (id) fetchExpectations(id);
  }, [id]);

  const submit = (data) => {
    const answered = parseInt(localStorage.getItem("answered") ?? "0");
    const leadershipCount = skills.find(
      (item) => item === "leadership_and_management"
    )
      ? 3
      : 2;

    const totalCount = answered + 8 + leadershipCount;
    localStorage.setItem("answered", `${totalCount}`);

    callback("soft_skill");
  };

  const skills = useMemo<string[]>(() => {
    if (fetchResponse?.status === 200) {
      const data = fetchResponse.data.data;

      const skill = [
        data.skill1,
        data.skill2,
        data.skill3,
        data.skill4,
        data.skill5,
      ];
      return skill;
    } else if (fetchError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          fetchError?.response?.data?.message ??
          "Failed to fetch expectations, please contact support",
        type: "error",
        timeOut: 5000,
      });
    }
    return [];
  }, [fetchResponse, fetchError]);

  return (
    <>
      <Loader loading={fetchStatus.isPending} />
      <PreviewSoftSkillsUI skills={skills} submit={submit} />
    </>
  );
};

export { AssessmentSoftSkills };
