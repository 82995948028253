import { resendVerificationService, verifyEmailService } from "api";
import { Loader } from "components";
import { EmailVerificationUI } from "features";
import { queryObject } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const EmailVerification = () => {
  const [success, setSuccess] = useState(false);

  // Hooks
  const { openToast } = useToast();
  const { search } = useLocation();

  const { email, token } = queryObject(search);

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});
  const {
    run: runResend,
    data: resendResponse,
    requestStatus: resendStatus,
    error: resendError,
  } = useApiRequest({});

  const handleVerification = () =>
    email && token && run(verifyEmailService({ email, token }));

  useEffect(() => {
    handleVerification();
  }, []);

  useMemo(() => {
    if (response?.status === 200) {
      setSuccess(true);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to verify your email address, please try again later and contact support if the error persists.",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const handleResend = () => runResend(resendVerificationService(email));

  useMemo(() => {
    if (resendResponse?.status === 200) {
      openToast({
        show: true,
        heading: `We have resent the verification mail to ${email}.`,
        text: "The email may take a few minutes to arrive. You may close this tab",
        type: "success",
        timeOut: 2000,
      });
    } else if (resendError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          resendError?.response?.data?.error?.message ??
          "Failed to resend the verification mail, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [resendResponse, resendError]);

  const showLoader = requestStatus.isPending || resendStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      {!requestStatus.isPending ? (
        <EmailVerificationUI handleResend={handleResend} success={success} />
      ) : (
        ""
      )}
    </>
  );
};

export { EmailVerification };
