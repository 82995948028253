import { authImg, logoWithText } from "assets";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Routes } from "router";

const AuthLayout = ({ children }) => {
  return (
    <>
      <main className={styles.container}>
        <div className={styles.body}>
          <Link to={Routes.home}>
            <img
              className={styles.logo}
              src={logoWithText}
              alt="precision logo"
            />
          </Link>
          {children}
        </div>
        <div className={styles.imgSec}>
          {/* <img className={styles.logo} src={logoWithText} alt="precision logo" /> */}
          <img className={styles.img} src={authImg} alt="" />
        </div>
      </main>
    </>
  );
};

export { AuthLayout };
