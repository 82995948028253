import { ContactUsRequestData, contactUsService } from "api/services/contact";
import { Loader } from "components";
import { ContactUI } from "features";
import { useToast, useApiRequest } from "hooks";
import { useMemo, useState } from "react";

const Contact = () => {
  const [clear, setClear] = useState(false);

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const submit = (data: ContactUsRequestData) => {
    run(contactUsService(data));
  };

  useMemo(() => {
    if (response?.status === 200) {
      openToast({
        show: true,
        heading: "Message sent!",
        text:
          response?.data?.message ??
          "We hae received your message and a representative will be in touch with you soon.",
        type: "success",
        timeOut: 5000,
      });
      setClear((prev) => !prev);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to send message, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <ContactUI contactForm={{ submit, clear }} />
    </>
  );
};
export { Contact };
