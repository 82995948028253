/*
=================================
EMPLOYER ASSESSMENT URLS
=================================
*/

import { appendParams } from "helpers";

/**
 * Start assessment url
 *
 * @returns url string
 *
 */

export const startAssessmentURL = () => `/assessment/assessments/`;

/**
 * Create basic expectation url
 *
 * @returns url string
 *
 */

export const createExpectationURL = () => `/assessment/basic-expectations/`;

/**
 * Create work culture url
 *
 * @returns url string
 *
 */

export const createWorkCultureURL = () =>
  `/assessment/work-culture-expectations/`;

/**
 * Create personality url
 *
 * @returns url string
 *
 */

export const createPersonalityURL = () =>
  `/assessment/personality-expectations/`;

/**
 * Create soft skills url
 *
 * @returns url string
 *
 */

export const createSoftSkillsURL = () =>
  `/assessment/soft-skills-expectations/`;

/**
 * Create domain knowledge url
 *
 * @returns url string
 *
 */

export const createDomainKnowledgeURL = () =>
  `/assessment/domain-knowledge-expectations/`;

/**
 * Activate assessment url
 *
 * @returns url string
 *
 */

export const activateAssessmentURL = () => `/assessment/publish/`;

/**
 * Fetch assessments url
 *
 * @returns url string
 *
 */

export const fetchAssessmentsURL = ({
  status,
  page,
  search,
  dateFrom,
  dateTo,
}) =>
  `/assessment/assessments/?${appendParams({
    status,
    page,
    search,
    dateFrom,
    dateTo,
  })}`;

/**
 * Fetch basic expectation url
 *
 * @returns url string
 *
 */

export const fetchExpectationURL = (id) =>
  `/assessment/basic-expectations/?${appendParams({ assessment: id })}`;

/**
 * Fetch work culture url
 *
 * @returns url string
 *
 */

export const fetchWorkCultureURL = (id) =>
  `/assessment/work-culture-expectations/?${appendParams({ assessment: id })}`;

/**
 * Fetch personality url
 *
 * @returns url string
 *
 */

export const fetchPersonalityURL = (id) =>
  `/assessment/personality-expectations/?${appendParams({ assessment: id })}`;

/**
 * Fetch soft skills url
 *
 * @returns url string
 *
 */

export const fetchSoftSkillsURL = (id) =>
  `/assessment/soft-skills-expectations/?${appendParams({ assessment: id })}`;

/**
 * Fetch domain knowledge url
 *
 * @returns url string
 *
 */

export const fetchDomainKnowledgeURL = (id) =>
  `/assessment/domain-knowledge-expectations/?${appendParams({
    assessment: id,
  })}`;

/**
 * Fetch domain knowledge unauth url
 *
 * @returns url string
 *
 */

export const fetchDomainKnowledgeUnauthURL = (id) =>
  `/assessment/domain-knowledge-expectations/noauth?${appendParams({
    assessment: id,
  })}`;

/**
 * Update assessment url url
 *
 * @returns url string
 *
 */

export const updateAssessmentURL = (id) => `/assessment/assessments/${id}/`;

/**
 * Fetch assessment info url
 *
 * @returns url string
 *
 */

export const fetchAssessmentInfoURL = (id) => `/assessment/assessments/${id}/`;

/**
 * Fetch assessment soft skill expectation url
 *
 * @returns url string
 *
 */

export const fetchAssessmentSoftSkillURL = (id) =>
  `/assessment/soft-skills-expectations/?${appendParams({ assessment: id })}`;

/**
 * Fetch assessment domain knowledge expectation url
 *
 * @returns url string
 *
 */

export const fetchAssessmentDomainKnowledgeURL = (id) =>
  `/assessment/domain-knowledge-expectations/?${appendParams({
    assessment: id,
  })}`;

/**
 * Fetch assessment activity url
 *
 * @returns url string
 *
 */

export const fetchAssessmentActivityURL = () =>
  `/assessment/dashboard/assessment-activity/`;

/**
 * Invite candidates url
 *
 * @returns url string
 *
 */

export const inviteCandidatesURL = (id) => `/assessment/invite/one/${id}/`;

/**
 * Invite candidates via csv upload url
 *
 * @returns url string
 *
 */

export const inviteCandidatesCSVURL = (id) => `/assessment/invite/bulk/${id}/`;

/**
 * Extend assessment deadline url
 *
 * @returns url string
 *
 */

export const extendDeadlineURL = () => `/assessment/extend-deadline/`;
