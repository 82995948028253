import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import {
  ArrowRightIcon,
  CloseIcon,
  TickIcon,
  TrashIcon,
  emptyAssessmentsImg,
  emptyReport2,
} from "assets";
import { Spinner } from "components";
import { Link } from "react-router-dom";
import { Routes } from "router";

interface NotificationModalProps {
  show: boolean;
  close: () => void;
  notifications: NotificationItemData[];
  handleClearAll: () => void;
  handleReadAll: () => void;
  handleClear: (id: string) => void;
  handleRead: (id: string) => void;
  isLoading: boolean;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  show,
  close,
  notifications,
  handleClear,
  handleClearAll,
  handleRead,
  handleReadAll,
  isLoading,
}) => {
  return (
    <>
      <Modal
        contentClassName={styles.modal}
        show={show}
        close={close}
        position="right"
      >
        <div className={styles.heading}>
          <h1>Notifications</h1> <CloseIcon role="button" onClick={close} />
        </div>
        {isLoading ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : notifications.length === 0 ? (
          <div className={styles.empty}>
            <img src={emptyReport2} />
            <p>You don't have any notifications</p>
          </div>
        ) : (
          <>
            <section className={styles.notificationList}>
              {notifications.map((item) => (
                <NotificationItem
                  {...item}
                  handleClear={handleClear}
                  handleRead={handleRead}
                />
              ))}
            </section>
            <footer className={styles.footer}>
              <button onClick={handleClearAll} className={styles.footer__clear}>
                <TrashIcon />
                Clear all
              </button>
              <button onClick={handleReadAll} className={styles.footer__read}>
                <TickIcon />
                Mark all as read
              </button>
            </footer>
          </>
        )}
      </Modal>
    </>
  );
};

export interface NotificationItemData {
  title: string;
  text: string;
  time: string;
  isRead: boolean;
  id: string;
  assessment: string;
  assessmentTitle: string;
}

interface NotificationItemProps extends NotificationItemData {
  handleRead: (id: string) => void;
  handleClear: (id: string) => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  text,
  time,
  title,
  id,
  handleClear,
  handleRead,
  isRead,
  assessment,
  assessmentTitle,
}) => {
  return (
    <div
      className={`${styles.notification} ${
        isRead ? styles["notification--read"] : ""
      }`}
    >
      <p className={styles.notification__ttl}>
        {title}
        <div className={styles.notification__btns}>
          {isRead ? (
            ""
          ) : (
            <TickIcon
              onClick={() => handleRead(id)}
              role="button"
              className={styles.notification__btns__read}
            />
          )}
          <TrashIcon
            onClick={() => handleClear(id)}
            role="button"
            className={styles.notification__btns__delete}
          />
        </div>
      </p>
      <p className={styles.notification__txt}>{text}</p>
      <div className={styles.notification__footer}>
        <p>{time}</p>
        {assessment && (
          <Link
            onClick={() => {
              if (!isRead) handleRead(id);
            }}
            to={{
              pathname: Routes.employerReportRoleID(assessment),
              search: `role=${assessmentTitle}`,
            }}
            state={{ role: assessmentTitle }}
            target="_blank"
          >
            Show more <ArrowRightIcon />
          </Link>
        )}
      </div>
    </div>
  );
};

export { NotificationModal };
