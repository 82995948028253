export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
    //   let encoded: any = reader.result?.toString().replace(/^data:(.*,)?/, "");
      let encoded: any = reader.result;
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      return resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};
